import React, { FormEvent, useCallback, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { editSelf } from "../api/auth";
import passwordRegex from "../constants/password.regex";
import { RequestStatus } from "../context/types";
import useSelf from "../hooks/useSelf";

import useUserWarnings from "../hooks/useUserWarnings";
import Button from "./Button";
import Dialog from "./Dialog";
import ErrorMessage from "./ErrorMessage";
import TextField from "./field/TextField";
import LoadingSpinner from "./LoadingSpinner";

const schema = yup.object().shape({
  id: yup.string().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required()
    .label("Confirm New Password"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      passwordRegex,
      "Password must contain a mix of lowercase, uppercase, numbers",
    )
    .notRequired()
    .label("New Password"),
});

const RuleUl = styled.ul`
  list-style-type: disc;
  padding-top: 1em;
  padding-left: 2em;
`;

const Warnings: React.FC = () => {
  const warnings = useUserWarnings();
  const self = useSelf();

  if (!self) {
    throw new Error("No self in Warnings.tsx");
  }

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [status, setStatus] = useState<RequestStatus>("NOT_ASKED");

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setError("");

      const variables = {
        id: self.id,
        first_name: self.first_name,
        last_name: self.last_name,
        email: self.email,
        password,
        passwordConfirm,
      };

      try {
        await schema.validate(variables);
      } catch (error) {
        const message =
          error instanceof Error ? error.message : "An unknown error occurred";
        return setError(message);
      }
      try {
        setStatus("LOADING");

        await editSelf(variables);

        setStatus("SUCCESS");
      } catch (error) {
        setError(
          "There was an error updating your password. Please contact an admin.",
        );
        setStatus("FAIL");
      }
    },
    [self, password, passwordConfirm],
  );

  if (!warnings) {
    return null;
  }

  const passwordWarning = warnings.find(
    (warning) => warning.slug === "password-strength",
  );

  if (!passwordWarning) {
    return null;
  }

  const isLoading = status === "LOADING";

  if (status === "SUCCESS") {
    return null;
  }

  return (
    <Dialog
      title="⚠️ Weak Password Warning"
      isOpen={true}
      onRequestClose={() => null}
    >
      <form>
        <legend>
          <p>
            Your current password no longer adheres to our security standards,
            please update it below.
          </p>
          <p>
            Your new password must conform to the following rules:
            <RuleUl>
              <li>Longer than 8 characters</li>
              <li>At least 1 uppercase letter</li>
              <li>At least 1 lowercase letter</li>
              <li>At least 1 number</li>
            </RuleUl>
          </p>
        </legend>
        <br />
        <TextField
          label="New Password"
          name="password"
          value={password}
          onChange={setPassword}
          inputProps={{ type: "password", autoComplete: "new-password" }}
        />
        <TextField
          label="Confirm New Password"
          name="password-confirm"
          value={passwordConfirm}
          onChange={setPasswordConfirm}
          inputProps={{ type: "password", autoComplete: "new-password" }}
        />
        <footer className="mb3 mt4">
          {error && (
            <ErrorMessage as="p" className="mra">
              {error}
            </ErrorMessage>
          )}
          <div className="flex justify-end">
            <Button onClick={onSubmit} disabled={isLoading} fill>
              <span style={{ opacity: isLoading ? 0 : 1 }}>
                Save and Continue
              </span>
              {isLoading && (
                <LoadingSpinner className="absolute" type="inline" small />
              )}
            </Button>
          </div>
        </footer>
      </form>
    </Dialog>
  );
};

export default Warnings;

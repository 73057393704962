import React, { Fragment, ReactNode } from "react";
import useOrganisations from "../hooks/useOrganisations";
import { Link, useParams } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";
import errorService from "../common-lib/error";
import { unknownErrorMessage } from "../constants/errors";
import NoContent from "./NoContent";
import colours from "../constants/colours";
import useLogout from "../hooks/useLogout";

const OrganisationAuth: React.FC<{ children: ReactNode }> = (props) => {
  const { children } = props;
  const { organisationSlug } = useParams<{ organisationSlug: string }>();
  const { organisations, loading } = useOrganisations();
  const logout = useLogout();

  if (!organisationSlug) {
    errorService.error(
      new Error("In OrganisationAuth without organisationSlug"),
    );
    return <ErrorMessage>{unknownErrorMessage}</ErrorMessage>;
  }

  const organisation = organisations.find(
    (org) => org.slug === organisationSlug,
  );

  if (!loading && !organisation) {
    // TODO: handle this error more gracefully
    return (
      <NoContent
        icon="warning"
        message={
          <Fragment>
            Organisation not found, or you are not a member.
            <br />
            <Link style={{ color: colours.blueBg }} to={"/"}>
              Take me home
            </Link>{" "}
            or{" "}
            <button style={{ color: colours.blueBg }} onClick={() => logout()}>
              Log me out
            </button>
          </Fragment>
        }
      />
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default OrganisationAuth;

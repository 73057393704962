import { logout } from "../api/auth";
import { useAppDispatch } from "../context/store";
import { LogoutReason } from "../context/types";
import { useHistory } from "react-router-dom";
import errorService from "../common-lib/error";

interface LogoutPayload {
  reason?: LogoutReason;
}

const useLogout = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  return async (payload: LogoutPayload = {}) => {
    const { reason = "USER_LOGOUT" } = payload;
    dispatch({
      type: "@@session/LOGOUT_START",
      payload: { reason },
    });
    try {
      await logout();

      dispatch({ type: "@@session/LOGOUT_SUCCESS" });
      history.push("/");
    } catch (error) {
      errorService.error(error);

      dispatch({ type: "@@session/LOGOUT_FAIL" });
    }
  };
};

export default useLogout;

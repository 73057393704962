import { graphql } from "../generated";

const FETCH_PROGRAMME = graphql(`
  query FetchProgramme($programmeSlug: String!) {
    programme(where: { slug: { _eq: $programmeSlug } }) {
      id
      name
      slug
      type
      status
      start_date
      end_date
      organisation_programmes {
        organisation {
          id
          name
          slug
        }
      }
      projects {
        id
        name
        slug
      }
      programme_group {
        id
        name
        slug
      }
    }
  }
`);

export default FETCH_PROGRAMME;

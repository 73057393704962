import React, { Fragment, useState, SyntheticEvent, useEffect } from "react";
import { useMutation } from "@apollo/client";
import * as yup from "yup";

import Dialog from "./Dialog";
import TextField from "./field/TextField";
import FlatButton from "./FlatButton";
import slugify from "../helpers/slugify";
import CREATE_LOCATION from "../graphql/queries/create-location";
import LoadingSpinner from "./LoadingSpinner";
import UPDATE_LOCATION from "../graphql/queries/update-location";
import { useHistory } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";
import MultiAutocompleteField from "./field/MultiAutocompleteField";
import useProgrammes from "../hooks/useProgrammes";
import { getAdminLocationPath } from "../helpers/route-path";
import SelectOrganisationField from "./SelectOrganisationField";
import useOrganisations from "../hooks/useOrganisations";
import {
  CreateLocationMutationVariables,
  FetchLocationQuery,
  UpdateLocationMutationVariables,
} from "@/graphql/generated/graphql";
import { DialogActions } from "./DialogActions";

const schema = yup.object().shape({
  slug: yup.string().required().label("URL friendly name"),
  name: yup.string().required().label("Name"),
  organisation_id: yup.string().required().label("Organisation"),
});

type Location = FetchLocationQuery["location"][number];

interface OpenerProps {
  onClick: () => void;
}
interface LocationFormProps {
  Opener: React.FC<OpenerProps>;
  initialValues?: Location;
  onClose: () => void;
}
const LocationForm: React.FC<LocationFormProps> = (props) => {
  const {
    Opener,
    onClose,
    initialValues = {
      id: undefined,
      name: "",
      slug: "",
      organisation_id: "",
      programme_locations: [] as Location["programme_locations"],
    },
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [_name, setName] = useState(initialValues.name);
  const [slug, setSlug] = useState(initialValues.slug);
  const [organisationId, setOrganisationId] = useState(
    initialValues.organisation_id
  );
  const [programmeLocations, setProgrammeLocations] = useState<
    {
      id: string;
      name: string;
    }[]
  >(initialValues.programme_locations.map((pc) => pc.programme));
  const [validationError, setValidationError] = useState("");
  const { programmes } = useProgrammes();

  const resetForm = () => {
    setName(initialValues.name);
    setSlug(initialValues.slug || "");
  };

  useEffect(() => {
    resetForm();
  }, [JSON.stringify(initialValues)]);

  const onRequestClose = () => {
    resetForm();
    setIsOpen(false);
    onClose();
  };
  const history = useHistory();

  const [update, { loading: createLoading, error: createError }] = useMutation(
    UPDATE_LOCATION,
    {
      onCompleted: () => {
        if (slug !== initialValues.slug) {
          history.replace(getAdminLocationPath({ locationSlug: slug }));
        }

        onRequestClose();
      },
    }
  );
  const [create, { loading: updateLoading, error: updateError }] = useMutation(
    CREATE_LOCATION,
    {
      onCompleted: onRequestClose,
    }
  );

  const loading = createLoading || updateLoading;
  const error = createError || updateError;

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    const payload = {
      name: _name,
      slug: slug,
      programme_locations: undefined,
      organisation_id: organisationId,
    };

    try {
      await schema.validate(payload);
    } catch (valiationError) {
      if (!(valiationError instanceof Error)) {
        return Promise.reject(new Error(JSON.stringify(error)));
      }
      return setValidationError(valiationError.message);
    }

    if (initialValues.id) {
      const variables: UpdateLocationMutationVariables = {
        id: initialValues.id,
        location: payload,
        programme_locations: programmeLocations.map((p) => ({
          programme_id: p.id,
          location_id: initialValues.id,
        })),
      };
      update({ variables });
    } else {
      const variables: CreateLocationMutationVariables = {
        location: {
          ...payload,
          programme_locations: {
            data: programmeLocations.map((p) => ({ programme_id: p.id })),
          },
        },
      };

      create({ variables });
    }
  };

  const { organisations } = useOrganisations();

  const onNameBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const organisation = organisations.find((org) => org.id === organisationId);
    const slugPrefix = organisation ? `${organisation.slug}-` : "";

    if (!slug) {
      setSlug(slugify(`${slugPrefix}${e.target.value}`));
    }
  };

  const onSlugBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(slugify(e.target.value));
  };

  return (
    <Fragment>
      <Dialog
        fullHeight
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={"New Location"}
      >
        {loading && <LoadingSpinner type="overlay" />}
        <form onSubmit={onSubmit} autoComplete="off">
          <input className="clip" />
          <input type="password" className="clip" />
          <SelectOrganisationField
            value={organisationId}
            onSelect={setOrganisationId}
            disabled={Boolean(initialValues.id)}
          />
          <TextField
            name="name"
            label="Name"
            value={_name}
            onChange={setName}
            inputProps={{ onBlur: onNameBlur }}
          />
          <TextField
            name="slug"
            label="URL Friendly Name"
            value={slug}
            onChange={setSlug}
            inputProps={{ onBlur: onSlugBlur }}
          />

          <MultiAutocompleteField
            label="Programme"
            name="programme"
            options={programmes.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            selected={programmeLocations.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            onSelect={(options) =>
              setProgrammeLocations(
                options.map(({ value, label }) => ({ id: value, name: label }))
              )
            }
          />
        </form>
        <DialogActions>
          <footer className="flex justify-end mb3 pt3">
            {validationError && (
              <ErrorMessage
                title="Fix these errors and try again"
                className="mra"
              >
                {validationError}
              </ErrorMessage>
            )}
            <FlatButton onClick={onRequestClose}>Cancel</FlatButton>
            <FlatButton onClick={onSubmit}>Save</FlatButton>
          </footer>
        </DialogActions>
      </Dialog>
      <Opener onClick={() => setIsOpen(true)} />
    </Fragment>
  );
};

export default LocationForm;

import { graphql } from "../generated";

const CREATE_PARENT_CHILD = graphql(`
  mutation CreateParentChild($parent_child: parent_child_insert_input!) {
    insert_parent_child(objects: [$parent_child]) {
      returning {
        parent_id
        child_id
      }
    }
  }
`);

export default CREATE_PARENT_CHILD;

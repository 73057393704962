import { graphql } from "../generated";

const CREATE_ORGANISATION_SILOED_USER= graphql(`
  mutation CreateOrganisationSiloedUser(
    $organisation_siloed_user: organisation_siloed_user_insert_input!
  ) {
    insert_organisation_siloed_user(objects: [$organisation_siloed_user]) {
      returning {
        user_id
        organisation_id
      }
    }
  }
`);

export default CREATE_ORGANISATION_SILOED_USER;

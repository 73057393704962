import { graphql } from "../generated";

const UPDATE_PROJECT= graphql(`
  mutation UpdateProject($id: uuid!, $project: project_set_input!) {
    update_project(_set: $project, where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`);

export default UPDATE_PROJECT;

import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import LoadingSpinner from "./LoadingSpinner";
import Button from "./Button";
import UserForm from "./UserForm";
import { getFullName } from "../helpers/user";
import Avatar from "./Avatar";
import Icon from "./Icon";
import useUsers from "../hooks/useUsers";
import { getOrganisationManagerUserPath } from "../helpers/route-path";
import {
  AdminGrid,
  AdminGridCell,
  AdminGridSearch,
  AdminGridHeader,
  AdminGridHeading,
} from "./AdminGrid";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import { Order_By, User_Order_By } from "@/graphql/generated/graphql";
import errorService from "@/common-lib/error";

const defaultOrder = [
  { first_name: Order_By.Asc },
  { last_name: Order_By.Asc },
];

const headings: AdminGridHeading<User_Order_By>[] = [
  {
    label: "Name",
    order: {
      asc: [{ first_name: Order_By.Asc }, { last_name: Order_By.Asc }],
      desc: [{ first_name: Order_By.Desc }, { last_name: Order_By.Desc }],
    },
  },
  {
    label: "Role",
  },
  {
    label: "Email",
    order: {
      asc: [{ email: Order_By.Asc }, ...defaultOrder],
      desc: [{ email: Order_By.Desc }, ...defaultOrder],
    },
  },
];

const ManagerUsers: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState<User_Order_By[]>(defaultOrder);

  const { users, error, loading, refetch } = useUsers({
    searchTerm,
    orderBy,
  });
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (error) {
      errorService.error(error);
    }
  }, [error]);

  const organisation = useSessionOrganisation();

  if (!organisation) {
    return null;
    // return <LoadingSpinner type="splash" text={"Loading Organisation"} />
  }

  return (
    <div>
      <header className="flex justify-between items-center mb3">
        <h1>Users </h1>
        <UserForm
          Opener={(props) => (
            <Button {...props} raised fill>
              New User
            </Button>
          )}
          onClose={refetch}
        />
      </header>
      <div className="flex mb2">
        <AdminGridSearch
          className="w-100 pa2 flex-auto"
          placeholder="Search (name or email)"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(e.target.value)
          }
          value={searchTerm}
        />
      </div>
      <AdminGrid columns={headings.length}>
        <AdminGridHeader
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          headings={headings}
        />
        {users.map((user) => {
          const role =
            user.role === "rti-admin"
              ? "admin"
              : organisation.organisation_managers
                  .map(({ user_id }) => user_id)
                  .includes(user.id)
              ? "user (manager)"
              : "user";

          return (
            <Fragment key={`ManagerUsers-li-${user.id}`}>
              <AdminGridCell>
                <div className="flex items-center">
                  <Avatar person={user} size="small" />
                  <div className="ml2">
                    <Link
                      to={getOrganisationManagerUserPath({
                        organisationSlug: organisation.slug,
                        userId: user.id,
                      })}
                    >
                      {getFullName(user)}
                    </Link>
                  </div>
                </div>
              </AdminGridCell>
              <AdminGridCell>
                <Icon icon="stars" text={role}></Icon>
              </AdminGridCell>
              <AdminGridCell>
                <Icon icon="email" text={user.email}></Icon>
              </AdminGridCell>
            </Fragment>
          );
        })}
      </AdminGrid>
      {loading && <LoadingSpinner type="row" />}
    </div>
  );
};

export default ManagerUsers;

import { useEffect, useState } from "react";
import styles from "./LoadingSplash.module.css";
import Logo from "./Logo";

export default function LoadingSplash(props: { isLoaded: boolean }) {
  const { isLoaded } = props;
  const [hasTransitioned, setHasTransitioned] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      const timer = setTimeout(() => setHasTransitioned(true), 800);

      return () => clearTimeout(timer);
    }
  }, [isLoaded]);

  return (
    <div
      className={[
        styles["rti-loading-splash"],
        isLoaded && styles["rti-loading-splash--loaded"],
        hasTransitioned && styles["rti-loading-splash--hidden"],
      ]
        .filter(Boolean)
        .join(" ")}
    >
      <div
        className={[
          styles["rti-loading-splash__wrapper"],
          isLoaded && styles["rti-loading-splash__wrapper--loaded"],
        ]
          .filter(Boolean)
          .join(" ")}
      >
        {/* <img src="/amna-logo-yellow.png" alt="refugee trauma initiative" /> */}
        <Logo
          className={[styles["rti-loading-splash__logo"], "mb3"].join(" ")}
        />
        <div
          className={[
            styles["rti-loading-splash__spinner"],
            isLoaded && styles["rti-loading-splash__spinner--loaded"],
          ]
            .filter(Boolean)
            .join(" ")}
        >
          <svg
            className={styles["rti-loading-splash__spinner-svg"]}
            viewBox="22 22 44 44"
          >
            <circle
              cx="44"
              cy="44"
              r="20.2"
              fill="none"
              stroke="currentColor"
              strokeWidth="3.6"
              className={styles["rti-loading-splash__spinner-circle"]}
            ></circle>
          </svg>
        </div>
        <div className={styles["rti-loading-splash__loading-text mt3"]}>
          <span className={styles["clip"]}>Loading</span>
        </div>
      </div>
    </div>
  );
}

import React, { ReactNode } from "react";

import LeftPanel from "./LeftPanel";
import {
  getOrganisationManagerUsersPath,
  getOrganisationManagerProjectsPath,
  getOrganisationManagerLocationsPath,
} from "../helpers/route-path";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import useIsManager from "../hooks/useIsManager";

const ManagerAuth: React.FC<{ children: ReactNode }> = (props) => {
  const { children } = props;
  const organisation = useSessionOrganisation();

  const isManager = useIsManager();

  if (!organisation) {
    return null;
    // return <LoadingSpinner type="splash" text={"Loading Organisation"} />
  }

  if (!isManager) {
    return <LeftPanel>You must be an admin to access this page.</LeftPanel>;
  }

  return (
    <LeftPanel
      heading={`${organisation.name}/Manager`}
      links={[
        {
          url: getOrganisationManagerUsersPath({
            organisationSlug: organisation.slug,
          }),
          text: "Users",
          icon: "person",
        },
        {
          url: getOrganisationManagerProjectsPath({
            organisationSlug: organisation.slug,
          }),
          text: "Projects",
          icon: "spa",
        },
        {
          url: getOrganisationManagerLocationsPath({
            organisationSlug: organisation.slug,
          }),
          text: "Locations",
          icon: "home_work",
        },
      ]}
    >
      {children}
    </LeftPanel>
  );
};

export default ManagerAuth;

import { graphql } from "../generated";

const DELETE_PARENT_CHILD= graphql(`
  mutation DeleteParentChild($parent_id: uuid!, $child_id: uuid!) {
    delete_parent_child(
      where: { child_id: { _eq: $child_id }, parent_id: { _eq: $parent_id } }
    ) {
      returning {
        child_id
        parent_id
      }
    }
  }
`);

export default DELETE_PARENT_CHILD;

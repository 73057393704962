import { useState, useEffect } from "react";

import {
  ApolloLink,
  Observable,
  Operation,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloClient,
} from "@apollo/client";

import { ObservableSubscription } from "@apollo/client/utilities/observables/Observable";
import { onError } from "@apollo/client/link/error";
import useGetToken, { GetToken } from "../hooks/useGetToken";
import useLoggedIn from "../hooks/useLoggedIn";
import errorService from "../common-lib/error";
import useSelf from "../hooks/useSelf";
import { User } from "../context/types";

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
});

const cache = new InMemoryCache();

const createErrorLink = (user?: User) =>
  onError((error) => {
    const { graphQLErrors, networkError } = error;

    if (graphQLErrors)
      graphQLErrors.map(({ message, ...extra }) => {
        if (message === "Malformed Authorization header") {
          return;
        }
        if (message === "Could not verify JWT: JWTExpired") {
          return;
        }
        errorService.error(new Error(`[GraphQL error]: Message: ${message}`), {
          user,
          extra,
        });
      });

    if (networkError) {
      const { message, ...extra } = networkError;

      if (networkError.message === "NO_TOKEN") {
        return;
      }
      errorService.error(new Error(`[Network error]: ${message}`), {
        user,
        extra,
      });
    }
  });

const createRequestLink = (getToken: GetToken) =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle: ObservableSubscription;
        Promise.resolve(operation)
          .then(async (operation: Operation) => {
            const { token, impersonation } = await getToken();

            const headers = impersonation
              ? {
                  "X-Hasura-Admin-Secret": impersonation.adminSecret,
                  "X-Hasura-User-Id": impersonation.userId,
                  "X-Hasura-Role": impersonation.role,
                  "x-hasura-allowed-org-ids": `{${impersonation.orgIds.join(
                    ","
                  )}}`,
                }
              : {
                  Authorization: token ? `Bearer ${token}` : null,
                };

            operation.setContext({
              headers,
            });
          })
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) {
            handle.unsubscribe();
          }
        };
      })
  );

const useClient = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const loggedIn = useLoggedIn();
  const user = useSelf();
  const getToken = useGetToken();

  useEffect(() => {
    const safeGetToken = async () => {
      try {
        await getToken();
      } catch (err) {
        console.log(
          "Failed to get token. Sentry has been notified inside getToken()"
        );
      }
    };

    safeGetToken();
  }, []);

  useEffect(() => {
    if (!loggedIn && client) {
      return client.stop();
    }

    const newClient = new ApolloClient({
      link: ApolloLink.from([
        createErrorLink(user),
        createRequestLink(getToken),
        httpLink,
      ]),
      cache,
      defaultOptions: {
        mutate: {
          errorPolicy: "all",
        },
        query: {
          errorPolicy: "all",
        },
      },
    });

    // See above for additional options, including other storage providers.
    setClient(newClient);
  }, [loggedIn]);

  return client;
};

export default useClient;

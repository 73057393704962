import { graphql } from "../generated";

const FETCH_PROGRAMME_GROUPS = graphql(`
  query FetchProgrammeGroups($dynamicWhere: programme_group_bool_exp) {
    programme_group(where: $dynamicWhere, order_by: { name: asc }) {
      id
      name
      slug
      programmes(order_by: { name: asc }) {
        id
        name
        slug
      }
    }
  }
`);

export default FETCH_PROGRAMME_GROUPS;

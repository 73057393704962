import { graphql } from "../generated";

const FETCH_PARTICIPANT_FILE_BY_ID= graphql(`
  query FetchParticipantFileById($id: uuid!) {
    participant_files_by_pk(id: $id) {
      id
      file_name
      file_size
      file_base64
    }
  }
`);

export default FETCH_PARTICIPANT_FILE_BY_ID;

import React, { Fragment, useState, SyntheticEvent, useEffect } from "react";
import * as yup from "yup";

import Dialog from "./Dialog";
import TextField from "./field/TextField";
import FlatButton from "./FlatButton";
import slugify from "../helpers/slugify";
import { useMutation } from "@apollo/client";
import CREATE_ORGANISATION from "../graphql/queries/create-organisation";
import LoadingSpinner from "./LoadingSpinner";
import UPDATE_ORGANISATION from "../graphql/queries/update-organisation";
import { useHistory } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";
import { getAdminOrganisationPath } from "../helpers/route-path";
import {
  CreateOrganisationMutationVariables,
  UpdateOrganisationMutationVariables,
} from "@/graphql/generated/graphql";
import { DialogActions } from "./DialogActions";

const schema = yup.object().shape({
  slug: yup.string().required().label("URL friendly name"),
  name: yup.string().required().label("Name"),
});

interface OpenerProps {
  onClick: () => void;
}
interface OrganisationFormProps {
  onClose: () => void;
  Opener: React.FC<OpenerProps>;
  initialValues?: any;
}
const OrganisationForm: React.FC<OrganisationFormProps> = (props) => {
  const { Opener, onClose, initialValues = {} } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [_name, setName] = useState(initialValues.name || "");
  const [slug, setSlug] = useState(initialValues.slug || "");

  const [validationError, setValidationError] = useState("");

  const resetForm = () => {
    setName(initialValues.name || "");
    setSlug(initialValues.slug || "");
  };

  useEffect(() => {
    resetForm();
  }, [JSON.stringify(initialValues)]);

  const onRequestClose = () => {
    resetForm();
    setIsOpen(false);
    onClose();
  };

  const history = useHistory();

  const [update, { loading: createLoading, error: createError }] = useMutation(
    UPDATE_ORGANISATION,
    {
      onCompleted: () => {
        if (slug !== initialValues.slug) {
          history.replace(getAdminOrganisationPath({ organisationSlug: slug }));
        }

        onRequestClose();
      },
    }
  );
  const [create, { loading: updateLoading, error: updateError }] = useMutation(
    CREATE_ORGANISATION,
    {
      onCompleted: () => {
        onRequestClose();

        history.push(getAdminOrganisationPath({ organisationSlug: slug }));
      },
    }
  );

  const loading = createLoading || updateLoading;
  const error = createError || updateError;

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    const payload = { name: _name, slug };

    try {
      await schema.validate(payload);
    } catch (err) {
      const message =
        error instanceof Error ? error.message : "An unknown error occurred";
      return setValidationError(message);
    }

    if (initialValues.id) {
      const variables: UpdateOrganisationMutationVariables = {
        id: initialValues.id,
        organisation: payload,
      };
      update({ variables });
    } else {
      const variables: CreateOrganisationMutationVariables = {
        organisation: payload,
      };
      create({ variables });
    }
  };

  const onNameBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!slug) {
      setSlug(slugify(e.target.value));
    }
  };

  const onSlugBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(slugify(e.target.value));
  };

  return (
    <Fragment>
      <Dialog
        fullHeight
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={"New Organisation"}
      >
        {loading && <LoadingSpinner type="overlay" />}
        <form onSubmit={onSubmit} autoComplete="off">
          <input className="clip" />
          <input type="password" className="clip" />
          <TextField
            name="name"
            label="Name"
            value={_name}
            onChange={setName}
            inputProps={{ onBlur: onNameBlur }}
          />
          <TextField
            name="slug"
            label="URL Friendly Name"
            value={slug}
            onChange={setSlug}
            inputProps={{ onBlur: onSlugBlur }}
          />
        </form>
        <DialogActions>
          <footer className="flex justify-end mb3 pt3">
            {error && <p>{error.message}</p>}
            {validationError && (
              <ErrorMessage className="mra">{validationError}</ErrorMessage>
            )}
            <FlatButton onClick={onRequestClose}>Cancel</FlatButton>
            <FlatButton onClick={onSubmit}>Save</FlatButton>
          </footer>
        </DialogActions>
      </Dialog>
      <Opener onClick={() => setIsOpen(true)} />
    </Fragment>
  );
};

export default OrganisationForm;

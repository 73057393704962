import React from "react";
import SelectField from "./field/SelectField";
import useUsers from "../hooks/useUsers";
import { UserId } from "../context/types";
import { getFullName } from "../helpers/user";

interface UserSelectProps {
  label?: string;
  name?: string;
  userId?: UserId;
  setUserId: (userId: UserId) => void;
}
const UserSelect: React.FC<UserSelectProps> = (props) => {
  const { name = "user", label = "User", userId = "", setUserId } = props;
  const { users } = useUsers();

  return (
    <SelectField
      label={label}
      name={name}
      options={users.map((user) => ({
        value: user.id,
        label: getFullName(user),
      }))}
      value={userId}
      onChange={setUserId}
    />
  );
};

export default UserSelect;

import React, { DetailedHTMLProps, useState } from "react";
import styled from "styled-components";
import { nanoid } from "nanoid";

import { Wrapper, Label, InputWrapper } from "./Common";
import colours from "../../constants/colours";

export const Select = styled.select<{ disabled?: boolean }>`
  max-width: 100%;
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1875em;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: MuiInputBase-keyframes-auto-fill-cancel;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  cursor: pointer;
  &:focus {
    border-color: ${colours.blue};
  }

  opacity: ${(props) => props.disabled && `0.3`};
  cursor: ${(props) => props.disabled && `not-allowed`};
`;

interface InputProps extends DetailedHTMLProps<any, any> {}

export type SelectFieldOptions = Array<{ value: string; label: string }>;

export interface SelectFieldProps {
  label: string;
  name: string;
  value: string;
  inputProps?: InputProps;
  inline?: boolean;
  onChange: (value: string) => void;
  options: SelectFieldOptions;
  disabled?: boolean;
  required?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = (props) => {
  const [keyPrefix] = useState(nanoid());
  const [focused, setFocused] = useState(false);

  const {
    inline,
    label,
    name,
    value,
    inputProps,
    onChange,
    options,
    disabled,
    required,
    ...otherProps
  } = props;
  const hasContent = value.length > 0;

  const stateProps = {
    focused,
    inline,
  };

  return (
    <Wrapper {...stateProps}>
      <Label htmlFor={name} lifted={hasContent || focused}>
        {label}{" "}
        {required && (
          <>
            <span className="red b">*</span>
          </>
        )}
      </Label>
      <InputWrapper {...stateProps}>
        <Select
          name={name}
          {...inputProps}
          {...otherProps}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            onChange(e.target.value)
          }
          value={value}
          disabled={disabled}
          {...stateProps}
        >
          {[{ value: "", label: "" }, ...options].map((option, i) => (
            <option key={`${keyPrefix}-${i}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </InputWrapper>
    </Wrapper>
  );
};
export default SelectField;

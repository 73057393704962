import React, { ReactNode } from "react";
import { nanoid } from "nanoid";

const id = nanoid();

export const commaSeparatedList = <T extends unknown>(
  arr: T[],
  getText: (el: T) => string,
  getId: (el: T) => string,
  Fallback?: ReactNode,
) => {
  return arr.length
    ? arr.map((el, i, arr) => (
        <span key={`list-${id}-${getId(el)}`}>
          {getText(el)}
          {i < arr.length - 1 && <span>,&nbsp;</span>}
        </span>
      ))
    : Fallback;
};

export const convertFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      typeof reader.result === "string"
        ? resolve(reader.result)
        : reject("invalid file");
    reader.onerror = (error) => reject(error);
  });

export function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export function createAndDownloadBlobFile(body: ArrayBuffer, fileName: string) {
  const blob = new Blob([body]);
  if ("msSaveBlob" in navigator) {
    // IE 10+
    // @ts-ignore
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const getNamesFromIds = (
  entities: { id: string; name: string }[],
  ids: string[],
) => {
  return ids
    .map((id) => entities.find((entity) => entity.id === id)?.name)
    .join(", ");
};

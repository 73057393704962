import { useParams } from "react-router-dom";

import useProgrammes from "./useProgrammes";
import { FetchPolicy } from "@apollo/client";

export type SessionProgramme = ReturnType<
  typeof useProgrammes
>["programmes"][0];
const useSessionProgramme = (props?: {
  fetchPolicy?: FetchPolicy;
}): SessionProgramme | undefined => {
  const { programmeSlug } = useParams<{ programmeSlug?: string }>();
  const { programmes } = useProgrammes({ ...props });

  const programme = programmes.find((p) => p.slug === programmeSlug);

  return programme;
};

export default useSessionProgramme;

import { graphql } from "../generated";

const SEARCH_PARTICIPANTS_BY_NAME = graphql(`
  query SearchParticipantsByName(
    $searchTerm: String!
    $excludeIds: [uuid!]!
    $dynamicFilters: [participant_sensitive_fields_bool_exp!]
    $dynamicOrderBy: [participant_sensitive_fields_order_by!]
    $limit: Int
    $offset: Int
  ) {
    participant_search_by_name: participant_search_by_name_sensitive(
      args: { search: $searchTerm }
      where: {
        participant: { id: { _nin: $excludeIds } }
        _and: $dynamicFilters
      }
      limit: $limit
      offset: $offset
      order_by: $dynamicOrderBy
    ) {
      participant {
        id
        created_at
        updated_at
        date_of_birth
        date_of_birth_accuracy
        date_of_first_attendance
        preferred_contact_method
        sex
        country_code
        sensitive_fields {
          first_name
          last_name
          notes
          phone
          email
        }
        participant_languages {
          language {
            code
            name
          }
        }
      }
    }
  }
`);

export default SEARCH_PARTICIPANTS_BY_NAME;

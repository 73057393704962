import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useQuery, useMutation } from "@apollo/client";

import userLookup from "../graphql/queries/user-lookup";
import LoadingSpinner from "./LoadingSpinner";
import Button from "./Button";
import UserForm from "./UserForm";
import DEACTIVATE_USER from "../graphql/queries/deactivate-user";
import { isActive } from "../helpers/user";
import Icon from "./Icon";
import useOrganisations from "../hooks/useOrganisations";
import CREATE_ORGANISATION_USER from "../graphql/queries/create-organisation-user";

import DELETE_ORGANISATION_USER from "../graphql/queries/delete-organisation-user";
import errorService from "../common-lib/error";

const AdminUsersUser: React.FC = () => {
  const { userId = "" } = useParams<{ userId: string | undefined }>();
  const { organisations } = useOrganisations();

  const { loading, error, data, refetch } = useQuery(userLookup, {
    variables: { id: userId },
  });

  const [createOrganisationUser] = useMutation(CREATE_ORGANISATION_USER, {
    onCompleted: () => refetch(),
  });
  const [deleteOrganisationUser] = useMutation(DELETE_ORGANISATION_USER, {
    onCompleted: () => refetch(),
  });

  const [deactivateUser] = useMutation(DEACTIVATE_USER, {
    onCompleted: () => refetch(),
  });

  if (error) {
    errorService.error(error);
  }

  const user = data ? data.user_by_pk : undefined;

  const notFound = !loading && !user;

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      {notFound && <p>User Not Found</p>}
      {loading && <LoadingSpinner type="overlay" />}
      {user && (
        <Fragment>
          <header className="flex justify-between items-center mb3">
            <h1>
              {user.first_name} {user.last_name}
            </h1>
            {isActive(user) && (
              <div className="mla mr2">
                <Button
                  onClick={() =>
                    deactivateUser({
                      variables: {
                        id: user.id,
                      },
                    })
                  }
                  colour="red"
                  raised
                  fill
                >
                  Deactivate
                </Button>
              </div>
            )}
            <UserForm
              initialValues={user}
              userId={user.id}
              Opener={(props) => (
                <Button {...props} raised fill>
                  Edit User
                </Button>
              )}
              onClose={refetch}
            />
          </header>
          <Icon className="mv2" text={user.email} icon="email" />
          <Icon className="mv2" text={user.role} icon="account_circle" />
          <br />
          <section>
            <h2>Organisations</h2>
            {user.organisation_users.length === 0 ? (
              <p>This user is not a member of any organisation</p>
            ) : (
              <p>This user is a member of the following organisations</p>
            )}
            {user.organisation_users.map(({ organisation }) => (
              <div
                key={`AdminUsersUser-organisation_users-${organisation.id}`}
                className="flex"
              >
                <Icon
                  className="mv2"
                  icon="assignment_turned_in"
                  text={organisation.name}
                />
                <Button
                  onClick={() =>
                    deleteOrganisationUser({
                      variables: {
                        user_id: user.id,
                        organisation_id: organisation.id,
                      },
                    })
                  }
                >
                  (Remove)
                </Button>
              </div>
            ))}
            {organisations
              .filter(
                (organisation) =>
                  !user.organisation_users.find(
                    (organisation_user) =>
                      organisation_user.organisation.id === organisation.id
                  )
              )
              .map((organisation) => {
                return (
                  <Button
                    key={`AdminUsersUser-organisations=${organisation.id}`}
                    className="mr2"
                    onClick={() =>
                      createOrganisationUser({
                        variables: {
                          organisation_user: {
                            user_id: user.id,
                            organisation_id: organisation.id,
                          },
                        },
                      })
                    }
                    outline
                  >
                    Add To {organisation.name}
                  </Button>
                );
              })}
          </section>
        </Fragment>
      )}
    </div>
  );
};

export default AdminUsersUser;

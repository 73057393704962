import React, { DetailedHTMLProps, useState, SyntheticEvent } from "react";
import styled from "styled-components";
import Autosuggest from "react-autosuggest";
import { nanoid } from "nanoid";

import { Wrapper, Label, InputWrapper } from "./Common";
import Chip from "./Chip";

function escapeRegexCharacters(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

const getSuggestions = (
  options: Array<FieldOption>,
  value: string,
  getSuggestionValue: (option: FieldOption) => string,
) => {
  const escapedValue = escapeRegexCharacters(value.trim());

  const regex = new RegExp("\\b" + escapedValue, "i");

  return options.filter((option) => regex.test(getSuggestionValue(option)));
  // .slice(0, 5)
};

const getSuggestionValue = (option: FieldOption) => {
  return option.label;
};

const renderSuggestion = (
  suggestion: FieldOption,
  _data: { query: string },
) => {
  return <span>{suggestion.label}</span>;
};

const SelectedValue = styled.div`
  position: relative;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  cursor: default;
  max-width: 100%;
  padding-top: 20px;
  flex-wrap: wrap;
`;
interface InputProps extends DetailedHTMLProps<any, any> {}
export type FieldOption = {
  value: string;
  label: string;
};
export interface FieldOptionsByValue {
  [value: string]: FieldOption;
}
interface FieldProps {
  label: string;
  name: string;
  selected: Array<FieldOption>;
  options: Array<FieldOption>;
  // getSuggestionValue: (option: FieldOption) => string;
  onSelect: (options: Array<FieldOption>) => void;
  inputProps?: InputProps;
  inline?: boolean;
}

const MultiAutocompleteField: React.FC<FieldProps> = (props) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([] as Array<FieldOption>);
  const [autoComplete] = useState(nanoid());

  const { inline, label, name, inputProps, options, selected, onSelect } =
    props;

  const hasContent = value.length > 0;

  const stateProps = {
    focused,
    inline,
    hasContent: value.length > 0,
  };

  const isSelected = (value: string) => {
    return selected.some((option) => option.value === value);
  };

  const addOptionToSelection = (option: FieldOption) => {
    const newSelected = [...selected, option];

    onSelect(newSelected);
  };

  const removeOptionFromSelection = (option: FieldOption) => {
    const newSelected = selected.filter(
      (_option) => _option.value !== option.value,
    );
    onSelect(newSelected);
  };

  const onChange = (
    _event: SyntheticEvent,
    data: { newValue: string; method: string },
  ) => {
    const { newValue } = data;

    setValue(newValue);
  };

  const onSuggestionsFetchRequested = (data: { value: string }) => {
    const { value } = data;
    const remainingOptions = options.filter(
      (option) =>
        !selected.some(
          (selectedOption) => selectedOption.value === option.value,
        ),
    );

    setSuggestions(getSuggestions(remainingOptions, value, getSuggestionValue));
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const onSuggestionSelected = (
    _event: SyntheticEvent,
    data: {
      suggestion: FieldOption;
    },
  ) => {
    const { suggestion } = data;

    if (isSelected(suggestion.value)) {
      removeOptionFromSelection(suggestion);
    } else {
      addOptionToSelection(suggestion);
    }

    setValue("");
  };

  const onBlur = (
    _event: SyntheticEvent,
    params: Autosuggest.BlurEvent<FieldOption | null> | undefined,
  ) => {
    if (
      params &&
      params.highlightedSuggestion &&
      !isSelected(params.highlightedSuggestion.value)
    ) {
      const newSelected = [...selected, params.highlightedSuggestion];
      onSelect(newSelected);
      setValue("");
    }

    setFocused(false);
  };

  const hasSelection = Boolean(selected.length > 0);

  return (
    <Wrapper {...stateProps}>
      <Label htmlFor={name} lifted={focused || hasContent || hasSelection}>
        {label}
      </Label>
      {hasSelection && (
        <SelectedValue>
          {selected.map((option) => {
            return (
              <Chip
                key={`MultiAutocompleteField-SelectedValue-${option.value}`}
                label={option.label}
                onDelete={() => removeOptionFromSelection(option)}
              />
            );
          })}
        </SelectedValue>
      )}
      <InputWrapper {...stateProps} hasChips={hasSelection}>
        <Autosuggest
          id={`${name}-multiautocomplete`}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={() => true}
          inputProps={{
            autoComplete,
            value,
            onChange,
            onFocus: () => setFocused(true),
            onBlur,
            ...inputProps,
          }}
        />
      </InputWrapper>
    </Wrapper>
  );
};
export default MultiAutocompleteField;

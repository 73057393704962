import React, { Fragment, useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/client";

import { Switch, Route } from "react-router-dom";

import Login from "./components/Login";
import useClient from "./graphql/client";
import useLoggedIn from "./hooks/useLoggedIn";
import SelectHubOrganisation from "./components/SelectHubOrganisation";
import AdminAuth from "./components/AdminAuth";
import AdminOrganisations from "./components/AdminOrganisations";
import AdminProgrammes from "./components/AdminProgrammes";
import AdminProgrammesProgramme from "./components/AdminProgrammesProgramme";
import AdminLocations from "./components/AdminLocations";
import AdminLocationsLocation from "./components/AdminLocationsLocation";
import AdminProjects from "./components/AdminProjects";
import AdminProjectsProject from "./components/AdminProjectsProject";
import AdminUsers from "./components/AdminUsers";
import AdminUsersUser from "./components/AdminUsersUser";
import {
  getOrganisationPath,
  getHomePath,
  getParticipantsPath,
  getParticipantPath,
  getRegistersPath,
  getRegisterPath,
  getAdminOrganisationsPath,
  getAdminProgrammesPath,
  getAdminProgrammePath,
  getAdminLocationsPath,
  getAdminLocationPath,
  getAdminProjectsPath,
  getAdminProjectPath,
  getAdminUsersPath,
  getAdminUserPath,
  getAdminHomePath,
  getAdminOrganisationPath,
  getAdminReportsPath,
  getOrganisationReportsPath,
  getOrganisationManagerPath,
  getOrganisationManagerUsersPath,
  getOrganisationManagerUserPath,
  getOrganisationManagerProjectsPath,
  getOrganisationManagerLocationsPath,
  getHubsPath,
} from "./helpers/route-path";
import OrganisationAuth from "./components/OrganisationAuth";
import SelectPartnerHub from "./components/SelectPartnerHub";
import ProgrammeAuth from "./components/ProgrammeAuth";
import Home from "./components/Home";
import ParticipantsView from "./components/Participants";
import ParticipantsParticipant from "./components/ParticipantsParticipant";
import Registers from "./components/Registers";
import RegistersRegister from "./components/RegistersRegister";
import AdminOrganisationsOrganisation from "./components/AdminOrganisationsOrganisation";
import Reports from "./components/Reports";
import ManagerAuth from "./components/ManagerAuth";
import ManagerUsers from "./components/ManagerUsers";
import ManagerUsersUser from "./components/ManagerUsersUser";
import ManagerProjects from "./components/ManagerProjects";
import ManagerLocations from "./components/ManagerLocations";
import SelectAmnaOrHubs from "./components/SelectAmnaOrHubs";
import SelectAmnaProgrammeGroup from "./components/SelectAmnaProgrammeGroup";
import SelectAmnaProgramme from "./components/SelectAmnaProgramme";
import { names } from "./common-lib/names";

const getRouterPath = (getPath: Function) => {
  // TODO: make this fn typesafe
  return getPath({
    organisationSlug: ":organisationSlug",
    programmeSlug: ":programmeSlug",
    locationSlug: ":locationSlug",
    projectSlug: ":projectSlug",
    participantId: ":participantId",
    registerId: ":registerId",
    userId: ":userId",
  });
};

const App: React.FC = () => {
  const loggedIn = useLoggedIn();
  const client = useClient();
  const [hasTransitioned, setHasTransitioned] = useState(false);

  useEffect(() => {
    if (client) {
      const timer = setTimeout(() => setHasTransitioned(true), 800);

      return () => clearTimeout(timer);
    }
  }, [client]);
  useEffect(() => {
    if (client && window) {
      const classNames = [
        ".rti-loading-splash",
        ".rti-loading-splash__wrapper",
        ".rti-loading-splash__spinner",
      ];
      classNames.forEach((className) => {
        const el = document.querySelector(className);
        if (el) {
          el.classList.add(`${className}--loaded`);
        }
      });
    }
  }, [client]);

  useEffect(() => {
    if (hasTransitioned && window) {
      const splash = document.querySelector(".rti-loading-splash");
      if (splash) {
        splash.classList.add("rti-loading-splash--hidden");
      }
    }
  }, [hasTransitioned]);

  if (!client || !hasTransitioned) {
    return null;
  }

  return (
    <Fragment>
      {!loggedIn ? (
        <Login />
      ) : (
        <ApolloProvider client={client}>
          <Switch>
            <Route path={getRouterPath(getAdminHomePath)}>
              <AdminAuth>
                <Route exact path={getRouterPath(getAdminOrganisationsPath)}>
                  <AdminOrganisations />
                </Route>
                <Route exact path={getRouterPath(getAdminOrganisationPath)}>
                  <AdminOrganisationsOrganisation />
                </Route>
                <Route exact path={getRouterPath(getAdminProgrammesPath)}>
                  <AdminProgrammes />
                </Route>
                <Route exact path={getRouterPath(getAdminProgrammePath)}>
                  <AdminProgrammesProgramme />
                </Route>
                <Route exact path={getRouterPath(getAdminLocationsPath)}>
                  <AdminLocations />
                </Route>
                <Route exact path={getRouterPath(getAdminLocationPath)}>
                  <AdminLocationsLocation />
                </Route>
                <Route exact path={getRouterPath(getAdminProjectsPath)}>
                  <AdminProjects />
                </Route>
                <Route exact path={getRouterPath(getAdminProjectPath)}>
                  <AdminProjectsProject />
                </Route>
                <Route exact path={getRouterPath(getAdminUsersPath)}>
                  <AdminUsers />
                </Route>
                <Route exact path={getRouterPath(getAdminUserPath)}>
                  <AdminUsersUser />
                </Route>
                <Route exact path={getRouterPath(getAdminReportsPath)}>
                  <Reports />
                </Route>
              </AdminAuth>
            </Route>
            <Route exact path="/">
              <SelectAmnaOrHubs />
            </Route>
            <Route exact path={`/amna`}>
              <SelectAmnaProgrammeGroup />
            </Route>
            <Route exact path={`/:organisationSlug/programmes`}>
              <SelectAmnaProgramme />
            </Route>
            <Route exact path={getRouterPath(getHubsPath)}>
              <SelectPartnerHub />
            </Route>
            <Route exact path={`/${names.hubs.slug}/:hubSlug`}>
              <SelectHubOrganisation />
            </Route>
            <Route path={getRouterPath(getOrganisationPath)}>
              <OrganisationAuth>
                <Switch>
                  <Route path={getRouterPath(getOrganisationManagerPath)}>
                    <ManagerAuth>
                      <Route
                        exact
                        path={getRouterPath(getOrganisationManagerPath)}
                      >
                        <div className="flex justify-center items-center flex-auto">
                          <h1>Manager Panel</h1>
                        </div>
                      </Route>
                      <Route
                        exact
                        path={getRouterPath(getOrganisationManagerUsersPath)}
                      >
                        <ManagerUsers />
                      </Route>
                      <Route
                        exact
                        path={getRouterPath(getOrganisationManagerUserPath)}
                      >
                        <ManagerUsersUser />
                      </Route>
                      <Route
                        exact
                        path={getRouterPath(getOrganisationManagerProjectsPath)}
                      >
                        <ManagerProjects />
                      </Route>
                      <Route
                        exact
                        path={getRouterPath(
                          getOrganisationManagerLocationsPath
                        )}
                      >
                        <ManagerLocations />
                      </Route>
                    </ManagerAuth>
                  </Route>
                  <Route path={getRouterPath(getHomePath)}>
                    <ProgrammeAuth>
                      <Route exact path={getRouterPath(getHomePath)}>
                        <Home />
                      </Route>
                      <Route exact path={getRouterPath(getParticipantsPath)}>
                        <ParticipantsView />
                      </Route>
                      <Route exact path={getRouterPath(getParticipantPath)}>
                        <ParticipantsParticipant />
                      </Route>
                      <Route exact path={getRouterPath(getRegistersPath)}>
                        <Registers />
                      </Route>
                      <Route exact path={getRouterPath(getRegisterPath)}>
                        <RegistersRegister />
                      </Route>
                      <Route
                        exact
                        path={getRouterPath(getOrganisationReportsPath)}
                      >
                        <Reports />
                      </Route>
                    </ProgrammeAuth>
                  </Route>
                </Switch>
              </OrganisationAuth>
            </Route>
            <Route render={() => <div>404</div>} />
          </Switch>
        </ApolloProvider>
      )}
    </Fragment>
  );
};

export default App;

import React, {
  Fragment,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  withRouter,
  RouteComponentProps,
  useParams,
  Link,
} from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import FETCH_PROGRAMME from "../graphql/queries/fetch-programme";
import LoadingSpinner from "./LoadingSpinner";
import ProgrammeForm from "./ProgrammeForm";
import Button from "./Button";
import { getProgrammeType } from "@/hooks/useProgrammes";
import { AdminGrid, AdminGridAddRow, AdminGridCell } from "./AdminGrid";
import { getAdminOrganisationPath } from "@/helpers/route-path";
import OrganisationsAllDialog from "./OrganisationsAllDialog";
import UPDATE_PROGRAMME from "@/graphql/queries/update-programme";
import IconButton from "./IconButton";
import { useToast } from "./Toast";

interface Props extends RouteComponentProps<any> {}

const AdminProgrammesProgramme: React.FC<Props> = () => {
  const { programmeSlug = "" } = useParams<{
    programmeSlug: string | undefined;
  }>();

  const { loading, error, data, refetch } = useQuery(FETCH_PROGRAMME, {
    variables: { programmeSlug },
  });

  const programme = data ? data.programme[0] : undefined;

  const notFound = !loading && !error && !programme;

  const [update] = useMutation(UPDATE_PROGRAMME, {
    onCompleted: () => {
      refetch();
    },
  });

  const currentOrgIds = programme
    ? programme.organisation_programmes.map((op) => op.organisation.id)
    : [];
  const currentOrganisationConnections = programme
    ? programme.organisation_programmes.map((op) => ({
        programme_id: programme.id,
        organisation_id: op.organisation.id,
      }))
    : [];

  const updateProgramme = async ({
    updates,
    orgIds,
  }: {
    /**
     * Updates the programme.
     * - If omitted, the programme will not be updated.
     * - If an empty object, no fields will be removed.
     */
    updates?: {};
    /**
     * Updates the list of organisations associated with the programme.
     * - If omitted, the list will not be updated.
     * - If an empty array, all organisations will be removed.
     */
    orgIds?: string[];
  }) => {
    if (!programme) {
      return window.alert(
        "Could not complete request, please contact an admin."
      );
    }
    return update({
      variables: {
        id: programme.id,
        programme: {
          ...updates,
        },
        organisation_programmes: orgIds
          ? orgIds.map((id) => ({
              programme_id: programme.id,
              organisation_id: id,
            }))
          : currentOrganisationConnections,
      },
    });
  };

  useEffect(() => {
    refetch();
  }, []);

  const addOrg = async (org: { id: string }) => {
    return updateProgramme({
      orgIds: [...currentOrgIds, org.id],
    });
  };

  const removeOrg = async (org: { id: string }) => {
    return updateProgramme({
      orgIds: currentOrgIds.filter((id) => id !== org.id),
    });
  };

  const onStatusSave = async (value: string) => {
    return updateProgramme({
      updates: { status: value },
    });
  };
  const onStartDateSave = async (value: string) => {
    return updateProgramme({
      updates: { start_date: value },
    });
  };
  const onEndDateSave = async (value: string) => {
    return updateProgramme({
      updates: { end_date: value },
    });
  };

  return (
    <div>
      {loading && <LoadingSpinner type="overlay" />}
      {programme && (
        <Fragment>
          <header className="flex justify-between items-center mb3">
            <h1>{programme.name}</h1>
            <ProgrammeForm
              onClose={refetch}
              initialValues={programme}
              Opener={(props) => (
                <Button {...props} raised fill>
                  Edit Programme
                </Button>
              )}
            />
          </header>
          <main>
            <EditableSection
              title="Status"
              type="SELECT"
              options={["active", "closed"]}
              value={programme.status}
              onSave={onStatusSave}
            />
            <EditableSection
              title="Start date"
              type="DATE"
              value={programme.start_date}
              onSave={onStartDateSave}
            />
            <EditableSection
              title="End date"
              type="DATE"
              value={programme.end_date}
              onSave={onEndDateSave}
            />
            <h2>Type</h2>
            <p>{getProgrammeType(programme).title}</p>
            <h2>Organisations</h2>
            <AdminGrid columns={2} background="white">
              {programme.organisation_programmes
                .map((op) => op.organisation)
                .sort((a, b) => (a.name < b.name ? -1 : 1))
                .map((org) => {
                  const { id, slug, name } = org;

                  return (
                    <>
                      <AdminGridCell key={`AdminOrganisations-li-${id}`}>
                        <Link
                          to={getAdminOrganisationPath({
                            organisationSlug: slug,
                          })}
                        >
                          {name}
                        </Link>
                      </AdminGridCell>
                      <AdminGridCell>
                        <IconButton
                          onClick={() => removeOrg(org)}
                          className="ml-auto"
                          icon="delete"
                          title="Remove"
                        />
                      </AdminGridCell>
                    </>
                  );
                })}
              <OrganisationsAllDialog
                Opener={(props) => (
                  <AdminGridAddRow
                    title="Add organisations"
                    gridColumn={"span 2"}
                    {...props}
                  />
                )}
                onClose={() => null}
                onSelect={addOrg}
                excludeIds={programme.organisation_programmes.map(
                  (op) => op.organisation.id
                )}
              />
            </AdminGrid>
          </main>
        </Fragment>
      )}
      {notFound && <p>Programme Not Found</p>}
      {error && <p>{error.message}</p>}
    </div>
  );
};

export default withRouter(AdminProgrammesProgramme);

type EditableSectionProps =
  | {
      type: "FREE_TEXT";
      title: string;
      onSave: (value: string) => Promise<unknown>;
      value: string;
    }
  | {
      type: "DATE";
      title: string;
      onSave: (value: string) => Promise<unknown>;
      value: string;
    }
  | {
      type: "SELECT";
      title: string;
      onSave: (value: string) => Promise<unknown>;
      value: string;
      options: string[];
    };
function EditableSection(props: EditableSectionProps) {
  const { title, onSave, value } = props;
  const [_value, setValue] = useState<typeof props.value>(value);
  const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();

  const onConfirm: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();
    try {
      if (_value) {
        await onSave(_value);
        toast.notify(`${title} saved`);
      }
    } catch (error) {
      toast.notify(`Could not save ${title}`);
    }
    setIsEditing(false);
  };

  return (
    <section>
      <h2>{title}</h2>
      <p className="flex items-center">
        {isEditing ? (
          <>
            {props.type === "SELECT" ? (
              <select value={_value} onChange={(e) => setValue(e.target.value)}>
                {props.options.map((option) => (
                  <option
                    key={`editable-section-${title}-${value}-${option}`}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </select>
            ) : null}
            {props.type === "DATE" ? (
              <input
                type="date"
                value={_value}
                onChange={(e) => setValue(e.target.value)}
              />
            ) : null}
            {props.type === "FREE_TEXT" ? (
              <input
                type="text"
                value={_value}
                onChange={(e) => setValue(e.target.value)}
              />
            ) : null}
            <IconButton onClick={onConfirm} className="ml3" icon="check" />{" "}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(false);
              }}
              className="ml1"
              icon="cancel"
            />
          </>
        ) : (
          <span
            className="flex items-center ttc"
            onClick={() => setIsEditing(true)}
          >
            {value || "Not set"}
            <IconButton
              onClick={() => setIsEditing(true)}
              className="ml3"
              icon="edit"
            />
          </span>
        )}
      </p>
    </section>
  );
}

import { graphql } from "../generated";

const CREATE_ORGANISATION_MANAGER= graphql(`
  mutation CreateOrganisationManager(
    $organisation_manager: organisation_manager_insert_input!
  ) {
    insert_organisation_manager(objects: [$organisation_manager]) {
      returning {
        user_id
        organisation_id
      }
    }
  }
`);

export default CREATE_ORGANISATION_MANAGER;

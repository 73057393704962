import React from "react";
import styled from "styled-components";
import randomColor from "randomcolor";
import colours, { Colour } from "../constants/colours";

type Size = "small" | "medium";
const colors = new Map();
const getColor = (id: string) => {
  if (colors.has(id)) {
    return colors.get(id);
  }

  const color = randomColor({ hue: "dark" });

  colors.set(id, color);

  return color;
};

interface AvatarWrapperProps {
  id: string;
  size: Size;
  color?: Colour;
}
const AvatarWrapper = styled.div<AvatarWrapperProps>`
  display: flex;
  background-color: ${(props) =>
    props.color ? colours[props.color] : getColor(props.id)};
  color: #fff;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.size === "small" &&
    `
      font-size: 16px;
      height: 30px;
      min-height: 30px;
      width: 30px;    
      min-width: 30px;
    `}
  ${(props) =>
    props.size === "medium" &&
    `
      font-size: 22px;
      height: 50px;
      min-height: 50px;
      width: 50px;
      min-width: 50px;
    `}
`;

function getInitials(
  person:
    | {
        first_name?: string | null;
        last_name?: string | null;
      }
    | {
        sensitive_fields?: {
          first_name?: string | null;
          last_name?: string | null;
        } | null;
      }
) {
  const firstName =
    ("sensitive_fields" in person && person.sensitive_fields?.first_name) ||
    ("first_name" in person && person.first_name) ||
    "";

  const lastName =
    ("sensitive_fields" in person && person.sensitive_fields?.last_name) ||
    ("last_name" in person && person.last_name) ||
    "";

  if (!firstName) {
    return "#";
  }

  return `${firstName?.[0]}${lastName?.[0] ?? ""}`;
}

interface AvatarProps {
  person:
    | {
        id: string;
        first_name?: string | null;
        last_name?: string | null;
      }
    | {
        id: string;
        sensitive_fields?: {
          first_name?: string | null;
          last_name?: string | null;
        } | null;
      };
  className?: string;
  size?: Size;
  color?: Colour;
  title?: string;
}
const Avatar: React.FC<AvatarProps> = (props) => {
  const { person, className, size = "medium", color, title } = props;

  const { id } = person;
  const initials = getInitials(person);

  return (
    <AvatarWrapper
      id={id}
      className={className}
      size={size}
      color={color}
      title={title}
    >
      {initials}
    </AvatarWrapper>
  );
};

export default Avatar;

import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import FETCH_REGISTER from "../graphql/queries/fetch-register";
import LoadingSpinner from "./LoadingSpinner";
import RegisterMeta from "./RegisterMeta";
import RegisterAttendees from "./RegisterAttendees";
import RegisterAddFrom from "./RegisterAddFrom";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import RegisterForm from "./RegisterForm";
import Button from "./Button";
import DELETE_REGISTER from "../graphql/queries/delete-register";
import Icon from "./Icon";
import colours from "../constants/colours";
import RESTORE_REGISTER from "../graphql/queries/restore-register";
import { useIsProgrammeClosed } from "@/hooks/useIsProgrammeClosed";

const AddFromWrapper = styled.div`
  min-width: 420px;
  position: relative;
`;

const DeletedText = styled.span`
  color: ${colours.textGreyLight};
`;

const RegistersRegister: React.FC = () => {
  const { registerId } = useParams<{ registerId: string }>();

  const [deleteRegister] = useMutation(DELETE_REGISTER, {
    onCompleted: () => refetch(),
  });
  const [restoreRegister] = useMutation(RESTORE_REGISTER, {
    onCompleted: () => refetch(),
  });

  const { loading, error, data, refetch } = useQuery(FETCH_REGISTER, {
    variables: { registerId },
  });

  const register = data ? data.register_by_pk : undefined;

  const isDeleted = Boolean(register && register.deleted_at);
  const isProgrammeClosed = useIsProgrammeClosed();

  return (
    <div>
      <header className="flex justify-between items-center">
        <h1>Register {isDeleted && <DeletedText>(deleted)</DeletedText>}</h1>
        {register && (
          <Fragment>
            <div className="mla mr2">
              {isDeleted ? (
                <Button
                  onClick={() => {
                    if (isProgrammeClosed) {
                      return window.alert(
                        "You cannot restore a register for a closed programme"
                      );
                    }

                    restoreRegister({
                      variables: {
                        id: register.id,
                      },
                    });
                  }}
                  colour="blueBg"
                  raised
                  fill
                >
                  <span className="mr1">Restore</span>
                  <Icon icon="restore_from_trash" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    deleteRegister({
                      variables: {
                        id: register.id,
                      },
                    });
                  }}
                  colour="red"
                  raised
                  fill
                  disabled={isProgrammeClosed}
                >
                  <span className="mr1">Delete</span>
                  <Icon icon="delete" />
                </Button>
              )}
            </div>
            <RegisterForm
              id={registerId}
              initialValues={register}
              onClose={refetch}
              Opener={(props) => (
                <Button {...props} raised fill disabled={isProgrammeClosed}>
                  Edit Register Metadata
                </Button>
              )}
            />
          </Fragment>
        )}
      </header>
      {loading && <LoadingSpinner type="overlay" />}
      {error && <p>{error.message}</p>}
      {isProgrammeClosed && (
        <p
          className="mb3 flex items-center pa3"
          style={{ background: colours.pinkBg }}
        >
          <Icon icon="warning" className="mr2" /> This programme is closed. Only
          admins can make changes to this register.
        </p>
      )}
      {register && (
        <Fragment>
          <RegisterMeta register={register} refetchRegister={refetch} />
          {!register.override_total_participants && (
            <div className="flex">
              <div className="w-70 pr3">
                <RegisterAttendees
                  register={register}
                  refetchRegister={refetch}
                />
              </div>
              <AddFromWrapper className="flex-auto">
                <RegisterAddFrom onAdd={refetch} register={register} />
              </AddFromWrapper>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default RegistersRegister;

import { graphql } from "../generated";

const UPDATE_PARTICIPANT = graphql(`
  mutation UpdateParticipant(
    $id: uuid!
    $participant: participant_set_input!
    $sensitive_fields: participant_sensitive_fields_set_input
    $participant_languages: [participant_language_insert_input!]!
  ) {
    update_participant(_set: $participant, where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
    update_participant_sensitive_fields(
      _set: $sensitive_fields
      where: { participant_id: { _eq: $id } }
    ) {
      returning {
        participant_id
      }
    }
    delete_participant_language(where: { participant_id: { _eq: $id } }) {
      returning {
        language_code
      }
    }
    insert_participant_language(
      objects: $participant_languages
      on_conflict: { constraint: participant_language_pkey, update_columns: [] }
    ) {
      returning {
        language_code
      }
    }
  }
`);

export default UPDATE_PARTICIPANT;

import { useIsAdmin } from "./useIsAdmin";
import useSessionProgramme from "./useSessionProgramme";

/**
 * Returns true if the session programme is closed and the user is not an
 * admin, otherwise returns false.
 * This is used to determine if a user should be able to edit a programme.
 */
export const useIsProgrammeClosed = () => {
  let programme = useSessionProgramme({ fetchPolicy: "cache-only" });
  const isAdmin = useIsAdmin();
  if (!programme) {
    return false;
  }
  return programme.status === "closed";
};

import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  LegacyRef,
} from "react";
import Dialog from "./Dialog";
import IconButton from "./IconButton";
import FlatButton from "./FlatButton";
import useScrollHeight from "../hooks/useScrollHeight";
import styled from "styled-components";

const TextareaWrapper = styled.div`
  position: relative;
`;

interface Props {
  title: string;
  initialValue?: string | null;
  onSave: (value: string) => Promise<any>;
  disabled?: boolean;
}
const DialogTextbox: React.FC<Props> = (props) => {
  const { onSave, title, initialValue, disabled } = props;
  const [value, setValue] = useState(initialValue || "");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setValue(initialValue || ""), [initialValue]);

  const resetForm = () => {
    setValue(initialValue || "");
  };

  useEffect(() => {
    resetForm();
  }, [initialValue]);

  const onRequestOpen = () => setIsOpen(true);
  const onRequestClose = () => {
    resetForm();
    setIsOpen(false);
  };
  const onRequestSave = async () => {
    await onSave(value);
    onRequestClose();
  };

  const dummyTextareaRef = useRef<HTMLTextAreaElement>(null);
  const scrollHeight = useScrollHeight(dummyTextareaRef);
  const [extraHeight, setExtraHeight] = useState(0);

  const textareaRef: LegacyRef<HTMLTextAreaElement> = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        const computedStyle = window.getComputedStyle(node);
        const extra =
          parseInt(computedStyle.getPropertyValue("border-top-width"), 10) +
          parseInt(computedStyle.getPropertyValue("padding-top"), 10) +
          parseInt(computedStyle.getPropertyValue("padding-bottom"), 10) +
          parseInt(computedStyle.getPropertyValue("border-bottom-width"), 10);
        setExtraHeight(extra);
      }
    },
    []
  );

  return (
    <Fragment>
      <Dialog isOpen={isOpen} onRequestClose={onRequestClose} title={title}>
        <TextareaWrapper>
          <textarea
            onChange={(e) => setValue(e.target.value)}
            value={value}
            className="w-100"
            rows={8}
            ref={textareaRef}
            style={{ height: scrollHeight + extraHeight }}
          />
          <textarea
            value={value}
            className="w-100"
            rows={8}
            ref={dummyTextareaRef}
            style={{ position: "absolute", zIndex: -1, left: 0 }}
            aria-hidden={true}
          />
        </TextareaWrapper>
        <footer className="flex justify-end">
          <FlatButton type="button" onClick={onRequestClose}>
            Cancel
          </FlatButton>
          <FlatButton type="button" onClick={onRequestSave}>
            Save
          </FlatButton>
        </footer>
      </Dialog>
      <IconButton
        disabled={disabled}
        title={title}
        icon="create"
        onClick={onRequestOpen}
      />
    </Fragment>
  );
};

export default DialogTextbox;

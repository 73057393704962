import React, { useEffect, useCallback, Fragment } from "react";
import { Redirect, useHistory } from "react-router-dom";

import useProgrammeGroups from "../hooks/useProgrammeGroups";
import LoadingSpinner from "./LoadingSpinner";
import LeftPanel from "./LeftPanel";
import ErrorMessage from "./ErrorMessage";
import useSelf from "../hooks/useSelf";
import {
  getAdminProgrammesPath,
  getProgrammeGroupPath,
} from "../helpers/route-path";
import Button from "./Button";
import NoContent from "./NoContent";
import NavigationLinkButton from "./NavigationLinkButton";

const SelectAmnaProgrammeGroup: React.FC = () => {
  const { programmeGroups, loading, error, refetch } = useProgrammeGroups();
  const user = useSelf();

  const history = useHistory();
  const goToAdmin = useCallback(() => {
    history.push(getAdminProgrammesPath());
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  if (!loading && programmeGroups.length === 1 && user?.role !== "rti-admin") {
    /**
     * If user only a member of a single org, then redirect them into that org
     * However, if user is an admin, then keep them on the "/" page so that they
     * have access to the "Database admin" button.
     */
    return (
      <Redirect
        to={getProgrammeGroupPath({
          programmeGroupSlug: programmeGroups[0].slug,
        })}
      />
    );
  }

  return (
    <LeftPanel>
      <header className="flex justify-between items-center mb3">
        <h1>Programme groups</h1>
        {user && user.role === "rti-admin" && (
          <Button onClick={goToAdmin} raised fill>
            Make changes
          </Button>
        )}
      </header>
      <div className="flex flex-column flex-auto">
        {error && (
          <ErrorMessage>
            There was an error loading your ProgrammeGroups. Please try
            refreshing the page.
          </ErrorMessage>
        )}
        {loading && <LoadingSpinner type="overlay" />}
        {!loading && !error && programmeGroups.length === 0 && (
          <NoContent
            icon="groups"
            message={
              <Fragment>
                You're not a member of an programme group.
                <br />
                Please contact an admin.
              </Fragment>
            }
          />
        )}
        {programmeGroups.length ? (
          <p className="mb3">Please select from the below programme groups:</p>
        ) : null}
        {programmeGroups.map((programmeGroup) => {
          return (
            <NavigationLinkButton
              key={`SelectAmnaProgrammeGroup-org-link-${programmeGroup.id}`}
              to={getProgrammeGroupPath({
                programmeGroupSlug: programmeGroup.slug,
              })}
            >
              {programmeGroup.name}
            </NavigationLinkButton>
          );
        })}
      </div>
    </LeftPanel>
  );
};

export default SelectAmnaProgrammeGroup;

import { graphql } from "../generated";

const REGISTER_PARTICIPANT_AGGREGATE = graphql(`
  query RegisterParticipantAggregate(
    $dynamicWhere: register_participant_bool_exp
    $distinctOn: [register_participant_select_column!]
  ) {
    register_participant_aggregate(
      where: $dynamicWhere
      distinct_on: $distinctOn
      order_by: { participant_id: asc, computed: { participant_age: asc } }
    ) {
      aggregate {
        count
      }
    }
    register_participant(
      where: $dynamicWhere
      distinct_on: $distinctOn
      order_by: { participant_id: asc, computed: { participant_age: asc } }
    ) {
      register {
        date
        override_total_participants
        organisation {
          id
          name
        }
        location {
          id
          name
        }
        project {
          id
          name
        }
      }
      participant {
        sensitive_fields {
          first_name
          last_name
        }
        id
        date_of_birth
        country_code
        sex
      }
    }
  }
`);

export default REGISTER_PARTICIPANT_AGGREGATE;

import React, { FC, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";

import errorService from "../common-lib/error";
import CREATE_PARTICIPANT_FILE from "../graphql/queries/create-participant-file";
import DELETE_PARTICIPANT_FILE_BY_ID from "../graphql/queries/delete-participant-file-by-id";
import FETCH_PARTICIPANT_FILE_BY_ID from "../graphql/queries/fetch-participant-file-by-id";
import {
  base64ToArrayBuffer,
  createAndDownloadBlobFile,
  convertFileToBase64,
} from "../helpers/util";
import ConfirmDialog from "./ConfirmDialog";
import Ellipsis from "./Ellipsis";
import FileInput from "./FileInput";
import IconButton from "./IconButton";
import { FetchParticipantByIdQuery } from "@/graphql/generated/graphql";

type Participant = NonNullable<FetchParticipantByIdQuery["participant_by_pk"]>;
type ParticipantFile = Participant["participant_files"][number];

type FileUploadButtonProps = {
  id: string;
  label: string;
  purpose: string;
  participant: Participant;
  onMutate: () => void;
};
const FileUploadButton: FC<FileUploadButtonProps> = ({
  id,
  label,
  purpose,
  participant,
  onMutate,
}) => {
  const [loading, setLoading] = useState(false);
  const [addParticipantFile] = useMutation(CREATE_PARTICIPANT_FILE, {
    onCompleted: () => onMutate(),
  });

  return (
    <>
      <FileInput
        id={id}
        label={`Add ${label} file`}
        loading={loading}
        onChange={async (e) => {
          setLoading(true);
          try {
            const file = e.target.files ? e.target.files[0] : undefined;

            if (!file) {
              throw new Error("No file");
            }
            const base64 = await convertFileToBase64(file);
            await addParticipantFile({
              variables: {
                participant_file: {
                  participant_id: participant.id,
                  file_base64: base64,
                  file_name: file?.name,
                  file_size: file?.size,
                  purpose,
                },
              },
            });
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        }}
      />
    </>
  );
};

const useFileDelete = ({
  id,
  onMutate,
}: {
  id: string;
  onMutate: () => void;
}) => {
  const [deleteMutate, { loading }] = useMutation(
    DELETE_PARTICIPANT_FILE_BY_ID,
    {
      variables: { id },
    }
  );

  return {
    delete: async () => {
      try {
        await deleteMutate();
        onMutate();
      } catch (error) {
        window.alert("Could not delete file");
        errorService.error(error);
      }
    },
    loading,
  };
};

const useFileDownload = ({ id, file_name }: ParticipantFile) => {
  const [loading, setLoading] = useState(false);
  const { refetch: downloadFetch } = useQuery(FETCH_PARTICIPANT_FILE_BY_ID, {
    variables: { id },
    skip: true,
  });

  const download = async () => {
    setLoading(true);
    try {
      const { data } = await downloadFetch();
      const file = data.participant_files_by_pk?.file_base64
        .split(",")
        .slice(1)
        .join(",");

      if (!file) {
        return errorService.error(`File not found, id: ${id}`);
      }

      const arrayBuffer = base64ToArrayBuffer(file);
      createAndDownloadBlobFile(arrayBuffer, file_name);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    download,
    loading,
  };
};

const FileAndActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
`;
const FileAction = styled(IconButton)`
  &:first-of-type {
    margin-left: 1rem;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.4rem;
  width: 100%;
  max-width: 400px;

  & * {
    white-space: nowrap;
  }
`;

const FileNameAndSize = styled(Ellipsis)`
  margin-right: auto;
`;
const FileAndActions: React.FC<{
  label: string;
  file: ParticipantFile;
  onMutate: () => void;
}> = ({ file, label, onMutate }) => {
  const fileDownload = useFileDownload(file);
  const fileDelete = useFileDelete({ ...file, onMutate });

  return (
    <FileAndActionsWrapper>
      <FileNameAndSize onClick={fileDownload.download}>
        {file.file_name} ({Math.round(file.file_size / 1000)}kb){" "}
      </FileNameAndSize>
      <FileAction
        icon="download"
        title={`Download ${label} file`}
        onClick={fileDownload.download}
        loading={fileDownload.loading}
        disabled={fileDownload.loading}
      />
      <ConfirmDialog
        title={`Delete ${label} file`}
        message={"Are you sure? This action is irreversible."}
        confirmButtonText={`Delete`}
        onConfirm={fileDelete.delete}
        Controller={(props) => (
          <FileAction icon="delete" title={`Delete ${label} file`} {...props} />
        )}
      />
    </FileAndActionsWrapper>
  );
};

const FilePurpose = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
`;
const FilePurposeText = styled.span`
  margin-right: 5px;
`;
type ParticipantFileProps = {
  id: string;
  label: string;
  purpose: string;
  participant: Participant;
  onMutate: () => void;
};
const ParticipantFile: FC<ParticipantFileProps> = ({
  id,
  label,
  purpose,
  participant,
  onMutate,
}) => {
  const files = participant.participant_files.filter(
    (file) => file.purpose === purpose
  );

  return (
    <Section>
      <FilePurpose>
        <FilePurposeText>{label}</FilePurposeText>
        <FileUploadButton
          id={id}
          onMutate={onMutate}
          participant={participant}
          label={label}
          purpose={purpose}
        />
      </FilePurpose>
      {files.map((file) => {
        return (
          <FileAndActions
            key={`ParticipantFile-FileAndActions-${file.id}`}
            label={label}
            file={file}
            onMutate={onMutate}
          />
        );
      })}
    </Section>
  );
};

export default ParticipantFile;

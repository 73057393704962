import colours from "@/constants/colours";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

const SectionLink = styled(Link)`
  max-width: 400px;
  color: ${colours.textGrey};
  background-color: ${colours.yellow};
  border: 2px solid ${colours.textGrey};
  height: 4em;
  text-align: left;
`;

export default function NavigationLinkButton(props: LinkProps) {
  return <SectionLink {...props} className="flex items-center mb3 ph3" />;
}

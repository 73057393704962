import React from "react";
import styled from "styled-components";

import errorService from "../common-lib/error";
import LoadingSpinner from "./LoadingSpinner";
import useParticipantSearch, {
  Filters,
  ParticipantSearchResult,
} from "../hooks/useParticipantSearch";
import { ParticipantId } from "../context/types";
import Avatar from "./Avatar";
import { getFullName } from "../helpers/user";
import { getCountryNameByCode } from "./CountrySelect";

const Ul = styled.ul`
  // The height of a single 'li'
  min-height: 50px;
`;
export interface ParticipantsAllProps {
  onSelect: (participant: ParticipantSearchResult) => void;
  excludeIds?: Array<ParticipantId>;
  searchTerm: string | undefined;
  filters?: Filters;
}
const ParticipantsAll: React.FC<ParticipantsAllProps> = (props) => {
  const { onSelect, excludeIds = [], searchTerm = "", filters } = props;
  const { loading, error, participants } = useParticipantSearch({
    searchTerm,
    excludeIds,
    filters,
  });

  if (error) {
    errorService.error(error);
  }

  return (
    <Ul className="relative flex-auto">
      {loading && <LoadingSpinner type="overlay" />}
      {participants.map((participant) => {
        const country = getCountryNameByCode(participant.country_code);
        return (
          <li
            className="card mb2 flex"
            key={`Participants-li-${participant.id}`}
          >
            <button
              className="flex items-center"
              onClick={() => onSelect(participant)}
              disabled={loading}
            >
              <Avatar className="mr2" person={participant} />
              {getFullName(participant.sensitive_fields)}
              {country && ` (${country})`}
              {loading && <LoadingSpinner type="inline" />}
            </button>
          </li>
        );
      })}
    </Ul>
  );
};

export default ParticipantsAll;

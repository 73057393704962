import { useQuery } from "@apollo/client";

import FETCH_USERS from "../graphql/queries/fetch-users";
import useSessionOrganisation from "./useSessionOrganisation";
import { OrganisationId } from "../context/types";
import truthy from "@/helpers/truthy";
import { User_Order_By } from "@/graphql/generated/graphql";

interface Opts {
  searchTerm?: string | undefined;
  orderBy?: User_Order_By[];
  filters?: {
    organisationId: OrganisationId;
  };
}
const useUsers = (opts: Opts = {}) => {
  const organisation = useSessionOrganisation();
  const { searchTerm = "", orderBy = [], filters } = opts;

  const [firstName, lastName] = searchTerm.split(" ");

  const { data, error, loading, refetch } = useQuery(FETCH_USERS, {
    variables: {
      dynamicOrderBy: orderBy,
      dynamicWhere: {
        _and: [
          filters?.organisationId
            ? {
                organisation_users: {
                  organisation_id: { _eq: filters.organisationId },
                },
              }
            : null,
          organisation
            ? {
                organisation_users: {
                  organisation_id: { _eq: organisation.id },
                },
              }
            : null,
          { _not: { deactivated_at: { _is_null: false } } },
          {
            _or: [
              {
                email: { _ilike: `%${searchTerm}%` },
              },
              {
                first_name: { _ilike: `%${firstName}%` },
                last_name: { _ilike: `%${lastName}%` },
              },
              {
                last_name: { _ilike: `%${firstName}%` },

                first_name: { _ilike: `%${lastName}%` },
              },
            ],
          },
        ].filter(truthy),
      },
    },
  });

  const users = data ? data.user : [];

  return { users, error, loading, refetch };
};

export default useUsers;

import React, { Fragment, useState, ChangeEvent } from "react";
import useLocalStorageState from "use-local-storage-state";

import { Select } from "./field/SelectField";
import ParticipantForm from "./ParticipantForm";
import Button from "./Button";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import CREATE_REGISTER_PARTICIPANT from "../graphql/queries/create-register-participant";
import RegisterAddFromResults from "./RegisterAddFromResults";
import RegisterAddFromAll from "./RegisterAddFromAll";
import { FetchRegisterQuery } from "@/graphql/generated/graphql";
import { useIsProgrammeClosed } from "@/hooks/useIsProgrammeClosed";

type ADD_FROM_VALUE = "latest_here" | "all" | "latest_anywhere";

export const StyledSelect = styled(Select)`
  margin-right: auto;
  width: auto;
  font-size: 1.4rem;
  opacity: 0.6;
  margin-top: -3px;
  padding: 0;
  border: 1px solid #aaa;
`;
const AddFromSelectWrapper = styled.div`
  width: 180px;
  margin-right: auto;
`;

interface RegisterAddFromProps {
  register: NonNullable<FetchRegisterQuery["register_by_pk"]>;
  onAdd: () => void;
}

const RegisterAddFrom: React.FC<RegisterAddFromProps> = (props) => {
  const { register, onAdd } = props;
  const isProgrammeClosed = useIsProgrammeClosed();

  const [addFrom, setAddFrom] = useLocalStorageState<ADD_FROM_VALUE>(
    "RegsterAddFrom-addFrom",
    {
      defaultValue: "latest_here",
    }
  );

  const [addFromSearchTerm, setAddFromSearchTerm] = useState("");

  const [addParticipant] = useMutation(CREATE_REGISTER_PARTICIPANT, {
    onCompleted: () => {
      onAdd();
    },
  });

  return (
    <Fragment>
      <header className="flex justify-between items-center mb3">
        <h2>Add from</h2>
        <AddFromSelectWrapper>
          <StyledSelect
            className="ml2"
            value={addFrom}
            // @ts-ignore
            onChange={(e) => setAddFrom(e.target.value)}
          >
            <option value="latest_here">
              {register.location.name} /{" "}
              {register.register_project_categories
                .map((rpc) => rpc.project_category.name)
                .join(", ")}{" "}
              / {register.project.name}
            </option>
            <option value="latest_anywhere">
              {" "}
              {register.register_project_categories
                .map((rpc) => rpc.project_category.name)
                .join(", ")}{" "}
              / {register.project.name}
            </option>
            <option value="all">All</option>
          </StyledSelect>
        </AddFromSelectWrapper>
        <ParticipantForm
          Opener={(props) => (
            <Button
              title="Add a new person to the database"
              {...props}
              raised
              fill
              disabled={isProgrammeClosed}
            >
              New
              <i className="material-icons ml2">person_add</i>
            </Button>
          )}
          onClose={() => undefined}
          onCreate={(id) => {
            if (id) {
              addParticipant({
                variables: {
                  register_participant: {
                    register_id: register.id,
                    participant_id: id,
                  },
                },
              });
            }
          }}
        />
      </header>
      <input
        className="w-100 pa2 mb3"
        placeholder="Search"
        value={addFromSearchTerm}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setAddFromSearchTerm(e.target.value)
        }
      />
      {addFrom !== "all" && (
        <RegisterAddFromResults
          searchTerm={addFromSearchTerm}
          projectId={register.project.id}
          locationId={
            addFrom === "latest_here" ? register.location.id : undefined
          }
          categoryIds={register.register_project_categories.map(
            (rpc) => rpc.project_category.id
          )}
          excludeIds={register.register_participants.map(
            (registerParticipant) => registerParticipant.participant.id
          )}
          registerId={register.id}
          onAdd={onAdd}
        />
      )}
      {addFrom === "all" && (
        <RegisterAddFromAll
          searchTerm={addFromSearchTerm}
          excludeIds={register.register_participants.map(
            (registerParticipant) => registerParticipant.participant.id
          )}
          registerId={register.id}
          onAdd={onAdd}
        />
      )}
    </Fragment>
  );
};

export default RegisterAddFrom;

import React, {
  useState,
  useEffect,
  useMemo,
  Fragment,
  useCallback,
} from "react";
import styled from "styled-components";

import useOrganisations from "../hooks/useOrganisations";
import useProgrammes from "../hooks/useProgrammes";
import TextField from "./field/TextField";
import { useLazyQuery } from "@apollo/client";
import { isValid } from "date-fns";
import ErrorMessage from "./ErrorMessage";
import REGISTER_PARTICIPANT_AGGREGATE from "../graphql/queries/register-participant-aggregate";
import {
  getDownloadReportBreakdownPayload,
  ReportByAge,
  ReportByCountry,
  ReportByGender,
  ReportByLocation,
  ReportByOrganisation,
  ReportByProject,
} from "./ReportBreakdown";
import CountrySelect, {
  CountryCode,
  getCountryNameByCode,
} from "./CountrySelect";
import { AdminGridFilters } from "./AdminGrid";
import useSessionProgramme from "../hooks/useSessionProgramme";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import Switch from "./field/Switch";
import useLocations from "../hooks/useLocations";
import useProjects from "../hooks/useProjects";
import FETCH_REGISTERS from "../graphql/queries/fetch-registers";
import colours from "../constants/colours";
import SelectField from "./field/SelectField";
import {
  LocationId,
  OrganisationId,
  ProgrammeId,
  ProjectId,
} from "../context/types";
import Button from "./Button";
import { getNamesFromIds } from "../helpers/util";
import OrganisationMultiSelect from "./OrganisationMultiSelect";
import ProgrammeMultiSelect from "./ProgrammeMultiSelect";
import ProjectMultiSelect from "./ProjectMultiSelect";
import LocationMultiSelect from "./LocationMultiSelect";
import LoadingSpinner from "./LoadingSpinner";
import ReportsAnswerSection from "./ReportsAnswerSection";
import truthy from "../helpers/truthy";
import useDownloadReport from "../api/use-download-report";
import {
  Register_Participant_Select_Column,
  RegisterParticipantAggregateQueryVariables,
} from "@/graphql/generated/graphql";

const QueryBuider = styled.p`
  max-width: 550px;
  line-height: 1.6;
  margin-top: 0;
`;

const FilterHeading = styled.h3`
  margin-top: 1.8rem;
`;

const AdminReports: React.FC = () => {
  const sessionOrganisation = useSessionOrganisation();
  const sessionProgramme = useSessionProgramme();

  /**
   * In order to ensure the user is download what the intend to, we only show the download
   * button when the current set of results matches the search query.
   */
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const { organisations } = useOrganisations();
  const { programmes } = useProgrammes();
  const { locations } = useLocations();
  const [organisationIds, setOrganisationIds] = useState<OrganisationId[]>(
    sessionOrganisation ? [sessionOrganisation.id] : []
  );
  const [programmeIds, setProgrammeIds] = useState<ProgrammeId[]>(
    sessionProgramme ? [sessionProgramme.id] : []
  );

  const { projects } = useProjects({
    organisationIds,
    programmeIds,
  });

  useEffect(() => {
    if (sessionProgramme) {
      setProgrammeIds([sessionProgramme.id]);
    }
  }, [sessionProgramme?.id]);

  const [projectIds, setProjectIds] = useState<ProjectId[]>([]);
  const [locationIds, setLocationIds] = useState<LocationId[]>([]);

  const projectCategories =
    projects.find(({ id }) => projectIds.includes(id))?.categories || [];

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [validationError, setValidationError] = useState("");
  const [countryFilter, setCountryFilter] = useState<CountryCode>();
  const [youngerThanFilter, setYoungerThanFilter] = useState<string | null>(
    null
  );
  const [olderThanFilter, setOlderThanFilter] = useState<string | null>(null);
  const [categoryId, setCategoryId] = useState<string>();
  const [uniqueParticipantsFilter, setUniqueParticipantsFilter] =
    useState(true);

  // TODO: show error if query fails
  const [
    fetchRegisterParticipantAggregateData,
    { data, loading: participantsLoading, error: participantAggregateError },
  ] = useLazyQuery(REGISTER_PARTICIPANT_AGGREGATE);
  const [
    fetchRegistersData,
    { data: registersData, loading: registersLoading, error: registersError },
  ] = useLazyQuery(FETCH_REGISTERS);

  const variables: RegisterParticipantAggregateQueryVariables = {
    dynamicWhere: {
      register: {
        ...(organisationIds.length
          ? {
              organisation_id: { _in: organisationIds },
            }
          : null),
        ...(programmeIds.length
          ? { project: { programme_id: { _in: programmeIds } } }
          : null),
        ...(projectIds.length ? { project_id: { _in: projectIds } } : null),
        ...(locationIds.length ? { location_id: { _in: locationIds } } : null),
        ...(categoryId
          ? {
              register_project_categories: {
                project_category: { id: { _eq: categoryId } },
              },
            }
          : null),
        deleted_at: { _is_null: true },
        date: { _gte: start },
        _and: [{ date: { _lte: end } }],
      },
      participant: {
        ...(countryFilter ? { country_code: { _eq: countryFilter } } : null),
      },
      computed: {
        _and: [
          olderThanFilter
            ? { participant_age: { _gte: olderThanFilter } }
            : null,
          youngerThanFilter
            ? { participant_age: { _lt: youngerThanFilter } }
            : null,
        ].filter(truthy),
      },
    },
    distinctOn: uniqueParticipantsFilter
      ? Register_Participant_Select_Column.ParticipantId
      : undefined,
  };

  const validatePayload = useCallback(() => {
    setValidationError("");
    if (!isValid(new Date(start))) {
      setValidationError("You must set a start date.");
      return false;
    }
    if (!isValid(new Date(end))) {
      setValidationError("You must set an end date.");
      return false;
    }
    return true;
  }, [start, end]);

  useEffect(() => {
    /**
     * on query change
     */
    setShowDownloadButton(false);
  }, [JSON.stringify(variables)]);

  const fetchReport = useCallback(() => {
    if (validatePayload()) {
      /**
       * fetch is now up to date with local payload, so show download button
       * (it will only actually show when results come back)
       */
      setShowDownloadButton(true);
      fetchRegisterParticipantAggregateData({
        variables,
      });
      fetchRegistersData({
        variables: {
          dynamicWhere: variables.dynamicWhere?.register,
        },
      });
    }
  }, [JSON.stringify(variables)]);

  const { loading: downloading, downloadReport } = useDownloadReport();

  const countIncludingOverrides = useMemo(
    () =>
      registersData?.register?.reduce((out, register) => {
        if (register.override_total_participants) {
          return out + register.override_total_participants;
        }

        return (
          out + (register.register_participants_aggregate.aggregate?.count || 0)
        );
      }, 0),
    [registersData]
  );

  const totalOrgsInAttendance = useMemo(
    () =>
      registersData?.register?.reduce((out, register) => {
        if (register.number_of_orgs_in_attendance) {
          return out + register.number_of_orgs_in_attendance;
        }

        return out;
      }, 0),
    [registersData]
  );

  const count = data?.register_participant_aggregate?.aggregate?.count;
  const registerParticipants = data?.register_participant || [];
  const totalSessions = registersData?.register.length;

  const loading = participantsLoading || registersLoading;

  return (
    <div>
      <QueryBuider>
        <h1>Report Builder</h1>
        <TextField
          name="state-date"
          label="Start date"
          value={start}
          inputProps={{ type: "date" }}
          onChange={setStart}
        />
        <TextField
          name="end-date"
          label="End date"
          value={end}
          inputProps={{ type: "date" }}
          onChange={setEnd}
        />
        <ProgrammeMultiSelect
          label="Programmes"
          name="reports-programmes"
          programmeIds={programmeIds}
          setProgrammeIds={setProgrammeIds}
        />
        <OrganisationMultiSelect
          label="Organisations (filtered by programmes)"
          name="reports-organisations"
          organisationIds={organisationIds}
          setOrganisationIds={setOrganisationIds}
          useOrganisationsOpts={{
            programmeIds,
          }}
        />
        <ProjectMultiSelect
          label="Projects (filtered by programmes and organsiations)"
          name="reports-projects"
          projectIds={projectIds}
          setProjectIds={setProjectIds}
          useProjectOpts={{
            organisationIds,
            programmeIds,
          }}
        />
        <LocationMultiSelect
          label="Locations"
          name="reports-locations"
          locationIds={locationIds}
          setLocationIds={setLocationIds}
        />
      </QueryBuider>
      <AdminGridFilters className="pa3 mb3">
        <h2>
          <button
            onClick={() => setFiltersExpanded(!filtersExpanded)}
            className="flex items-center"
          >
            More filters{" "}
            <i className="material-icons">
              {filtersExpanded ? "expand_less" : "expand_more"}
            </i>
          </button>
        </h2>
        {filtersExpanded && (
          <div className="w-50">
            <Switch
              name="unique-attendances"
              onLabel="Unique participants"
              offLabel="Total attendances"
              checked={uniqueParticipantsFilter}
              onChange={setUniqueParticipantsFilter}
            />
            <FilterHeading>Participant</FilterHeading>
            <CountrySelect
              label="Country"
              name="country"
              value={countryFilter}
              onSelect={setCountryFilter}
            />
            <TextField
              name="older-than"
              label="Older than ( >= age at time of session)"
              value={olderThanFilter}
              inputProps={{ type: "number" }}
              onChange={setOlderThanFilter}
            />
            <TextField
              name="younger-than"
              label="Younger than ( < age at time of session)"
              value={youngerThanFilter}
              inputProps={{ type: "number" }}
              onChange={setYoungerThanFilter}
            />
            <FilterHeading>Project</FilterHeading>
            <SelectField
              label={"Category (filtered by projects)"}
              name={"project-categories"}
              options={projectCategories.map((category) => ({
                value: category.id,
                label: category.name,
              }))}
              value={categoryId || ""}
              onChange={setCategoryId}
            />
          </div>
        )}
      </AdminGridFilters>
      <section className="flex mb4 items-center">
        <Button onClick={fetchReport} disabled={loading} raised fill>
          Fetch Report
        </Button>
        {showDownloadButton && typeof count === "number" && (
          <Button
            className="ml2"
            disabled={loading || downloading}
            onClick={() =>
              downloadReport({
                filters: {
                  country: getCountryNameByCode(countryFilter),
                  organisation: getNamesFromIds(organisations, organisationIds),
                  programme: getNamesFromIds(programmes, programmeIds),
                  location: getNamesFromIds(locations, locationIds),
                  project: getNamesFromIds(projects, projectIds),
                  category: getNamesFromIds(
                    projectCategories,
                    categoryId ? [categoryId] : []
                  ),
                  fromDate: start,
                  toDate: end,
                  olderThan: olderThanFilter,
                  youngerThan: youngerThanFilter,
                },
                isAttendanceUnique: uniqueParticipantsFilter,
                totalAttendancesExcludingManualOverride: count,
                totalAttendancesIncludingManualOverride:
                  countIncludingOverrides,
                totalOrgsInAttendance,
                breakdowns: getDownloadReportBreakdownPayload({
                  count,
                  registerParticipants,
                }),
              })
            }
            fill
            raised
          >
            Download Results (.xlsx)
          </Button>
        )}
        {(loading || downloading) && <LoadingSpinner type="inline" small />}
      </section>
      <section>
        {validationError && (
          <ErrorMessage title="Fix these errors and try again">
            {validationError}
          </ErrorMessage>
        )}
        {(participantAggregateError || registersError) && (
          <ErrorMessage>
            There was an error fetching your report. We've been notified and are
            working to fix the issue.
          </ErrorMessage>
        )}
        {typeof count === "number" &&
          typeof countIncludingOverrides === "number" && (
            <Fragment>
              <h1 className="flex">
                Results
                {loading && <LoadingSpinner type="inline" xsmall />}
              </h1>
              <div>
                <ReportsAnswerSection title="Total Sessions" initialExpanded>
                  {totalSessions}{" "}
                  {uniqueParticipantsFilter && (
                    <span style={{ color: colours.textGreyLight }}>
                      (to get the average count per session, please select
                      "Total attendances" above)
                    </span>
                  )}
                </ReportsAnswerSection>
                {typeof totalOrgsInAttendance === "number" &&
                  typeof totalSessions === "number" && (
                    <ReportsAnswerSection
                      title="Total Organisations in Attendance"
                      initialExpanded
                    >
                      <h3>Total: {totalOrgsInAttendance}</h3>

                      <h3>
                        Average:{" "}
                        {Math.round(
                          (totalOrgsInAttendance / totalSessions) * 10
                        ) / 10}{" "}
                      </h3>
                    </ReportsAnswerSection>
                  )}
                {!uniqueParticipantsFilter &&
                  typeof totalSessions === "number" && (
                    <ReportsAnswerSection
                      title="Average Participants per Session"
                      initialExpanded
                    >
                      {Math.round((count / totalSessions) * 10) / 10}{" "}
                      <span style={{ color: colours.textGreyLight }}>
                        (note: this does not include manual attendance
                        overrides)
                      </span>
                      <br />
                      <br />
                      {Math.round(
                        (countIncludingOverrides / totalSessions) * 10
                      ) / 10}{" "}
                      including manual attendance overrides{" "}
                      <span style={{ color: colours.textGreyLight }}>
                        (note: this number ignores the participant filters
                        above)
                      </span>
                    </ReportsAnswerSection>
                  )}
                <ReportsAnswerSection
                  title={`Total
                    ${
                      uniqueParticipantsFilter
                        ? "Unique Participants"
                        : "Attendances"
                    }`}
                  initialExpanded
                >
                  {count}{" "}
                  <span style={{ color: colours.textGreyLight }}>
                    (note: this does not include manual attendance overrides)
                  </span>
                  <br />
                  {!uniqueParticipantsFilter && (
                    <Fragment>
                      <br />
                      {countIncludingOverrides} including manual attendance
                      overrides{" "}
                      <span style={{ color: colours.textGreyLight }}>
                        (note: this number ignores the participant filters
                        above)
                      </span>
                      <br />
                      <br />
                      <ErrorMessage>
                        Note: The breakdowns below do not include manual
                        attendance overrides
                      </ErrorMessage>
                    </Fragment>
                  )}
                </ReportsAnswerSection>
                <ReportsAnswerSection title="Breakdown by Age">
                  {!count ? (
                    "No data to display"
                  ) : (
                    <ReportByAge
                      count={count}
                      registerParticipants={registerParticipants}
                    />
                  )}
                </ReportsAnswerSection>
                <ReportsAnswerSection title="Breakdown by Country">
                  {!count ? (
                    "No data to display"
                  ) : (
                    <ReportByCountry
                      count={count}
                      registerParticipants={registerParticipants}
                    />
                  )}
                </ReportsAnswerSection>
                <ReportsAnswerSection title="Breakdown by Gender">
                  {!count ? (
                    "No data to display"
                  ) : (
                    <ReportByGender
                      count={count}
                      registerParticipants={registerParticipants}
                    />
                  )}
                </ReportsAnswerSection>
                <ReportsAnswerSection title="Breakdown by Organisation">
                  {!count ? (
                    "No data to display"
                  ) : (
                    <ReportByOrganisation
                      count={count}
                      registerParticipants={registerParticipants}
                    />
                  )}
                </ReportsAnswerSection>
                <ReportsAnswerSection title="Breakdown by Location">
                  {!count ? (
                    "No data to display"
                  ) : (
                    <ReportByLocation
                      count={count}
                      registerParticipants={registerParticipants}
                    />
                  )}
                </ReportsAnswerSection>
                <ReportsAnswerSection title="Breakdown by Project">
                  {!count ? (
                    "No data to display"
                  ) : (
                    <ReportByProject
                      count={count}
                      registerParticipants={registerParticipants}
                    />
                  )}
                </ReportsAnswerSection>
              </div>
            </Fragment>
          )}
      </section>
    </div>
  );
};

export default AdminReports;

import { FetchPolicy, useQuery } from "@apollo/client";

import FETCH_ORGANISATIONS from "../graphql/queries/fetch-organisations";
import { ProgrammeId, OrganisationId } from "../context/types";

export type UseOrganisationsOpts = {
  programmeIds?: ProgrammeId[];
  excludeIds?: OrganisationId[];
  fetchPolicy?: FetchPolicy;
};
const useOrganisations = (props: UseOrganisationsOpts = {}) => {
  const { programmeIds, excludeIds, fetchPolicy = "cache-and-network" } = props;
  const { data, error, loading, refetch } = useQuery(FETCH_ORGANISATIONS, {
    partialRefetch: true,
    fetchPolicy,
    variables: {
      dynamicWhere: {
        id: { _nin: excludeIds },
        ...(programmeIds && programmeIds.length
          ? {
              organisation_programmes: {
                programme: {
                  id: { _in: programmeIds },
                },
              },
            }
          : null),
      },
    },
  });

  const organisations = data ? data.organisation : [];

  return { organisations, error, loading: !data && loading, refetch };
};

export type UseOrganisationsReturnType = ReturnType<typeof useOrganisations>;
export type Organisations = UseOrganisationsReturnType["organisations"];

export default useOrganisations;

import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import Button from "./Button";
import FlatButton from "./FlatButton";
import ParticipantForm from "./ParticipantForm";
import { getFullName } from "../helpers/user";
import { getAge } from "../helpers/participant";
import Avatar from "./Avatar";
import useParticipantSearch, { Filters } from "../hooks/useParticipantSearch";
import IconButton from "./IconButton";
import { LanguageCode, LanguageSelectSingle } from "./LanguagesSelect";
import { getParticipantPath } from "../helpers/route-path";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import useSessionProgramme from "../hooks/useSessionProgramme";
import {
  AdminGrid,
  AdminGridCell,
  AdminGridSearch,
  AdminGridFilters,
  AdminGridHeader,
  AdminGridHeading,
  AdminGridLoadMore,
} from "./AdminGrid";
import CountrySelect, {
  CountryCode,
  getCountryNameByCode,
} from "./CountrySelect";
import AgeInput from "./AgeInput";
import ParticipantMissingInfo from "./ParticipantMissingInfo";
import errorService from "../common-lib/error";
import TextField from "./field/TextField";
import useRefetchOnLand from "../hooks/useRefetchOnLand";
import {
  Order_By,
  Participant_Sensitive_Fields_Order_By,
} from "@/graphql/generated/graphql";

const defaultOrder = [
  { first_name: Order_By.Asc },
  { last_name: Order_By.Asc },
];

const headings: AdminGridHeading<Participant_Sensitive_Fields_Order_By>[] = [
  {
    label: "Name",
    order: {
      asc: [{ first_name: Order_By.Asc }, { last_name: Order_By.Asc }],
      desc: [{ first_name: Order_By.Desc }, { last_name: Order_By.Desc }],
    },
  },
  {
    label: "Age",
    order: {
      asc: [{ participant: { date_of_birth: Order_By.Asc } }, ...defaultOrder],
      desc: [
        { participant: { date_of_birth: Order_By.Desc } },
        ...defaultOrder,
      ],
    },
  },
  {
    label: "Country",
    order: {
      asc: [{ participant: { country_code: Order_By.Asc } }, ...defaultOrder],
      desc: [{ participant: { country_code: Order_By.Desc } }, ...defaultOrder],
    },
  },
  {
    label: "Languages",
  },
];

const Participants: React.FC = () => {
  const organisation = useSessionOrganisation();
  const programme = useSessionProgramme();
  const [searchTerm, setSearchTerm] = useState("");
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    languageCode: undefined,
    countryCode: undefined,
    dobAfter: undefined,
    dobBefore: undefined,
    dobEqual: undefined,
    phone: undefined,
  });
  const [orderBy, setOrderBy] =
    useState<Participant_Sensitive_Fields_Order_By[]>(defaultOrder);

  const addLanguageFilter = (languageCode: LanguageCode) => {
    setFilters({
      ...filters,
      languageCode,
    });
  };

  const addCountryFilter = (countryCode: CountryCode) => {
    setFilters({
      ...filters,
      countryCode,
    });
  };

  const addDobAfterFilter = (date?: Date) => {
    setFilters({
      ...filters,
      dobAfter: date,
    });
  };

  const addDobBeforeFilter = (date?: Date) => {
    setFilters({
      ...filters,
      dobBefore: date,
    });
  };

  const addDobEqualFilter = (date: string | null) => {
    // Signature different to other date filters since it's an 'input'
    setFilters({
      ...filters,
      dobEqual: date ? new Date(date) : undefined,
    });
  };

  const addPhoneFilter = (phone: string) => {
    setFilters({
      ...filters,
      phone,
    });
  };

  const { loading, error, refetch, participants, loadMore, endOfResults } =
    useParticipantSearch({
      searchTerm,
      excludeIds: [],
      filters,
      orderBy,
    });

  useRefetchOnLand({
    loading,
    refetch,
  });

  if (!organisation || !programme) {
    return null;
  }

  if (error) {
    errorService.error(error);
  }

  return (
    <div className="flex-auto">
      <header className="flex justify-between items-center mb3">
        <h1>Participants</h1>
        <ParticipantForm
          Opener={(props) => (
            <Button {...props} raised fill>
              New participant
            </Button>
          )}
          onClose={refetch}
        />
      </header>
      <div className="flex mb2">
        <AdminGridSearch
          className="w-100 pa2 flex-auto"
          placeholder="Search"
          value={searchTerm}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(e.target.value)
          }
        />
        <IconButton
          className="ph2"
          icon="filter_list"
          onClick={() => setFiltersOpen(!filtersOpen)}
        />
      </div>
      {filtersOpen && (
        <AdminGridFilters className="pt1 pb3 ph2 mb2">
          <h2 className="mb0">Filters</h2>
          <div className="w-50">
            <LanguageSelectSingle
              label="Language"
              name="language"
              value={filters.languageCode}
              onSelect={addLanguageFilter}
            />
            <CountrySelect
              label="Country"
              name="country"
              value={filters.countryCode}
              onSelect={addCountryFilter}
            />
            <AgeInput
              name="older-than"
              label="Older than (years)"
              date={filters.dobBefore}
              onDateChange={addDobBeforeFilter}
            />
            <AgeInput
              name="younger-than"
              label="Younger than (years)"
              date={filters.dobAfter}
              onDateChange={addDobAfterFilter}
            />
            <TextField
              name="date_of_birth"
              label="Date of Birth"
              value={filters.dobEqual?.toISOString().split("T")[0] || null}
              inputProps={{ type: "date" }}
              onChange={addDobEqualFilter}
            />
            <TextField
              onChange={addPhoneFilter}
              value={filters.phone || ""}
              label="Phone Number"
              name="phone-filter"
            />
          </div>
        </AdminGridFilters>
      )}
      <AdminGrid columns={headings.length}>
        <AdminGridHeader
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          headings={headings}
        />
        {participants.map((participant) => {
          return (
            <Fragment key={`Participants-li-${participant.id}`}>
              <AdminGridCell>
                <Avatar size="small" person={participant} />
                <div className="ml2 flex items-center justify-center">
                  <Link
                    to={getParticipantPath({
                      organisationSlug: organisation.slug,
                      programmeSlug: programme.slug,
                      participantId: participant.id,
                    })}
                  >
                    {getFullName(participant.sensitive_fields)}
                  </Link>
                  <ParticipantMissingInfo participant={participant} />
                </div>
              </AdminGridCell>
              <AdminGridCell>{getAge(participant)}</AdminGridCell>
              <AdminGridCell>
                {getCountryNameByCode(participant.country_code)}
              </AdminGridCell>
              <AdminGridCell>
                {participant.participant_languages
                  .map(({ language }) => language.name)
                  .join(", ")}
              </AdminGridCell>
            </Fragment>
          );
        })}
      </AdminGrid>
      {!loading && (
        <AdminGridLoadMore>
          <FlatButton onClick={loadMore} disabled={endOfResults}>
            {endOfResults ? "End of Results" : "Load More"}
          </FlatButton>
        </AdminGridLoadMore>
      )}
      {loading && <LoadingSpinner className="mt3" type="row" />}
    </div>
  );
};

export default Participants;

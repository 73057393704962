import React, { useState, Fragment } from "react";
import Dialog from "./Dialog";
import Avatar from "./Avatar";
import { getFullName } from "../helpers/user";
import { ParticipantId } from "../context/types";

interface OpenerProps {
  onClick: () => void;
}
interface Participant {
  sensitive_fields?: {
    first_name: string;
    last_name: string;
  } | null;
  id: string;
}

interface ParticipantsDialogProps {
  participants: Array<Participant>;
  excludeIds?: Set<ParticipantId>;
  title?: string;
  onSelect: (participant: Participant) => void;
  Opener: React.FC<OpenerProps>;
}
const ParticipantsDialog: React.FC<ParticipantsDialogProps> = (props) => {
  const {
    title,
    onSelect,
    participants,
    excludeIds = new Set(),
    Opener,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const onRequestOpen = () => setIsOpen(true);
  const onRequestClose = () => setIsOpen(false);

  const participantsFiltered = participants.filter(
    (participant) => !excludeIds.has(participant.id)
  );

  return (
    <Fragment>
      <Dialog
        fullHeight
        title={title || "Participants"}
        onRequestClose={onRequestClose}
        isOpen={isOpen}
      >
        <ul className="relative flex-auto">
          {participantsFiltered.map((participant) => {
            return (
              <li
                className="card mb2 flex"
                key={`Participants-li-${participant.id}`}
              >
                <button
                  className="flex items-center flex-auto"
                  onClick={() => onSelect(participant)}
                >
                  <Avatar className="mr2" person={participant} />
                  {getFullName(participant.sensitive_fields)}
                </button>
              </li>
            );
          })}
        </ul>
      </Dialog>
      <Opener onClick={onRequestOpen} />
    </Fragment>
  );
};

export default ParticipantsDialog;

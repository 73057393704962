import { graphql } from "../generated";

const UPDATE_REGISTER= graphql(`
  mutation UpdateRegister(
    $id: uuid!
    $register: register_set_input!
    $facilitators_to_add: [register_facilitator_insert_input!]!
    $facilitator_ids_to_remove: [uuid!]!
    $register_project_categories: [register_project_category_insert_input!]!
  ) {
    update_register(_set: $register, where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_register_project_category(where: { register_id: { _eq: $id } }) {
      returning {
        project_category_id
      }
    }
    insert_register_project_category(
      objects: $register_project_categories
      on_conflict: {
        constraint: register_project_category_pkey
        update_columns: []
      }
    ) {
      returning {
        project_category_id
      }
    }
    insert_register_facilitator(
      objects: $facilitators_to_add
      on_conflict: { constraint: register_facilitator_pkey, update_columns: [] }
    ) {
      returning {
        facilitator_id
      }
    }
    # Delete last in case they delete themselves and in doing so, revoke their permissions to update the register
    delete_register_facilitator(
      where: {
        register_id: { _eq: $id }
        facilitator_id: { _in: $facilitator_ids_to_remove }
      }
    ) {
      returning {
        facilitator_id
      }
    }
  }
`);

export default UPDATE_REGISTER;

import "react-app-polyfill/stable";

import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import { Provider as StoreProvider } from "./context/store";
import ErrorBoundary from "./components/ErrorBoundary";
import { ToastProvider } from "./components/Toast";

export default function CreateReactAppEntryPoint() {
  return (
    <StoreProvider>
      <ToastProvider>
        <Router>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Router>
      </ToastProvider>
    </StoreProvider>
  );
}

import React, { FC, ReactNode, useState } from "react";
import styled from "styled-components";

const Root = styled.div`
  margin-bottom: 2rem;
`;

type ReportsAnswerSectionProps = {
  title: string;
  initialExpanded?: boolean;
  children?: ReactNode;
};
const ReportsAnswerSection: FC<ReportsAnswerSectionProps> = (props) => {
  const { title, children, initialExpanded = false } = props;
  const [expanded, setExpanded] = useState(initialExpanded);
  return (
    <Root>
      <h2>
        <button
          onClick={() => setExpanded(!expanded)}
          className="flex items-center"
        >
          {title}{" "}
          <i className="material-icons">
            {expanded ? "expand_less" : "expand_more"}
          </i>
        </button>
      </h2>
      {expanded ? children : null}
    </Root>
  );
};

export default ReportsAnswerSection;

import { graphql } from "../generated";

const UPDATE_REGISTER_SENSITIVE_FIELDS = graphql(`
  mutation UpdateRegisterSensitiveFields(
    $register_id: uuid!
    $register_sensitive_fields: register_sensitive_fields_set_input!
  ) {
    update_register_sensitive_fields(_set: $register_sensitive_fields, where: { register_id: { _eq: $register_id } }) {
      returning {
        register_id
        notes
        created_at
        updated_at
      }
    }
  }
`);

export default UPDATE_REGISTER_SENSITIVE_FIELDS;

import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";

import LoadingSpinner from "./LoadingSpinner";
import Button from "./Button";
import ProjectForm from "./ProjectForm";
import useProjects from "../hooks/useProjects";
import { getAdminProjectPath } from "../helpers/route-path";
import {
  AdminGrid,
  AdminGridCell,
  AdminGridHeading,
  AdminGridHeader,
} from "./AdminGrid";
import { Order_By, Project_Order_By } from "@/graphql/generated/graphql";

const defaultOrder = [{ name: Order_By.Asc }];

const headings: AdminGridHeading<Project_Order_By>[] = [
  {
    label: "Name",
    order: {
      asc: [{ name: Order_By.Asc }],
      desc: [{ name: Order_By.Desc }],
    },
  },
  {
    label: "Programme",
    order: {
      asc: [{ programme: { name: Order_By.Asc } }, ...defaultOrder],
      desc: [{ programme: { name: Order_By.Desc } }, ...defaultOrder],
    },
  },
  {
    label: "Organisation",
    order: {
      asc: [{ organisation: { name: Order_By.Asc } }, ...defaultOrder],
      desc: [{ organisation: { name: Order_By.Desc } }, ...defaultOrder],
    },
  },
];
const AdminProjects: React.FC = () => {
  const [orderBy, setOrderBy] = useState<Project_Order_By[]>(defaultOrder);

  const { projects, error, loading, refetch } = useProjects({
    orderBy,
  });
  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <header className="flex justify-between items-center mb3">
        <h1>Admin/Projects </h1>
        <ProjectForm
          onClose={refetch}
          Opener={(props) => (
            <Button {...props} raised fill>
              New project
            </Button>
          )}
        />
      </header>
      <AdminGrid columns={headings.length}>
        <AdminGridHeader
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          headings={headings}
        />
        {projects.map((project) => {
          const { id, slug, name, programme, organisation } = project;

          return (
            <Fragment key={`AdminProjects-li-${id}`}>
              <AdminGridCell>
                <Link to={getAdminProjectPath({ projectSlug: slug })}>
                  {name}
                </Link>
              </AdminGridCell>
              <AdminGridCell>{programme.name}</AdminGridCell>
              <AdminGridCell>{organisation.name}</AdminGridCell>
            </Fragment>
          );
        })}
      </AdminGrid>
      {loading && <LoadingSpinner type="row" />}
      {error && <p>{error.message}</p>}
    </div>
  );
};

export default AdminProjects;

import React, { Fragment, useEffect } from "react";
import {
  withRouter,
  RouteComponentProps,
  useParams,
  Link,
} from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import FETCH_ORGANISATION from "../graphql/queries/fetch-organisation";
import LoadingSpinner from "./LoadingSpinner";
import OrganisationForm from "./OrganisationForm";
import Button from "./Button";
import styled from "styled-components";
import Icon from "./Icon";
import ProgrammesAllDialog from "./ProgrammesAllDialog";
import IconButton from "./IconButton";
import CREATE_ORGANISATION_PROGRAMME from "../graphql/queries/create-organisation-programme";
import DELETE_ORGANISATION_PROGRAMME from "../graphql/queries/delete-organisation_programme";
import CREATE_ORGANISATION_MANAGER from "../graphql/queries/create-organisation-manager";
import errorService from "../common-lib/error";
import ErrorMessage from "./ErrorMessage";
import {
  getAdminLocationsPath,
  getAdminLocationPath,
  getAdminProjectPath,
  getAdminProjectsPath,
  getAdminUserPath,
} from "../helpers/route-path";
import colours from "../constants/colours";
import { getFullName } from "../helpers/user";
import DELETE_ORGANISATION_MANAGER from "../graphql/queries/delete-organisation-manager";
import { getIsManager } from "../helpers/organisation";

const OrganisationProgramme = styled.li``;

const Subheading = styled.p`
  color: ${colours.textGreyLight};
`;

const ErrorListItem = styled.li`
  list-style-type: disc;
  margin-left: 2em;

  a {
    text-decoration: underline;
  }
`;

interface Props extends RouteComponentProps<any> {}

const AdminOrganisationsOrganisation: React.FC<Props> = () => {
  const { organisationSlug = "" } = useParams<{
    organisationSlug: string | undefined;
  }>();

  const { loading, error, data, refetch } = useQuery(FETCH_ORGANISATION, {
    variables: {
      organisationSlug,
    },
  });
  const [addOrganisationProgramme] = useMutation(
    CREATE_ORGANISATION_PROGRAMME,
    { onCompleted: () => refetch() }
  );
  const [deleteOrganisationProgramme] = useMutation(
    DELETE_ORGANISATION_PROGRAMME,
    { onCompleted: () => refetch() }
  );

  const [createOrganisationManager] = useMutation(CREATE_ORGANISATION_MANAGER, {
    onCompleted: () => refetch(),
  });
  const [deleteOrganisationManager] = useMutation(DELETE_ORGANISATION_MANAGER, {
    onCompleted: () => refetch(),
  });

  const organisation = data ? data.organisation[0] : undefined;

  const notFound = !loading && !error && !organisation;

  const noProgrammes = organisation?.organisation_programmes.length === 0;
  const noLocations = organisation?.locations.length === 0;
  const noProjects = organisation?.projects.length === 0;

  const organsationIncomplete = noProgrammes || noLocations || noProjects;

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      {loading && <LoadingSpinner type="overlay" />}
      {organisation && (
        <Fragment>
          <header className="flex justify-between items-center mb3">
            <h1>{organisation.name}</h1>
            <OrganisationForm
              onClose={refetch}
              initialValues={organisation}
              Opener={(props) => (
                <Button {...props} raised fill>
                  Edit Organisation
                </Button>
              )}
            />
          </header>
          {organsationIncomplete && (
            <ul>
              <h2>
                <ErrorMessage>Warning: Organisation Incomplete</ErrorMessage>
              </h2>
              {noProgrammes && (
                <ErrorListItem>
                  <ErrorMessage>
                    Please link this Organisation with a Programme below
                  </ErrorMessage>
                </ErrorListItem>
              )}
              {noProjects && (
                <ErrorListItem>
                  <ErrorMessage>
                    Please go to{" "}
                    <Link to={getAdminProjectsPath()}>admin/projects</Link> and
                    add a new Project for this Organisation
                  </ErrorMessage>
                </ErrorListItem>
              )}
              {noLocations && (
                <ErrorListItem>
                  <ErrorMessage>
                    Please go to{" "}
                    <Link to={getAdminLocationsPath()}>admin/locations</Link>{" "}
                    and add a new Location for this Organisation
                  </ErrorMessage>
                </ErrorListItem>
              )}
            </ul>
          )}
          <h2>
            <Icon
              className="mv3"
              icon="category"
              text={
                <Fragment>
                  Programmes
                  <ProgrammesAllDialog
                    excludeIds={organisation.organisation_programmes.map(
                      ({ programme }) => programme.id
                    )}
                    Opener={(props) => (
                      <IconButton
                        {...props}
                        icon="add_circle"
                        colour="blue"
                        className="ml1"
                        size="small"
                        title={`Add a programme to ${organisation.name}`}
                      />
                    )}
                    onSelect={(programme) => {
                      return addOrganisationProgramme({
                        variables: {
                          organisation_programme: {
                            organisation_id: organisation.id,
                            programme_id: programme.id,
                          },
                        },
                      });
                    }}
                    onClose={refetch}
                  />
                </Fragment>
              }
            />
          </h2>
          <Subheading>
            To link {organisation.name} with a programme, click the + above.
          </Subheading>
          <ul>
            {organisation.organisation_programmes.map(({ programme }) => {
              return (
                <OrganisationProgramme
                  className="flex content-center"
                  key={`AdminOrgsOrg-${organisation.id}-OrgProg-${programme.id}`}
                >
                  {programme.name}{" "}
                  <IconButton
                    icon="remove_circle"
                    colour="red"
                    className="ml2"
                    size="small"
                    title={`Remove ${programme.name} from ${organisation.name}`}
                    onClick={async () => {
                      try {
                        await deleteOrganisationProgramme({
                          variables: {
                            organisation_id: organisation.id,
                            programme_id: programme.id,
                          },
                        });
                        refetch();
                      } catch (error) {
                        errorService.error(error);
                      }
                    }}
                  />
                </OrganisationProgramme>
              );
            })}
          </ul>
          <h2>
            <Icon
              className="mv3"
              icon="spa"
              text={<Fragment>Projects</Fragment>}
            />
          </h2>
          <Subheading>
            To add projects, go to{" "}
            <Link to={getAdminProjectsPath()}>admin/projects</Link>, create a
            new project and select {organisation.name} as the organisation.
          </Subheading>
          <ul>
            {organisation.projects.map((project) => {
              return (
                <li
                  className="flex content-center"
                  key={`AdminOrgsOrg-${organisation.id}-Project-${project.id}`}
                >
                  <Link to={getAdminProjectPath({ projectSlug: project.slug })}>
                    {project.name}
                  </Link>
                </li>
              );
            })}
          </ul>

          <h2>
            <Icon
              className="mv3"
              icon="home_work"
              text={<Fragment>Locations</Fragment>}
            />
          </h2>
          <Subheading>
            To add locations, go to{" "}
            <Link to={getAdminLocationsPath()}>admin/locations</Link>, create a
            new location and select {organisation.name} as the organisation.
          </Subheading>
          <ul>
            {organisation.locations.map((location) => {
              return (
                <li
                  className="flex content-center"
                  key={`AdminOrgsOrg-${organisation.id}-Location-${location.id}`}
                >
                  <Link
                    to={getAdminLocationPath({ locationSlug: location.slug })}
                  >
                    {location.name}
                  </Link>
                </li>
              );
            })}
          </ul>
          <h2>
            <Icon
              className="mv3"
              icon="admin_panel_settings"
              text={<Fragment>Users/Managers</Fragment>}
            />
          </h2>
          <Subheading>
            To add users, go to a user's profile and link them to this
            organisation. <br />
            To make a user a manager, click the star next to their name below.
            After you make them a manager, they will need to log out and log
            back in.
          </Subheading>
          <ul>
            {organisation.organisation_users.map(({ user }) => {
              const isManager = getIsManager(user.id, organisation);
              const deleteManager = () =>
                deleteOrganisationManager({
                  variables: {
                    user_id: user.id,
                    organisation_id: organisation.id,
                  },
                });
              const createManager = () =>
                createOrganisationManager({
                  variables: {
                    organisation_manager: {
                      user_id: user.id,
                      organisation_id: organisation.id,
                    },
                  },
                });

              return (
                <li
                  className="flex content-center items-center"
                  key={`AdminOrgsOrg-${organisation.id}-User-${user.id}`}
                >
                  <IconButton
                    icon="star"
                    size="medium"
                    colour={isManager ? "yellow" : "borderGrey"}
                    className="mr2"
                    onClick={isManager ? deleteManager : createManager}
                    title={
                      isManager
                        ? "Remove manager privileges"
                        : "Add manager privileges"
                    }
                  />
                  <Link to={getAdminUserPath({ userId: user.id })}>
                    {getFullName(user)}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Fragment>
      )}
      {notFound && <p>Organisation Not Found</p>}
      {error && <p>{error.message}</p>}
    </div>
  );
};

export default withRouter(AdminOrganisationsOrganisation);

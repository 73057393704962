import styled from "styled-components";
import colours, { contrastColours, Colour } from "../constants/colours";

interface ButtonProps {
  raised?: boolean;
  fill?: boolean;
  outline?: boolean;
  fullwidth?: boolean;
  colour?: Colour;
}

const Button = styled.button<ButtonProps>`
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition:
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  color: inherit;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 6px 8px;
  font-weight: bold;
  ${(props) =>
    props.fullwidth &&
    `width: 100%;
  `}

  ${(props) =>
    props.outline &&
    `color: ${colours[props.colour!]};
    border: 1px solid  ${colours[props.colour!]};`}

  ${(props) =>
    props.fill &&
    `background-color: ${colours[props.colour!]};
    color: ${contrastColours[props.colour!]};`}
  ${(props) =>
    props.raised &&
    `box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);`}


    &:hover {
    text-decoration: none;
    opacity: 0.6;
  }
  &:disabled {
    color: #cecece;
    opacity: 0.5;
    border-color: #cecece;
    cursor: not-allowed;
  }
  &:disabled:hover {
    opacity: 0.5;
  }
`;

Button.defaultProps = {
  colour: "blue",
};

export default Button;

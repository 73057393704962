import React from "react";
import { GetMissingInfoProps, getMissingInfo } from "../helpers/participant";
import Tooltip from "./Tooltip";
import styled from "styled-components";
import colours from "../constants/colours";

const WarningIcon = styled.span`
  color: ${colours.red};
  position: relative;
`;

interface ParticipantMissingInfoProps {
  participant: GetMissingInfoProps;
}
const ParticipantMissingInfo: React.FC<ParticipantMissingInfoProps> = (
  props
) => {
  const { participant } = props;
  return getMissingInfo(participant) ? (
    <Tooltip text={getMissingInfo(participant)}>
      <WarningIcon className="ml2 material-icons">info</WarningIcon>
    </Tooltip>
  ) : null;
};

export default ParticipantMissingInfo;

import React, { Fragment, useState, SyntheticEvent, useEffect } from "react";
import * as yup from "yup";

import Dialog from "./Dialog";
import TextField from "./field/TextField";
import FlatButton from "./FlatButton";
import slugify from "../helpers/slugify";
import { useMutation } from "@apollo/client";
import CREATE_PROJECT from "../graphql/queries/create-project";
import LoadingSpinner from "./LoadingSpinner";
import UPDATE_PROJECT from "../graphql/queries/update-project";
import SelectProgrammeField from "./SelectProgrammeField";
import ErrorMessage from "./ErrorMessage";
import SelectOrganisationField from "./SelectOrganisationField";
import useOrganisations from "../hooks/useOrganisations";
import pick from "lodash/pick";
import {
  CreateProjectMutationVariables,
  FetchProjectQuery,
  UpdateProjectMutationVariables,
} from "@/graphql/generated/graphql";
import { DialogActions } from "./DialogActions";

type Project = FetchProjectQuery["project"][number];

const schema = yup.object().shape({
  slug: yup.string().required().label("URL friendly name"),
  name: yup.string().required().label("Name"),
  programme_id: yup.string().required().label("Programme"),
  organisation_id: yup.string().required().label("Organisation"),
});

export interface ProjectFormOnSuccessArgs {
  slug: string;
}

interface OpenerProps {
  onClick: () => void;
}
interface ProjectFormProps {
  onClose: () => void;
  Opener: React.FC<OpenerProps>;
  initialValues?: Project;
  onSuccess?: (args: ProjectFormOnSuccessArgs) => void;
}
const ProjectForm: React.FC<ProjectFormProps> = (props) => {
  const {
    Opener,
    onClose,
    onSuccess,
    // TODO: change this pattern
    initialValues = {} as Project,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(initialValues.name || "");
  const [slug, setSlug] = useState(initialValues.slug || "");
  const [programmeId, setProgrammeId] = useState(
    initialValues.programme_id || ""
  );
  const [organisationId, setOrganisationId] = useState(
    initialValues.organisation_id || ""
  );

  const [networkError, setNetworkError] = useState("");
  const [isComplete, setIsComplete] = useState(false);
  const [validationError, setValidationError] = useState("");

  const resetForm = () => {
    setName(initialValues.name || "");
    setSlug(initialValues.slug || "");
    setProgrammeId(initialValues.programme_id || "");
    setOrganisationId(initialValues.organisation_id || "");
  };

  useEffect(() => {
    resetForm();
  }, [JSON.stringify(initialValues)]);

  const onRequestClose = () => {
    resetForm();
    setIsOpen(false);
    onClose();
  };

  const [update, { loading: createLoading, error: updateGraphQLError }] =
    useMutation(UPDATE_PROJECT, {
      onError: () => {
        setNetworkError(
          "We could not update this project. Please check your connection."
        );
      },
      onCompleted: () => {
        setIsComplete(true);
      },
    });
  const [create, { loading: updateLoading, error: createGraphQLError }] =
    useMutation(CREATE_PROJECT, {
      onError: () => {
        setNetworkError(
          "We could not create this project. Please check your connection."
        );
      },
      onCompleted: () => {
        setIsComplete(true);
      },
    });

  const loading = createLoading || updateLoading;

  const graphQLError =
    createGraphQLError || updateGraphQLError
      ? "There was an error saving the project. Please check that the slug is unique."
      : "";

  useEffect(() => {
    if (!loading && !networkError && !graphQLError && isComplete) {
      if (onSuccess) {
        onSuccess({ slug });
      }

      onRequestClose();
    }
  }, [isComplete, networkError, graphQLError, loading]);

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    const payload = {
      name: name,
      slug: slug,
      programme_id: programmeId,
      organisation_id: organisationId,
    };

    try {
      await schema.validate(payload);
    } catch (error) {
      const message =
        error instanceof Error ? error.message : "An unknown error occurred";
      return setValidationError(message);
    }

    if (initialValues.id) {
      type UpdateField = keyof UpdateProjectMutationVariables["project"];
      const updateFields: UpdateField[] = ["name", "slug"];

      const variables = {
        id: initialValues.id,
        project: pick(payload, updateFields),
      };

      update({ variables });
    } else {
      type CreateField = keyof CreateProjectMutationVariables["project"];
      const createFields: CreateField[] = [
        "name",
        "slug",
        "programme_id",
        "organisation_id",
      ];

      const variables = {
        project: pick(payload, createFields),
      };

      create({ variables });
    }
  };

  const { organisations } = useOrganisations();

  const onNameBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const organisation = organisations.find((org) => org.id === organisationId);
    const slugPrefix = organisation ? `${organisation.slug}-` : "";
    if (!slug) {
      setSlug(slugify(`${slugPrefix}${e.target.value}`));
    }
  };

  const onSlugBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(slugify(e.target.value));
  };

  const errorMessage = validationError || networkError || graphQLError;

  const shouldDisableOrganisationField =
    Boolean(organisationId) && Boolean(initialValues.id);

  return (
    <Fragment>
      <Dialog
        fullHeight
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={initialValues.id ? "Edit Project" : "New Project"}
      >
        {loading && <LoadingSpinner type="overlay" />}
        <form onSubmit={onSubmit} autoComplete="off">
          <input className="clip" />
          <input type="password" className="clip" />
          <SelectOrganisationField
            value={organisationId}
            onSelect={setOrganisationId}
            disabled={shouldDisableOrganisationField}
            autoFocus={!shouldDisableOrganisationField}
          />
          <TextField
            name="name"
            label="Name"
            value={name}
            onChange={setName}
            inputProps={{ onBlur: onNameBlur }}
            autoFocus={shouldDisableOrganisationField}
          />
          <TextField
            name="slug"
            label="URL Friendly Name"
            value={slug}
            onChange={setSlug}
            inputProps={{ onBlur: onSlugBlur }}
          />
          <SelectProgrammeField
            disabled={Boolean(initialValues.id)}
            value={programmeId}
            onSelect={setProgrammeId}
          />
        </form>
        <DialogActions>
          <footer className="flex justify-end mb3 pt3">
            {errorMessage && (
              <ErrorMessage className="mra">{errorMessage}</ErrorMessage>
            )}
            <FlatButton onClick={onRequestClose}>Cancel</FlatButton>
            <FlatButton onClick={onSubmit}>Save</FlatButton>
          </footer>
        </DialogActions>
      </Dialog>
      <Opener onClick={() => setIsOpen(true)} />
    </Fragment>
  );
};

export default ProjectForm;

import React from "react";
import { Link } from "react-router-dom";

import LoadingSpinner from "./LoadingSpinner";
import OrganisationForm from "./OrganisationForm";
import Button from "./Button";
import { getAdminOrganisationPath } from "../helpers/route-path";
import { AdminGridCell, AdminGrid } from "./AdminGrid";
import useOrganisations from "../hooks/useOrganisations";

const AdminOrganisations: React.FC = () => {
  const { organisations, loading, refetch } = useOrganisations();

  const headings = ["Name"];

  return (
    <div>
      <header className="flex justify-between items-center mb3">
        <h1>Admin/Organisations </h1>
        <OrganisationForm
          onClose={refetch}
          Opener={(props) => (
            <Button {...props} raised fill>
              New Organisation
            </Button>
          )}
        />
      </header>
      {loading && <LoadingSpinner type="overlay" />}

      <AdminGrid columns={headings.length}>
        {headings.map((heading, i) => (
          <AdminGridCell key={`AdminOrganisation-${i}`} isHeading>
            {heading}
          </AdminGridCell>
        ))}

        {organisations.map((organisations) => {
          const { id, slug, name } = organisations;

          return (
            <AdminGridCell key={`AdminOrganisations-li-${id}`}>
              <Link to={getAdminOrganisationPath({ organisationSlug: slug })}>
                {name}
              </Link>
            </AdminGridCell>
          );
        })}
      </AdminGrid>
    </div>
  );
};

export default AdminOrganisations;

import React, {
  Ref,
  useState,
  useCallback,
  Fragment,
  MouseEventHandler,
} from "react";
import styled from "styled-components";
import colours from "../constants/colours";
import { nanoid } from "nanoid";

const TooltipElement = styled.span<{
  active: boolean;
}>`
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.6s;
  background-color: ${colours.darkGreyBg};
  color: ${colours.white};
  text-align: center;
  padding: 6px;
  border-radius: 3px;
  box-shadow: 0 0 6px 0 ${colours.borderGrey};
  font-size: 16px;

  // transform: translate(0%, -100%);

  position: absolute;
  z-index: 20000;

  ${(props) =>
    props.active &&
    `
    visibility: visible;
    opacity: 1;
  `}
`;
interface TooltipProps {
  children: JSX.Element;
  text: string;
}
const Tooltip: React.FC<TooltipProps> = React.forwardRef(
  (props, ref: Ref<HTMLElement>) => {
    const { children, text } = props;
    const [active, setActive] = useState(false);
    const [tooltipId] = useState(nanoid());

    const onMouseEnter = useCallback(() => setActive(true), []);
    const onMouseLeave = useCallback(() => setActive(false), []);
    // const onClick: MouseEventHandler = useCallback(
    //   (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();

    //     setActive(!active);
    //   },
    //   [active]
    // );

    const innerRef = React.useRef(ref);

    // const rect = useBoundingRect(innerRef)
    // const scrollPosition = useScrollPosition()
    // const style = getPositionStyle(rect, scrollPosition)(position)

    return (
      <span>
        {React.cloneElement(children, {
          onMouseEnter,
          onMouseLeave,
          // onClick,
          "aria-describedby": tooltipId,
          ref: innerRef,
        })}
        <TooltipElement id={tooltipId} active={active}>
          {text}
        </TooltipElement>
      </span>
    );
  },
);
Tooltip.displayName = "Tooltip";

export default Tooltip;

import React, { ReactNode } from "react";

import LoadingSpinner from "./LoadingSpinner";
import LeftPanel from "./LeftPanel";
import useSelf from "../hooks/useSelf";
import {
  getAdminUsersPath,
  getAdminProgrammesPath,
  getAdminProjectsPath,
  getAdminLocationsPath,
  getAdminOrganisationsPath,
  getAdminReportsPath,
} from "../helpers/route-path";
import { names } from "@/common-lib/names";
import colours from "@/constants/colours";

const AdminAuth: React.FC<{ children: ReactNode }> = (props) => {
  const { children } = props;
  const user = useSelf();

  if (!user) {
    return <LoadingSpinner type="splash" />;
  }

  if (user.role !== "rti-admin") {
    return <LeftPanel>You must be an admin to access this page.</LeftPanel>;
  }

  return (
    <LeftPanel
      heading="Admin"
      headingColors={{ background: colours.red, text: colours.white }}
      links={[
        {
          url: getAdminUsersPath(),
          text: "Users",
          icon: "person",
        },
        {
          url: getAdminProgrammesPath(),
          text: `Programmes & ${names.hubs.shortPlural}`,
          icon: "category",
        },
        {
          url: getAdminProjectsPath(),
          text: "Projects",
          icon: "spa",
        },
        {
          url: getAdminLocationsPath(),
          text: "Locations",
          icon: "home_work",
        },
        {
          url: getAdminOrganisationsPath(),
          text: "Organisations",
          icon: "people",
        },
        {
          url: getAdminReportsPath(),
          text: "Reports",
          icon: "bar_chart",
        },
      ]}
    >
      {children}
    </LeftPanel>
  );
};

export default AdminAuth;

import SelectField from "./SelectField";

const PREFER_NOT_TO_SAY = "prefer_not_to_say";

/**
 * @description A field for selecting a boolean value
 * If `withPreferNotToSay` is true, the options will include "Prefer not to say"
 * The yup schema for this field is `yup.boolean().nullable()`:
 * - this will allow `true`, `false`, or `null`, or `undefined`
 * If `withPreferNotToSay` is true, the schema is `yup.boolean().nullable().defined()`:
 * - this will allow `true`, `false`, or `null`, but not `undefined`, meaning the user
 * must select an option.
 */
export function BooleanSelect({
  name,
  label,
  value,
  setValue,
  required,
  withPreferNotToSay,
}: {
  name: string;
  label: string;
  value: boolean | null | undefined;
  setValue: (value: boolean | null | undefined) => void;
  required?: boolean;
  withPreferNotToSay?: boolean;
}) {
  return (
    <SelectField
      name={name}
      label={label}
      value={
        typeof value === "boolean"
          ? String(value)
          : typeof value === "string"
          ? value
          : value === null
          ? PREFER_NOT_TO_SAY
          : ""
      }
      onChange={(str) => {
        switch (str) {
          case "true":
            setValue(true);
            return;
          case "false":
            setValue(false);
            return;
          case "":
            setValue(undefined);
            return;
          case PREFER_NOT_TO_SAY:
            setValue(null);
            return;
        }
      }}
      options={[
        { value: "true", label: "True" },
        { value: "false", label: "False" },
        ...(withPreferNotToSay
          ? [{ value: PREFER_NOT_TO_SAY, label: "Prefer not to say" }]
          : []),
      ]}
      required={required}
    />
  );
}

export const colours = {
  blue: "#384686",
  blueBg: "#384686aa",
  blue2: "rgb(0, 169, 164)",
  blue2Bg: "rgba(0, 169, 164, 0.6)",
  blue2Highlight: "rgba(0, 169, 164, 0.1)",
  yellow: "#ffd500",
  red: "#ff705c",
  pinkBg: "#f8ecd7",
  textGrey: "#212121",
  borderGrey: "#63636355",
  white: "#fff",
  textGreyLight: "#636363",
  darkGreyBg: "#212121",
};

export const contrastColours: Record<Colour, string> = {
  blue: "#fff",
  blueBg: "#fff",
  blue2: "#fff",
  blue2Bg: "#fff",
  blue2Highlight: "#fff",
  yellow: "#000",
  red: "#fff",
  pinkBg: "#000",
  textGrey: "#fff",
  borderGrey: "#fff",
  white: "#000",
  textGreyLight: "#fff",
  darkGreyBg: "#fff",
};

export default colours;

export type Colour = keyof typeof colours;

import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import FETCH_LOCATION from "../graphql/queries/fetch-location";
import LocationForm from "./LocationForm";
import { useQuery } from "@apollo/client";
import LoadingSpinner from "./LoadingSpinner";
import Button from "./Button";

const AdminLocationsLocation: React.FC = () => {
  const { locationSlug = "" } = useParams<{
    locationSlug: string | undefined;
  }>();

  const { loading, error, data, refetch } = useQuery(FETCH_LOCATION, {
    variables: { locationSlug },
  });

  const location = data ? data.location[0] : undefined;
  const notFound = !loading && !error && !location;

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      {loading && <LoadingSpinner type="overlay" />}
      {location && (
        <Fragment>
          <header className="flex justify-between items-center mb3">
            <h1>{location.name}</h1>
            <LocationForm
              initialValues={location}
              Opener={(props) => (
                <Button {...props} raised fill>
                  Edit Location
                </Button>
              )}
              onClose={refetch}
            />
          </header>
        </Fragment>
      )}

      {notFound && <p>Location Not Found</p>}
      {error && <p>{error.message}</p>}
    </div>
  );
};

export default AdminLocationsLocation;

import React from "react";
import MultiAutocompleteField, {
  FieldOption,
  FieldOptionsByValue,
} from "./field/MultiAutocompleteField";
import languages from "../constants/languages.json";
import SingleAutocompleteField from "./field/SingleAutocompleteField";

export type LanguageCode = string;

export interface Language {
  name: string;
  code: LanguageCode;
}

export interface LanguageFieldOption extends FieldOption {
  name: string;
  code: LanguageCode;
  native?: string;
}

export const languageToFieldOption = (language: Language) => ({
  value: language.code,
  label: language.name,
});

export const languageFieldOptions = (
  languages as Array<LanguageFieldOption>
).map(languageToFieldOption);

const fieldOptionsByValue = languageFieldOptions.reduce((acc, cur) => {
  acc[cur.value] = cur;

  return acc;
}, {} as FieldOptionsByValue);

const getFieldOptionsFromCodes = (value: Array<LanguageCode>) => {
  const languages = value.map((code) => fieldOptionsByValue[code]);

  return languages;
};

const getFieldOptionFromCode = (value?: LanguageCode) => {
  if (!value) {
    return undefined;
  }

  const language = fieldOptionsByValue[value];

  if (!language) {
    return undefined;
  }
  return language;
};

interface LanguagesSelectProps {
  value: Array<LanguageCode>;
  label: string;
  name: string;
  onSelect: (languageCodes: Array<LanguageCode>) => void;
}
const LanguagesSelect: React.FC<LanguagesSelectProps> = (props) => {
  const { value, label, name, onSelect } = props;

  return (
    <MultiAutocompleteField
      label={label}
      name={name}
      selected={getFieldOptionsFromCodes(value)}
      options={languageFieldOptions.filter(
        (language) =>
          !value.some(
            (selectedLanguage) => selectedLanguage === language.value,
          ),
      )}
      onSelect={(options) => {
        onSelect(options.map((option) => option.value));
      }}
    />
  );
};

export default LanguagesSelect;

interface LanguageSelectSingleProps {
  value?: LanguageCode;
  label: string;
  name: string;
  onSelect: (languageCode: LanguageCode) => void;
}
export const LanguageSelectSingle: React.FC<LanguageSelectSingleProps> = (
  props,
) => {
  const { label, name, value, onSelect } = props;
  return (
    <SingleAutocompleteField
      label={label}
      name={name}
      selected={getFieldOptionFromCode(value)}
      options={languageFieldOptions}
      onSelect={(option) => {
        if (!option) {
          return onSelect("");
        }
        onSelect(option.value);
      }}
    />
  );
};

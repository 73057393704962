import * as Sentry from "@sentry/browser";
import { CaptureContext } from "@sentry/types";
import pick from "lodash/pick";

const error = (err: any, context?: CaptureContext) => {
  if (context && "user" in context) {
    context = {
      ...context,
      user: pick(context.user, ["id", "email"]),
    };
  }
  if (process.env.NODE_ENV !== "production") {
    console.error(err, context);
  }
  Sentry.captureException(err, context);
};

const info = (message: string) => {
  if (process.env.NODE_ENV !== "production") {
    console.warn(message);
  }
  Sentry.captureMessage(message);
};

const errorService = {
  error,
  info,
  addExtras: Sentry.setExtras,
  setUser: Sentry.setUser,
};

export default errorService;

import { graphql } from "../generated";

const DEACTIVATE_USER= graphql(`
  mutation DeactivateUser($id: uuid!) {
    update_user(
      _set: { deactivated_at: "now()" }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
      }
    }
  }
`);

export default DEACTIVATE_USER;

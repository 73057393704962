import { graphql } from "../generated";

const FETCH_REGISTERS = graphql(`
  query FetchRegisters(
    $dynamicWhere: register_bool_exp
    $dynamicOrderBy: [register_order_by!]
    $limit: Int
    $offset: Int
  ) {
    register(
      where: $dynamicWhere
      order_by: $dynamicOrderBy
      limit: $limit
      offset: $offset
    ) {
      date
      id
      time
      organisation {
        name
      }
      location {
        id
        name
        slug
      }
      sensitive_fields {
        notes
      }
      project {
        id
        name
        slug
        programme {
          name
        }
      }
      register_project_categories {
        project_category {
          id
          name
        }
      }
      register_facilitators {
        user {
          first_name
          last_name
          id
        }
      }
      override_total_participants
      number_of_orgs_in_attendance
      register_participants_aggregate {
        aggregate {
          count(columns: participant_id)
        }
      }
    }
    register_aggregate(where: $dynamicWhere) {
      aggregate {
        count
      }
    }
  }
`);

export default FETCH_REGISTERS;

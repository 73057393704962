import { graphql } from "../generated";

const FETCH_PROGRAMMES = graphql(`
  query FetchProgrammes($dynamicWhere: programme_bool_exp) {
    programme(where: $dynamicWhere, order_by: { name: asc }) {
      id
      name
      slug
      type
      start_date
      end_date
      status
      programme_locations {
        location {
          id
          name
          slug
        }
      }
      programme_group {
        id
        name
        slug
      }
    }
  }
`);

export default FETCH_PROGRAMMES;

import { useQuery } from "@apollo/client";

import SEARCH_PARTICIPANTS_BY_NAME from "../graphql/queries/search-participants-by-name";
import { LanguageCode } from "../components/LanguagesSelect";
import { ParticipantId } from "../context/types";
import useSessionOrganisation from "./useSessionOrganisation";
import useSessionProgramme from "./useSessionProgramme";
import { CountryCode } from "../components/CountrySelect";
import { useState, useEffect } from "react";
import truthy from "@/helpers/truthy";
import {
  Exact,
  Participant_Sensitive_Fields_Bool_Exp,
  Participant_Sensitive_Fields_Order_By,
  SearchParticipantsByNameQueryVariables,
} from "@/graphql/generated/graphql";

const DEFAULT_LIMIT = 10;

const parsePhoneFilter = (phone: string | undefined) => {
  if (!phone) {
    return undefined;
  }
  if (phone[0] === "0" || phone[0] === "+") {
    return phone.slice(1);
  }

  return phone;
};

export interface Filters {
  languageCode?: LanguageCode;
  countryCode?: CountryCode;
  dobAfter?: Date;
  dobBefore?: Date;
  dobEqual?: Date;
  phone?: string;
}
interface Params {
  searchTerm: string;
  excludeIds: Array<ParticipantId>;
  filters?: Filters;
  orderBy?: Participant_Sensitive_Fields_Order_By[];
}
const useParticipantSearch = (opts: Params) => {
  const organisation = useSessionOrganisation();
  const programme = useSessionProgramme();

  const [endOfResults, setEndOfResults] = useState(false);

  const { searchTerm, excludeIds, filters = {}, orderBy = [] } = opts;

  const { languageCode, countryCode, dobAfter, dobBefore, dobEqual, phone } =
    filters;

  const phoneFilter = parsePhoneFilter(phone);

  const dynamicFilters: Exact<Participant_Sensitive_Fields_Bool_Exp>[] = [
    organisation
      ? {
          participant: {
            organisation_participants: {
              organisation_id: { _eq: organisation.id },
            },
          },
        }
      : null,
    programme
      ? {
          participant: {
            programme_participants: {
              programme_id: { _eq: programme.id },
            },
          },
        }
      : null,
    languageCode
      ? {
          participant: {
            participant_languages: { language_code: { _eq: languageCode } },
          },
        }
      : null,
    countryCode ? { country_code: { _eq: countryCode } } : null,
    dobAfter ? { participant: { date_of_birth: { _gte: dobAfter } } } : null,
    dobBefore ? { participant: { date_of_birth: { _lte: dobBefore } } } : null,
    dobEqual ? { participant: { date_of_birth: { _eq: dobEqual } } } : null,
    phoneFilter
      ? {
          participant: {
            phone: { _ilike: `%${phoneFilter}%` },
          },
        }
      : null,
  ].filter(truthy);

  const variables: SearchParticipantsByNameQueryVariables = {
    searchTerm,
    excludeIds,
    dynamicFilters,
    dynamicOrderBy: orderBy,
    offset: 0,
    limit: DEFAULT_LIMIT,
  };

  const variablesString = JSON.stringify(variables);

  useEffect(() => {
    setEndOfResults(false);
  }, [variablesString]);

  const { data, error, loading, refetch, fetchMore } = useQuery(
    SEARCH_PARTICIPANTS_BY_NAME,
    {
      variables,
      partialRefetch: true,
      fetchPolicy: "cache-and-network",
    }
  );

  const participants = data
    ? data.participant_search_by_name.map(({ participant }) => participant)
    : [];

  const loadMore = () => {
    fetchMore({
      variables: {
        offset: participants.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        if (fetchMoreResult.participant_search_by_name.length === 0) {
          setEndOfResults(true);
        }
        return Object.assign({}, prev, {
          participant_search_by_name: [
            ...prev.participant_search_by_name,
            ...fetchMoreResult.participant_search_by_name,
          ],
        });
      },
    });
  };

  return {
    participants,
    error,
    loading,
    refetch: () =>
      refetch({
        ...variables,
        limit:
          participants.length > DEFAULT_LIMIT
            ? participants.length
            : DEFAULT_LIMIT,
      }),
    loadMore,
    endOfResults,
  };
};

export type ParticipantSearchResult = ReturnType<
  typeof useParticipantSearch
>["participants"][number];

export default useParticipantSearch;

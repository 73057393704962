import React, { useEffect, useState, Fragment } from "react";

import LoadingSpinner from "./LoadingSpinner";
import Button from "./Button";
import LocationForm from "./LocationForm";
import useLocations from "../hooks/useLocations";
import {
  AdminGrid,
  AdminGridCell,
  AdminGridHeading,
  AdminGridHeader,
} from "./AdminGrid";
import IconButton from "./IconButton";
import { commaSeparatedList } from "../helpers/util";
import { Location_Order_By, Order_By } from "@/graphql/generated/graphql";

const defaultOrder = [{ name: Order_By.Asc }];

const headings: AdminGridHeading<Location_Order_By>[] = [
  {
    label: "Name",
    order: {
      asc: [{ name: Order_By.Asc }],
      desc: [{ name: Order_By.Desc }],
    },
  },
  {
    label: "Programmes",
  },
  {
    label: "",
  },
];
const ManagerLocations: React.FC = () => {
  const [orderBy, setOrderBy] = useState<Location_Order_By[]>(defaultOrder);

  const { locations, error, loading, refetch } = useLocations({
    orderBy,
  });
  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <header className="flex justify-between items-center mb3">
        <h1>Locations </h1>
        <LocationForm
          onClose={refetch}
          Opener={(props) => (
            <Button {...props} raised fill>
              New location
            </Button>
          )}
        />
      </header>
      <AdminGrid columns={headings.length}>
        <AdminGridHeader
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          headings={headings}
        />
        {locations.map((location) => {
          const { id, name, programme_locations } = location;

          return (
            <Fragment key={`ManagerLocations-li-${id}`}>
              <AdminGridCell>{name}</AdminGridCell>
              <AdminGridCell>
                {commaSeparatedList(
                  programme_locations,
                  ({ programme }) => programme.name,
                  ({ programme }) => programme.id
                )}
              </AdminGridCell>
              <AdminGridCell justify="flex-end">
                <LocationForm
                  onClose={refetch}
                  initialValues={location}
                  Opener={(props) => <IconButton icon="edit" {...props} />}
                />
              </AdminGridCell>
            </Fragment>
          );
        })}
      </AdminGrid>
      {loading && <LoadingSpinner type="row" />}
      {error && <p>{error.message}</p>}
    </div>
  );
};

export default ManagerLocations;

import React, { useEffect, useCallback, Fragment } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";

import useOrganisations from "../hooks/useOrganisations";
import LoadingSpinner from "./LoadingSpinner";
import LeftPanel from "./LeftPanel";
import ErrorMessage from "./ErrorMessage";
import useSelf from "../hooks/useSelf";
import {
  getAdminProgrammePath,
  getOrganisationPath,
  getRegistersPath,
} from "../helpers/route-path";
import Button from "./Button";
import NoContent from "./NoContent";
import useProgrammes from "@/hooks/useProgrammes";
import NavigationLinkButton from "./NavigationLinkButton";
import { names } from "@/common-lib/names";

const SelectHubOrganisation: React.FC = () => {
  const params = useParams<{ hubSlug: string }>();

  const { programmes, loading: hubLoading, error: hubError } = useProgrammes();

  const hub = programmes.find((p) => p.slug === params.hubSlug);

  const {
    organisations: allOrganisations,
    loading: organisationsLoading,
    error: organisationsError,
    refetch,
  } = useOrganisations();

  const organisations = allOrganisations.filter((organisation) =>
    organisation.organisation_programmes.some(
      (op) => op.programme.id === hub?.id
    )
  );

  const loading = hubLoading || organisationsLoading;
  const error = hubError || organisationsError;
  const user = useSelf();

  const history = useHistory();
  const goToAdmin = () => {
    if (hub) {
      history.push(getAdminProgrammePath({ programmeSlug: hub?.slug }));
    } else {
      window.alert("An error occurred, please contact an admin");
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  if (!loading && organisations.length === 1 && user?.role !== "rti-admin") {
    /**
     * If user only a member of a single org, then redirect them into that org
     * However, if user is an admin, then keep them on the "/" page so that they
     * have access to the "Database admin" button.
     */
    return (
      <Redirect
        to={getOrganisationPath({
          organisationSlug: organisations[0].slug,
        })}
      />
    );
  }

  return (
    <LeftPanel>
      <header className="flex justify-between items-center mb3">
        <h1>{hub?.name}</h1>
        {user && user.role === "rti-admin" && (
          <Button onClick={goToAdmin} raised fill>
            Make changes
          </Button>
        )}
      </header>
      <div className="flex flex-column flex-auto">
        {error && (
          <ErrorMessage>
            There was an error loading {names.hubs.longSingle} Organisations.
            Please try refreshing the page.
          </ErrorMessage>
        )}
        {loading && <LoadingSpinner type="overlay" />}
        {!loading && !hub && (
          <NoContent
            icon="groups"
            message={<Fragment>{names.hubs.longSingle} not found</Fragment>}
          />
        )}
        {!loading && !error && hub && organisations.length === 0 && (
          <NoContent
            icon="groups"
            message={<Fragment>No organisations found</Fragment>}
          />
        )}
        {organisations.length ? (
          <p className="mb3">Please select from the below organisations:</p>
        ) : null}
        {hub &&
          organisations.map((organisation) => {
            return (
              <NavigationLinkButton
                key={`SelectOrganisation-org-link-${organisation.id}`}
                to={getRegistersPath({
                  organisationSlug: organisation.slug,
                  programmeSlug: hub?.slug,
                })}
              >
                {organisation.name}
              </NavigationLinkButton>
            );
          })}
      </div>
    </LeftPanel>
  );
};

export default SelectHubOrganisation;

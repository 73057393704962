import { graphql } from "../generated";

const UPDATE_REGISTER_PARTICIPANT = graphql(`
  mutation UpdateRegisterParticipant(
    $register_id: uuid!
    $participant_id: uuid!
    $sensitive_fields: register_participant_sensitive_fields_set_input!
  ) {
    update_register_participant_sensitive_fields(
      _set: $sensitive_fields
      where: {
        register_id: { _eq: $register_id }
        participant_id: { _eq: $participant_id }
      }
    ) {
      returning {
        register_id
        participant_id
      }
    }
  }
`);

export default UPDATE_REGISTER_PARTICIPANT;

import { graphql } from "../generated";

const USER_LOOKUP = graphql(`
  query UserLookup($id: uuid!) {
    user_by_pk(id: $id) {
      id
      first_name
      last_name
      email
      role
      deactivated_at
      organisation_users {
        organisation {
          id
          slug
          name
        }
      }
      organisation_siloed_users {
        user_id
        organisation_id
      }
    }
  }
`);

export default USER_LOOKUP;

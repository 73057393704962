import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import colours from "../../constants/colours";

const SwitchLabel = styled.label`
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;

  :focus-within {
    outline: 2px solid rgba(0, 169, 164, 0.6);
  }
`;
const SwitchRoot = styled.span`
  width: 58px;
  height: 38px;
  display: inline-flex;
  padding: 12px;
  z-index: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  vertical-align: middle;
`;
interface SwitchTrackProps {
  checked: boolean;
}
const SwitchTrack = styled.span<SwitchTrackProps>`
  width: 100%;
  height: 100%;
  opacity: 0.38;
  z-index: -1;
  transition:
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 7px;
  background-color: #000;

  ${(props) =>
    props.checked &&
    `
    background-color: ${colours.blueBg};
    opacity: 0.5;
  `}
`;
const SwitchLabelText = styled.span<{ active: boolean }>`
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin: 0;

  ${(props) =>
    props.active &&
    `
    color: #000;
  `}
  ${(props) =>
    !props.active &&
    `
    color: rgba(0, 0, 0, 0.54);
  `}
`;

interface SwitchButtonProps {
  checked: boolean;
}
const SwitchButton = styled.span<SwitchButtonProps>`
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;

  top: -3px;
  left: 0;
  color: #fafafa;
  z-index: 1;
  position: absolute;
  transition:
    left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ${(props) =>
    props.checked &&
    `
    transform: translateX(50%);
    color: ${colours.blue};
  `}
`;

const SwitchThumbWrapper = styled.span`
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
`;
const SwitchThumb = styled.span`
  width: 20px;
  height: 20px;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  background-color: currentColor;
`;

const SwitchCheckbox = styled.input.attrs((props) => ({
  type: "checkbox",
}))`
  left: -100%;
  width: 300%;

  top: 0;
  left: 0;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;

interface SwitchProps {
  label?: string;
  offLabel?: string;
  onLabel?: string;
  checked: boolean;
  name: string;
  onChange: (checked: boolean) => void;
  className?: string;
}
const Switch: React.FC<SwitchProps> = (props) => {
  const { label, offLabel, onLabel, checked, onChange, name, className } =
    props;

  const onSwitch = (e: SyntheticEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    // const checked = target.checked;

    onChange(target.checked);
  };

  const onLabelClick = () => {
    onChange(!checked);
  };

  return (
    <SwitchLabel htmlFor={name} className={className}>
      {label && (
        <SwitchLabelText active={false} onClick={onLabelClick}>
          {label}:
        </SwitchLabelText>
      )}
      {offLabel && (
        <SwitchLabelText active={!checked} onClick={onLabelClick}>
          &nbsp;
          {offLabel}
        </SwitchLabelText>
      )}
      <SwitchRoot>
        <SwitchButton checked={checked}>
          <SwitchThumbWrapper>
            <SwitchCheckbox checked={checked} onChange={onSwitch} name={name} />
            <SwitchThumb></SwitchThumb>
          </SwitchThumbWrapper>
        </SwitchButton>
        <SwitchTrack checked={checked} />
      </SwitchRoot>
      {onLabel && (
        <SwitchLabelText active={checked} onClick={onLabelClick}>
          {onLabel}
        </SwitchLabelText>
      )}
    </SwitchLabel>
  );
};

export default Switch;

import React, { Fragment } from "react";
import { format } from "date-fns";
import { getDatetime } from "../helpers/register";
import Icon from "./Icon";
import { getFullName } from "../helpers/user";
import { commaSeparatedList } from "../helpers/util";
import DialogTextbox from "./DialogTextbox";
import { useMutation } from "@apollo/client";

import styled from "styled-components";
import { FetchRegisterQuery } from "@/graphql/generated/graphql";
import UPDATE_REGISTER_SENSITIVE_FIELDS from "@/graphql/queries/update-register-notes";
import { useIsProgrammeClosed } from "@/hooks/useIsProgrammeClosed";

const Notes = styled.div`
  white-space: pre-line;
`;
interface RegisterMetaProps {
  register: NonNullable<FetchRegisterQuery["register_by_pk"]>;
  refetchRegister: () => void;
}

const RegisterMeta: React.FC<RegisterMetaProps> = (props) => {
  const { register, refetchRegister } = props;
  const {
    project,
    location,
    register_facilitators,
    register_project_categories,
    sensitive_fields,
  } = register;

  const [updateSensitiveFields] = useMutation(
    UPDATE_REGISTER_SENSITIVE_FIELDS,
    {
      onCompleted: () => refetchRegister(),
    }
  );

  const isProgrammeClosed = useIsProgrammeClosed();
  return (
    <Fragment>
      <Icon
        className="mv3"
        icon="calendar_today"
        text={format(getDatetime(register), "iiii do MMM, h:mm aaaa")}
      />
      <Icon className="mv3" icon="spa" text={project.name} />
      {register_project_categories.length > 0 && (
        <Icon
          className="mv3"
          icon="tag"
          text={register_project_categories
            .map((pc) => pc.project_category.name)
            .join(", ")}
        />
      )}
      <Icon className="mv3" icon="home_work" text={location.name} />
      <Icon
        className="mv3"
        icon="account_box"
        text={commaSeparatedList(
          register_facilitators,
          (el) => getFullName(el.user),
          (el) => `${register.id}${el.user.id}`,
          <em>No facilitator set</em>
        )}
      />
      <Icon
        className="mv3"
        icon="people"
        text={`${
          register.override_total_participants
            ? register.override_total_participants
            : register.register_participants.length
        } total attendees`}
      />
      <Icon
        className="mv3"
        icon="business"
        text={`${register.number_of_orgs_in_attendance} organisations in attendance`}
      />
      <Icon
        className="mv3"
        icon="notes"
        title={"Session Notes"}
        text={
          <Fragment>
            <Notes>
              {sensitive_fields ? sensitive_fields.notes : "REDACTED"}
            </Notes>
            <DialogTextbox
              disabled={!sensitive_fields || isProgrammeClosed}
              title={"Session Notes"}
              onSave={async (value) =>
                await updateSensitiveFields({
                  variables: {
                    register_id: register.id,
                    register_sensitive_fields: {
                      notes: value,
                    },
                  },
                })
              }
              initialValue={sensitive_fields?.notes}
            />
          </Fragment>
        }
      />
    </Fragment>
  );
};

export default RegisterMeta;

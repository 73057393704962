import React, { Fragment, useState, useEffect } from "react";
import Dialog from "./Dialog";
import IconButton from "./IconButton";
import FlatButton from "./FlatButton";
import styled from "styled-components";
import TextField from "./field/TextField";

const InputWrapper = styled.div`
  position: relative;
  padding-bottom: 1rem;
`;

interface Props {
  title: string;
  initialValue?: string | null;
  onSave: (value: string) => Promise<any>;
}
const DialogTextInput: React.FC<Props> = (props) => {
  const { onSave, title, initialValue } = props;
  const [value, setValue] = useState(initialValue || "");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setValue(initialValue || ""), [initialValue]);

  const resetForm = () => {
    setValue(initialValue || "");
  };

  useEffect(() => {
    resetForm();
  }, [initialValue]);

  const onRequestOpen = () => setIsOpen(true);
  const onRequestClose = () => {
    resetForm();
    setIsOpen(false);
  };
  const onRequestSave = async () => {
    await onSave(value);
    onRequestClose();
  };

  return (
    <Fragment>
      <Dialog isOpen={isOpen} onRequestClose={onRequestClose} title={title}>
        <InputWrapper>
          <TextField
            onChange={setValue}
            value={value}
            className="w-100"
            label="Value"
            name="value"
          />
        </InputWrapper>
        <footer className="flex justify-end">
          <FlatButton type="button" onClick={onRequestClose}>
            Cancel
          </FlatButton>
          <FlatButton type="button" onClick={onRequestSave}>
            Save
          </FlatButton>
        </footer>
      </Dialog>
      <IconButton title={title} icon="create" onClick={onRequestOpen} />
    </Fragment>
  );
};

export default DialogTextInput;

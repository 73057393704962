import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import LoadingSpinner from "./LoadingSpinner";
import Button from "./Button";
import UserForm from "./UserForm";
import { getFullName } from "../helpers/user";
import Avatar from "./Avatar";
import Icon from "./Icon";
import useUsers from "../hooks/useUsers";
import { getAdminUserPath } from "../helpers/route-path";
import {
  AdminGrid,
  AdminGridCell,
  AdminGridSearch,
  AdminGridHeader,
  AdminGridHeading,
  AdminGridFilters,
} from "./AdminGrid";
import { commaSeparatedList } from "../helpers/util";
import IconButton from "./IconButton";
import OrganisationSelect from "./OrganisationSelect";
import errorService from "../common-lib/error";
import { Order_By, User_Order_By } from "@/graphql/generated/graphql";

const defaultOrder = [
  { first_name: Order_By.Asc },
  { last_name: Order_By.Asc },
];

const headings: AdminGridHeading<User_Order_By>[] = [
  {
    label: "Name",
    order: {
      asc: [{ first_name: Order_By.Asc }, { last_name: Order_By.Asc }],
      desc: [{ first_name: Order_By.Desc }, { last_name: Order_By.Desc }],
    },
  },
  {
    label: "Role",
    order: {
      asc: [{ role: Order_By.Asc }, ...defaultOrder],
      desc: [{ role: Order_By.Desc }, ...defaultOrder],
    },
  },
  {
    label: "Email",
    order: {
      asc: [{ email: Order_By.Asc }, ...defaultOrder],
      desc: [{ email: Order_By.Desc }, ...defaultOrder],
    },
  },
  {
    label: "Organisation",
  },
];

const AdminUsers: React.FC = () => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [organisationId, setOrganisationId] = useState("");
  const [orderBy, setOrderBy] = useState<User_Order_By[]>(defaultOrder);

  const { users, error, loading, refetch } = useUsers({
    searchTerm,
    orderBy,
    filters: {
      organisationId,
    },
  });
  useEffect(() => {
    refetch();
  }, []);

  if (error) {
    errorService.error(error);
  }

  return (
    <div>
      <header className="flex justify-between items-center mb3">
        <h1>Admin/Users </h1>
        <UserForm
          Opener={(props) => (
            <Button {...props} raised fill>
              New User
            </Button>
          )}
          onClose={refetch}
        />
      </header>
      <div className="flex mb2">
        <AdminGridSearch
          className="w-100 pa2 flex-auto"
          placeholder="Search (name or email)"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(e.target.value)
          }
          value={searchTerm}
        />
        <IconButton
          className="ph2"
          icon="filter_list"
          onClick={() => setFiltersOpen(!filtersOpen)}
        />
      </div>

      {filtersOpen && (
        <AdminGridFilters className="pt1 pb3 ph2 mb2">
          <h2 className="mb0">Filters</h2>
          <div className="w-50">
            <OrganisationSelect
              organisationId={organisationId}
              setOrganisationId={setOrganisationId}
            />
          </div>
        </AdminGridFilters>
      )}

      <AdminGrid columns={headings.length}>
        <AdminGridHeader
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          headings={headings}
        />
        {users.map((user) => {
          return (
            <Fragment key={`AdminUsers-li-${user.id}`}>
              <AdminGridCell>
                <div className="flex items-center">
                  <Avatar person={user} size="small" />
                  <div className="ml2">
                    <Link to={getAdminUserPath({ userId: user.id })}>
                      {getFullName(user)}
                    </Link>
                  </div>
                </div>
              </AdminGridCell>
              <AdminGridCell>
                <Icon icon="stars" text={user.role}></Icon>
              </AdminGridCell>
              <AdminGridCell>
                <Icon icon="email" text={user.email}></Icon>
              </AdminGridCell>
              <AdminGridCell>
                {commaSeparatedList(
                  user.organisation_users,
                  ({ organisation }) => organisation.name,
                  ({ organisation }) => organisation.id,
                  "No Organisation"
                )}
              </AdminGridCell>
            </Fragment>
          );
        })}
      </AdminGrid>
      {loading && <LoadingSpinner type="row" />}
    </div>
  );
};

export default AdminUsers;

import { useState } from "react";
import { createAndDownloadBlobFile } from "../helpers/util";
import errorService from "../common-lib/error";

export type DownloadReportBreakdown = {
  by: string;
  columnHeadings: string[];
  rawValues: number[];
  percentValues: string[];
};

type DownloadReportPayload = {
  filters: {
    organisation?: string;
    programme?: string;
    project?: string;
    location?: string;
    fromDate?: string;
    toDate?: string;
    country?: string;
    olderThan?: string | null;
    youngerThan?: string | null;
    category?: string;
  };
  isAttendanceUnique: boolean;
  totalAttendancesExcludingManualOverride: number;
  totalAttendancesIncludingManualOverride?: number;
  totalOrgsInAttendance?: number;
  breakdowns: DownloadReportBreakdown[];
};

const downloadReport = async (
  payload: DownloadReportPayload
): Promise<void> => {
  try {
    const response = await fetch(`/api/downloads/report`, {
      method: "POST",
      body: JSON.stringify(payload),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.arrayBuffer();

    const header = response.headers.get("Content-Disposition");
    const parts = header!.split(";");
    const fileName = parts[1].split("=")[1];

    createAndDownloadBlobFile(result, fileName);
  } catch (error) {
    errorService.error(error);
  }
};

const useDownloadReport = () => {
  const [loading, setLoading] = useState(false);
  return {
    loading,
    downloadReport: async (payload: DownloadReportPayload) => {
      setLoading(true);

      try {
        const res = await downloadReport(payload);

        return res;
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    },
  };
};

export default useDownloadReport;

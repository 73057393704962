import React, { useCallback } from "react";
import TextField from "./field/TextField";
import { differenceInYears, isValid, subYears } from "date-fns";

interface AgeInputProps {
  label: string;
  name: string;
  onDateChange: (date?: Date) => void;
  date?: Date;
}

const AgeInput: React.FC<AgeInputProps> = (props) => {
  const { label, name, date, onDateChange } = props;

  const age =
    date && isValid(date) ? differenceInYears(new Date(), date) : undefined;

  const ageString = age ? String(age) : null;

  const onChange = useCallback(
    (value: string) => {
      if (!value) {
        return onDateChange(undefined);
      }
      const newAge = Number(value);
      const newDate = subYears(new Date(), newAge);

      onDateChange(newDate);
    },
    [onDateChange]
  );

  return (
    <TextField
      name={name}
      label={label}
      value={ageString}
      inputProps={{ type: "number" }}
      onChange={onChange}
    />
  );
};

export default AgeInput;

import { graphql } from "../generated";

const CREATE_ORGANISATION_USER= graphql(`
  mutation CreateOrganisationUser(
    $organisation_user: organisation_user_insert_input!
  ) {
    insert_organisation_user(objects: [$organisation_user]) {
      returning {
        user_id
        organisation_id
      }
    }
  }
`);

export default CREATE_ORGANISATION_USER;

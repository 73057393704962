import { graphql } from "../generated";

const FETCH_REGISTER = graphql(`
  query FetchRegister($registerId: uuid!) {
    register_by_pk(id: $registerId) {
      id
      date
      time
      deleted_at
      project {
        id
        name
        slug
        programme {
          id
          slug
        }
      }
      location {
        id
        name
        slug
      }
      sensitive_fields {
        notes
      }
      override_total_participants
      number_of_orgs_in_attendance
      register_participants(
        order_by: {
          participant: { sensitive_fields: { last_name: asc, first_name: asc } }
        }
      ) {
        _temp_is_child
        _temp_is_parent
        sensitive_fields {
          notes
        }
        participant {
          sensitive_fields {
            first_name
            last_name
          }
          id
          date_of_birth
          parents {
            parent {
              id
              sensitive_fields {
                first_name
                last_name
              }
            }
          }
          children {
            child {
              id
              sensitive_fields {
                first_name
                last_name
              }
            }
          }
        }
      }
      register_facilitators {
        user {
          first_name
          last_name
          id
        }
      }
      register_project_categories {
        project_category {
          id
          name
        }
      }
      created_by_user_id
    }
  }
`);

export default FETCH_REGISTER;

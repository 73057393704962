import React, { useState, Fragment } from "react";

import Dialog from "./Dialog";
import useProgrammes from "../hooks/useProgrammes";
import { ProgrammeId } from "../context/types";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import errorService from "../common-lib/error";
import { unknownErrorMessage } from "../constants/errors";
import { FetchProgrammesQuery } from "@/graphql/generated/graphql";

type Programme = FetchProgrammesQuery["programme"][number];

interface OpenerProps {
  onClick: () => void;
}
interface ProgrammesAllDialogProps {
  onSelect: (programme: Programme) => Promise<any>;
  excludeIds?: Array<ProgrammeId>;
  onClose: () => void;
  Opener: React.FC<OpenerProps>;
}
const ProgrammesAllDialog: React.FC<ProgrammesAllDialogProps> = (props) => {
  const { onSelect, excludeIds, onClose, Opener } = props;

  const { loading, error, programmes } = useProgrammes({
    excludeIds,
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const onRequestOpen = () => setIsOpen(true);
  const onRequestClose = () => setIsOpen(false);

  const onClick = async (programme: Programme) => {
    try {
      await onSelect(programme);

      setIsOpen(false);
      onClose();
    } catch (err) {
      errorService.error(err);
      setErrorMessage(unknownErrorMessage);
    }
  };

  return (
    <Fragment>
      <Dialog
        fullHeight
        title={"Programmes"}
        onRequestClose={onRequestClose}
        isOpen={isOpen}
      >
        <legend>Please select from the programmes below:</legend>
        <br />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <ul className="relative flex-auto">
          {loading && <LoadingSpinner type="overlay" />}
          {programmes.map((programme) => {
            return (
              <li className="mb2 flex" key={`Programmes-li-${programme.id}`}>
                <button
                  className="flex items-center tl"
                  onClick={() => onClick(programme)}
                  disabled={loading}
                >
                  {programme.name}
                </button>
              </li>
            );
          })}
        </ul>
      </Dialog>
      <Opener onClick={onRequestOpen} />
    </Fragment>
  );
};

export default ProgrammesAllDialog;

import { DownloadAttendancePayload } from "@/pages/api/downloads/attendance";
import { createAndDownloadBlobFile } from "../helpers/util";
import errorService from "../common-lib/error";

export const downloadAttendance = async (
  payload: DownloadAttendancePayload
): Promise<void> => {
  try {
    const response = await fetch(`/api/downloads/attendance`, {
      method: "POST",
      body: JSON.stringify(payload),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.arrayBuffer();

    const header = response.headers.get("Content-Disposition");
    const parts = header!.split(";");
    const fileName = parts[1].split("=")[1];

    createAndDownloadBlobFile(result, fileName);
  } catch (error) {
    errorService.error(error);
  }
};

import React, { useState, SyntheticEvent, Fragment } from "react";
import styled from "styled-components";
import * as yup from "yup";

import TextField from "./field/TextField";
import Button from "./Button";
import { login, requestCode } from "../api/auth";
import LoadingSpinner from "./LoadingSpinner";
import { useAppState, useAppDispatch } from "../context/store";
import Logo from "./Logo";
import useIsMounted from "../hooks/useIsMounted";
import colours from "../constants/colours";
import Icon from "./Icon";

const requestCodeSchema = yup.object().shape({
  email: yup.string().required().label("Email"),
  password: yup.string().required().label("Password"),
});
const loginSchema = yup.object().shape({
  loginCode: yup.string().required().label("Code"),
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: auto;
`;

const LoginLogo = styled(Logo)<{ isMounted: boolean }>`
  position: absolute;
  max-width: 136px;
  top: 1rem;
  left: 1rem;

  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  ${(props) =>
    props.isMounted &&
    `
  opacity: 1;
`}
`;

const Form = styled.form<{ isMounted: boolean }>`
  position: relative;
  width: 100%;
  max-width: 300px;
  opacity: 0;
  transform: translateY(3rem);
  transition:
    opacity 0.8s ease-in-out,
    transform 0.8s ease-in-out;
  ${(props) =>
    props.isMounted &&
    `
    opacity: 1;
    transform: translateY(0);
  `}
`;

const Legend = styled.legend`
  color: ${colours.textGreyLight};
  margin-bottom: 1rem;
`;
const SubmitWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
`;
const FormFooter = styled.div`
  position: absolute;
`;
const ErrorMessage = styled.p`
  color: red;
`;
const ResetButton = styled.button`
  display: flex;
  color: ${colours.blue};
`;

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
  const { logoutReason } = useAppState();
  const [step, setStep] = useState<"request-code" | "login">("request-code");
  const [loginCode, setLoginCode] = useState("");

  const isMounted = useIsMounted();

  const reset = () => {
    setStep("request-code");
    setEmail("");
    setPassword("");
    setError("");
    setLoading(false);
    setValidationError("");
    setLoginCode("");
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    setValidationError("");
    setError("");
    setLoading(true);

    if (step === "request-code") {
      const payload = { email, password };

      try {
        await requestCodeSchema.validate(payload);
      } catch (error) {
        setLoading(false);
        if (!(error instanceof Error)) {
          return Promise.reject(new Error(JSON.stringify(error)));
        }
        return setValidationError(error.message);
      }
      try {
        await requestCode(payload);
        setStep("login");
        setLoading(false);
        return;
      } catch (error) {
        if (!(error instanceof Error)) {
          return Promise.reject(new Error(JSON.stringify(error)));
        }
        setError(error.message);
        setLoading(false);
        return;
      }
    }
    if (step === "login") {
      const payload = { loginCode };

      try {
        await loginSchema.validate(payload);
      } catch (error) {
        setLoading(false);

        const message =
          error instanceof Error ? error.message : "An unknown error occurred";
        return setValidationError(message);
      }
      try {
        dispatch({ type: "@@session/LOGIN_START" });
        const { user, token } = await login(payload);

        dispatch({ type: "@@session/LOGIN_SUCCESS", payload: { user, token } });
        return;
      } catch (error) {
        const message =
          error instanceof Error ? error.message : "An unknown error occurred";

        setError(message);
        return;
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <LoginLogo isMounted={isMounted} />
      <Form onSubmit={onSubmit} isMounted={isMounted} autoComplete="off">
        {loading && <LoadingSpinner type="overlay" />}
        <h1>
          {logoutReason !== "JWT_EXPIRED" ? "Welcome Back" : "Please Sign In"}
        </h1>
        {step === "request-code" && (
          <Fragment>
            <Legend>
              Type your email and password and we'll send you a code
            </Legend>

            <TextField
              name="email"
              label="Email"
              value={email}
              onChange={setEmail}
              inputProps={{
                type: "email",
              }}
              autoFocus
              // forceFocusStyles
            />
            <TextField
              name="password"
              label="Password"
              value={password}
              onChange={setPassword}
              inputProps={{
                type: "password",
              }}
              // forceFocusStyles
            />
          </Fragment>
        )}
        {step === "login" && (
          <Fragment>
            <Legend>Enter the code we sent to {email}</Legend>
            <TextField
              name="login-code"
              label="Code"
              value={loginCode}
              onChange={setLoginCode}
              autoFocus
              // forceFocusStyles
            />
          </Fragment>
        )}
        <SubmitWrapper>
          <Button fill fullwidth onClick={onSubmit}>
            {step === "request-code" ? "Send me a code" : "Sign in"}
          </Button>
        </SubmitWrapper>
        <FormFooter>
          {validationError && (
            <ErrorMessage className="mra">{validationError}</ErrorMessage>
          )}
          <ErrorMessage>{error}</ErrorMessage>
          {step === "login" && (
            <ResetButton onClick={reset}>
              <Icon className="mr2" icon="keyboard_return" />
              Go back
            </ResetButton>
          )}
        </FormFooter>
      </Form>
    </Container>
  );
};

export default Login;

import { useQuery } from "@apollo/client";

import FETCH_PROGRAMME_GROUPS from "../graphql/queries/fetch-programme-groups";

const useProgrammeGroups = () => {
  const { data, error, loading, refetch } = useQuery(FETCH_PROGRAMME_GROUPS, {
    partialRefetch: true,
    fetchPolicy: "cache-and-network",
  });

  const programmeGroups = data ? data.programme_group : [];

  return { programmeGroups, error, loading: !data && loading, refetch };
};

export default useProgrammeGroups;

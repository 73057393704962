import { graphql } from "../generated";

const FETCH_USER_WARNINGS= graphql(`
  query FetchUserWarnings($userId: uuid!) {
    user_warning(where: { user_id: { _eq: $userId } }) {
      warning {
        created_at
        id
        message
        slug
        updated_at
      }
    }
  }
`);

export default FETCH_USER_WARNINGS;

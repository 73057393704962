import React from "react";
import SelectField from "./field/SelectField";
import useOrganisations from "../hooks/useOrganisations";
import { OrganisationId } from "../context/types";

interface OrganisationSelectProps {
  label?: string;
  name?: string;
  organisationId?: OrganisationId;
  setOrganisationId: (organisationId: OrganisationId) => void;
  disabled?: boolean;
}
const OrganisationSelect: React.FC<OrganisationSelectProps> = (props) => {
  const {
    name = "organisation",
    label = "Organisation",
    organisationId = "",
    setOrganisationId,
    disabled,
  } = props;
  const { organisations } = useOrganisations();

  return (
    <SelectField
      label={label}
      name={name}
      options={organisations.map((organisation) => ({
        value: organisation.id,
        label: organisation.name,
      }))}
      value={organisationId}
      onChange={setOrganisationId}
      disabled={disabled}
    />
  );
};

export default OrganisationSelect;

import { useState, useEffect } from "react";
import LoadingSplash from "../components/LoadingSplash";

import CreateReactAppEntryPoint from "../CreateReactAppEntryPoint";

function App() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <LoadingSplash isLoaded={isMounted} />
      {isMounted ? <CreateReactAppEntryPoint /> : null}
    </>
  );
}

export default App;

import { graphql } from "../generated";

const UPDATE_LOCATION= graphql(`
  mutation UpdateLocation(
    $id: uuid!
    $location: location_set_input!
    $programme_locations: [programme_location_insert_input!]!
  ) {
    update_location(_set: $location, where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_programme_location(where: { location_id: { _eq: $id } }) {
      returning {
        programme_id
        location_id
      }
    }
    insert_programme_location(
      objects: $programme_locations
      on_conflict: { constraint: programme_centre_pkey, update_columns: [] }
    ) {
      returning {
        programme_id
        location_id
      }
    }
  }
`);

export default UPDATE_LOCATION;

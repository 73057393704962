import React, { Fragment, useState } from "react";

import Avatar from "./Avatar";
import { getFullName } from "../helpers/user";
import { isChildAtTimeOfRegister } from "../helpers/participant";

import Ellipsis from "./Ellipsis";
import DialogTextbox from "./DialogTextbox";
import IconButton from "./IconButton";
import { useMutation } from "@apollo/client";
import { ExecutionResult } from "graphql";
import UPDATE_REGISTER_PARTICIPANT from "../graphql/queries/update-register-participant";
import DELETE_REGISTER_PARTICIPANT from "../graphql/queries/delete-register-participant";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Icon from "./Icon";
import ParticipantsDialog from "./ParticipantsDialog";
import CREATE_REGISTER_PARTICIPANT from "../graphql/queries/create-register-participant";
import Switch from "./field/Switch";
import { ParticipantId } from "../context/types";
import { getParticipantPath } from "../helpers/route-path";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import useSessionProgramme from "../hooks/useSessionProgramme";
import ParticipantsSearchDialog from "./ParticipantsSearchDialog";
import CREATE_PARENT_CHILD from "../graphql/queries/create-parent-child";
import ParticipantMissingInfo from "./ParticipantMissingInfo";
import {
  FetchRegisterQuery,
  UpdateRegisterParticipantMutation,
} from "@/graphql/generated/graphql";
import errorService from "@/common-lib/error";
import useSelf from "@/hooks/useSelf";
import { useIsAdmin } from "@/hooks/useIsAdmin";
import { useIsProgrammeClosed } from "@/hooks/useIsProgrammeClosed";

type Register = NonNullable<FetchRegisterQuery["register_by_pk"]>;
type RegisterParticipants = Register["register_participants"];
type RegisterParticipant = RegisterParticipants[number];
type Participant = RegisterParticipant["participant"];
type Attendee = RegisterParticipant & {
  parentsPresent: Array<Attendee>;
  childrenPresent: Array<Attendee>;
};

const addParentsAndChildren = (
  register: Register,
  rp: RegisterParticipant,
  depth: number
): Attendee => {
  if (depth > 1 || !register) {
    return { ...rp, parentsPresent: [], childrenPresent: [] };
  }

  return {
    ...rp,
    parentsPresent: register.register_participants
      .filter(({ participant }) => {
        return rp.participant.parents.some(({ parent }) => {
          return parent.id === participant.id;
        });
      })
      .map((parent) => addParentsAndChildren(register, parent, depth + 1)),
    childrenPresent: register.register_participants
      .filter(({ participant }) => {
        return rp.participant.children.some(({ child }) => {
          return child.id === participant.id;
        });
      })
      .map((parent) => addParentsAndChildren(register, parent, depth + 1)),
  };
};

const Placeholder = styled.button<{ size?: "medium" }>`
  border: 3px dashed lightgrey;
  color: grey;
  height: 30px;
  display: flex;
  align-items: center;
  width: 100%;

  ${(props) =>
    props.size === "medium" &&
    `
      height: 50px;
  `}
`;

interface RegisterAttendeeProps {
  participant: Participant;
  userHasEditPermission: boolean;
  notes?: string | null;
  register: Register;
  registerParticipant: RegisterParticipant;
  updateNote: (
    participantId: string,
    notes: string
  ) => Promise<ExecutionResult<UpdateRegisterParticipantMutation>>;
  parentsPresent: Array<Attendee>;
  childrenPresent: Array<Attendee>;
  depth: number;
  rankByFamily: boolean;
  refetchRegister: () => void;
}
const RegisterAttendee: React.FC<RegisterAttendeeProps> = (props) => {
  const {
    participant,
    register,
    updateNote,
    notes,
    parentsPresent,
    childrenPresent,
    depth,
    rankByFamily,
    refetchRegister,
    registerParticipant,
    userHasEditPermission,
  } = props;

  const isAdmin = useIsAdmin();
  const programme = useSessionProgramme({ fetchPolicy: "cache-first" });
  const organisation = useSessionOrganisation();
  const isProgrammeClosed = useIsProgrammeClosed();

  const [removed, setRemoved] = useState(false);
  const [removeParticipant] = useMutation(DELETE_REGISTER_PARTICIPANT, {
    onCompleted: () => {
      setRemoved(true);
      refetchRegister();
    },
  });

  const onRemove = () => {
    if (!register) {
      errorService.error(
        new Error("Cannot remove participant from register: no register found")
      );
      return;
    }
    removeParticipant({
      variables: {
        register_id: register.id,
        participant_id: participant.id,
      },
    });
  };

  const [addParticipant] = useMutation(CREATE_REGISTER_PARTICIPANT, {
    onCompleted: () => refetchRegister(),
  });

  const addToRegister = (participant: { id: ParticipantId }) => {
    if (!register) {
      errorService.error(
        new Error("Cannot remove participant from register: no register found")
      );
      return;
    }
    addParticipant({
      variables: {
        register_participant: {
          register_id: register.id,
          participant_id: participant.id,
        },
      },
    });
  };

  const [addParentChild] = useMutation(CREATE_PARENT_CHILD, {
    onCompleted: () => refetchRegister(),
  });

  if (removed) {
    return null;
  }

  if (depth > 1) {
    return null;
  }

  if (!programme || !organisation) {
    return null;
  }

  if (!register || !registerParticipant) {
    return null;
  }
  const isChild = isChildAtTimeOfRegister(
    participant,
    register,
    registerParticipant
  );

  const isParent = isChild === false;

  const shouldShowParentsPlaceholder =
    rankByFamily && participant.parents.length > parentsPresent.length;

  const shouldShowNoParentPlaceholder =
    rankByFamily && isChild && participant.parents.length === 0;

  const shouldShowChildrenPlaceholder =
    rankByFamily && participant.children.length > childrenPresent.length;

  const shouldIndent =
    rankByFamily &&
    (depth > 0 ||
      shouldShowParentsPlaceholder ||
      shouldShowNoParentPlaceholder);

  const fullName = getFullName(participant.sensitive_fields);

  const userCanRemoveParticipant =
    userHasEditPermission || isAdmin || !isProgrammeClosed;

  return (
    <li>
      {shouldShowParentsPlaceholder && (
        <ParticipantsDialog
          title="Add Parents"
          participants={participant.parents.map(({ parent }) => parent)}
          excludeIds={
            new Set(parentsPresent.map(({ participant }) => participant.id))
          }
          onSelect={addToRegister}
          Opener={(props) => (
            <Placeholder {...props} size="medium" className="mb2">
              <Icon className="ph2 mr2" icon="group_add" />
              Add {parentsPresent.length > 0 ? "another" : "a"} parent for{" "}
              {fullName}
            </Placeholder>
          )}
        />
      )}
      {shouldShowNoParentPlaceholder && (
        <ParticipantsSearchDialog
          title={`Who is ${fullName}'s parent?`}
          excludeIds={[participant.id]}
          filters={
            {
              // TODO this doesn't work because we're missing so many DOBs
              // dobBefore: subYears(new Date(participant.date_of_birth), 12),
            }
          }
          onSelect={(value) =>
            addParentChild({
              variables: {
                parent_child: {
                  child_id: participant.id,
                  parent_id: value.id,
                },
              },
            })
          }
          Opener={(props) => (
            <Placeholder {...props} size="medium" className="mb2">
              <Icon className="ph2 mr2" icon="group_add" />
              Who is {fullName}'s parent?
            </Placeholder>
          )}
        />
      )}
      <div
        className={`card flex items-center justify-between mb2 pr2 ${
          shouldIndent ? "ml3" : ""
        }`}
      >
        <Avatar
          person={participant}
          className="mr3"
          size={shouldIndent ? "small" : "medium"}
          color={isChild ? "pinkBg" : isParent ? "blue2Bg" : "borderGrey"}
          title={
            isChild
              ? "Child"
              : isParent
              ? "Carer"
              : "More info needed to know if this person is a child or carer"
          }
        />
        <Link
          title={`Go to ${fullName}'s profile`}
          className="mr3 nowrap"
          to={getParticipantPath({
            organisationSlug: organisation.slug,
            programmeSlug: register.project.programme.slug,
            participantId: participant.id,
          })}
        >
          {fullName}{" "}
        </Link>
        <ParticipantMissingInfo participant={participant} />
        <div className="flex-auto justify-end i flex pr2 pl4">
          <Ellipsis className="flex items-center ">{notes}</Ellipsis>
        </div>
        <DialogTextbox
          disabled={!userHasEditPermission || isProgrammeClosed}
          title={`Add Session Notes for ${fullName}`}
          onSave={async (value) => await updateNote(participant.id, value)}
          initialValue={notes}
        />
        <IconButton
          disabled={!userCanRemoveParticipant || isProgrammeClosed}
          title={`Remove ${fullName} from this Register`}
          onClick={onRemove}
          icon="delete"
        />
      </div>
      {depth === 0 && (
        <ul>
          {childrenPresent.map((child) => (
            <RegisterAttendee
              key={`RegisterAttendees-${child.participant.id}`}
              {...props}
              {...child}
              depth={depth + 1}
            />
          ))}
          {shouldShowChildrenPlaceholder && (
            <div className="pl3">
              <ParticipantsDialog
                title="Add Children"
                participants={participant.children.map(({ child }) => child)}
                excludeIds={
                  new Set(
                    childrenPresent.map(({ participant }) => participant.id)
                  )
                }
                onSelect={addToRegister}
                Opener={(props) => (
                  <Placeholder {...props} className="mb2">
                    <Icon className="mh2" icon="group_add" /> Add{" "}
                    {childrenPresent.length > 0 ? "another" : "a"} child for{" "}
                    {fullName}
                  </Placeholder>
                )}
              />
            </div>
          )}
        </ul>
      )}
    </li>
  );
};

interface RegisterAttendeesProps {
  register: Register;
  refetchRegister: () => void;
}
const RegisterAttendees: React.FC<RegisterAttendeesProps> = (props) => {
  const { register, refetchRegister } = props;
  const [rankByFamily, setRankByFamily] = useState(false);

  const [updateParticipant] = useMutation(UPDATE_REGISTER_PARTICIPANT, {
    onCompleted: () => refetchRegister(),
  });

  if (!register) {
    return null;
  }

  const updateNote = (participantId: ParticipantId, notes: string) => {
    return updateParticipant({
      variables: {
        register_id: register.id,
        participant_id: participantId,
        sensitive_fields: { notes },
      },
    });
  };

  const attendees: Array<
    Attendee & {
      parentsPresent: Array<Attendee>;
      childrenPresent: Array<Attendee>;
    }
  > = rankByFamily
    ? register.register_participants.map((rp) =>
        addParentsAndChildren(register, rp, 0)
      )
    : register.register_participants.map((rp) => ({
        ...rp,
        parentsPresent: [],
        childrenPresent: [],
      }));

  return (
    <Fragment>
      <h2 className="flex items-center justify-between mb3">
        Attendees{" "}
        <Switch
          checked={rankByFamily}
          name="family-view"
          offLabel="Family View"
          onChange={setRankByFamily}
        />
      </h2>
      <ul>
        {attendees.map((attendee) => {
          const {
            participant,
            sensitive_fields,
            parentsPresent,
            childrenPresent,
          } = attendee;

          const shouldHide =
            childrenPresent.length === 0 && parentsPresent.length > 0;

          if (shouldHide) {
            return null;
          }

          return (
            <RegisterAttendee
              key={`RegisterAttendees-RegisterAttendee-${register.id}-${participant.id}`}
              register={register}
              participant={participant}
              userHasEditPermission={Boolean(sensitive_fields)}
              notes={sensitive_fields ? sensitive_fields.notes : "REDACTED"}
              parentsPresent={parentsPresent}
              childrenPresent={childrenPresent}
              updateNote={updateNote}
              depth={0}
              rankByFamily={rankByFamily}
              refetchRegister={refetchRegister}
              registerParticipant={attendee}
            />
          );
        })}
      </ul>
    </Fragment>
  );
};

export default RegisterAttendees;

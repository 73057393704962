import { graphql } from "../generated";

const FETCH_PROJECTS= graphql(`
  query FetchProjects(
    $dynamicWhere: project_bool_exp
    $dynamicOrderBy: [project_order_by!]
  ) {
    project(where: $dynamicWhere, order_by: $dynamicOrderBy) {
      id
      name
      slug
      programme_id
      organisation_id
      categories {
        id
        name
      }
      programme {
        id
        name
        slug
      }
      organisation {
        id
        name
        slug
      }
    }
  }
`);

export default FETCH_PROJECTS;

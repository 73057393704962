import React, { SyntheticEvent, ReactNode, useEffect } from "react";
import styled from "styled-components";
import { FocusOn } from "react-focus-on";

interface RootProps {
  isOpen: boolean;
}
const Root = styled.div<RootProps>`
  position: fixed;
  z-index: 1300;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;

  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  touch-action: none;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const DialogContainer = styled.div`
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;

  &::after {
    width: 0;
    height: 100%;
    content: "";
    display: inline-block;
    vertical-align: middle;
  }
`;

const DialogInner = styled.div<{ fullHeight?: boolean; locationd?: boolean }>`
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  display: inline-flex;
  text-align: left;
  vertical-align: top;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  max-width: 600px;
  margin: 32px auto;
  position: relative;
  overflow-y: auto;
  width: 100%;

  ${(props) => props.fullHeight && `height: calc(100% - 64px);`}
  ${(props) =>
    props.locationd &&
    `
    vertical-align: middle;
  `}
`;

const DialogTitle = styled.header`
  flex: 0 0 auto;
  padding: 0px 24px;
  padding-top: 1.5rem;
`;

const DialogContent = styled.div`
  flex: 1 1 auto;
  padding: 8px 24px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
`;

const ClickAway = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 20px; // To allow for scrollbar
  bottom: 0;
`;

interface Props {
  children: ReactNode;
  title: ReactNode;
  isOpen: boolean;
  onRequestClose: () => void;
  fullHeight?: boolean;
  locationd?: boolean;
}

const Dialog: React.FC<Props> = (props) => {
  const { isOpen, title, children, onRequestClose, fullHeight, locationd } =
    props;

  const onClickAwayClick = (e: SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.currentTarget === e.target) {
      onRequestClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <FocusOn onEscapeKey={onRequestClose}>
      <Root isOpen={isOpen}>
        <Backdrop />
        <DialogContainer>
          <ClickAway onClick={onClickAwayClick} />
          <DialogInner fullHeight={fullHeight} locationd={locationd}>
            <DialogTitle>
              <h2>{title}</h2>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
          </DialogInner>
        </DialogContainer>
      </Root>
    </FocusOn>
  );
};

export default Dialog;

import React from "react";

import errorService from "../common-lib/error";
import LoadingSpinner from "./LoadingSpinner";
import useParticipantSearch from "../hooks/useParticipantSearch";
import { ParticipantId, RegisterId } from "../context/types";
import RegisterAddFromParticipantItem from "./RegisterAddFromParticipantItem";

export interface RegisterAddFromAllProps {
  registerId: RegisterId;
  excludeIds?: Array<ParticipantId>;
  searchTerm: string;
  onAdd: () => void;
}
const RegisterAddFromAll: React.FC<RegisterAddFromAllProps> = (props) => {
  const { registerId, excludeIds = [], searchTerm, onAdd } = props;
  const { loading, error, participants } = useParticipantSearch({
    searchTerm,
    excludeIds,
  });

  if (error) {
    errorService.error(error);
  }

  return (
    <ul className="relative flex-auto">
      {participants.map((participant) => {
        return (
          <RegisterAddFromParticipantItem
            key={`Participants-li-${participant.id}`}
            participant={participant}
            registerId={registerId}
            onAdd={onAdd}
          />
        );
      })}
      {participants.length === 0 && loading && <LoadingSpinner type="row" />}
    </ul>
  );
};

export default RegisterAddFromAll;

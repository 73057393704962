import React, { useState, Fragment } from "react";
import { nanoid } from "nanoid";

import { getAgeAtTimeOfRegister } from "../helpers/participant";
import { AdminGrid, AdminGridCell } from "./AdminGrid";
import { getCountryName } from "../helpers/country";
import { DownloadReportBreakdown } from "../api/use-download-report";
import { RegisterParticipantAggregateQuery } from "@/graphql/generated/graphql";

type RegisterParticipants =
  RegisterParticipantAggregateQuery["register_participant"];

interface ReportBreakdownProps {
  registerParticipants: RegisterParticipants;
  count: number;
}

type SortBy = "id" | "total" | null;
type SortDir = "asc" | "desc";

const getSortDir = (
  oldSortDir: SortDir,
  oldSortBy: SortBy,
  newSortBy: SortBy
) => {
  const isFreshSort = newSortBy !== oldSortBy;

  if (isFreshSort) {
    return "asc";
  }

  if (oldSortDir === "asc") {
    return "desc";
  }

  return "asc";
};

interface BreakdownTableProps {
  rows: { id: string; total: number }[];
  headings: { name: string; sortBy: SortBy }[];
  count: number;
}

const BreakdownTable: React.FC<BreakdownTableProps> = (props) => {
  const [sortBy, setSortBy] = useState<SortBy>("id");
  const [sortDir, setSortDir] = useState<SortDir>("asc");

  const { rows, headings, count } = props;

  const sortedRows = [...rows].sort((a, b) => {
    if (sortBy === null) {
      return 1;
    }
    if (sortDir === "asc") {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    }

    return a[sortBy] > b[sortBy] ? -1 : 1;
  });

  const onSortClick = (newSortBy: SortBy) => {
    const newSortDir = getSortDir(sortDir, sortBy, newSortBy);

    setSortBy(newSortBy);
    setSortDir(newSortDir);
  };

  const [tableId] = useState(nanoid());

  return (
    <AdminGrid columns={headings.length}>
      {headings.map((heading, i) => (
        <AdminGridCell
          key={`ReportBreakdown-BreakdownTable-${tableId}-${i}`}
          isHeading
        >
          <button
            className="flex items-center"
            onClick={() => onSortClick(heading.sortBy)}
          >
            {heading.name}
            {heading.sortBy === sortBy && (
              <span className="material-icons">
                {sortDir === "asc" ? "expand_more" : "expand_less"}
              </span>
            )}
          </button>
        </AdminGridCell>
      ))}

      {sortedRows.map((row) => {
        const { id, total } = row;

        return (
          <Fragment key={`AdminReports-by-age-${id}`}>
            <AdminGridCell>{id}</AdminGridCell>
            <AdminGridCell>{total}</AdminGridCell>
            <AdminGridCell>{((total / count) * 100).toFixed(1)}%</AdminGridCell>
          </Fragment>
        );
      })}
    </AdminGrid>
  );
};

const getBreakdownPropsForDownload = ({
  headings,
  rows,
  count,
}: BreakdownTableProps): DownloadReportBreakdown => {
  return {
    by: headings[0]?.name,
    columnHeadings: rows.map((row) => row.id),
    rawValues: rows.map((row) => row.total),
    percentValues: rows.map((row) => ((row.total / count) * 100).toFixed(1)),
  };
};

export const getDownloadReportBreakdownPayload = (
  props: ReportBreakdownProps
) => {
  if (!props.count) {
    return [];
  }
  return [
    getReportByAgeProps,
    getReportByCountryProps,
    getReportByGenderProps,
    getReportByLocationProps,
    getReportByOrganisationProps,
    getReportByProjectProps,
  ].map((fn) => getBreakdownPropsForDownload(fn(props)));
};

const getReportByAgeProps = (
  props: ReportBreakdownProps
): BreakdownTableProps => {
  const headings: { name: string; sortBy: SortBy }[] = [
    { name: "Age Group", sortBy: null },
    { name: "Number", sortBy: "total" },
    { name: "Percentage", sortBy: "total" },
  ];
  const { registerParticipants, count } = props;

  const ageGroups = registerParticipants.reduce(
    (accum, registerParticipant) => {
      const age = getAgeAtTimeOfRegister(registerParticipant);

      if (age === null) {
        accum.Unknown = accum.Unknown + 1;
      } else if (age < 3) {
        accum["0-2"] = accum["0-2"] + 1;
      } else if (age < 7) {
        accum["3-6"] = accum["3-6"] + 1;
      } else if (age < 13) {
        accum["7-12"] = accum["7-12"] + 1;
      } else if (age < 18) {
        accum["13-17"] = accum["13-17"] + 1;
      } else {
        accum["18+"] = accum["18+"] + 1;
      }

      return accum;
    },
    {
      "0-2": 0,
      "3-6": 0,
      "7-12": 0,
      "13-17": 0,
      "18+": 0,
      Unknown: 0,
    }
  );

  const rows = Object.keys(ageGroups).map((key) => {
    return {
      id: key,
      // @ts-ignore
      total: ageGroups[key],
    };
  });

  return {
    headings,
    rows,
    count,
  };
};

export const ReportByAge: React.FC<ReportBreakdownProps> = (props) => {
  return <BreakdownTable {...getReportByAgeProps(props)} />;
};

const getReportByCountryProps = (
  props: ReportBreakdownProps
): BreakdownTableProps => {
  const headings: { name: string; sortBy: SortBy }[] = [
    { name: "Country", sortBy: "id" },
    { name: "Number", sortBy: "total" },
    { name: "Percentage", sortBy: "total" },
  ];
  const { registerParticipants, count } = props;

  const countryGroups = registerParticipants.reduce(
    (accum, registerParticipant) => {
      const countryCode = registerParticipant.participant.country_code;

      if (!countryCode) {
        accum.null = (accum.null || 0) + 1;
      } else {
        accum[countryCode] = (accum[countryCode] || 0) + 1;
      }

      return accum;
    },
    {} as { [countryCode: string]: number }
  );

  const rows = Object.keys(countryGroups).map((key) => {
    return {
      id: getCountryName(key) || "Unknown",
      // @ts-ignore
      total: countryGroups[key],
    };
  });

  return {
    headings,
    rows,
    count,
  };
};
export const ReportByCountry: React.FC<ReportBreakdownProps> = (props) => {
  return <BreakdownTable {...getReportByCountryProps(props)} />;
};

const getReportByGenderProps = (
  props: ReportBreakdownProps
): BreakdownTableProps => {
  const headings: { name: string; sortBy: SortBy }[] = [
    { name: "Gender", sortBy: "id" },
    { name: "Number", sortBy: "total" },
    { name: "Percentage", sortBy: "total" },
  ];
  const { registerParticipants, count } = props;

  const genderGroups = registerParticipants.reduce(
    (accum, registerParticipant) => {
      const sex = registerParticipant.participant.sex;

      if (sex === null) {
        accum["Unknown"] = (accum["Unknown"] || 0) + 1;
      } else if (sex === "male") {
        accum["Male"] = (accum["Male"] || 0) + 1;
      } else if (sex === "female") {
        accum["Female"] = (accum["Female"] || 0) + 1;
      } else if (sex === "other") {
        accum["Other"] = (accum["Other"] || 0) + 1;
      }

      return accum;
    },
    { Male: 0, Female: 0, Other: 0, Unknown: 0 }
  );

  const rows = Object.keys(genderGroups).map((key) => {
    return {
      id: key,
      // @ts-ignore
      total: genderGroups[key],
    };
  });

  return {
    headings,
    rows,
    count,
  };
};
export const ReportByGender: React.FC<ReportBreakdownProps> = (props) => {
  return <BreakdownTable {...getReportByGenderProps(props)} />;
};

const getReportByOrganisationProps = (
  props: ReportBreakdownProps
): BreakdownTableProps => {
  const headings: { name: string; sortBy: SortBy }[] = [
    { name: "Organisation", sortBy: "id" },
    { name: "Number", sortBy: "total" },
    { name: "Percentage", sortBy: "total" },
  ];
  const { registerParticipants, count } = props;

  const organisationGroups = registerParticipants.reduce(
    (accum, registerParticipant) => {
      const { name: organisationName } =
        registerParticipant.register.organisation;

      accum[organisationName] = (accum[organisationName] || 0) + 1;

      return accum;
    },
    {} as { [organisationName: string]: number }
  );

  const rows = Object.keys(organisationGroups).map((key) => {
    return {
      id: key,
      // @ts-ignore
      total: organisationGroups[key],
    };
  });

  return {
    headings,
    rows,
    count,
  };
};

export const ReportByOrganisation: React.FC<ReportBreakdownProps> = (props) => {
  return <BreakdownTable {...getReportByOrganisationProps(props)} />;
};

const getReportByLocationProps = (
  props: ReportBreakdownProps
): BreakdownTableProps => {
  const headings: { name: string; sortBy: SortBy }[] = [
    { name: "Location", sortBy: "id" },
    { name: "Number", sortBy: "total" },
    { name: "Percentage", sortBy: "total" },
  ];
  const { registerParticipants, count } = props;

  const locationGroups = registerParticipants.reduce(
    (accum, registerParticipant) => {
      const { name: locationName } = registerParticipant.register.location;

      accum[locationName] = (accum[locationName] || 0) + 1;

      return accum;
    },
    {} as { [locationName: string]: number }
  );

  const rows = Object.keys(locationGroups).map((key) => {
    return {
      id: key,
      // @ts-ignore
      total: locationGroups[key],
    };
  });

  return {
    headings,
    rows,
    count,
  };
};
export const ReportByLocation: React.FC<ReportBreakdownProps> = (props) => {
  return <BreakdownTable {...getReportByLocationProps(props)} />;
};

const getReportByProjectProps = (
  props: ReportBreakdownProps
): BreakdownTableProps => {
  const headings: { name: string; sortBy: SortBy }[] = [
    { name: "Project", sortBy: "id" },
    { name: "Number", sortBy: "total" },
    { name: "Percentage", sortBy: "total" },
  ];
  const { registerParticipants, count } = props;

  const projectGroups = registerParticipants.reduce(
    (accum, registerParticipant) => {
      const { name: projectName } = registerParticipant.register.project;

      accum[projectName] = (accum[projectName] || 0) + 1;

      return accum;
    },
    {} as { [projectName: string]: number }
  );

  const rows = Object.keys(projectGroups).map((key) => {
    return {
      id: key,
      // @ts-ignore
      total: projectGroups[key],
    };
  });
  return {
    headings,
    rows,
    count,
  };
};
export const ReportByProject: React.FC<ReportBreakdownProps> = (props) => {
  return <BreakdownTable {...getReportByProjectProps(props)} />;
};

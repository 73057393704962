import { useEffect } from "react";
import { useParams } from "react-router-dom";

import useOrganisations, { Organisations } from "./useOrganisations";
import { FetchPolicy } from "@apollo/client";

const useSessionOrganisation = (props?: { fetchPolicy: FetchPolicy }) => {
  const { organisationSlug } = useParams<{ organisationSlug?: string }>();
  const { organisations, refetch } = useOrganisations({ ...props });

  const organisation = organisations.find((p) => p.slug === organisationSlug);

  useEffect(() => {
    refetch();
  }, [organisationSlug]);

  return organisation;
};

export type SessionOrganisation = Organisations[number];

export default useSessionOrganisation;

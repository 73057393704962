import { SessionOrganisation } from "@/hooks/useSessionOrganisation";

export const getIsManager = (
  userId: string,
  organisation?: SessionOrganisation
) => {
  if (organisation) {
    return organisation.organisation_managers.some(
      ({ user_id }) => user_id === userId
    );
  }
};

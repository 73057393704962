import { graphql } from "../generated";

const CREATE_PARTICIPANT_FILE= graphql(`
  mutation CreateParticipantFile(
    $participant_file: participant_files_insert_input!
  ) {
    insert_participant_files(objects: [$participant_file]) {
      returning {
        participant_id
        file_size
        file_name
        id
      }
    }
  }
`);

export default CREATE_PARTICIPANT_FILE;

import { graphql } from "../generated";

const CREATE_PROJECT= graphql(`
  mutation CreateProject($project: project_insert_input!) {
    insert_project(objects: [$project]) {
      returning {
        id
      }
    }
  }
`);

export default CREATE_PROJECT;

import React from "react";
import SelectField from "./field/SelectField";
import useLocations from "../hooks/useLocations";
import { LocationId } from "../context/types";

interface LocationSelectProps {
  label?: string;
  name?: string;
  locationId?: LocationId;
  setLocationId: (locationId: LocationId) => void;
}
const LocationSelect: React.FC<LocationSelectProps> = (props) => {
  const {
    name = "location",
    label = "Location",
    locationId = "",
    setLocationId,
  } = props;
  const { locations } = useLocations();

  return (
    <SelectField
      label={label}
      name={name}
      options={locations.map((location) => ({
        value: location.id,
        label: location.name,
      }))}
      value={locationId}
      onChange={setLocationId}
    />
  );
};

export default LocationSelect;

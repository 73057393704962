import { ProjectId } from "../context/types";
import useProjects from "./useProjects";
import flatten from "lodash/flatten";

interface Opts {
  projectId?: ProjectId;
}
const useProjectCategories = (opts: Opts) => {
  const { projectId } = opts;
  const { projects, ...rest } = useProjects();

  const projectCategories = projectId
    ? projects.find(({ id }) => id === projectId)?.categories || []
    : flatten(projects.map((project) => project.categories));

  return {
    projectCategories,
    ...rest,
  };
};

export default useProjectCategories;

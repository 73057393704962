import React, { ReactNode } from "react";
import styled from "styled-components";
import colours from "../constants/colours";
import Icon from "./Icon";

const NoContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  color: ${colours.textGreyLight};
`;
const NoContentIcon = styled(Icon)`
  width: 120px;
`;
const NoContentMessage = styled.p`
  max-width: 420px;
  text-align: center;
  margin-top: 1rem;
`;

interface NoContentProps {
  icon: string;
  message: ReactNode;
}
const NoContent: React.FC<NoContentProps> = (props) => {
  const { icon, message } = props;

  return (
    <NoContentRoot>
      <NoContentIcon icon={icon} size="xlarge" />
      <NoContentMessage>{message}</NoContentMessage>
    </NoContentRoot>
  );
};

export default NoContent;

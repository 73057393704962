import { useQuery } from "@apollo/client";

import FETCH_LOCATIONS from "../graphql/queries/fetch-locations";
import useSessionProgramme from "./useSessionProgramme";
import useSessionOrganisation from "./useSessionOrganisation";
import { useEffect } from "react";
import { Location_Order_By } from "@/graphql/generated/graphql";

interface Opts {
  orderBy?: Location_Order_By[];
}
const useLocations = (opts: Opts = {}) => {
  const programme = useSessionProgramme();
  const organisation = useSessionOrganisation();
  const { orderBy = [] } = opts;

  const { data, error, loading, refetch } = useQuery(FETCH_LOCATIONS, {
    variables: {
      dynamicOrderBy: orderBy,
      dynamicWhere: {
        ...(organisation
          ? { organisation_id: { _eq: organisation.id } }
          : null),
        ...(programme
          ? {
              programme_locations: {
                programme: { id: { _eq: programme.id } },
              },
            }
          : null),
      },
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const locations = data ? data.location : [];

  return { locations, error, loading, refetch };
};

export default useLocations;

import { FetchUsersQuery } from "@/graphql/generated/graphql";
import { User, Token } from "../context/types";

interface RequestCodePayload {
  email: string;
  password: string;
}
export const requestCode = async (
  payload: RequestCodePayload
): Promise<{
  expires_at: string;
}> => {
  const response = await fetch(`/api/auth/request-code`, {
    method: "POST",
    body: JSON.stringify(payload),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result.message);
  }

  return result;
};
interface LoginPayload {
  loginCode: string;
}
export const login = async (
  payload: LoginPayload
): Promise<{
  user: User;
  token: Token;
}> => {
  const response = await fetch(`/api/auth/login`, {
    method: "POST",
    body: JSON.stringify(payload),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result.message);
  }

  return result;
};

export type NewUserPayload = Omit<
  FetchUsersQuery["user"][number],
  "__typename" | "organisation_users"
> & {
  password: string;
};

export type EditUserPayload = Omit<NewUserPayload, "password"> & {
  password?: string;
};

export type EditSelfPayload = Omit<EditUserPayload, "role"> & {};

export const newUser = async (
  payload: NewUserPayload
): Promise<{
  user: User;
  token: Token;
}> => {
  const response = await fetch(`/api/user/create-user`, {
    method: "POST",
    body: JSON.stringify(payload),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result.message);
  }

  return result;
};

export const logout = async (): Promise<{}> => {
  const response = await fetch(`/api/auth/logout`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("Could not logout");
  }

  return response.statusText;
};

export const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));
export const REFRESH_TOKEN_INVALID = "REFRESH_TOKEN_INVALID";
export const REFRESH_TOKEN_CONNECTION_FAILED =
  "REFRESH_TOKEN_CONNECTION_FAILED";
export const refreshToken = async (
  retryTimes: number = 0
): Promise<{
  token: Token;
}> => {
  try {
    const response = await fetch(`/api/auth/refresh-token`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 401) {
      // LOGOUT
      throw new Error(REFRESH_TOKEN_INVALID);
    }
    if (!response.ok) {
      throw new Error(REFRESH_TOKEN_CONNECTION_FAILED);
    }
    const result = await response.json();

    return result;
  } catch (error) {
    if (!(error instanceof Error)) {
      return Promise.reject(new Error(JSON.stringify(error)));
    }
    if (error.message === REFRESH_TOKEN_INVALID) {
      return Promise.reject(new Error(REFRESH_TOKEN_INVALID));
    }
    if (retryTimes > 1) {
      await wait(2000);

      return refreshToken(retryTimes - 1);
    }

    return Promise.reject(new Error(REFRESH_TOKEN_CONNECTION_FAILED));
  }
};

export const editUser = async (
  payload: EditUserPayload
): Promise<{
  user: User;
  token: Token;
}> => {
  const response = await fetch(`/api/user/update-user`, {
    method: "PUT",
    body: JSON.stringify(payload),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result.message);
  }

  return result;
};

export const editSelf = async (
  payload: EditSelfPayload
): Promise<{
  user: User;
  token: Token;
}> => {
  const response = await fetch(`/api/self`, {
    method: "PUT",
    body: JSON.stringify(payload),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result.message);
  }

  return result;
};

import { graphql } from "../generated";

const CREATE_PARTICIPANT= graphql(`
  mutation CreateParticipant($participant: participant_insert_input!) {
    insert_participant(objects: [$participant]) {
      returning {
        id
      }
    }
  }
`);

export default CREATE_PARTICIPANT;

import { graphql } from "../generated";

const RESTORE_REGISTER = graphql(`
  mutation RestoreRegister($id: uuid!) {
    update_register(_set: { deleted_at: null }, where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`);

export default RESTORE_REGISTER;

import React, { ReactNode, HTMLProps } from "react";
import Age from "./icons/Age";
import styled from "styled-components";
import Groups from "./icons/Groups";

const icons: { [key: string]: React.FC<HTMLProps<SVGSVGElement>> } = {
  age: Age,
  groups: Groups,
};

export type Size = "small" | "medium" | "xlarge";

const Container = styled.span`
  color: ${(props) => props.color || "inherit"};
`;
const MaterialIcon = styled.i<{ size: Size }>`
  ${(props) =>
    props.size === "small" &&
    `
font-size: 16px;
`}

  ${(props) =>
    props.size === "medium" &&
    `
font-size: 24px;
`}
${(props) =>
    props.size === "xlarge" &&
    `
font-size: 120px;
`}
`;
const LocalIconWrapper = styled.div<{ size: Size }>`
  ${(props) =>
    props.size === "small" &&
    `
width: 16px;
`}

  ${(props) =>
    props.size === "medium" &&
    `
width: 24px;
`}
${(props) =>
    props.size === "xlarge" &&
    `
width: 120px;
`}
`;
interface IconProps extends Omit<HTMLProps<HTMLSpanElement>, "size"> {
  icon: string;
  className?: string;
  text?: string | ReactNode;
  size?: Size;
}
const Icon: React.FC<IconProps> = (props) => {
  const { icon, text, size = "medium", className, ...spanProps } = props;
  const margin = text ? "mr2" : "";
  const LocalIcon = icons[icon];

  return (
    <span className={`flex items-center ${className || ""}`} {...spanProps}>
      {LocalIcon ? (
        <LocalIconWrapper size={size} className={margin}>
          <LocalIcon width="100%" height="100%" />
        </LocalIconWrapper>
      ) : (
        <MaterialIcon size={size} className={`material-icons ${margin}`}>
          {props.icon}
        </MaterialIcon>
      )}
      {props.text}
    </span>
  );
};

export default Icon;

import { graphql } from "../generated";

const DELETE_REGISTER = graphql(`
  mutation DeleteRegister($id: uuid!) {
    update_register(
      _set: { deleted_at: "now()" }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
      }
    }
  }
`);

export default DELETE_REGISTER;

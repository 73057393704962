import React, {
  Fragment,
  useState,
  SyntheticEvent,
  useEffect,
  useCallback,
} from "react";
import { format } from "date-fns";
import * as yup from "yup";

import Dialog from "./Dialog";
import TextField from "./field/TextField";
import FlatButton from "./FlatButton";

import { useMutation } from "@apollo/client";
import CREATE_REGISTER from "../graphql/queries/create-register";
import LoadingSpinner from "./LoadingSpinner";

import useSessionProgramme from "../hooks/useSessionProgramme";
import useUsers from "../hooks/useUsers";
import { getFullName } from "../helpers/user";
import { useHistory } from "react-router-dom";
import MultiAutocompleteField from "./field/MultiAutocompleteField";
import ErrorMessage from "./ErrorMessage";
import useSelf from "../hooks/useSelf";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import { getRegisterPath } from "../helpers/route-path";
import ProjectSelect from "./ProjectSelect";
import LocationSelect from "./LocationSelect";
import { unknownErrorMessage } from "../constants/errors";
import errorService from "../common-lib/error";
import { RegisterId } from "../context/types";
import UPDATE_REGISTER from "../graphql/queries/update-register";
import useProjects from "../hooks/useProjects";
import SelectField from "./field/SelectField";
import Checkbox from "./field/Checkbox";
import {
  CreateRegisterMutationVariables,
  FetchRegisterQuery,
  UpdateRegisterMutationVariables,
} from "@/graphql/generated/graphql";
import { DialogActions } from "./DialogActions";

const schema = yup.object().shape({
  project_id: yup.string().required().label("Project"),
  location_id: yup.string().required().label("Location"),
  date: yup.date().label("Date"),
  time: yup.string().required().nonNullable().label("Time"),
  override_total_participants: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .moreThan(
      0,
      "You can only override the total in groups where you cannot record every single participant (min 30)"
    ),
});

interface OpenerProps {
  onClick: () => void;
}
interface RegisterFormProps {
  id?: RegisterId;
  Opener: React.FC<OpenerProps>;
  initialValues?: NonNullable<FetchRegisterQuery["register_by_pk"]>;
  onClose: () => void;
}
const RegisterForm: React.FC<RegisterFormProps> = (props) => {
  const history = useHistory();
  const programme = useSessionProgramme();
  const organisation = useSessionOrganisation();
  const { projects } = useProjects();
  const user = useSelf();
  const { users } = useUsers();
  const { id, initialValues, Opener, onClose } = props;

  const initialValuesString = JSON.stringify(initialValues);
  const [isOpen, setIsOpen] = useState(false);
  const [projectId, setProjectId] = useState(initialValues?.project.id || "");
  const [categoryId, setCategoryId] = useState(
    initialValues?.register_project_categories[0]?.project_category.id || ""
  );
  const [locationId, setLocationId] = useState(
    initialValues?.location.id || ""
  );
  const [facilitators, setFacilitators] = useState(
    initialValues?.register_facilitators
      ? initialValues?.register_facilitators.map((rf) => ({
          id: rf.user.id,
          name: getFullName(rf.user),
        }))
      : user
        ? [{ id: user.id, name: getFullName(user) }]
        : []
  );
  const [date, setDate] = useState(
    format(
      initialValues?.date ? new Date(initialValues?.date) : new Date(),
      "yyyy-MM-dd"
    )
  );
  const [time, setTime] = useState(initialValues?.time || "");
  const [overrideTotalParticipants, setOverrideTotalParticipants] = useState<
    string | null
  >(String(initialValues?.override_total_participants) || null);
  const [numberOfOrgsInAttendance, setNumberOfOrgsInAttendance] = useState(
    initialValues?.number_of_orgs_in_attendance
      ? String(initialValues?.number_of_orgs_in_attendance)
      : null
  );
  const [shouldOverrideTotal, setShouldOverrideTotal] = useState(
    Boolean(initialValues?.override_total_participants)
  );

  const [validationError, setValidationError] = useState("");

  const projectCategories =
    projects.find((project) => project.id === projectId)?.categories || [];

  useEffect(() => {
    setCategoryId("");
  }, [projectId]);

  useEffect(() => {
    if (!shouldOverrideTotal) {
      setOverrideTotalParticipants(null);
    }
  }, [shouldOverrideTotal]);

  const resetForm = useCallback(() => {
    setProjectId(initialValues?.project.id || "");
    setCategoryId(
      initialValues?.register_project_categories[0]?.project_category.id || ""
    );
    setLocationId(initialValues?.location.id || "");
    setFacilitators(
      initialValues?.register_facilitators
        ? initialValues?.register_facilitators.map((rf) => ({
            id: rf.user.id,
            name: getFullName(rf.user),
          }))
        : user
          ? [{ id: user.id, name: getFullName(user) }]
          : []
    );
    setDate(
      format(
        initialValues?.date ? new Date(initialValues?.date) : new Date(),
        "yyyy-MM-dd"
      )
    );
    setTime(initialValues?.time || "");
    setOverrideTotalParticipants(
      initialValues?.override_total_participants
        ? String(initialValues?.override_total_participants)
        : null
    );
    setShouldOverrideTotal(Boolean(initialValues?.override_total_participants));
  }, [initialValuesString]);

  useEffect(() => {
    resetForm();
  }, [initialValuesString, resetForm]);

  const onRequestClose = () => {
    resetForm();
    setIsOpen(false);
    onClose();
  };

  const payload = {
    project_id: projectId,
    location_id: locationId,
    override_total_participants: overrideTotalParticipants
      ? parseInt(overrideTotalParticipants)
      : null,
    number_of_orgs_in_attendance: numberOfOrgsInAttendance
      ? parseInt(numberOfOrgsInAttendance)
      : null,
    date,
    time,
  };

  const [createRegister, { loading, error }] = useMutation(CREATE_REGISTER, {
    onError: (error) => {
      errorService.error({ originalError: error, payload });
      setValidationError(unknownErrorMessage);
    },
    onCompleted: async (data) => {
      setValidationError("");
      if (data?.insert_register && data?.insert_register.returning[0]) {
        const register = data.insert_register.returning[0];

        if (!organisation || !programme) {
          return;
        }

        history.push(
          getRegisterPath({
            organisationSlug: organisation.slug,
            programmeSlug: programme.slug,
            registerId: register.id,
          })
        );
      }
    },
  });

  const [updateRegister] = useMutation(UPDATE_REGISTER, {
    onCompleted: () => onRequestClose(),
  });

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    if (!organisation) {
      errorService.error(
        new Error("Attempted to submit RegisterForm without organisation")
      );
      return setValidationError(unknownErrorMessage);
    }

    try {
      await schema.validate(payload);
    } catch (err) {
      const message =
        err instanceof Error ? err.message : "An unknown error occurred";
      return setValidationError(message);
    }

    if (id) {
      const facilitatorIdsToRemove =
        initialValues?.register_facilitators
          .filter(
            ({ user }) =>
              !facilitators.some((facilitator) => facilitator.id === user.id)
          )
          .map(({ user }) => user.id) || [];

      const variables: UpdateRegisterMutationVariables = {
        id,
        register: payload,
        register_project_categories: !categoryId
          ? []
          : [
              {
                project_category_id: categoryId,
                register_id: id,
              },
            ],
        facilitators_to_add: facilitators.map((facilitator) => ({
          facilitator_id: facilitator.id,
          register_id: id,
        })),
        facilitator_ids_to_remove: facilitatorIdsToRemove,
      };

      updateRegister({
        variables,
      });
    } else {
      const variables: CreateRegisterMutationVariables = {
        register: {
          ...payload,
          organisation_id: organisation.id,
          register_project_categories: {
            data:
              categoryId === ""
                ? []
                : [
                    {
                      project_category_id: categoryId,
                    },
                  ],
          },
          register_facilitators: {
            data: facilitators.map(({ id }) => ({
              facilitator_id: id,
            })),
          },
          sensitive_fields: {
            data: {
              notes: null,
            },
          },
          // TODO: currently admin craeated_by_user_id can't be generated serverside
          // created_by_user_id: user?.role === "rti-admin" ? user?.id : undefined,
        },
      };
      createRegister({ variables });
    }
  };

  return (
    <Fragment>
      <Dialog
        fullHeight
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={
          <div className="flex justify-between items-center">
            {id ? "Edit" : "New"} Register
          </div>
        }
      >
        {loading && <LoadingSpinner type="overlay" />}
        <form onSubmit={onSubmit} autoComplete="off">
          <input className="clip" />
          <input type="password" className="clip" />
          <ProjectSelect projectId={projectId} setProjectId={setProjectId} />
          {projectCategories.length > 0 && (
            <SelectField
              label={"Category"}
              name={"project-categories"}
              options={projectCategories.map((category) => ({
                value: category.id,
                label: category.name,
              }))}
              value={categoryId}
              onChange={setCategoryId}
            />
          )}
          <LocationSelect
            locationId={locationId}
            setLocationId={setLocationId}
          />
          <TextField
            name="date"
            label="Date"
            value={date}
            onChange={setDate}
            inputProps={{
              type: "date",
            }}
          />
          <TextField
            name="time"
            label="Time"
            value={time}
            onChange={setTime}
            inputProps={{
              type: "time",
            }}
          />
          <MultiAutocompleteField
            label="Facilitator"
            name="facilitator"
            options={users.map((user) => ({
              value: user.id,
              label: getFullName(user),
            }))}
            selected={facilitators.map((user) => ({
              value: user.id,
              label: user.name,
            }))}
            onSelect={(facilitators) =>
              setFacilitators(
                facilitators.map((option) => ({
                  id: option.value,
                  name: option.label,
                }))
              )
            }
          />
          <TextField
            label="Number of organisations in attendance"
            name="number-of-orgs-in-attendance"
            value={numberOfOrgsInAttendance}
            onChange={setNumberOfOrgsInAttendance}
            inputProps={{
              type: "number",
              min: 1,
            }}
          />
          <Checkbox
            label="Set total participants?"
            name="should-override-total"
            checked={shouldOverrideTotal}
            onChange={setShouldOverrideTotal}
          />

          {shouldOverrideTotal && (
            <Fragment>
              <ErrorMessage className="mt2 mb1">
                Only use this feature for online events where you cannot
                register individuals.
              </ErrorMessage>
              <TextField
                label="Total Participants"
                name="override-total-participants"
                value={overrideTotalParticipants}
                onChange={setOverrideTotalParticipants}
                inputProps={{
                  type: "number",
                  min: 1,
                }}
              />
            </Fragment>
          )}
        </form>
        <DialogActions>
          {error && (
            <ErrorMessage as="p" className="mra">
              {error.message}
            </ErrorMessage>
          )}
          {validationError && (
            <ErrorMessage as="p" className="mra">
              {validationError}
            </ErrorMessage>
          )}
          <footer className="flex justify-end mb3 pt3">
            <FlatButton onClick={onRequestClose}>Cancel</FlatButton>
            <FlatButton onClick={onSubmit}>Save</FlatButton>
          </footer>
        </DialogActions>
      </Dialog>
      <Opener onClick={() => setIsOpen(true)} />
    </Fragment>
  );
};

export default RegisterForm;

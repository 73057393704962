import styled from "styled-components";
import colours from "../../constants/colours";
import { HTMLProps } from "react";

interface WrapperProps {
  inline?: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
  margin-top: 16px;
  margin-bottom: 8px;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;

  ${(props) =>
    props.inline &&
    `
  
  display: inline-flex;
  `}
`;

type LabelProps = HTMLProps<HTMLLabelElement> & {
  lifted: boolean;
  required?: boolean;
};
const _Label = styled.label<LabelProps>`
  z-index: 1;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  display: block;
  transform-origin: top left;
  top: 0;
  left: 0;
  position: absolute;
  transform: translate(0, 24px) scale(1);
  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  ${(props) =>
    props.lifted &&
    `color: ${colours.textGrey};
    transform: translate(0, 5.5px) scale(0.75);
  `}
`;
export function Label({ required, children, as, ref, ...props }: LabelProps) {
  return (
    <_Label {...props}>
      <>
        {children}{" "}
        {required && (
          <>
            <span className="red b">*</span>
          </>
        )}
      </>
    </_Label>
  );
}

export interface InputWrapperProps {
  focused: boolean;
  hasChips?: boolean;
}
export const InputWrapper = styled.div<InputWrapperProps>`
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.1875em;
  margin-top: 16px;
  width: 100%;

  //underline
  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
  }
  &:hover::before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
  }
  ${(props) =>
    props.focused &&
    `
    &::after {
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid ${colours.blue2};
      pointer-events: none;
      transform: scaleX(1);
    }
    `}

  ${(props) =>
    props.hasChips &&
    `
      margin-top: 0;
    `}
`;

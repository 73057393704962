import { graphql } from "../generated";

const FETCH_USERS = graphql(`
  query FetchUsers(
    $dynamicWhere: user_bool_exp
    $dynamicOrderBy: [user_order_by!]
  ) {
    user(where: $dynamicWhere, order_by: $dynamicOrderBy) {
      id
      email
      first_name
      last_name
      role
      organisation_users {
        organisation {
          id
          slug
          name
        }
      }
    }
  }
`);

export default FETCH_USERS;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colours from "../constants/colours";
import useIsMounted from "../hooks/useIsMounted";
import Logo from "./Logo";

type LoadingSpinnerType = "splash" | "overlay" | "inline" | "row";

interface ContainerProps {
  type: LoadingSpinnerType;
  isMounted: boolean;
}
const Container = styled.span<ContainerProps>`
  display: inline-block;

  ${({ type }) =>
    type === "inline" &&
    `
    display: inline-flex;
    height: 1em;
    align-items: center;
  `}

  ${({ type }) =>
    (type === "splash" || type === "overlay") &&
    `
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.8);
    z-index: 9;
    `};

  ${({ type, isMounted }) =>
    type === "splash" &&
    `
    position: fixed;
    background-color: ${colours.white};
    transform: translateY(0);
    opacity: 0;
    transition: opacity 0.4s ease-in-out;

    ${
      isMounted &&
      ` 
        opacity: 1;
        transform: translateY(3rem);
      `
    }
  `}

  ${({ type }) =>
    type === "row" &&
    `
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `};
`;

const Wrapper = styled.span`
  width: 40px;
  height: 40px;
  color: ${colours.blueBg};
  animation: LoadingSpinner-keyframes-circular-rotate 1.4s linear infinite;

  @keyframes LoadingSpinner-keyframes-circular-rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SVG = styled.svg`
  display: block;
`;

const Circle = styled.circle`
  animation: LoadingSpinner-keyframes-circular-dash 1.4s ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px;

  @keyframes LoadingSpinner-keyframes-circular-dash {
    0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0px;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -125px;
    }
  }
`;

const getApologyText = (time: number) => {
  if (time > 20) {
    return " - Try refreshing the browser";
  }
  if (time > 8) {
    return " - Hang in there";
  }
  if (time > 3) {
    return " - Sorry, this is taking a little while";
  }
  return "";
};
interface LoadingSpinnerProps {
  type: LoadingSpinnerType;
  className?: string;
  text?: string;
  small?: boolean;
  xsmall?: boolean;
}
const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) => {
  const [time, setTime] = useState(0);

  const isMounted = useIsMounted();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time + 1);
    }, 1000);

    return () => clearTimeout(timer);
  });

  const {
    type,
    className,
    text = type === "splash" ? "Loading" : undefined,
    small,
    xsmall,
  } = props;
  const apologyText = getApologyText(time);
  const radius = xsmall ? "10" : small ? "13.4" : "20.2";
  const strokeWidth = xsmall ? "1.8" : small ? "2.4" : "3.6";
  return (
    <Container
      isMounted={isMounted}
      className={className}
      type={type}
      role="progressbar"
    >
      {type === "splash" && <Logo className="mb3" />}
      <Wrapper>
        <SVG viewBox="22 22 44 44">
          <Circle
            cx="44"
            cy="44"
            r={radius}
            fill="none"
            stroke="currentColor"
            strokeWidth={strokeWidth}
          ></Circle>
        </SVG>
      </Wrapper>
      {text && (
        <span className="mt3">
          {text} {apologyText && apologyText}
        </span>
      )}
    </Container>
  );
};

export default LoadingSpinner;

import * as yup from "yup";
import passwordRegex from "./password.regex";

export const Password = yup
  .string()
  .min(8, "Password must be at least 8 characters")
  .matches(
    passwordRegex,
    "Password must contain a mix of lowercase, uppercase, numbers",
  )
  .label("Password");

export const PasswordConfirm = yup
  .string()
  .oneOf([yup.ref("password")], "Passwords must match")
  .label("Password confirm");

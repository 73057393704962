import React, { useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";

import LoadingSpinner from "./LoadingSpinner";
import RegisterForm from "./RegisterForm";
import Button from "./Button";

import { getDatetime } from "../helpers/register";
import { getFullName } from "../helpers/user";
import { commaSeparatedList } from "../helpers/util";
import useRegisters from "../hooks/useRegisters";
import { getRegisterPath } from "../helpers/route-path";
import {
  AdminGrid,
  AdminGridHeader,
  AdminGridCell,
  AdminGridHeading,
  AdminGridFilters,
  AdminGridLoadMore,
} from "./AdminGrid";
import ProjectSelect from "./ProjectSelect";
import LocationSelect from "./LocationSelect";
import UserSelect from "./UserSelect";
import Switch from "./field/Switch";
import FlatButton from "./FlatButton";
import { OrganisationSlug, ProgrammeSlug } from "../context/types";
import SelectField from "./field/SelectField";
import useProjectCategories from "../hooks/useProjectCategories";
import useRefetchOnLand from "../hooks/useRefetchOnLand";
import RegistersDownloadButton from "./RegistersDownloadButton";
import TextField from "./field/TextField";
import styled from "styled-components";
import { Order_By, Register_Order_By } from "@/graphql/generated/graphql";
import { useIsProgrammeClosed } from "@/hooks/useIsProgrammeClosed";

const FiltersFooters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 0.5rem;
`;

const defaultOrder: Register_Order_By[] = [
  { date: Order_By.DescNullsLast },
  { time: Order_By.DescNullsLast },
  { location: { name: Order_By.AscNullsFirst } },
];

const headings: AdminGridHeading<Register_Order_By>[] = [
  {
    label: "Date",
    order: {
      asc: [{ date: Order_By.AscNullsFirst }, { time: Order_By.AscNullsFirst }],
      desc: [
        { date: Order_By.DescNullsLast },
        { time: Order_By.DescNullsLast },
      ],
    },
  },
  { label: "Project" },
  { label: "Location" },
  { label: "# Attendees" },
  { label: "# Orgs" },
  { label: "Facilitators" },
  { label: "Category" },
];

const Registers: React.FC = () => {
  const { organisationSlug, programmeSlug } = useParams<{
    organisationSlug: OrganisationSlug;
    programmeSlug: ProgrammeSlug;
  }>();

  const [isDeleted, setIsDeleted] = useState(false);
  const [projectId, setProjectId] = useState<string | undefined>(undefined);
  const [projectCategoryId, setProjectCategoryId] = useState<
    string | undefined
  >(undefined);
  const [locationId, setLocationId] = useState<string | undefined>(undefined);
  const [facilitatorId, setFacilitatorId] = useState<string | undefined>(
    undefined
  );
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [orderBy, setOrderBy] = useState<Register_Order_By[]>(defaultOrder);

  const { projectCategories } = useProjectCategories({ projectId });
  const isProgrammeClosed = useIsProgrammeClosed();

  const {
    registers,
    error,
    loading,
    refetch,
    loadMore,
    endOfResults,
    totalCount,
  } = useRegisters({
    filters: {
      projectId,
      projectCategoryId,
      locationId,
      facilitatorId,
      isDeleted,
      startDate,
      endDate,
    },
    orderBy,
  });

  useRefetchOnLand({
    loading,
    refetch,
  });

  if (!organisationSlug || !programmeSlug) {
    return null;
  }

  return (
    <div>
      <header className="flex justify-between items-center mb3">
        <h1>Attendance Registers</h1>
        <RegisterForm
          Opener={(props) => (
            <Button
              {...props}
              raised
              fill
              disabled={isProgrammeClosed}
              title={isProgrammeClosed ? "Programme closed" : undefined}
            >
              New Register
            </Button>
          )}
          onClose={refetch}
        />
      </header>
      <AdminGridFilters className="pt1 pb3 ph2 mb2">
        <div className="flex justify-between items-center">
          <h2 className="mb0">Filters</h2>
          <Switch
            name="is-deleted"
            label="Deleted"
            checked={isDeleted}
            onChange={setIsDeleted}
          />
        </div>
        <div className="w-50-l">
          <ProjectSelect projectId={projectId} setProjectId={setProjectId} />
          <SelectField
            label={"Category (depends on selected project)"}
            name={"project-categories"}
            options={projectCategories.map((category) => ({
              value: category.id,
              label: category.name,
            }))}
            value={projectCategoryId || ""}
            onChange={setProjectCategoryId}
          />
          <LocationSelect
            locationId={locationId}
            setLocationId={setLocationId}
          />
          <UserSelect
            userId={facilitatorId}
            setUserId={setFacilitatorId}
            label="Facilitator"
            name="facilitator"
          />
          <TextField
            name="start_date"
            label="Start Date"
            value={startDate}
            inputProps={{ type: "date" }}
            onChange={setStartDate}
          />
          <TextField
            name="end_date"
            label="End Date"
            value={endDate}
            inputProps={{ type: "date" }}
            onChange={setEndDate}
          />
        </div>
        <FiltersFooters className="mt3">
          <span>
            Total results: {totalCount}, viewing: 1 - {registers.length}
          </span>
          <RegistersDownloadButton registers={registers} />
        </FiltersFooters>
      </AdminGridFilters>
      <AdminGrid columns={headings.length}>
        <AdminGridHeader
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          headings={headings}
        />
        {registers.map((register) => {
          const { id } = register;
          const {
            project,
            location,
            register_participants_aggregate,
            register_facilitators,
            register_project_categories,
          } = register;

          return (
            <Fragment key={`AdminRegisters-grid-row-${register.id}`}>
              <AdminGridCell>
                <Link
                  to={getRegisterPath({
                    organisationSlug,
                    programmeSlug,
                    registerId: id,
                  })}
                >
                  {format(getDatetime(register), "iiii do MMM yyyy, h:mm aaaa")}
                </Link>
              </AdminGridCell>
              <AdminGridCell>{project.name}</AdminGridCell>
              <AdminGridCell>{location.name}</AdminGridCell>
              <AdminGridCell>
                {register.override_total_participants
                  ? register.override_total_participants
                  : register_participants_aggregate.aggregate!.count}
              </AdminGridCell>
              <AdminGridCell>
                {register.number_of_orgs_in_attendance}
              </AdminGridCell>
              <AdminGridCell>
                {commaSeparatedList(
                  register_facilitators,
                  (el) => getFullName(el.user),
                  (el) => `${register.id}${el.user.id}`,
                  <em>Not Set</em>
                )}
              </AdminGridCell>
              <AdminGridCell>
                {commaSeparatedList(
                  register_project_categories,
                  (el) => el.project_category.name,
                  (el) => el.project_category.id,
                  null
                )}
              </AdminGridCell>
            </Fragment>
          );
        })}
      </AdminGrid>
      {!loading && (
        <AdminGridLoadMore>
          <FlatButton onClick={loadMore} disabled={endOfResults}>
            {endOfResults ? "End of Results" : "Load More"}
          </FlatButton>
        </AdminGridLoadMore>
      )}
      {loading && <LoadingSpinner type="row" />}
      {error && <p>{error.message}</p>}
    </div>
  );
};

export default Registers;

import React from "react";

import SelectField, { SelectFieldOptions } from "./field/SelectField";
import { ProgrammeId } from "../context/types";
import useProgrammes from "../hooks/useProgrammes";

interface SelectProgrammeFieldProps {
  onSelect: (id: ProgrammeId) => void;
  value: ProgrammeId;
  disabled?: boolean;
}
const SelectProgrammeField: React.FC<SelectProgrammeFieldProps> = (props) => {
  const { onSelect, value, disabled } = props;

  const { programmes } = useProgrammes();

  const options: SelectFieldOptions = programmes.map((programme) => ({
    label: programme.name,
    value: programme.id,
  }));

  return (
    <SelectField
      name="programme_id"
      label="Programme"
      value={value}
      onChange={onSelect}
      options={options}
      disabled={disabled}
    />
  );
};

export default SelectProgrammeField;

import { graphql } from "../generated";

const FETCH_PARTICIPANT_BY_ID = graphql(`
  query FetchParticipantById($id: uuid!) {
    participant_by_pk(id: $id) {
      sensitive_fields {
        email
        first_name
        last_name
        notes
        phone
        emergency_contact_name
        emergency_contact_detail
      }
      id
      country_code
      created_at
      date_of_birth
      date_of_birth_accuracy
      date_of_first_attendance
      preferred_contact_method
      sex
      updated_at
      is_refugee
      is_caregiver
      has_disability
      data_protection_signed
      children {
        child {
          id
          sensitive_fields {
            first_name
            last_name
          }
        }
      }
      _temp_parent_names
      _temp_child_names
      parents {
        parent {
          id
          sensitive_fields {
            first_name
            last_name
          }
          children {
            child {
              id
              sensitive_fields {
                first_name
                last_name
              }
            }
          }
        }
      }
      siblings {
        sibling {
          id
          sensitive_fields {
            first_name
            last_name
          }
        }
      }
      participant_languages {
        language {
          code
          name
        }
      }
      register_participants {
        sensitive_fields {
          notes
        }
        _temp_is_child
        _temp_is_parent
        register {
          id
          time
          date
          project {
            name
            slug
            id
          }
        }
      }
      organisation_participants {
        organisation_id
        participant_id
      }
      programme_participants {
        programme_id
        participant_id
      }
      participant_files {
        id
        file_name
        file_size
        purpose
      }
      referral_organisation
    }
  }
`);

export default FETCH_PARTICIPANT_BY_ID;

import React, { useEffect } from "react";
import useOrganisations, {
  UseOrganisationsOpts,
} from "../hooks/useOrganisations";
import { OrganisationId } from "../context/types";
import MultiAutocompleteField from "./field/MultiAutocompleteField";
import truthy from "../helpers/truthy";
import { useToast } from "./Toast";

interface OrganisationMultiSelectProps {
  label?: string;
  name?: string;
  organisationIds?: OrganisationId[];
  setOrganisationIds: (organisationIds: OrganisationId[]) => void;
  useOrganisationsOpts?: UseOrganisationsOpts;
}
const OrganisationMultiSelect: React.FC<OrganisationMultiSelectProps> = (
  props,
) => {
  const {
    name = "organisation",
    label = "Organisation",
    organisationIds = [],
    setOrganisationIds,
    useOrganisationsOpts,
  } = props;
  const { organisations } = useOrganisations(useOrganisationsOpts);
  const toast = useToast();

  const options = organisations.map((organisation) => ({
    value: organisation.id,
    label: organisation.name,
  }));

  const currentSelection = organisationIds
    .map((id) => options.find((option) => option.value === id))
    .filter(truthy);

  const invalidOrganisationIds = organisationIds.filter(
    (organisationId) =>
      !currentSelection.some((selected) => selected.value === organisationId),
  );
  useEffect(() => {
    if (invalidOrganisationIds.length > 0) {
      /**
       * Ensure that the selected ids (from parent) are in sync with:
       * 1. available options, therefore
       * 2. what the user sees
       */
      setOrganisationIds(currentSelection.map((selected) => selected.value));
      toast.notify(
        "One or more organisations have been automatically deselected, because of your programme selection",
      );
    }
  }, [invalidOrganisationIds.length]);

  return (
    <MultiAutocompleteField
      label={label}
      name={name}
      options={options}
      selected={currentSelection}
      onSelect={(selected) =>
        setOrganisationIds(selected.map((org) => org.value))
      }
    />
  );
};

export default OrganisationMultiSelect;

import { graphql } from "../generated";

const CREATE_PROGRAMME= graphql(`
  mutation CreateProgramme($programme: programme_insert_input!) {
    insert_programme(objects: [$programme]) {
      returning {
        id
      }
    }
  }
`);

export default CREATE_PROGRAMME;

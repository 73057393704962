import React, { createContext, ReactNode, useContext, useReducer } from "react";

import { Action, initialState, reducer } from "./reducer";

const stateCtx = createContext(initialState);
const { Provider: StateProvider } = stateCtx;

const dispatchCtx = createContext((() => 0) as React.Dispatch<Action>);
const { Provider: DispatchProvider } = dispatchCtx;

export const Provider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatchProvider value={dispatch}>
      <StateProvider value={state}>{children}</StateProvider>
    </DispatchProvider>
  );
};

export const useAppDispatch = () => {
  const dispatch = useContext(dispatchCtx);

  return dispatch;
};

export const useAppState = () => {
  const state = useContext(stateCtx);

  return state;
};

import { useQuery } from "@apollo/client";

import {
  ParticipantId,
  LocationId,
  ProjectId,
  CategoryId,
} from "../context/types";
import FETCH_PARTICIPANT_LATEST from "../graphql/queries/fetch-participant-latest";
import useSessionProgramme from "./useSessionProgramme";
import useSessionOrganisation from "./useSessionOrganisation";

interface ParticipantsLatestOpts {
  excludeIds?: ParticipantId[];
  locationId?: LocationId;
  categoryIds: CategoryId[];
  projectId: ProjectId;
  searchTerm: string;
}

const useParticipantsLatest = (opts: ParticipantsLatestOpts) => {
  const organisation = useSessionOrganisation();
  const programme = useSessionProgramme();  

  const {
    excludeIds = [],
    searchTerm,
    projectId,
    locationId,
    categoryIds,
  } = opts;

  const [firstName = "", lastName = ""] = searchTerm.split(" ");

  const { loading, error, data, refetch } = useQuery(FETCH_PARTICIPANT_LATEST, {
    partialRefetch: true,
    fetchPolicy: "cache-and-network",
    variables: {
      dynamicWhere: {
        ...(organisation
          ? {
              participant: {
                organisation_participants: {
                  organisation_id: { _eq: organisation.id },
                },
              },
            }
          : null),
        ...(programme
          ? {
              participant: {
                programme_participants: {
                  programme_id: { _eq: programme.id },
                },
              },
            }
          : null),
        participant_id: { _nin: excludeIds },
        register: {
          project: { id: { _eq: projectId } },
          ...(locationId ? { location: { id: { _eq: locationId } } } : null),
          ...(categoryIds.length
            ? {
                register_project_categories: {
                  project_category_id: { _in: categoryIds },
                },
              }
            : null),
        },
        _or: [
          {
            participant: {
              sensitive_fields: {
                first_name: { _ilike: `%${firstName}%` },
                last_name: { _ilike: `%${lastName}%` },
              },
            },
          },
          {
            participant: {
              sensitive_fields: {
                last_name: { _ilike: `%${firstName}%` },
                first_name: { _ilike: `%${lastName}%` },
              },
            },
          },
        ],
      },
    },
  });

  const participants = data
    ? data.register_participant_latest.map(({ participant }) => participant)
    : [];

  return { participants, error, loading, refetch };
};

export type ParticipantLatestResult = ReturnType<
  typeof useParticipantsLatest
>["participants"][number];

export default useParticipantsLatest;

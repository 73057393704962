import { graphql } from "../generated";

const FETCH_ORGANISATION = graphql(`
  query FetchOrganisation($organisationSlug: String!) {
    organisation(where: { slug: { _eq: $organisationSlug } }) {
      id
      name
      slug
      locations {
        id
        name
        slug
      }
      projects {
        id
        name
        slug
      }
      organisation_users(
        where: { user: { deactivated_at: { _is_null: true } } }
      ) {
        user {
          id
          first_name
          last_name
        }
      }
      organisation_managers {
        user_id
      }
      organisation_programmes {
        programme {
          id
          name
          slug
        }
      }
    }
  }
`);

export default FETCH_ORGANISATION;

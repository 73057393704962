import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { differenceInSeconds } from "date-fns";
import colours from "../constants/colours";

const ToastGroup = styled.div`
  position: fixed;
  z-index: 99999;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;
const ToastItem = styled.div`
  padding: 1rem;
  background: ${colours.darkGreyBg};
  color: ${colours.white};
  margin-top: 0.5rem;
  max-width: 600px;
`;

type ToastProps = {
  messages: string[];
};
const Toast: FC<ToastProps> = (props) => {
  const { messages } = props;
  return (
    <ToastGroup>
      {messages.map((message, i) => {
        return (
          <ToastItem key={`ToastGroup-ToastItem-${i}`}>{message}</ToastItem>
        );
      })}
    </ToastGroup>
  );
};

export default Toast;

const toastContext = createContext<{
  messages: string[];
  notify: (text: string) => void;
} | null>(null);

export const useToast = () => {
  const toast = useContext(toastContext);
  if (!toast) {
    throw new Error("Toast context called outside toast provider");
  }

  return toast;
};

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [allMesages, setAllMessages] = useState<
    { text: string; createdAt: Date }[]
  >([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setAllMessages((currentMessages) =>
        currentMessages.filter(
          ({ createdAt }) => differenceInSeconds(new Date(), createdAt) < 5
        )
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const messages = useMemo(
    () =>
      allMesages
        .filter(
          ({ createdAt }) => differenceInSeconds(new Date(), createdAt) < 5
        )
        .map(({ text }) => text),
    [JSON.stringify(allMesages)]
  );

  const notify = useCallback(
    (text: string) => {
      setAllMessages([...allMesages, { text, createdAt: new Date() }]);
    },
    [allMesages]
  );

  const value = useMemo(() => ({ messages, notify }), [messages, notify]);

  return (
    <toastContext.Provider value={value}>
      {children}
      <Toast messages={messages} />
    </toastContext.Provider>
  );
};

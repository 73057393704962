import React, { useEffect, useState, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";

import Button from "./Button";
import LoadingSpinner from "./LoadingSpinner";
import LocationForm from "./LocationForm";
import { getAdminLocationPath } from "../helpers/route-path";
import {
  AdminGrid,
  AdminGridCell,
  AdminGridHeading,
  AdminGridHeader,
} from "./AdminGrid";
import useLocations from "../hooks/useLocations";
import { Location_Order_By, Order_By } from "@/graphql/generated/graphql";

const defaultOrder = [{ name: Order_By.Asc }];

const headings: AdminGridHeading<Location_Order_By>[] = [
  {
    label: "Name",
    order: {
      asc: [{ name: Order_By.Asc }],
      desc: [{ name: Order_By.Desc }],
    },
  },
  {
    label: "Programmes",
  },
  {
    label: "Organisation",
    order: {
      asc: [{ organisation: { name: Order_By.Asc } }, ...defaultOrder],
      desc: [{ organisation: { name: Order_By.Desc } }, ...defaultOrder],
    },
  },
];

const Locations: React.FC = () => {
  const [orderBy, setOrderBy] = useState<Location_Order_By[]>(defaultOrder);
  const { locations, error, loading, refetch } = useLocations({ orderBy });
  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <header className="flex justify-between items-center mb3">
        <h1>Admin/Locations </h1>
        <LocationForm
          onClose={refetch}
          Opener={(props) => (
            <Button {...props} raised fill>
              New location
            </Button>
          )}
        />
      </header>

      <AdminGrid columns={headings.length}>
        <AdminGridHeader
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          headings={headings}
        />
        {locations.map((location) => {
          const { id, slug, name, organisation, programme_locations } =
            location;

          return (
            <Fragment key={`AdminLocations-li-${id}`}>
              <AdminGridCell>
                <Link to={getAdminLocationPath({ locationSlug: slug })}>
                  {name}
                </Link>
              </AdminGridCell>
              <AdminGridCell>
                {programme_locations
                  .map(({ programme }) => programme.name)
                  .join(", ")}
              </AdminGridCell>
              <AdminGridCell>{organisation.name}</AdminGridCell>
            </Fragment>
          );
        })}
      </AdminGrid>
      {loading && <LoadingSpinner type="row" />}
      {error && <p>{error.message}</p>}
    </div>
  );
};

export default withRouter(Locations);

import React from "react";
import useSelf from "../hooks/useSelf";
import { names } from "@/common-lib/names";
import {
  getProgrammeGroupsPath,
  getProgrammeGroupPath,
  getHubsPath,
  getHomePath,
  getAdminProgrammePath,
} from "@/helpers/route-path";
import useProgrammes from "@/hooks/useProgrammes";
import useSessionOrganisation from "@/hooks/useSessionOrganisation";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import Button from "./Button";
import { useIsAdmin } from "@/hooks/useIsAdmin";

const Home: React.FC = () => {
  const { programmeSlug } = useParams<{ programmeSlug?: string }>();
  const organisation = useSessionOrganisation();
  const { programmes, loading } = useProgrammes({ organisation });
  const programme = programmes.find((p) => p.slug === programmeSlug);

  const history = useHistory();
  const isAdmin = useIsAdmin();
  const goToAdmin = () => {
    if (programmeSlug) {
      history.push(getAdminProgrammePath({ programmeSlug }));
    }
  };
  if (!organisation) {
    // return null
    return <LoadingSpinner type="splash" text={"Loading Organisation"} />;
  }

  if (loading) {
    // return null
    return <LoadingSpinner type="splash" text={"Loading Programme"} />;
  }

  if (!programmeSlug || !programme) {
    return <>Programme not found, or you are not assigned.</>;
  }

  const TYPE: "amna-programme" | "partner-hub" =
    programme.type === "amna-programme" ? "amna-programme" : "partner-hub";

  return (
    <div className="flex flex-column justify-center items-center flex-auto">
      <h1 className="flex flex-wrap align-center justify-center tc">
        <>
          {TYPE === "amna-programme" ? (
            <>
              <span>
                <Link to={getProgrammeGroupsPath()}>{organisation.name}</Link>{" "}
              </span>
              <span>
                →{" "}
                <Link
                  to={getProgrammeGroupPath({
                    programmeGroupSlug: programme.programme_group?.slug!!,
                  })}
                >
                  {programme.programme_group?.name}
                </Link>{" "}
              </span>
            </>
          ) : (
            <>
              <span>
                <Link to={getHubsPath()}>{names.hubs.shortPlural}</Link>{" "}
              </span>
              <span>
                → <Link to={getHubsPath()}>{organisation.name}</Link>{" "}
              </span>
            </>
          )}
          <span>
            →{" "}
            <Link
              to={getHomePath({
                organisationSlug: organisation.slug,
                programmeSlug: programme.slug,
              })}
            >
              {programme.name}
            </Link>
          </span>
        </>{" "}
      </h1>
      {isAdmin && (
        <Button onClick={goToAdmin} fill colour="red" className="mt4">
          Make changes
        </Button>
      )}
    </div>
  );
};

export default Home;

import React from "react";
import { useLazyQuery } from "@apollo/client";
import { downloadAttendance } from "../api/download-attendance";
import FETCH_REGISTER_ATTENDEES from "../graphql/queries/fetch-register-attendees";
import Button from "./Button";
import Icon from "./Icon";
import { Registers } from "@/hooks/useRegisters";
import errorService from "@/common-lib/error";

type RegistersDownloadButtonProps = {
  registers: Registers;
};
const RegistersDownloadButton: React.FC<RegistersDownloadButtonProps> = (
  props
) => {
  const { registers } = props;

  const [fetchAttendees, { loading }] = useLazyQuery(FETCH_REGISTER_ATTENDEES);

  const onClick = async () => {
    const { data, error } = await fetchAttendees({
      variables: {
        registerIds: registers.map((register) => register.id),
      },
    });

    if (!data) {
      window.alert("Error fetching register attendees");
      const err = error || new Error("Error fetching register attendees");
      errorService.error(err);
      return;
    }

    downloadAttendance({
      registers: registers.map((register) => {
        const attendees = data.register_participant
          .filter((rp) => rp.register_id === register.id)
          .map((rp) => rp.participant._temp_id || rp.participant.id);
        return {
          date: register.date,
          programme: register.project.programme.name,
          organisation: register.organisation.name,
          location: register.location.name,
          facilitators: register.register_facilitators
            .map((rf) => [rf.user.first_name, rf.user.last_name].join(" "))
            .join(","),
          notes: register.sensitive_fields
            ? register.sensitive_fields.notes
            : "REDACTED",
          project: register.project.name,
          categories: register.register_project_categories
            .map((c) => c.project_category.name)
            .join(","),
          overrideTotalAttendees: register.override_total_participants,
          attendees,
          attendeesTotal:
            register.override_total_participants || attendees.length,
          attendeesTotalType: register.override_total_participants
            ? "override"
            : "count",
        };
      }),
    });
  };
  return (
    <Button onClick={onClick} fill disabled={loading}>
      Download .xlsx <Icon className="ml2" icon="download" />
    </Button>
  );
};

export default RegistersDownloadButton;

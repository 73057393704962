import { graphql } from "../generated";

const UPDATE_PROJECT_CATEGORY= graphql(`
  mutation UpdateProjectCategory(
    $id: uuid!
    $project_category: project_category_set_input!
  ) {
    update_project_category_by_pk(
      pk_columns: { id: $id }
      _set: $project_category
    ) {
      id
      project_id
      name
    }
  }
`);

export default UPDATE_PROJECT_CATEGORY;

import React from "react";
import SelectField from "./field/SelectField";
import useProjects from "../hooks/useProjects";
import { ProjectId } from "../context/types";

interface ProjectSelectProps {
  label?: string;
  name?: string;
  projectId?: ProjectId;
  setProjectId: (projectId: ProjectId) => void;
}
const ProjectSelect: React.FC<ProjectSelectProps> = (props) => {
  const {
    name = "project",
    label = "Project",
    projectId = "",
    setProjectId,
  } = props;
  const { projects } = useProjects();

  return (
    <SelectField
      label={label}
      name={name}
      options={projects.map((project) => ({
        value: project.id,
        label: project.name,
      }))}
      value={projectId}
      onChange={setProjectId}
    />
  );
};

export default ProjectSelect;

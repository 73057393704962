import React, { Fragment, useEffect, useState } from "react";
import Dialog from "./Dialog";
import FlatButton from "./FlatButton";
import LoadingSpinner from "./LoadingSpinner";

interface ControllerProps {
  onClick: () => void;
}
interface Props {
  title: string;
  message: string;
  onConfirm: () => Promise<any>;
  onOpen?: () => void;
  loading?: boolean;
  Controller: React.FC<ControllerProps>;
  confirmButtonText: string;
}
const ConfirmDialog: React.FC<Props> = (props) => {
  const {
    onConfirm,
    title,
    message,
    Controller,
    onOpen,
    loading,
    confirmButtonText,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const onRequestOpen = () => setIsOpen(true);
  const onRequestClose = () => {
    setIsOpen(false);
  };
  const onRequestSave = async () => {
    await onConfirm();
    onRequestClose();
  };

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen, onOpen]);

  return (
    <Fragment>
      <Dialog isOpen={isOpen} onRequestClose={onRequestClose} title={title}>
        {loading ? <LoadingSpinner type="row" /> : <p>{message}</p>}
        <footer className="flex justify-end">
          <FlatButton type="button" onClick={onRequestClose}>
            Cancel
          </FlatButton>
          <FlatButton type="button" onClick={onRequestSave} disabled={loading}>
            {confirmButtonText}
          </FlatButton>
        </footer>
      </Dialog>
      <Controller onClick={onRequestOpen} />
    </Fragment>
  );
};

export default ConfirmDialog;

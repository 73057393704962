import { differenceInMonths, differenceInYears } from "date-fns";
import { ParticipantSearchResult } from "@/hooks/useParticipantSearch";

export const getAgeAsNumber = (user: { date_of_birth: string }) => {
  if (!user.date_of_birth) {
    return undefined;
  }
  return differenceInYears(new Date(), new Date(user.date_of_birth));
};

export const getAge = (user: {
  date_of_birth?: string | null | undefined;
  date_of_birth_accuracy?: string | null | undefined;
}) => {
  if (!user.date_of_birth) {
    return "Age not known";
  }
  const years = differenceInYears(new Date(), new Date(user.date_of_birth));

  const prefix =
    user.date_of_birth_accuracy === "exact"
      ? ""
      : user.date_of_birth_accuracy === "estimate"
        ? "~"
        : "~~";

  if (years >= 1) {
    return `${prefix}${years}y`;
  }

  const months = differenceInMonths(new Date(), new Date(user.date_of_birth));

  return `${prefix}${months}m`;
};

export const getAgeAtTimeOfRegister = ({
  participant,
  register,
}: {
  participant: { date_of_birth?: string | null | undefined };
  register?: { date: string } | null;
}) => {
  if (!participant.date_of_birth || !register) {
    return null;
  }

  const age = differenceInYears(
    new Date(register.date),
    new Date(participant.date_of_birth)
  );

  return age;
};

export const isChildAtTimeOfRegister = (
  participant: { date_of_birth?: string | null | undefined },
  register?: { date: string },
  register_participant?: {
    _temp_is_child?: boolean | null | undefined;
    _temp_is_parent?: boolean | null | undefined;
  }
) => {
  if (register_participant?._temp_is_child) {
    return true;
  }
  if (register_participant?._temp_is_parent) {
    return false;
  }

  const age = getAgeAtTimeOfRegister({ participant, register });

  if (age === null) {
    return null;
  }

  return age < 18;
};

export type GetMissingInfoProps = Pick<
  ParticipantSearchResult,
  "date_of_birth"
> & {
  sensitive_fields?: { first_name?: string | null | undefined } | null;
};
export const getMissingInfo = (participant: GetMissingInfoProps) => {
  if (!participant.date_of_birth) {
    return `We don't know ${
      participant.sensitive_fields?.first_name ?? "REDACTED"
    }'s Date of Birth`;
  }

  return "";
};

export const getHisHer = (participant: { sex: string | null | undefined }) => {
  if (participant.sex === "male") {
    return "his";
  }
  if (participant.sex === "female") {
    return "her";
  }

  return "their";
};

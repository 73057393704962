import React, {
  ComponentPropsWithoutRef,
  HTMLAttributes,
  HTMLProps,
} from "react";
import styled from "styled-components";
import colours, { Colour } from "../constants/colours";
import Icon, { Size } from "./Icon";
import LoadingSpinner from "./LoadingSpinner";

interface IconButtonWrapperProps {
  colour?: Colour;
}

const IconButtonWrapper = styled.button.attrs(({ className }) => ({
  type: "button",
  className,
}))<IconButtonWrapperProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  color: ${(props) => (props.colour ? colours[props.colour] : "inherit")};

  &:hover {
    opacity: 0.6;
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type IconButtonProps = ComponentPropsWithoutRef<"button"> & {
  icon: string;
  colour?: Colour;
  size?: Size;
  loading?: boolean;
};
const StyledIcon = styled(Icon)`
  color: ${(props) => props.color || "inherit"};
`;
const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const { icon, size, loading, ...buttonProps } = props;

    return (
      <IconButtonWrapper ref={ref} {...buttonProps}>
        <StyledIcon
          icon={icon}
          size={size}
          color={loading ? "transparent" : "inherit"}
        />
        {loading && (
          <LoadingWrapper>
            <LoadingSpinner type="inline" xsmall />
          </LoadingWrapper>
        )}
      </IconButtonWrapper>
    );
  }
);

IconButton.displayName = "IconButton";

export default IconButton;

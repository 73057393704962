import { graphql } from "../generated";

const DELETE_REGISTER_PARTICIPANT= graphql(`
  mutation DeleteRegisterParticipant(
    $register_id: uuid!
    $participant_id: uuid!
  ) {
    delete_register_participant(
      where: {
        participant_id: { _eq: $participant_id }
        register_id: { _eq: $register_id }
      }
    ) {
      returning {
        participant_id
        register_id
      }
    }
  }
`);

export default DELETE_REGISTER_PARTICIPANT;

import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";

import userLookup from "../graphql/queries/user-lookup";
import LoadingSpinner from "./LoadingSpinner";
import Button from "./Button";
import UserForm from "./UserForm";

import Icon from "./Icon";

import errorService from "../common-lib/error";
import { getIsManager } from "../helpers/organisation";
import useSessionOrganisation from "../hooks/useSessionOrganisation";

const ManagerUsersUser: React.FC = () => {
  const { userId } = useParams<{ userId: string | undefined }>();
  const organisation = useSessionOrganisation();

  const { loading, error, data, refetch } = useQuery(userLookup, {
    variables: { id: userId },
  });

  useEffect(() => {
    refetch();
  }, []);

  if (error) {
    errorService.error(error);
  }

  const user = data ? data.user_by_pk : undefined;

  const notFound = !loading && !user;

  const isSiloedUser = user?.organisation_siloed_users.some(
    ({ user_id }) => user_id === user.id
  );

  return (
    <div>
      {notFound && <p>User Not Found</p>}
      {loading && <LoadingSpinner type="overlay" />}
      {user && (
        <Fragment>
          <header className="flex justify-between items-center mb3">
            <h1>
              {user.first_name} {user.last_name}
            </h1>
            <UserForm
              initialValues={user}
              userId={user.id}
              Opener={(props) => (
                <Button {...props} raised fill>
                  Edit User
                </Button>
              )}
              onClose={refetch}
              wasManager={getIsManager(user.id, organisation)}
              wasSiloedUser={isSiloedUser}
            />
          </header>
          <Icon className="mv2" text={user.email} icon="email" />
          <Icon className="mv2" text={user.role} icon="account_circle" />
          <br />
        </Fragment>
      )}
    </div>
  );
};

export default ManagerUsersUser;

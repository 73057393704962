import React, { Component, ReactNode } from "react";
import styled from "styled-components";

import errorService from "../common-lib/error";
import colours from "../constants/colours";
import Logo from "./Logo";

const MISSING_ERROR = "Error was swallowed during propagation.";

const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: auto;
`;
const ErrorLogo = styled(Logo)`
  position: absolute;
  top: 1em;
  left: 1em;
`;
const ErrorText = styled.section`
  color: ${colours.textGrey};
  text-align: center;
`;

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error | null) {
    return { error: error || new Error(MISSING_ERROR) };
  }

  componentDidCatch(error: Error | null, info: object) {
    errorService.error({ error, info });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    }
    return (
      <Main>
        <ErrorLogo />
        <ErrorText className="mt4">
          <header className="mb2">
            <h2>
              Seems something's gone wrong{" "}
              <span role="img" aria-label="sad face">
                😞
              </span>
            </h2>
          </header>
          <p>We are working to fix this problem. </p>
        </ErrorText>
      </Main>
    );
  }
}

export default ErrorBoundary;

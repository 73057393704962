import React, { Fragment, useState, SyntheticEvent, useEffect } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { nanoid } from "nanoid";

import Dialog from "./Dialog";
import FlatButton from "./FlatButton";
import TextField from "./field/TextField";
import {
  newUser,
  editUser,
  EditUserPayload,
  editSelf,
  EditSelfPayload,
} from "../api/auth";
import errorService from "../common-lib/error";
import SelectField from "./field/SelectField";
import { useAppDispatch } from "../context/store";
import { ROLES } from "../context/types";
import useSelf from "../hooks/useSelf";
import OrganisationSelect from "./OrganisationSelect";
import Checkbox from "./field/Checkbox";
import { useMutation } from "@apollo/client";
import CREATE_ORGANISATION_MANAGER from "../graphql/queries/create-organisation-manager";
import DELETE_ORGANISATION_MANAGER from "../graphql/queries/delete-organisation-manager";
import CREATE_ORGANISATION_USER from "../graphql/queries/create-organisation-user";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import useOrganisations from "../hooks/useOrganisations";
import ProgrammeSelect from "./ProgrammeSelect";
import { Password, PasswordConfirm } from "../constants/password.yup";
import CREATE_ORGANISATION_SILOED_USER from "../graphql/queries/create-organisation-siloed-user";
import DELETE_ORGANISATION_SILOED_USER from "../graphql/queries/delete-organisation-siloed-user";
import { DialogActions } from "./DialogActions";

const getSchema = (isEdit: boolean) =>
  yup.object().shape({
    first_name: yup.string().required().label("First name"),
    last_name: yup.string().required().label("Last name"),
    email: yup.string().email().label("Email"),
    role: yup
      .mixed()
      .oneOf(ROLES as any)
      .required()
      .label("Role"),
    ...(isEdit
      ? {
          password: Password.notRequired(),
          passwordConfirm: PasswordConfirm.notRequired(),
        }
      : {
          password: Password.required(),
          passwordConfirm: PasswordConfirm.required(),
        }),
  });

const ErrorMessage = styled.p`
  color: red;
`;

interface OpenerProps {
  onClick: () => void;
}
interface UserFormProps {
  initialValues?: Omit<EditUserPayload, "password" | "organisation_users">;
  userId?: string;
  self?: Boolean;
  Opener: React.FC<OpenerProps>;
  onClose: () => void;
  wasManager?: boolean;
  wasSiloedUser?: boolean;
}
const UserForm: React.FC<UserFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const {
    userId,
    self,
    initialValues = {
      first_name: "",
      last_name: "",
      email: "",
      role: "user",
    },
    Opener,
    onClose,
    wasManager,
    wasSiloedUser,
  } = props;

  let newUserId: string | undefined = undefined;

  const { refetch: refetchOrganisations } = useOrganisations();
  const organisation = useSessionOrganisation();
  const organisationId = organisation?.id;

  const isEdit = Boolean(props.initialValues);
  const isInManagerPanel = Boolean(organisationId);
  initialValues;

  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState(initialValues?.first_name);
  const [lastName, setLastName] = useState(initialValues.last_name);
  const [email, setEmail] = useState(initialValues.email);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [role, setRole] = useState(initialValues.role);
  const [isManager, setIsManager] = useState(wasManager || false);
  const [isSiloedUser, setIsSiloedUser] = useState(wasSiloedUser || false);
  const [submitError, setSubmitError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [newUserOrganisationId, setNewUserOrganisationId] = useState<
    string | undefined
  >();
  const [newUserProgrammeId, setNewUserProgrammeId] = useState<
    string | undefined
  >();

  const me = useSelf();
  const myRole = me ? me.role : "user";
  const ALLOWED_ROLES = ROLES.slice(0, ROLES.indexOf(myRole) + 1);
  const iAmAdmin = myRole === "rti-admin";

  const resetForm = () => {
    setFirstName(initialValues.first_name);
    setLastName(initialValues.last_name);
    setEmail(initialValues.email);
    setRole(initialValues.role);
    setIsManager(wasManager || false);
    setIsSiloedUser(wasSiloedUser || false);
    setPassword("");
    setPasswordConfirm("");
    setSubmitError("");
  };

  const [emailId] = useState(nanoid());

  const [createOrganisationSiloedUser] = useMutation(
    CREATE_ORGANISATION_SILOED_USER,
    {
      onCompleted: () => refetchOrganisations(),
    }
  );
  const [createOrganisationManager] = useMutation(CREATE_ORGANISATION_MANAGER, {
    onCompleted: () => refetchOrganisations(),
  });
  const [createOrganisationUser] = useMutation(CREATE_ORGANISATION_USER);
  const [deleteOrganisationSiloedUser] = useMutation(
    DELETE_ORGANISATION_SILOED_USER,
    {
      onCompleted: () => refetchOrganisations(),
    }
  );

  const [deleteOrganisationManager] = useMutation(DELETE_ORGANISATION_MANAGER, {
    onCompleted: () => refetchOrganisations(),
  });

  useEffect(() => {
    resetForm();
  }, [JSON.stringify(initialValues), wasManager]);

  const onRequestOpen = () => setIsOpen(true);
  const onRequestClose = () => {
    resetForm();
    setIsOpen(false);
    onClose();
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    type Payload = {
      id: string | undefined;
      first_name: string;
      last_name: string;
      email: string;
      password?: string;
      passwordConfirm?: string;
      role: string;
    };

    const payload: Payload = {
      id: userId,
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      passwordConfirm,
      role,
    };

    if (payload.password === "") {
      payload.password = undefined;
      payload.passwordConfirm = undefined;
    }

    try {
      await getSchema(isEdit).validate(payload);
    } catch (error) {
      const message =
        error instanceof Error ? error.message : "An unknown error occurred";
      return setValidationError(message);
    }

    if (!isEdit) {
      if (!organisationId && !newUserOrganisationId) {
        return setValidationError(
          "Please select an organisation for this user"
        );
      }
      if (newUserOrganisationId && !newUserProgrammeId) {
        return setValidationError("Please select a programme for this user");
      }
    }

    try {
      if (self) {
        const editSelfPayload: EditSelfPayload & { role?: string } = {
          ...payload,
        };
        delete editSelfPayload.role;
        const { user, token } = await editSelf(payload);
        // TODO: this should not be a login success message
        dispatch({
          type: "@@session/LOGIN_SUCCESS",
          payload: {
            user,
            token,
          },
        });
      } else if (userId) {
        await editUser(payload);
      } else {
        const password = payload.password;
        if (!password) {
          throw new Error("Cannot create new user without password");
        }
        const newUserPayload = {
          ...payload,
          password,
        };
        const newUserRes = await newUser(newUserPayload);

        newUserId = newUserRes.user.id;
      }
      if (isInManagerPanel) {
        if (isSiloedUser && !wasSiloedUser) {
          await createOrganisationSiloedUser({
            variables: {
              organisation_siloed_user: {
                user_id: userId || newUserId,
                organisation_id: organisationId,
              },
            },
          });
        }
        if (!isSiloedUser && wasSiloedUser) {
          await deleteOrganisationSiloedUser({
            variables: {
              user_id: userId || newUserId,
              organisation_id: organisationId,
            },
          });
        }
        if (isManager && !wasManager) {
          await createOrganisationManager({
            variables: {
              organisation_manager: {
                user_id: userId || newUserId,
                organisation_id: organisationId,
              },
            },
          });
        }
        if (!isManager && wasManager) {
          await deleteOrganisationManager({
            variables: {
              user_id: userId || newUserId,
              organisation_id: organisationId,
            },
          });
        }

        if (newUserId) {
          await createOrganisationUser({
            variables: {
              organisation_user: {
                user_id: newUserId,
                organisation_id: organisationId,
              },
            },
          });
        }
      }

      if (newUserId) {
        if (newUserOrganisationId) {
          await createOrganisationUser({
            variables: {
              organisation_user: {
                user_id: newUserId,
                organisation_id: newUserOrganisationId,
              },
            },
          });
          if (isSiloedUser) {
            await createOrganisationSiloedUser({
              variables: {
                organisation_siloed_user: {
                  user_id: newUserId,
                  organisation_id: newUserOrganisationId,
                },
              },
            });
          }
          if (isManager) {
            await createOrganisationManager({
              variables: {
                organisation_manager: {
                  user_id: newUserId,
                  organisation_id: newUserOrganisationId,
                },
              },
            });
          }
        }
      }

      onRequestClose();
    } catch (err) {
      errorService.error(err);

      if (err instanceof Error) {
        setSubmitError(err.message);
      } else {
        setSubmitError(
          "There was an error in the form. Please try refreshing the page."
        );
      }
    }
  };

  return (
    <Fragment>
      <Dialog
        fullHeight
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={`${userId ? "Edit" : "New"} User`}
      >
        <form onSubmit={onSubmit} autoComplete="off">
          <input className="clip" />
          <input name="username" className="clip" />
          <TextField
            name="first_name"
            label="First Name"
            value={firstName}
            onChange={setFirstName}
            autoFocus
          />
          <TextField
            name="last_name"
            label="Last Name"
            value={lastName}
            onChange={setLastName}
          />
          <TextField
            name={emailId}
            label="Email"
            value={email}
            onChange={setEmail}
          />
          <TextField
            name="password"
            label="Enter a new password"
            value={password}
            onChange={setPassword}
            inputProps={{
              type: "password",
              autoComplete: "new-password",
            }}
          />
          <TextField
            name="password-confirm"
            label="Confirm password"
            value={passwordConfirm}
            onChange={setPasswordConfirm}
            inputProps={{
              type: "password",
              autoComplete: "new-password",
            }}
          />
          {!self && (
            <Fragment>
              <SelectField
                label="Role"
                name="role"
                value={role}
                onChange={setRole}
                options={ALLOWED_ROLES.map((role) => ({
                  value: role,
                  label: role,
                }))}
              />
              {/* Within manager panel */}
              {organisationId && (
                <OrganisationSelect
                  organisationId={organisationId}
                  setOrganisationId={() => undefined}
                  disabled
                />
              )}
              {/* Within admin panel, and only when creating a user */}
              {!organisationId && !isEdit && (
                <OrganisationSelect
                  organisationId={newUserOrganisationId}
                  setOrganisationId={setNewUserOrganisationId}
                />
              )}
              {/* Only when creating a user -- because they might be part of multiple organisationss */}
              {(organisationId || newUserOrganisationId) &&
                !isEdit &&
                !isSiloedUser && (
                  <Checkbox
                    label="Manager"
                    checked={isManager}
                    name="manager"
                    onChange={setIsManager}
                  />
                )}
              <br />
              {/* Only when creating a user -- because they might be part of multiple organisationss */}
              {iAmAdmin &&
                (organisationId || newUserOrganisationId) &&
                (!isEdit || isInManagerPanel) &&
                !isManager && (
                  <Checkbox
                    label="Siloed user (can only access their own data)"
                    checked={isSiloedUser}
                    name="siloed-user"
                    onChange={setIsSiloedUser}
                  />
                )}
              {!isEdit && (
                <ProgrammeSelect
                  programmeId={newUserProgrammeId}
                  setProgrammeId={setNewUserProgrammeId}
                />
              )}
            </Fragment>
          )}
        </form>
        <DialogActions>
          {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
          {validationError && (
            <ErrorMessage className="mra">{validationError}</ErrorMessage>
          )}
          <footer className="flex mv3 justify-end">
            <FlatButton onClick={onRequestClose}>Cancel</FlatButton>
            <FlatButton onClick={onSubmit}>Save</FlatButton>
          </footer>
        </DialogActions>
      </Dialog>
      {<Opener onClick={onRequestOpen} />}
    </Fragment>
  );
};

export default UserForm;

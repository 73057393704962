import React, { CSSProperties, Fragment, HTMLProps, useState } from "react";
import styled from "styled-components";
import colours, { Colour } from "../constants/colours";
import { nanoid } from "nanoid";
import ExpandMore from "./icons/ExpandMore";
import { Order_By } from "@/graphql/generated/graphql";
import ExpandLess from "./icons/ExpandLess";

export const AdminGridLoadMore = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
`;
export const AdminGridSearch = styled.input`
  border: 1px solid ${colours.borderGrey};
`;
export const AdminGridFilters = styled.div`
  background: #eee;
`;
export const AdminGrid = styled.div<{ columns: number; background?: Colour }>`
  display: grid;
  grid-template-columns: ${(props) =>
    [...Array(props.columns)].map(() => "auto").join(" ")};

  background: ${(props) => props.background};
`;
export const AdminGridCell = styled.div<{
  isHeading?: boolean;
  justify?: "flex-end";
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: center;
  border: none;
  border-bottom: 1px solid ${colours.borderGrey};
  color: #000;
  padding: 0.5rem;

  ${(props) =>
    props.isHeading &&
    `
    color: ${colours.textGrey};
  `}
`;
export interface AdminGridHeading<EntityOrderBy> {
  label: string;
  order?: {
    asc: EntityOrderBy[];
    desc: EntityOrderBy[];
  };
}

interface AdminGridHeadingCellProps<EntityOrderBy> {
  headings: AdminGridHeading<EntityOrderBy>[];
  orderBy: EntityOrderBy[];
  setOrderBy: (orderBy: EntityOrderBy[]) => void;
}

const AdminGridRowButton = styled.button`
  transition: background 0.1s ease-in-out;
  background: ${colours.pinkBg}aa;
  &:hover {
    background: ${colours.pinkBg};
  }
`;
export const AdminGridAddRow = ({
  title,
  onClick,
  gridColumn,
}: HTMLProps<HTMLButtonElement> & {
  title?: string;
  onClick: () => void;
  gridColumn: CSSProperties["gridColumn"];
}) => {
  return (
    <AdminGridRowButton
      title={title}
      style={{ gridColumn }}
      onClick={onClick}
      className="pv3"
    >
      <span style={{ fontSize: "2rem" }} className="material-icons">
        add
      </span>
    </AdminGridRowButton>
  );
};

export const AdminGridHeader = <
  EntityOrderBy extends Record<string, Order_By | any>,
>({
  headings,
  orderBy,
  setOrderBy,
}: React.PropsWithChildren<AdminGridHeadingCellProps<EntityOrderBy>>) => {
  const [keyPrefix] = useState(nanoid());

  return (
    <Fragment>
      {headings.map((heading, i) => {
        const { label, order } = heading;
        const sortable = Boolean(order);
        const orderByString = JSON.stringify(orderBy);
        const ascString = JSON.stringify(order?.asc);
        const descString = JSON.stringify(order?.desc);

        const isActive =
          orderByString === ascString || orderByString === descString || false;

        const isAsc = orderByString === ascString;

        const onSort = () => {
          if (!order) {
            return undefined;
          }

          const newOrder = isActive && isAsc ? order.desc : order.asc;

          setOrderBy(newOrder);
        };

        return (
          <AdminGridCell key={`${keyPrefix}-${i}`} isHeading>
            <button
              className="flex items-center relative"
              disabled={!sortable}
              onClick={onSort}
            >
              {label}
              <span
                className="absolute flex items-center"
                style={{ marginLeft: "0.5rem", right: "-1.5rem" }}
              >
                {isActive && (isAsc ? <ExpandMore /> : <ExpandLess />)}
              </span>
            </button>
          </AdminGridCell>
        );
      })}
    </Fragment>
  );
};

import React, { useState, Fragment } from "react";

import Dialog from "./Dialog";
import ParticipantsAll, { ParticipantsAllProps } from "./ParticipantsAll";
import { Filters } from "../hooks/useParticipantSearch";

interface OpenerProps {
  onClick: () => void;
}
interface ParticipantsSearchDialogProps
  extends Omit<ParticipantsAllProps, "searchTerm"> {
  Opener: React.FC<OpenerProps>;
  title?: string;
  filters?: Filters;
}
const ParticipantsSearchDialog: React.FC<ParticipantsSearchDialogProps> = (
  props,
) => {
  const { onSelect, excludeIds, Opener, title, filters } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const onRequestOpen = () => setIsOpen(true);
  const onRequestClose = () => setIsOpen(false);

  return (
    <Fragment>
      <Dialog
        fullHeight
        title={title || "Participants"}
        onRequestClose={onRequestClose}
        isOpen={isOpen}
      >
        <input
          className="w-100 pa2 mb3"
          placeholder="Search"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(e.target.value)
          }
        />
        <ParticipantsAll
          searchTerm={searchTerm}
          onSelect={(participant) => {
            onSelect(participant);
            onRequestClose();
          }}
          excludeIds={excludeIds}
          filters={filters}
        />
      </Dialog>
      <Opener onClick={onRequestOpen} />
    </Fragment>
  );
};

export default ParticipantsSearchDialog;

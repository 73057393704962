import { graphql } from "../generated";

const CREATE_REGISTER_PARTICIPANT = graphql(`
  mutation CreateRegisterParticipant(
    $register_participant: register_participant_insert_input!
  ) {
    insert_register_participant(objects: [$register_participant]) {
      returning {
        register_id
        participant_id
        sensitive_fields {
          notes
        }
      }
    }
  }
`);

export default CREATE_REGISTER_PARTICIPANT;

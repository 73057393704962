import { graphql } from "../generated";

const DELETE_ORGANISATION_PROGRAMME= graphql(`
  mutation DeleteOrganisationProgramme(
    $organisation_id: uuid!
    $programme_id: uuid!
  ) {
    delete_organisation_programme(
      where: {
        programme_id: { _eq: $programme_id }
        organisation_id: { _eq: $organisation_id }
      }
    ) {
      returning {
        programme_id
        organisation_id
      }
    }
  }
`);

export default DELETE_ORGANISATION_PROGRAMME;

import React, { ReactNode } from "react";
import useProgrammes from "../hooks/useProgrammes";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import LeftPanel from "./LeftPanel";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import {
  getRegistersPath,
  getParticipantsPath,
  getOrganisationReportsPath,
  getHomePath,
  getHubsPath,
  getProgrammeGroupsPath,
  getProgrammeGroupPath,
} from "../helpers/route-path";
import { names } from "@/common-lib/names";

const ProgrammeAuth: React.FC<{ children: ReactNode }> = (props) => {
  const { children } = props;
  const { programmeSlug } = useParams<{ programmeSlug?: string }>();
  const organisation = useSessionOrganisation();
  const { programmes, loading } = useProgrammes({ organisation });
  const programme = programmes.find((p) => p.slug === programmeSlug);

  if (!organisation) {
    // return null
    return <LoadingSpinner type="overlay" text={"Loading Organisation"} />;
  }

  if (loading) {
    // return null
    return <LoadingSpinner type="overlay" text={"Loading Programme"} />;
  }

  if (!programmeSlug || !programme) {
    return <LeftPanel>Programme not found, or you are not assigned.</LeftPanel>;
  }

  const organisationSlug = organisation.slug;

  const TYPE: "amna-programme" | "partner-hub" =
    programme.type === "amna-programme" ? "amna-programme" : "partner-hub";

  return (
    <LeftPanel
      heading={
        <>
          {TYPE === "amna-programme" ? (
            <>
              <span>
                <Link to={getProgrammeGroupsPath()}>{organisation.name}</Link>{" "}
              </span>
              <span>
                /{" "}
                <Link
                  to={getProgrammeGroupPath({
                    programmeGroupSlug: programme.programme_group?.slug!!,
                  })}
                >
                  {programme.programme_group?.name}
                </Link>{" "}
              </span>
            </>
          ) : (
            <>
              <span>
                <Link to={getHubsPath()}>{names.hubs.shortPlural}</Link>{" "}
              </span>
              <span>
                / <Link to={getHubsPath()}>{organisation.name}</Link>{" "}
              </span>
            </>
          )}
          <span>
            /{" "}
            <Link
              to={getHomePath({
                organisationSlug: organisation.slug,
                programmeSlug: programme.slug,
              })}
            >
              {programme.name}
            </Link>
          </span>
        </>
      }
      links={[
        {
          url: getRegistersPath({
            organisationSlug,
            programmeSlug,
          }),
          text: "Attendance Registers",
          icon: "list_alt",
        },
        {
          url: getParticipantsPath({
            organisationSlug,
            programmeSlug,
          }),
          text: "Participants",
          icon: "people_alt",
        },
        {
          url: getOrganisationReportsPath({
            organisationSlug,
            programmeSlug,
          }),
          text: "Reports",
          icon: "bar_chart",
        },
      ]}
    >
      {children}
    </LeftPanel>
  );
};

export default ProgrammeAuth;

import { graphql } from "../generated";

const REGISTER_AGGREGATE= graphql(`
  query RegisterAggregate(
    $dynamicWhere: register_bool_exp
    $distinctOn: [register_select_column!]
  ) {
    register_aggregate(where: $dynamicWhere, distinct_on: $distinctOn) {
      aggregate {
        count
      }
    }
  }
`);

export default REGISTER_AGGREGATE;

import React, {
  useRef,
  useCallback,
  useState,
  LegacyRef,
  ReactNode,
  FC,
  ComponentPropsWithRef,
} from "react";
import TextField, { TextFieldProps, inputStyles } from "./TextField";
import styled from "styled-components";
import useScrollHeight from "../../hooks/useScrollHeight";
import { InputWrapper, InputWrapperProps } from "./Common";

const RelativeWrapper = styled.div`
  position: relative;
`;
const DummyTextAreaWrapper = styled(
  (props: Partial<InputWrapperProps & { children: ReactNode }>) => (
    <InputWrapper focused={false} hasChips={false} {...props} />
  )
)`
  position: absolute;
  visibility: hidden;
`;
const DummyTextArea: FC<
  ComponentPropsWithRef<"textarea"> & {
    hasContent: boolean;
    focused: boolean;
  }
> = styled.textarea`
  ${inputStyles}
  position: absolute;
`;
interface TextareaFieldProps extends TextFieldProps {
  rows?: number;
}
const TextareaField: React.FC<TextareaFieldProps> = (props) => {
  const dummyTextAreaRef = useRef<HTMLTextAreaElement & HTMLInputElement>(null);
  const { value } = props;
  const scrollHeight = useScrollHeight(dummyTextAreaRef);
  const [extraHeight, setExtraHeight] = useState(0);

  const textareaRef: LegacyRef<HTMLInputElement> = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        const computedStyle = window.getComputedStyle(node);
        const extra =
          parseInt(computedStyle.getPropertyValue("border-top-width"), 10) +
          parseInt(computedStyle.getPropertyValue("padding-top"), 10) +
          parseInt(computedStyle.getPropertyValue("padding-bottom"), 10) +
          parseInt(computedStyle.getPropertyValue("border-bottom-width"), 10);
        setExtraHeight(extra);
      }
    },
    []
  );

  return (
    <RelativeWrapper>
      <TextField
        tag="textarea"
        rows={5}
        {...props}
        inputProps={{
          ...props.inputProps,
          ref: textareaRef,
          style: { height: scrollHeight + extraHeight },
        }}
      ></TextField>
      <DummyTextAreaWrapper focused={false}>
        <DummyTextArea
          tabIndex={-1}
          hasContent={Boolean(value)}
          focused={false}
          rows={5}
          value={value ? value : ""}
          readOnly
          ref={dummyTextAreaRef}
        />
      </DummyTextAreaWrapper>
    </RelativeWrapper>
  );
};

export default TextareaField;

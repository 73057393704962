import { names } from "@/common-lib/names";
import {
  UserId,
  ProjectSlug,
  OrganisationSlug,
  LocationSlug,
  ProgrammeSlug,
} from "../context/types";

export const getProgrammeGroupsPath = () => `/amna`;
export const getHubsPath = () => `/${names.hubs.slug}`;
export const getProgrammeGroupPath = ({
  programmeGroupSlug,
}: {
  programmeGroupSlug: string;
}) => `/amna/programmes?programme-group=${programmeGroupSlug}`;
interface OrganisationPathParams {
  organisationSlug: string;
}
export const getOrganisationPath = ({
  organisationSlug,
}: OrganisationPathParams) => `/${organisationSlug}`;
// organisationSlug === "amna" ? `/${organisationSlug}` : "";

interface OrganisationManagerParams extends OrganisationPathParams {}
export const getOrganisationManagerPath = ({
  organisationSlug,
}: OrganisationManagerParams) => `/${organisationSlug}/manager`;

interface OrganisationManagerProjectsParams extends OrganisationPathParams {}
export const getOrganisationManagerProjectsPath = ({
  organisationSlug,
}: OrganisationManagerProjectsParams) =>
  `/${organisationSlug}/manager/projects`;

interface OrganisationManagerLocationsParams extends OrganisationPathParams {}
export const getOrganisationManagerLocationsPath = ({
  organisationSlug,
}: OrganisationManagerLocationsParams) =>
  `/${organisationSlug}/manager/locations`;

interface OrganisationUsersParams extends OrganisationPathParams {}
export const getOrganisationManagerUsersPath = ({
  organisationSlug,
}: OrganisationUsersParams) => `/${organisationSlug}/manager/users`;

interface OrganisationUserParams extends OrganisationPathParams {
  userId: UserId;
}
export const getOrganisationManagerUserPath = ({
  organisationSlug,
  userId,
}: OrganisationUserParams) => `/${organisationSlug}/manager/users/${userId}`;

interface OrganisationProgrammePathParams {
  organisationSlug: string;
  programmeSlug: string;
}

interface HomePathParams extends OrganisationProgrammePathParams {}
export const getHomePath = ({
  organisationSlug,
  programmeSlug,
}: HomePathParams) => `/${organisationSlug}/${programmeSlug}`;

export const getPartnerHubPath = ({
  programmeSlug,
}: {
  programmeSlug: string;
}) => `${getHubsPath()}/${programmeSlug}`;

interface OrganisationReportsParams extends OrganisationProgrammePathParams {}
export const getOrganisationReportsPath = ({
  organisationSlug,
  programmeSlug,
}: OrganisationReportsParams) =>
  `/${organisationSlug}/${programmeSlug}/reports`;

interface ParticipantsPathParams extends OrganisationProgrammePathParams {}
export const getParticipantsPath = ({
  organisationSlug,
  programmeSlug,
}: ParticipantsPathParams) =>
  `/${organisationSlug}/${programmeSlug}/participants`;

interface ParticipantPathParams extends OrganisationProgrammePathParams {
  participantId: string;
}
export const getParticipantPath = ({
  organisationSlug,
  programmeSlug,
  participantId,
}: ParticipantPathParams) =>
  `/${organisationSlug}/${programmeSlug}/participants/${participantId}`;

interface ProjectsPathParams extends OrganisationProgrammePathParams {}
export const getProjectsPath = ({
  organisationSlug,
  programmeSlug,
}: ProjectsPathParams) => `/${organisationSlug}/${programmeSlug}/projects`;

interface RegistersPathParams extends OrganisationProgrammePathParams {}
export const getRegistersPath = ({
  organisationSlug,
  programmeSlug,
}: RegistersPathParams) => `/${organisationSlug}/${programmeSlug}/registers`;

interface RegisterPathParams extends OrganisationProgrammePathParams {
  registerId: string;
}
export const getRegisterPath = ({
  organisationSlug,
  programmeSlug,
  registerId,
}: RegisterPathParams) =>
  `/${organisationSlug}/${programmeSlug}/registers/${registerId}`;

export const getAdminHomePath = () => `/admin`;

export const getAdminUsersPath = () => `/admin/users`;
interface AdminUserPathParams {
  userId: UserId;
}
export const getAdminUserPath = ({ userId }: AdminUserPathParams) =>
  `/admin/users/${userId}`;

export const getAdminProgrammesPath = () => `/admin/programmes`;
interface AdminProgrammePathParams {
  programmeSlug: ProgrammeSlug;
}
export const getAdminProgrammePath = ({
  programmeSlug,
}: AdminProgrammePathParams) => `/admin/programmes/${programmeSlug}`;

export const getAdminProjectsPath = () => `/admin/projects`;
interface AdminProjectPathParams {
  projectSlug: ProjectSlug;
}
export const getAdminProjectPath = ({ projectSlug }: AdminProjectPathParams) =>
  `/admin/projects/${projectSlug}`;

export const getAdminLocationsPath = () => `/admin/locations`;
interface AdminLocationPathParams {
  locationSlug: LocationSlug;
}
export const getAdminLocationPath = ({
  locationSlug,
}: AdminLocationPathParams) => `/admin/locations/${locationSlug}`;

export const getAdminOrganisationsPath = () => `/admin/organisations`;
interface AdminOrganisationPathParams {
  organisationSlug: OrganisationSlug;
}
export const getAdminOrganisationPath = ({
  organisationSlug,
}: AdminOrganisationPathParams) => `/admin/organisations/${organisationSlug}`;

export const getAdminReportsPath = () => `/admin/reports`;

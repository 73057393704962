import { graphql } from "../generated";

const UPDATE_ORGANISATION= graphql(`
  mutation UpdateOrganisation(
    $id: uuid!
    $organisation: organisation_set_input!
  ) {
    update_organisation(_set: $organisation, where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`);

export default UPDATE_ORGANISATION;

import React, { useEffect, useCallback, Fragment } from "react";
import { Redirect, useHistory } from "react-router-dom";

import useOrganisations from "../hooks/useOrganisations";
import LoadingSpinner from "./LoadingSpinner";
import LeftPanel from "./LeftPanel";
import ErrorMessage from "./ErrorMessage";
import useSelf from "../hooks/useSelf";
import {
  getAdminHomePath,
  getHubsPath,
  getOrganisationPath,
} from "../helpers/route-path";
import Button from "./Button";
import NoContent from "./NoContent";
import NavigationLinkButton from "./NavigationLinkButton";
import { names } from "@/common-lib/names";
import { useIsAdmin } from "@/hooks/useIsAdmin";

const SelectAmnaOrHubs: React.FC = () => {
  const { organisations, loading, error, refetch } = useOrganisations();
  const user = useSelf();

  const history = useHistory();
  const goToAdmin = useCallback(() => {
    history.push(getAdminHomePath());
  }, []);
  const isAdmin = useIsAdmin();

  useEffect(() => {
    refetch();
  }, []);

  if (!loading && organisations.length === 1 && user?.role !== "rti-admin") {
    /**
     * If user only a member of a single org, then redirect them into that org
     * However, if user is an admin, then keep them on the "/" page so that they
     * have access to the "Database admin" button.
     */
    return (
      <Redirect
        to={getOrganisationPath({
          organisationSlug: organisations[0].slug,
        })}
      />
    );
  }

  return (
    <LeftPanel>
      <header className="flex justify-between items-center mb3">
        <h1>Amna Database</h1>
        {isAdmin && (
          <Button onClick={goToAdmin} raised fill>
            Admin Panel
          </Button>
        )}
      </header>
      <div className="flex flex-column flex-auto">
        {error && (
          <ErrorMessage>
            There was an error loading your Organisations. Please try refreshing
            the page.
          </ErrorMessage>
        )}
        {loading && <LoadingSpinner type="overlay" />}
        {!loading && !error && organisations.length === 0 && (
          <NoContent
            icon="groups"
            message={
              <Fragment>
                You're not a member of an organisation.
                <br />
                Please contact an admin.
              </Fragment>
            }
          />
        )}
        {organisations.some((org) => org.slug === "amna") && (
          <NavigationLinkButton to={"/amna"}>Amna</NavigationLinkButton>
        )}
        {organisations.some((org) => org.slug !== "amna") && (
          <NavigationLinkButton to={getHubsPath()}>
            {names.hubs.longPlural}
          </NavigationLinkButton>
        )}
      </div>
    </LeftPanel>
  );
};

export default SelectAmnaOrHubs;

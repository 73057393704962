import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import FETCH_USER_WARNINGS from "../graphql/queries/fetch-user-warnings";
import useSelf from "./useSelf";

const useUserWarnings = () => {
  const self = useSelf();

  if (!self) {
    throw new Error("No self");
  }

  const [fetchUserWarnings, { data: userWarnings, loading, error }] =
    useLazyQuery(FETCH_USER_WARNINGS, {
      fetchPolicy: "no-cache",
    });

  useEffect(() => {
    if (self.id) {
      fetchUserWarnings({ variables: { userId: self.id } });
    }
  }, [self.id]);

  return userWarnings?.user_warning.map((warning) => warning.warning);
};

export default useUserWarnings;

import React, { useState } from "react";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import TextField from "./TextField";
import styled from "styled-components";

const Warning = styled.i`
  position: absolute;
  right: 0;
  bottom: 6px;
`;

interface PhoneFieldProps {
  onChange: (phone: string) => void;
  onBlur?: (phone: string) => void;
  value: string | undefined;
}
const PhoneField: React.FC<PhoneFieldProps> = (props) => {
  const { value, onChange } = props;
  const [warning, setWarning] = useState(false);

  const onBlur = (value: string | null) => {
    if (props.onBlur) {
      props.onBlur(value || "");
    }

    if (!value) {
      return;
    }

    const parsed = parsePhoneNumberFromString(value, "GR");

    const isValid = parsed ? parsed.isValid() : false;

    setWarning(!isValid);

    if (parsed) {
      props.onChange(parsed.number as string);
    }
  };

  return (
    <div className="relative">
      <TextField
        name="phone"
        label="Phone Number (eg. +306940068192)"
        value={value || ""}
        onChange={onChange}
        onBlur={onBlur}
      />
      {warning && (
        <Warning title="This is not a valid phone number">⚠️</Warning>
      )}
    </div>
  );
};

export default PhoneField;

import React, { HTMLProps } from "react";

export default function Age(props: HTMLProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-23 0 512 512" {...props}>
      <path
        d="m85.945312 509.804688c-2.929687-2.929688-2.929687-7.679688 0-10.609376 10.984376-10.984374 10.984376-28.855468 0-39.839843-16.832031-16.835938-16.832031-44.222657 0-61.058594 10.984376-10.984375 10.984376-28.855469 0-39.84375-2.929687-2.925781-2.929687-7.675781 0-10.605469 2.929688-2.925781 7.679688-2.925781 10.605469 0 16.835938 16.835938 16.835938 44.222656 0 61.058594-10.984375 10.984375-10.984375 28.855469 0 39.839844 16.835938 16.835937 16.835938 44.226562 0 61.058594-2.925781 2.925781-7.675781 2.925781-10.605469 0zm0 0"
        fill="#bdbdbd"
      />
      <path
        d="m173.277344 241.898438c-28.226563-30.246094-47.980469-80.378907-46.140625-124.445313.132812-3.277344-1.875-6.261719-4.964844-7.371094-9.808594-3.515625-20.210937-5.296875-30.921875-5.296875-50.726562 0-91.25 39.71875-91.25 99.050782 0 58.53125 42.121094 128.515624 91.25 128.515624 39.023438 0 70.734375-43.035156 83.683594-83.023437.835937-2.59375.203125-5.4375-1.65625-7.429687zm0 0"
        fill="#ffca28"
      />
      <path
        d="m173.277344 241.898438c-28.226563-30.246094-47.980469-80.378907-46.140625-124.445313.132812-3.277344-1.875-6.261719-4.964844-7.371094-12.691406-4.546875-26.382813-6.167969-39.996094-4.859375 1.335938 28.164063 10.878907 58.648438 25.730469 83.996094 16.316406 27.847656 14.121094 63.066406-6.65625 87.765625-15.488281 18.40625-36.554688 32.664063-60.527344 30.054687 15.464844 16.285157 33.183594 25.3125 50.527344 25.3125 39.023438 0 70.734375-43.039062 83.679688-83.023437.839843-2.59375.207031-5.4375-1.652344-7.429687zm0 0"
        fill="#ffa000"
      />
      <path
        d="m223.976562 509.804688c-2.929687-2.929688-2.929687-7.679688 0-10.609376 10.246094-10.246093 10.246094-26.824218 0-37.070312-16.003906-16.003906-16.070312-42-.203124-58.085938-10.871094-1.164062-19.367188-10.394531-19.367188-21.566406 0-11.960937 9.734375-21.695312 21.695312-21.695312 5.121094 0 9.835938 1.785156 13.550782 4.765625-.09375-7.90625-3.21875-15.324219-8.816406-20.925781-8.523438-8.519532-13.214844-19.847657-13.214844-31.898438 0-4.144531 3.359375-7.5 7.5-7.5s7.5 3.355469 7.5 7.5c0 8.042969 3.132812 15.605469 8.820312 21.292969 15.613282 15.613281 17.3125 39.65625 5.554688 57.070312-1.675782 8.792969-5.9375 16.886719-12.414063 23.359375-10.222656 10.222656-10.222656 26.855469 0 37.074219 16.070313 16.070313 16.070313 42.21875 0 58.289063-2.929687 2.925781-7.679687 2.925781-10.605469 0zm2.125-134.027344c-4.125 0-7.386718 3.75-6.566406 8.019531.523438 2.703125 2.753906 4.851563 5.476563 5.28125 4.226562.664063 7.789062-2.613281 7.78125-6.566406 0-.007813 0-.019531 0-.027344 0-3.703125-3-6.707031-6.691407-6.707031zm0 0"
        fill="#bdbdbd"
      />
      <path
        d="m369.085938 512c-4.140626 0-7.5-3.359375-7.5-7.5 0-6.730469-2.621094-13.058594-7.382813-17.820312-13.886719-13.890626-15.390625-35.214844-5.058594-50.6875 1.519531-7.757813 5.300781-14.898438 11.023438-20.621094 8.503906-8.503906 8.503906-22.339844 0-30.84375-14.386719-14.386719-14.386719-37.671875 0-52.058594 2.929687-2.929688 7.679687-2.929688 10.605469 0 2.929687 2.929688 2.929687 7.675781 0 10.605469-8.523438 8.523437-8.523438 22.320312 0 30.84375 13.996093 13.996093 14.257812 36.179687 1.464843 50.507812 9.027344 1.859375 15.832031 9.863281 15.832031 19.433594 0 15.660156-17.402343 25.140625-30.539062 16.699219.53125 5.863281 3.066406 11.300781 7.28125 15.515625 7.589844 7.59375 11.773438 17.6875 11.773438 28.425781 0 4.140625-3.359376 7.5-7.5 7.5zm-5.691407-68.429688c-.03125 3.574219 2.953125 5.632813 5.878907 5.019532 1.867187-.390625 3.359374-1.925782 3.710937-3.796875.625-3.320313-2.171875-6.199219-5.46875-5.71875-1.925781.277343-4.101563 2.039062-4.121094 4.496093zm0 0"
        fill="#bdbdbd"
      />
      <path
        d="m365.253906 64.929688c-15.300781 0-29.800781 3.253906-43.105468 9.675781-3.375 1.625-5.027344 5.492187-3.878907 9.054687 3.972657 12.324219 5.988281 25.730469 5.988281 39.847656 0 38.394532-16.171874 82.605469-41.207031 112.636719-1.855469 2.226563-2.261719 5.328125-1.039062 7.960938 20.050781 43.171875 51.945312 69.992187 83.238281 69.992187 53.664062 0 99.761719-76.75 99.761719-140.816406 0-64.691406-44.074219-108.351562-99.757813-108.351562zm0 0"
        fill="#42a5f5"
      />
      <path
        d="m388.933594 67.699219c.671875.667969 1.335937 1.339843 1.992187 2.03125 18.757813 19.726562 29.085938 47.625 29.085938 78.550781 0 31.0625-10.953125 66.113281-29.304688 93.761719-19.84375 29.902343-45.523437 47.054687-70.457031 47.054687-2.734375 0-5.476562-.21875-8.214844-.621094 16.269532 16.367188 34.84375 25.621094 53.214844 25.621094 53.664062 0 99.761719-76.75 99.761719-140.816406 0-55.441406-32.191407-95.0625-76.078125-105.582031zm0 0"
        fill="#1976d2"
      />
      <path
        d="m225.632812 0c-63.988281 0-113.625 50.335938-113.625 123.507812 0 73.3125 52.757813 160.847657 113.625 160.847657 60.976563 0 113.625-87.691407 113.625-160.847657 0-73.121093-49.585937-123.507812-113.625-123.507812zm0 0"
        fill="#ef5350"
      />
      <path
        d="m256.179688 4.070312c25.789062 23.902344 38.078124 58.511719 38.078124 94.4375 0 77.800782-59.707031 172.613282-125.984374 159.617188 84.949218 80.328125 170.984374-42.015625 170.984374-134.617188 0-62.148437-35.3125-106.308593-83.078124-119.4375zm0 0"
        fill="#d32f2f"
      />
      <path
        d="m82.253906 356.152344c-4.570312 0-7.914062-4.304688-6.78125-8.738282l4.257813-16.621093c1.347656-5.265625 6.085937-8.941407 11.519531-8.941407s10.171875 3.675782 11.519531 8.941407l4.257813 16.621093c1.132812 4.429688-2.207032 8.738282-6.78125 8.738282zm0 0"
        fill="#ffa000"
      />
      <path
        d="m212.859375 315.71875c-4.574219 0-7.917969-4.308594-6.78125-8.738281l5.738281-22.398438c1.617188-6.3125 7.296875-10.726562 13.816406-10.726562 6.519532 0 12.203126 4.410156 13.820313 10.726562l5.738281 22.398438c1.132813 4.429687-2.210937 8.738281-6.78125 8.738281zm0 0"
        fill="#d32f2f"
      />
      <path
        d="m354.816406 340.769531c-4.570312 0-7.914062-4.304687-6.78125-8.734375l4.824219-18.820312c1.449219-5.664063 6.546875-9.617188 12.394531-9.617188 5.84375 0 10.941406 3.953125 12.390625 9.617188l4.820313 18.816406c1.136718 4.429688-2.207032 8.738281-6.78125 8.738281zm0 0"
        fill="#1976d2"
      />
    </svg>
  );
}

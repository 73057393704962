import React, { HTMLProps } from "react";

import SelectField, { SelectFieldOptions } from "./field/SelectField";
import { OrganisationId } from "../context/types";
import useOrganisations from "../hooks/useOrganisations";

interface SelectOrganisationFieldProps
  extends Omit<HTMLProps<SelectFieldOptions>, "onSelect"> {
  onSelect: (id: OrganisationId) => void;
  value: OrganisationId;
}
const SelectOrganisationField: React.FC<SelectOrganisationFieldProps> = (
  props,
) => {
  const { onSelect, value, ...otherProps } = props;

  const { organisations } = useOrganisations();

  const options: SelectFieldOptions = organisations.map((organisation) => ({
    label: organisation.name,
    value: organisation.id,
  }));

  return (
    <SelectField
      {...otherProps}
      name="organisation_id"
      label="Organisation"
      value={value}
      onChange={onSelect}
      options={options}
    />
  );
};

export default SelectOrganisationField;

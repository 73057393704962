import React from "react";

import errorService from "../common-lib/error";
import LoadingSpinner from "./LoadingSpinner";
import {
  ProjectId,
  LocationId,
  ParticipantId,
  RegisterId,
  CategoryId,
} from "../context/types";
import RegisterAddFromParticipantItem from "./RegisterAddFromParticipantItem";
import useParticipantsLatest from "../hooks/useParticipantsLatest";

interface RegisterAddFromResultsProps {
  registerId: RegisterId;
  projectId: ProjectId;
  categoryIds: CategoryId[];
  locationId?: LocationId;
  excludeIds?: Array<ParticipantId>;
  searchTerm: string;
  onAdd: () => void;
}
const RegisterAddFromResults: React.FC<RegisterAddFromResultsProps> = (
  props
) => {
  const {
    registerId,
    excludeIds = [],
    projectId,
    locationId,
    categoryIds,
    searchTerm,
    onAdd,
  } = props;

  const { participants, error, loading } = useParticipantsLatest({
    excludeIds,
    projectId,
    locationId,
    searchTerm,
    categoryIds,
  });

  if (error) {
    errorService.error(error);
  }

  return (
    <ul className="relative">
      {participants.map((participant) => {
        if (!participant) return null;

        return (
          <RegisterAddFromParticipantItem
            key={`Participants-li-${participant.id}`}
            participant={participant}
            registerId={registerId}
            onAdd={onAdd}
          />
        );
      })}
      {participants.length === 0 && loading && <LoadingSpinner type="row" />}
    </ul>
  );
};

export default RegisterAddFromResults;

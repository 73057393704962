import { graphql } from "../generated";

const FETCH_ORGANISATIONS = graphql(`
  query FetchOrganisations($dynamicWhere: organisation_bool_exp) {
    organisation(where: $dynamicWhere, order_by: { name: asc }) {
      id
      name
      slug
      organisation_managers {
        user_id
      }
      organisation_programmes {
        programme {
          id
          name
          slug
        }
      }
    }
  }
`);

export default FETCH_ORGANISATIONS;

import { graphql } from "../generated";

const FETCH_LOCATION = graphql(`
  query FetchLocation($locationSlug: String!) {
    location(where: { slug: { _eq: $locationSlug } }) {
      id
      name
      slug
      organisation_id
      programme_locations {
        programme {
          id
          name
          slug
        }
      }
    }
  }
`);

export default FETCH_LOCATION;

import { graphql } from "../generated";

const FETCH_PARTICIPANTS_BY_PHONE = graphql(`
  query FetchParticipantsByPhone($phone: String!, $excludeId: uuid) {
    participant(
      where: {
        sensitive_fields: { phone: { _eq: $phone } }
        _and: { id: { _neq: $excludeId } }
      }
    ) {
      id
      sensitive_fields {
        first_name
        last_name
      }
    }
  }
`);

export default FETCH_PARTICIPANTS_BY_PHONE;

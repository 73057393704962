import { useLazyQuery } from "@apollo/client";

import FETCH_REGISTERS from "../graphql/queries/fetch-registers";
import useSessionProgramme from "./useSessionProgramme";
import useSessionOrganisation from "./useSessionOrganisation";
import { LocationId, ProjectId } from "../context/types";
import { useEffect, useMemo } from "react";
import truthy from "@/helpers/truthy";
import { Register_Order_By } from "@/graphql/generated/graphql";

const DEFAULT_LIMIT = 30;

export interface Filters {
  projectId?: ProjectId;
  projectCategoryId?: string;
  locationId?: LocationId;
  facilitatorId?: string;
  isDeleted: boolean;
  startDate: string;
  endDate: string;
}
interface Opts {
  filters?: Filters;
  orderBy: Register_Order_By[];
}
const useRegisters = (opts: Opts) => {
  const organisation = useSessionOrganisation();
  const programme = useSessionProgramme();

  // const [endOfResults, setEndOfResults] = useState(false);

  const {
    filters = {
      projectId: undefined,
      projectCategoryId: undefined,
      locationId: undefined,
      facilitatorId: undefined,
      isDeleted: false,
      startDate: undefined,
      endDate: undefined,
    },
    orderBy,
  } = opts;

  const dynamicWhere = {
    _and: [
      { deleted_at: { _is_null: !filters.isDeleted } },
      organisation ? { organisation_id: { _eq: organisation.id } } : null,
      programme
        ? {
            project: {
              programme: { id: { _eq: programme.id } },
            },
          }
        : null,
      filters.projectId ? { project_id: { _eq: filters.projectId } } : null,
      filters.projectCategoryId
        ? {
            register_project_categories: {
              project_category_id: { _eq: filters.projectCategoryId },
            },
          }
        : null,
      filters.locationId ? { location_id: { _eq: filters.locationId } } : null,
      filters.facilitatorId
        ? {
            register_facilitators: {
              facilitator_id: { _eq: filters.facilitatorId },
            },
          }
        : null,
      filters.startDate
        ? {
            date: {
              _gte: filters.startDate,
            },
          }
        : null,
      filters.endDate
        ? {
            date: {
              _lte: filters.endDate,
            },
          }
        : null,
    ].filter(truthy),
  };

  const varsJson = JSON.stringify({
    dynamicOrderBy: orderBy,
    dynamicWhere,
    offset: 0,
    limit: DEFAULT_LIMIT,
  });

  const variables = useMemo(() => {
    return {
      dynamicOrderBy: orderBy,
      dynamicWhere,
      offset: 0,
      limit: DEFAULT_LIMIT,
    };
  }, [varsJson]);

  const [fetchRegisters, { loading, error, data, refetch, fetchMore }] =
    useLazyQuery(FETCH_REGISTERS);

  const totalCount = data?.register_aggregate.aggregate?.count;
  const registers = data ? data.register : [];
  const endOfResults = registers.length === totalCount;

  useEffect(() => {
    fetchRegisters({ variables });
  }, [varsJson]);

  const loadMore = () => {
    fetchMore({
      variables: {
        ...variables,
        offset: registers.length,
        limit: DEFAULT_LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return Object.assign({}, prev, {
          register: [...prev.register, ...fetchMoreResult.register],
        });
      },
    });
  };

  return {
    registers,
    error,
    loading,
    refetch: () =>
      refetch({
        ...variables,
        limit:
          registers.length > DEFAULT_LIMIT ? registers.length : DEFAULT_LIMIT,
      }),
    loadMore,
    endOfResults,
    totalCount,
  };
};

export type Registers = ReturnType<typeof useRegisters>["registers"];

export default useRegisters;

import { graphql } from "../generated";

const CREATE_ORGANISATION_PROGRAMME= graphql(`
  mutation CreateOrganisationProgramme(
    $organisation_programme: organisation_programme_insert_input!
  ) {
    insert_organisation_programme(objects: [$organisation_programme]) {
      returning {
        organisation_id
        programme_id
      }
    }
  }
`);

export default CREATE_ORGANISATION_PROGRAMME;

import React, { Fragment, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";

import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import {
  getAdminProgrammePath,
  getAdminProgrammesPath,
  getHomePath,
  getOrganisationManagerPath,
} from "../helpers/route-path";
import LeftPanel from "./LeftPanel";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import Button from "./Button";
import useIsManager from "../hooks/useIsManager";
import NoContent from "./NoContent";
import useProgrammeGroups from "@/hooks/useProgrammeGroups";
import NavigationLinkButton from "./NavigationLinkButton";
import { useIsAdmin } from "@/hooks/useIsAdmin";

const SelectAmnaProgramme: React.FC = () => {
  const history = useHistory();
  const organisation = useSessionOrganisation();

  const searchParams = new URLSearchParams(history.location.search);
  const programmeGroupSlug = searchParams.get("programme-group");

  const { programmeGroups, loading, error } = useProgrammeGroups();

  // const { programmes, loading, error } = useProgrammes({
  //   organisation,
  //   type: "amna-programme",
  //   programmeGroupSlug,
  // });

  const programmeGroup = programmeGroups.find(
    (programmeGroup) => programmeGroup.slug === programmeGroupSlug
  );

  const { programmes } = programmeGroup || {
    programmes: [],
  };

  const goToAdmin = useCallback(() => {
    history.push(getAdminProgrammesPath());
  }, []);
  const isAdmin = useIsAdmin();
  const isManager = useIsManager();

  if (loading || !organisation) {
    return <LoadingSpinner type="overlay" />;
  }

  if (!loading && !isManager && programmes.length === 1) {
    return (
      <Redirect
        to={getHomePath({
          organisationSlug: organisation.slug,
          programmeSlug: programmes[0].slug,
        })}
      />
    );
  }

  return (
    <LeftPanel heading={`${organisation.name}`}>
      <header className="flex justify-between items-center mb3">
        <h1>{programmeGroup?.name}</h1>

        {isAdmin && (
          <Button onClick={goToAdmin} raised fill>
            Make changes
          </Button>
        )}
      </header>
      <div className="flex flex-column flex-auto">
        {error && (
          <ErrorMessage>
            There was an error loading your Programmes. Please try refreshing
            the page.
          </ErrorMessage>
        )}
        {loading && <LoadingSpinner type="overlay" />}
        {!loading && !error && programmes.length === 0 && (
          <NoContent
            icon="groups"
            message={<Fragment>No programmes found.</Fragment>}
          />
        )}
        {programmes.length ? (
          <p className="mb3">Please select from the below programmes:</p>
        ) : null}
        {programmes.map((programme) => {
          return (
            <NavigationLinkButton
              key={`SelectAmnaProgramme-Programme-${programme.id}`}
              to={getHomePath({
                organisationSlug: organisation.slug,
                programmeSlug: programme.slug,
              })}
            >
              {programme.name}
            </NavigationLinkButton>
          );
        })}
      </div>
    </LeftPanel>
  );
};

export default SelectAmnaProgramme;

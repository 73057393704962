import { AppState } from "./context/reducer";
import errorService from "./common-lib/error";
import safeLocalStorage from "./common-lib/safeLocalStorage";

const STATE_KEY = "state";

export const loadState = () => {
  try {
    const serializedState = safeLocalStorage.getItem(STATE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState) as AppState;
  } catch (err) {
    errorService.error(err);
  }
};

export const saveState = (state: AppState) => {
  try {
    const serializedState = JSON.stringify(state);
    safeLocalStorage.setItem(STATE_KEY, serializedState);
  } catch (err) {
    // ignore write errors
    errorService.error(err);
  }
};

import { useLayoutEffect, useCallback, useState } from "react";

const useScrollHeight = (ref: React.RefObject<HTMLElement>) => {
  const [rect, setRect] = useState(getScrollHeight(ref ? ref.current : null));

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return;
    }

    // Update client rect
    setRect(getScrollHeight(ref.current));
  }, [ref]);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    handleResize();

    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(handleResize);

    observer.observe(element, config);
    // if (typeof ResizeObserver === 'function') {
    //   let resizeObserver = new ResizeObserver(() => handleResize())
    //   resizeObserver.observe(element)

    //   return () => {
    //     if (!resizeObserver) {
    //       return
    //     }

    //     resizeObserver.disconnect()
    //     resizeObserver = null
    //   }
    // } else {
    // Browser support, remove freely
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // }
  }, [ref.current]);

  return rect;
};

function getScrollHeight(element: HTMLElement | null) {
  if (!element) {
    return 0;
  }

  return element.scrollHeight;
}

export default useScrollHeight;

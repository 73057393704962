import { graphql } from "../generated";

const DELETE_PARTICIPANT_SIBLING= graphql(`
  mutation DeleteParticipantSibling(
    $participant_id: uuid!
    $sibling_id: uuid!
  ) {
    delete_participant_sibling(
      where: {
        sibling_id: { _eq: $sibling_id }
        participant_id: { _eq: $participant_id }
      }
    ) {
      returning {
        sibling_id
        participant_id
      }
    }
  }
`);

export default DELETE_PARTICIPANT_SIBLING;

import React from "react";
import SelectField from "./field/SelectField";
import useProgrammes from "../hooks/useProgrammes";
import { ProgrammeId } from "../context/types";

interface ProgrammeSelectProps {
  label?: string;
  name?: string;
  programmeId?: ProgrammeId;
  setProgrammeId: (programmeId: ProgrammeId) => void;
  disabled?: boolean;
}
const ProgrammeSelect: React.FC<ProgrammeSelectProps> = (props) => {
  const {
    name = "programme",
    label = "Programme",
    programmeId = "",
    setProgrammeId,
    disabled,
  } = props;
  const { programmes } = useProgrammes();

  return (
    <SelectField
      label={label}
      name={name}
      options={programmes.map((programme) => ({
        value: programme.id,
        label: programme.name,
      }))}
      value={programmeId}
      onChange={setProgrammeId}
      disabled={disabled}
    />
  );
};

export default ProgrammeSelect;

import { graphql } from "../generated";

const UPDATE_PROGRAMME= graphql(`
  mutation UpdateProgramme(
    $id: uuid!
    $programme: programme_set_input!
    $organisation_programmes: [organisation_programme_insert_input!]!
  ) {
    update_programme(_set: $programme, where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_organisation_programme(where: { programme_id: { _eq: $id } }) {
      returning {
        organisation_id
      }
    }
    insert_organisation_programme(
      objects: $organisation_programmes
      on_conflict: {
        constraint: organisation_programme_pkey
        update_columns: []
      }
    ) {
      returning {
        organisation_id
      }
    }
  }
`);

export default UPDATE_PROGRAMME;

import { graphql } from "../generated";

const CREATE_LOCATION= graphql(`
  mutation CreateLocation($location: location_insert_input!) {
    insert_location(objects: [$location]) {
      returning {
        id
      }
    }
  }
`);

export default CREATE_LOCATION;

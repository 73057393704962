import countries from "../constants/countries.json";
import { Country } from "../components/CountrySelect";

export const countriesMap = (countries as Array<Country>).reduce(
  (accum, country) => {
    accum[country.code] = country;

    return accum;
  },
  {} as {
    [code: string]: Country | undefined;
  },
);

export const getCountryName = (code: string) => {
  const country = countriesMap[code];
  return country ? country.name : "";
};

import useSessionOrganisation from "./useSessionOrganisation";
import useSelf from "./useSelf";

const useIsManager = () => {
  const organisation = useSessionOrganisation();
  const user = useSelf();

  if (!user || !organisation) {
    return false;
  }

  if (user.role === "rti-admin") {
    return true;
  }

  if (user.managerOrganisations?.includes(organisation.id)) {
    return true;
  }

  return false;
};

export default useIsManager;

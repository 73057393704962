import { useEffect } from "react";

import useIsMounted from "./useIsMounted";

type UseRefetchOnLandProps = {
  refetch: () => void;
  loading: boolean;
};
const useRefetchOnLand = ({ refetch, loading }: UseRefetchOnLandProps) => {
  const isMounted = useIsMounted();
  useEffect(() => {
    // this should accurately refetch (including pagination) when landing on the page not for the first time
    if (!isMounted && !loading) {
      refetch();
    }
  }, [isMounted, loading]);
};

export default useRefetchOnLand;

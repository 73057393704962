import React, { useState } from "react";
import styled from "styled-components";
import colours from "../../constants/colours";

const CheckboxRoot = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  color: rgba(0, 0, 0, 0.54);
`;
const CheckboxLabel = styled.span`
  margin-left: 0.5rem;
`;
const CheckboxSVG = styled.svg`
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
`;
const CheckboxInner = styled.span`
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
`;

const CheckboxOuter = styled.span<{ checked: boolean; focus: boolean }>`
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;

  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  ${(props) =>
    props.focus &&
    `
    background-color: ${colours.blueBg};
  `}
  ${(props) =>
    props.checked &&
    `
    color: ${colours.blue};
  `}
`;

const CheckboxInput = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;
interface CheckboxProps {
  label: string;
  checked: boolean;
  name: string;
  onChange: (checked: boolean) => void;
}
const Checkbox: React.FC<CheckboxProps> = (props) => {
  const [focus, setFocus] = useState(false);
  const { name, label, checked, onChange } = props;

  return (
    <CheckboxRoot>
      <CheckboxOuter focus={focus} checked={checked} aria-disabled="false">
        <CheckboxInner className="MuiIconButton-label">
          <CheckboxInput
            name={name}
            type="checkbox"
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
          <CheckboxSVG
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            {checked ? (
              <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
            ) : (
              <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
            )}
          </CheckboxSVG>
        </CheckboxInner>
      </CheckboxOuter>
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxRoot>
  );
};

export default Checkbox;

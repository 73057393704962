import { FetchPolicy, useQuery } from "@apollo/client";

import FETCH_PROGRAMMES from "../graphql/queries/fetch-programmes";
import { ProgrammeId } from "../context/types";
import { SessionOrganisation } from "./useSessionOrganisation";
import { names } from "@/common-lib/names";

export type ProgrammeType = "amna-programme" | "partner-hub";

export function getProgrammeType(programme: { type: string }) {
  return {
    slug: programme.type,
    title:
      programme.type === "amna-programme"
        ? "Amna Programme"
        : names.hubs.longSingle,
  };
}
interface ProgrammesOpts {
  excludeIds?: ProgrammeId[];
  organisation?: SessionOrganisation;
  type?: ProgrammeType;
  programmeGroupSlug?: string | null;
  fetchPolicy?: FetchPolicy;
}

const useProgrammes = (opts: ProgrammesOpts = {}) => {
  const {
    excludeIds = [],
    organisation,
    type,
    programmeGroupSlug,
    fetchPolicy = "cache-and-network",
  } = opts;

  const variables = {
    dynamicWhere: {
      id: { _nin: excludeIds },
      ...(type
        ? {
            type: { _eq: type },
          }
        : null),
      ...(programmeGroupSlug
        ? {
            programme_group: {
              slug: { _eq: programmeGroupSlug },
            },
          }
        : null),
      ...(organisation
        ? {
            organisation_programmes: {
              organisation: {
                id: { _eq: organisation.id },
              },
            },
          }
        : null),
    },
  };

  const { data, error, loading, refetch } = useQuery(FETCH_PROGRAMMES, {
    partialRefetch: true,
    fetchPolicy,
    variables,
  });
  const programmes = data ? data.programme : [];

  return { programmes, error, loading: !data && loading, refetch };
};

export default useProgrammes;

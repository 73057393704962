import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import IconButton from "../IconButton";

const ChipRoot = styled.div`
  color: rgba(0, 0, 0, 0.87);
  border: none;
  cursor: default;
  height: 32px;
  display: inline-flex;
  outline: 0;
  padding: 0;
  font-size: 0.8125rem;
  box-sizing: border-box;
  transition:
    background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
  height: 20px;
  margin-right: 2px;
  margin-bottom: 2px;
`;
const ChipLabel = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
`;

const ChipDelete = styled(IconButton)`
  color: rgba(0, 0, 0, 0.26);
  width: 22px;
  cursor: pointer;
  height: 22px;
  -webkit-tap-highlight-color: transparent;
  padding: 3px;
`;

interface ChipProps {
  label: string;
  onDelete: (e: SyntheticEvent<HTMLButtonElement>) => void;
}
const Chip: React.FC<ChipProps> = (props) => {
  const { label, onDelete } = props;
  return (
    <ChipRoot>
      <ChipLabel>{label}</ChipLabel>
      <ChipDelete icon="close" onClick={onDelete} size="small" />
    </ChipRoot>
  );
};

export default Chip;

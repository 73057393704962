import { graphql } from "../generated";

const CREATE_REGISTER = graphql(`
  mutation CreateRegister($register: register_insert_input!) {
    insert_register(objects: [$register]) {
      returning {
        id
      }
    }
  }
`);

export default CREATE_REGISTER;

import jwt from "jsonwebtoken";

import {
  refreshToken,
  REFRESH_TOKEN_INVALID,
  REFRESH_TOKEN_CONNECTION_FAILED,
} from "../api/auth";
import useLogout from "./useLogout";
import { useAppDispatch } from "../context/store";
import { Token } from "../context/types";
import errorService from "../common-lib/error";
import useLoggedIn from "./useLoggedIn";
import safeLocalStorage from "../common-lib/safeLocalStorage";

interface TokenData {
  exp: number;
}
export type GetToken = () => Promise<{
  token: Token;
  impersonation?: {
    adminSecret: string;
    userId: string;
    role: string;
    orgIds: string[];
  } | null;
}>;

const useGetToken = (): GetToken => {
  const dispatch = useAppDispatch();
  const logout = useLogout();
  const loggedIn = useLoggedIn();

  const getToken = async () => {
    const token = safeLocalStorage.getItem("token") || "";
    const impersonation = JSON.parse(
      safeLocalStorage.getItem("impersonation") || "null"
    );

    if (process.env.NODE_ENV === "development") {
      // DEBUG
      // console.log(">>>>>>>>", token)
    }

    if (!loggedIn) {
      return { token: "" };
    }

    try {
      const data = jwt.decode(token) as TokenData;

      if (data) {
        const expires = new Date(data.exp * 1000);

        if (expires > new Date()) {
          return { token, impersonation };
        }
      }

      const { token: newToken } = await refreshToken(5);

      dispatch({
        type: "@@session/TOKEN_REFRESH",
        payload: { token: newToken },
      });

      errorService.addExtras({ token, impersonation });

      return { token: newToken, impersonation };
    } catch (error) {
      const message =
        error instanceof Error ? error.message : "An unknown error occurred";

      if (message === REFRESH_TOKEN_INVALID) {
        logout({ reason: "JWT_EXPIRED" });
      } else if (message === REFRESH_TOKEN_CONNECTION_FAILED) {
        errorService.error(error);
      } else {
        errorService.error(error);
      }

      throw new Error("NO_TOKEN");
    }
  };

  return getToken;
};

export default useGetToken;

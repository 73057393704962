import React from "react";
import SingleAutocompleteField, {
  FieldOption,
  FieldOptionsByValue,
} from "./field/SingleAutocompleteField";
import countries from "../constants/countries.json";

export type CountryCode = string;
export interface Country {
  name: string;
  code: CountryCode;
}
export const countryToFieldOption = (country: Country): FieldOption => ({
  value: country.code,
  label: country.name,
});

export const countryFieldOptions = (countries as Array<Country>).map(
  countryToFieldOption,
);

const fieldOptionsByValue = countryFieldOptions.reduce((acc, cur) => {
  acc[cur.value] = cur;

  return acc;
}, {} as FieldOptionsByValue);

const getFieldOptionFromCode = (value?: CountryCode) => {
  if (!value) {
    return undefined;
  }
  const country = fieldOptionsByValue[value];

  if (!country) {
    return undefined;
  }
  return country;
};

export const getCountryNameByCode = (code?: CountryCode | null) => {
  if (!code) {
    return "";
  }
  const country = getFieldOptionFromCode(code);

  if (!country) {
    console.error(
      `Error in CountrySelect.tsx getCountryNameByCode: ${code} not found`,
    );

    return "";
  }

  return country.label;
};

interface CountrySelectProps {
  value?: CountryCode;
  label: string;
  name: string;
  onSelect: (countryCode: CountryCode) => void;
}

const CountrySelect: React.FC<CountrySelectProps> = (props) => {
  const { value, label, name, onSelect } = props;

  return (
    <SingleAutocompleteField
      label={label}
      name={name}
      selected={getFieldOptionFromCode(value)}
      options={countryFieldOptions}
      onSelect={(option) => {
        if (!option) {
          return onSelect("");
        }
        onSelect(option.value);
      }}
    />
  );
};

export default CountrySelect;

import { graphql } from "../generated";

const FETCH_PROJECT= graphql(`
  query FetchProject($projectSlug: String!) {
    project(where: { slug: { _eq: $projectSlug } }) {
      id
      name
      slug
      programme_id
      programme {
        id
        name
        slug
      }
      categories {
        id
        name
      }
      organisation_id
    }
  }
`);

export default FETCH_PROJECT;

import { graphql } from "../generated";

const CREATE_PROJECT_CATEGORY= graphql(`
  mutation CreateProjectCategory(
    $project_category: project_category_insert_input!
  ) {
    insert_project_category(objects: [$project_category]) {
      returning {
        id
        project_id
        name
      }
    }
  }
`);

export default CREATE_PROJECT_CATEGORY;

import React, { useEffect } from "react";
import useLocations from "../hooks/useLocations";
import { LocationId } from "../context/types";
import MultiAutocompleteField from "./field/MultiAutocompleteField";
import truthy from "../helpers/truthy";

interface LocationMultiSelectProps {
  label?: string;
  name?: string;
  locationIds?: LocationId[];
  setLocationIds: (locationIds: LocationId[]) => void;
}
const LocationMultiSelect: React.FC<LocationMultiSelectProps> = (props) => {
  const {
    name = "location",
    label = "Location",
    locationIds = [],
    setLocationIds,
  } = props;
  const { locations } = useLocations();

  const options = locations.map((location) => ({
    value: location.id,
    label: location.name,
  }));

  const currentSelection = locationIds
    .map((id) => options.find((option) => option.value === id))
    .filter(truthy);

  const invalidLocationIds = locationIds.filter(
    (locationId) =>
      !currentSelection.some((selected) => selected.value === locationId),
  );
  useEffect(() => {
    if (invalidLocationIds.length > 0) {
      /**
       * Ensure that the selected ids (from parent) are in sync with:
       * 1. available options, therefore
       * 2. what the user sees
       */
      setLocationIds(currentSelection.map((selected) => selected.value));
    }
  }, [invalidLocationIds.length]);

  return (
    <MultiAutocompleteField
      label={label}
      name={name}
      options={options}
      selected={currentSelection}
      onSelect={(selected) =>
        setLocationIds(selected.map((prog) => prog.value))
      }
    />
  );
};

export default LocationMultiSelect;

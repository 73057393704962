import { nanoid } from "nanoid";
import { RequestStatus, LogoutReason, User } from "./types";
import { saveState, loadState } from "../localStorage";
import safeLocalStorage from "../common-lib/safeLocalStorage";
import errorService from "@/common-lib/error";

export interface AppState {
  logoutStatus: RequestStatus | string;
  logoutReason?: LogoutReason;
  user?: User;
  sessionId?: string;
}

const persistedState = loadState();

const emptyState = {
  logoutStatus: "NOT_ASKED",
  logoutReason: undefined,
  user: undefined,
  sessionId: undefined,
};

export const initialState: AppState = persistedState || emptyState;

export type Action =
  | { type: "@@session/LOGIN_START" }
  | { type: "@@session/LOGIN_SUCCESS"; payload: { user: User; token: string } }
  | { type: "@@session/TOKEN_REFRESH"; payload: { token: string } }
  | { type: "@@session/LOGOUT_START"; payload: { reason: LogoutReason } }
  | { type: "@@session/LOGOUT_FAIL" }
  | { type: "@@session/LOGOUT_SUCCESS" };

export const reducer = (state: AppState, action: Action): AppState => {
  let newState = { ...state };

  switch (action.type) {
    case "@@session/LOGIN_START": {
      newState = emptyState;
      break;
    }
    case "@@session/LOGIN_SUCCESS": {
      const sessionId = nanoid();
      safeLocalStorage.setItem("token", action.payload.token);
      // Set user in sentry
      errorService.setUser(action.payload.user);

      newState = {
        ...state,
        user: action.payload.user,
        sessionId,
      };
      break;
    }

    case "@@session/TOKEN_REFRESH": {
      safeLocalStorage.setItem("token", action.payload.token);

      break;
    }
    case "@@session/LOGOUT_SUCCESS": {
      safeLocalStorage.removeItem("token");
      // Remove user from sentry
      errorService.setUser(null);

      newState = {
        ...emptyState,
        logoutReason: state.logoutReason,
        logoutStatus: "SUCCESS",
      };
      break;
    }
    case "@@session/LOGOUT_FAIL": {
      newState = {
        ...emptyState,
        logoutReason: state.logoutReason,
        logoutStatus: "FAIL",
      };
      break;
    }
    case "@@session/LOGOUT_START": {
      newState = {
        ...emptyState,
        logoutReason: action.payload.reason,
        logoutStatus: "LOADING",
      };
      break;
    }
    default: {
      newState = {
        ...state,
      };
      break;
    }
  }

  // No need to throttle as actions are infrequent
  saveState(newState as AppState);

  return newState;
};

import { graphql } from "../generated";

const DELETE_ORGANISATION_SILOED_USER= graphql(`
  mutation DeleteOrganisationSiloedUser(
    $user_id: uuid!
    $organisation_id: uuid!
  ) {
    delete_organisation_siloed_user(
      where: {
        organisation_id: { _eq: $organisation_id }
        user_id: { _eq: $user_id }
      }
    ) {
      returning {
        organisation_id
        user_id
      }
    }
  }
`);

export default DELETE_ORGANISATION_SILOED_USER;

import React, { ChangeEventHandler } from "react";
import styled from "styled-components";
import colours from "../constants/colours";
import Icon from "./Icon";
import IconButton from "./IconButton";

import Spinner from "./LoadingSpinner";

const FileInputContainer = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0;
  cursor: pointer;
  &:focus-within {
    outline: 2px solid rgba(0, 169, 164, 0.6);
  }
`;

const IconWrapper = styled.div`
  position: absolute;
`;

interface FileInputProps {
  id: string;
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  loading?: boolean;
}
const FileInput: React.FC<FileInputProps> = (props) => {
  const { id, onChange, loading, label } = props;
  const inputId = `file-upload-${id}`;
  return (
    <>
      <input hidden id={inputId} type="file" onChange={onChange} />
      <IconButton
        colour="blue"
        title={label}
        disabled={loading}
        loading={loading}
        onClick={() => document?.getElementById(inputId)?.click()}
        icon="file_upload"
      />
    </>
  );
};

export default FileInput;

import { graphql } from "../generated";

const FETCH_PARTICIPANTS_BY_EMAIL = graphql(`
  query FetchParticipantsByEmail($email: String!, $excludeId: uuid) {
    participant(
      where: {
        sensitive_fields: { email: { _eq: $email } }
        _and: { id: { _neq: $excludeId } }
      }
    ) {
      id
      sensitive_fields {
        first_name
        last_name
      }
    }
  }
`);

export default FETCH_PARTICIPANTS_BY_EMAIL;

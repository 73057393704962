import React, { Fragment, useEffect } from "react";
import { withRouter, useParams, Link } from "react-router-dom";
import { nanoid } from "nanoid";
import uniqBy from "lodash/uniqBy";

import { useQuery, useMutation } from "@apollo/client";
import FETCH_PARTICIPANT_BY_ID from "../graphql/queries/fetch-participant-by-id";
import errorService from "../common-lib/error";
import LoadingSpinner from "./LoadingSpinner";
import Button from "./Button";
import ParticipantForm from "./ParticipantForm";
import { getAge } from "../helpers/participant";
import FETCH_REGISTER_PARTICIPANT_NOTES from "../graphql/queries/fetch-register-participant-notes";
import { getDatetime } from "../helpers/register";
import { commaSeparatedList } from "../helpers/util";
import ParticipantsSearchDialog from "./ParticipantsSearchDialog";
import CREATE_PARENT_CHILD from "../graphql/queries/create-parent-child";
import DELETE_PARENT_CHILD from "../graphql/queries/delete-parent-child";
import CREATE_PARTICIPANT_SIBLING from "../graphql/queries/create-participant-sibling";
import DELETE_PARTICIPANT_SIBLING from "../graphql/queries/delete-participant-sibling";
import Avatar from "./Avatar";
import IconButton from "./IconButton";
import Icon from "./Icon";
import { getCountryNameByCode } from "./CountrySelect";
import styled from "styled-components";
import colours from "../constants/colours";
import { getFullName } from "../helpers/user";
import { getParticipantPath, getRegisterPath } from "../helpers/route-path";
import useSessionProgramme from "../hooks/useSessionProgramme";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import ErrorMessage from "./ErrorMessage";
import Tooltip from "./Tooltip";
import ParticipantFile from "./ParticipantFile";
import { format } from "date-fns";

function SensitiveFields({
  sensitive_fields,
  render,
}: {
  sensitive_fields?: {
    first_name: string;
    last_name: string;
    email?: string | null;
    phone?: string | null;
    emergency_contact_name?: string | null;
    emergency_contact_detail?: string | null;
    notes?: string | null;
  } | null;
  render: (sensitive_fields: {
    first_name: string;
    last_name: string;
    email?: string | null;
    phone?: string | null;
    emergency_contact_name?: string | null;
    emergency_contact_detail?: string | null;
    notes?: string | null;
  }) => React.ReactNode;
}) {
  if (!sensitive_fields) {
    return <p>You don't have the permission to view these details.</p>;
  }

  return render(sensitive_fields);
}

const Notes = styled.div`
  white-space: pre-line;
`;
interface ParticipantProjectSummary {
  project: {
    name: string;
    slug: string;
    id: string;
  };
  attendances: { date: string; time: string; id: string }[];
}
const fileid = nanoid();

const SiblingByAssociationText = styled.span`
  color: ${colours.textGrey};
  margin-left: 0.5rem;
  font-style: italic;
`;

const ParticipantsParticipant: React.FC = () => {
  const { participantId = "" } = useParams<{ participantId?: string }>();

  const programme = useSessionProgramme();
  const organisation = useSessionOrganisation();
  const { loading, error, data, refetch } = useQuery(FETCH_PARTICIPANT_BY_ID, {
    variables: { id: participantId },
  });

  useEffect(() => {
    refetch();
  }, [participantId]);

  const notes = useQuery(FETCH_REGISTER_PARTICIPANT_NOTES, {
    variables: { participantId },
  });

  if (error) {
    errorService.error(error);
  }

  const participant = data ? data.participant_by_pk : undefined;

  const [addParticipantSibling] = useMutation(CREATE_PARTICIPANT_SIBLING, {
    onCompleted: () => refetch(),
  });
  const [deleteParticipantSibling] = useMutation(DELETE_PARTICIPANT_SIBLING, {
    onCompleted: () => refetch(),
  });

  const [addParentChild] = useMutation(CREATE_PARENT_CHILD, {
    onCompleted: () => refetch(),
  });
  const [deleteParentChild] = useMutation(DELETE_PARENT_CHILD, {
    onCompleted: () => refetch(),
  });

  const excludeIds = [
    participantId,
    ...(participant ? participant.children.map(({ child }) => child.id) : []),
    ...(participant ? participant.parents.map(({ parent }) => parent.id) : []),
  ];

  const siblings = participant
    ? uniqBy(
        participant.parents
          .reduce(
            (out, { parent }) => {
              // @ts-ignore
              return out.concat(parent.children);
            },
            [] as {
              id: any;
              child: {
                id: any;
                sensitive_fields: {
                  first_name: string;
                  last_name: string;
                };
              };
            }[]
          )
          .filter(({ child }) => child.id !== participant.id)
          .map(({ child }) => ({ ...child, byAssociation: true }))
          .concat(
            // @ts-ignore
            participant.siblings.map(({ sibling }) => ({
              ...sibling,
              byAssociation: false,
            }))
          ),
        ({ id, byAssociation }) => `${id}-${byAssociation}`
      )
    : [];

  const participantProjects: ParticipantProjectSummary[] = participant
    ? participant.register_participants.reduce((accum, { register }) => {
        // TODO: find a way to fix this issue (ideally in gql query we can filter not_null projects)
        if (!register.project) {
          return accum;
        }

        if (
          accum.find((summary) => summary.project.id === register.project.id)
        ) {
          return accum.map((summary) => {
            return summary.project.id === register.project.id
              ? {
                  ...summary,
                  attendances: [
                    ...summary.attendances,
                    {
                      date: register.date,
                      time: register.time,
                      id: register.id,
                    },
                  ],
                }
              : summary;
          });
        }

        return [
          ...accum,
          {
            project: register.project,
            attendances: [
              { date: register.date, time: register.time, id: register.id },
            ],
          },
        ];
      }, [] as ParticipantProjectSummary[])
    : [];

  if (!programme || !organisation) {
    return null;
  }

  const fullName = getFullName(participant?.sensitive_fields);

  return (
    <div className="relative flex-auto">
      {loading && <LoadingSpinner type="overlay" />}
      {participant && (
        <Fragment>
          <header className="flex justify-between items-center mb3">
            <h1>{getFullName(participant.sensitive_fields)}</h1>
            {/* @todo verify that we don't need merge participants any more */}
            {/* <MergeParticipantsDialog
              participantToDeleteId={participantId}
              Opener={(props) => (
                <div className="mla mr2">
                  <Button {...props} colour="red" raised fill>
                    Merge Participant
                  </Button>
                </div>
              )}
            /> */}
            <ParticipantForm
              id={participantId}
              initialValues={participant}
              onClose={refetch}
              Opener={(props) => (
                <Button {...props} raised fill>
                  Edit Participant
                </Button>
              )}
            />
          </header>
          <h2>Contact Details</h2>
          {SensitiveFields({
            sensitive_fields: participant.sensitive_fields,
            render: (sensitive_fields) => (
              <>
                {!sensitive_fields.phone && !sensitive_fields.email && (
                  <p>
                    There are currently no contact details for{" "}
                    {getFullName(sensitive_fields)} on the system.
                  </p>
                )}
                {sensitive_fields.phone && (
                  <a
                    className="flex items-center mv2"
                    href={`tel:${sensitive_fields.phone}`}
                  >
                    <Icon icon="phone" text={sensitive_fields.phone} />
                  </a>
                )}
                {sensitive_fields.email && (
                  <a
                    className="flex items-center mv2"
                    href={`mailto:${sensitive_fields.email}`}
                  >
                    <Icon icon="email" text={sensitive_fields.email} />
                  </a>
                )}
              </>
            ),
          })}

          <h2>Languages</h2>
          <div className="flex items-center mv2">
            <Icon
              icon="language"
              text={commaSeparatedList(
                participant.participant_languages,
                (el) => `${el.language.name}`,
                (el) => `${participant.id}${el.language.code}`,
                <span>
                  We don't know which languages{" "}
                  {getFullName(participant.sensitive_fields)} speaks
                </span>
              )}
            />
          </div>
          <h2>Origin</h2>
          <Icon
            icon="terrain"
            text={
              getCountryNameByCode(participant.country_code) ||
              `We don't know where ${participant.sensitive_fields?.first_name} is from.`
            }
          />
          <h2>Birth Day</h2>
          {!participant.date_of_birth && (
            <ErrorMessage>
              <Icon
                icon="info"
                text={`We currently have no date of birth for ${getFullName(
                  participant.sensitive_fields
                )}`}
              />
            </ErrorMessage>
          )}
          {participant.date_of_birth && (
            <Fragment>
              <Icon
                icon="age"
                text={`${format(
                  new Date(participant.date_of_birth),
                  "dd/MM/yyyy"
                )} (${getAge(participant)})`}
              />
              {participant.date_of_birth_accuracy === "guess" && (
                <ErrorMessage>
                  <Icon
                    icon="info"
                    text={`This Date of Birth is a guess based on Child/Adult status. Please update with a more accurate value`}
                  />
                </ErrorMessage>
              )}
            </Fragment>
          )}
          <h2>Notes</h2>
          {SensitiveFields({
            sensitive_fields: participant.sensitive_fields,
            render: (sensitive_fields) => {
              return !sensitive_fields.notes ? (
                <p className="mv2">
                  We currently have no notes for {fullName} on the system
                </p>
              ) : (
                <Notes>{sensitive_fields.notes}</Notes>
              );
            },
          })}
          {participant.referral_organisation && (
            <>
              <h2>Referral Organisation</h2>
              <p className="mv2">{participant.referral_organisation}</p>
            </>
          )}
          <hr />
          <h2>Other</h2>
          <ul>
            <li>
              Is a refugee:{" "}
              {participant.is_refugee === true
                ? "True"
                : participant.is_refugee === false
                  ? "False"
                  : "Unknown/prefers not to say"}
            </li>
            <li>
              Is a caregiver:{" "}
              {participant.is_caregiver === true
                ? "True"
                : participant.is_caregiver === false
                  ? "False"
                  : "Unknown/prefers not to say"}
            </li>
            <li>
              Has a disability:{" "}
              {participant.has_disability === true
                ? "True"
                : participant.has_disability === false
                  ? "False"
                  : "Unknown/prefers not to say"}
            </li>
          </ul>
          <hr />
          <h2>Emergency Contact</h2>
          {SensitiveFields({
            sensitive_fields: participant.sensitive_fields,
            render: (sensitive_fields) => {
              return (
                <>
                  <p className="mv2">
                    {sensitive_fields.emergency_contact_name}
                  </p>
                  <p className="mv2">
                    {sensitive_fields.emergency_contact_detail}
                  </p>
                  {!sensitive_fields.emergency_contact_name &&
                    !sensitive_fields.emergency_contact_detail && (
                      <p className="mv2">No emergency contact information</p>
                    )}
                </>
              );
            },
          })}
          <hr />
          <h2>Family</h2>
          <div className="relative">
            {participant._temp_child_names && (
              <Tooltip text="This information might not yet in the database. Please add these children below.">
                <ErrorMessage>
                  <Icon
                    icon="info"
                    text={`Children: ${participant._temp_child_names}`}
                  />
                </ErrorMessage>
              </Tooltip>
            )}{" "}
            {participant._temp_parent_names && (
              <Tooltip text="This information might not yet in the database. Please add parents below.">
                <ErrorMessage>
                  <Icon
                    icon="info"
                    text={`Parents: ${participant._temp_parent_names}`}
                  />
                </ErrorMessage>
              </Tooltip>
            )}
          </div>
          <h3 className="flex">
            Children
            <ParticipantsSearchDialog
              title={`Add a child for ${fullName}`}
              excludeIds={excludeIds}
              Opener={(props) => (
                <IconButton
                  {...props}
                  icon="add_circle"
                  colour="blue"
                  size="small"
                  className="ml1"
                  title={`Connect ${fullName} with their child`}
                />
              )}
              onSelect={(value) =>
                addParentChild({
                  variables: {
                    parent_child: {
                      parent_id: participantId,
                      child_id: value.id,
                    },
                  },
                })
              }
            />
          </h3>
          <ul>
            {participant.children.map(({ child }) => {
              return (
                <li
                  className="flex items-center"
                  key={`${fileid}-participant-children-${child.id}`}
                >
                  <Avatar person={child} size="small" className="mr2 mb1" />
                  <Link
                    to={getParticipantPath({
                      organisationSlug: organisation.slug,
                      programmeSlug: programme.slug,
                      participantId: child.id,
                    })}
                  >
                    {getFullName(child.sensitive_fields)}
                  </Link>{" "}
                  <IconButton
                    icon="remove_circle"
                    colour="red"
                    className="ml2"
                    size="small"
                    onClick={() =>
                      deleteParentChild({
                        variables: {
                          parent_id: participantId,
                          child_id: child.id,
                        },
                      })
                    }
                    title="Remove this relationship"
                  />
                </li>
              );
            })}
          </ul>
          <h3 className="flex">
            Parents
            <ParticipantsSearchDialog
              title={`Add a parent for ${fullName}`}
              excludeIds={excludeIds}
              Opener={(props) => (
                <IconButton
                  {...props}
                  icon="add_circle"
                  colour="blue"
                  className="ml1"
                  size="small"
                  title={`Connect ${fullName} with their parent`}
                />
              )}
              onSelect={(value) =>
                addParentChild({
                  variables: {
                    parent_child: {
                      parent_id: value.id,
                      child_id: participantId,
                    },
                  },
                })
              }
            />
          </h3>
          <ul>
            {participant.parents.map(({ parent }) => {
              return (
                <li
                  className="flex items-center"
                  key={`${fileid}-participant-parents-${parent.id}`}
                >
                  <Avatar person={parent} size="small" className="mr2 mb1" />
                  <Link
                    to={getParticipantPath({
                      organisationSlug: organisation.slug,
                      programmeSlug: programme.slug,
                      participantId: parent.id,
                    })}
                  >
                    {getFullName(parent.sensitive_fields)}
                  </Link>
                  <IconButton
                    icon="remove_circle"
                    colour="red"
                    className="ml2"
                    size="small"
                    onClick={() =>
                      deleteParentChild({
                        variables: {
                          parent_id: parent.id,
                          child_id: participantId,
                        },
                      })
                    }
                    title="Remove this relationship"
                  />
                </li>
              );
            })}
          </ul>
          <h3 className="flex">
            Siblings
            <ParticipantsSearchDialog
              title={`Add a sibling for ${fullName}`}
              excludeIds={[
                participantId,
                ...siblings.map((sibling) => sibling.id),
              ]}
              Opener={(props) => (
                <IconButton
                  {...props}
                  icon="add_circle"
                  colour="blue"
                  className="ml1"
                  size="small"
                  title={`Connect ${fullName} with their brother/sister`}
                />
              )}
              onSelect={(value) => {
                addParticipantSibling({
                  variables: {
                    participant_sibling: {
                      participant_id: participantId,
                      sibling_id: value.id,
                    },
                  },
                });
                addParticipantSibling({
                  variables: {
                    participant_sibling: {
                      participant_id: value.id,
                      sibling_id: participantId,
                    },
                  },
                });
              }}
            />
          </h3>
          <ul>
            {siblings.map((sibling, i) => {
              return (
                <li
                  className="flex items-center"
                  key={`${fileid}-participant-siblings-${sibling.id}-${i}`}
                >
                  <Avatar person={sibling} size="small" className="mr2 mb1" />
                  <Link
                    to={getParticipantPath({
                      organisationSlug: organisation.slug,
                      programmeSlug: programme.slug,
                      participantId: sibling.id,
                    })}
                  >
                    {getFullName(sibling.sensitive_fields)}
                  </Link>
                  {sibling.byAssociation ? (
                    <SiblingByAssociationText>
                      (shared parent)
                    </SiblingByAssociationText>
                  ) : (
                    <IconButton
                      icon="remove_circle"
                      colour="red"
                      className="ml2"
                      size="small"
                      onClick={() => {
                        deleteParticipantSibling({
                          variables: {
                            participant_id: participantId,
                            sibling_id: sibling.id,
                          },
                        });
                        deleteParticipantSibling({
                          variables: {
                            participant_id: sibling.id,
                            sibling_id: participantId,
                          },
                        });
                      }}
                      title="Remove this relationship"
                    />
                  )}
                </li>
              );
            })}
          </ul>
          <hr />
          <h2>Participant Files</h2>
          {[
            {
              label: "GDPR Consent Form",
              purpose: "gdpr",
            },
            {
              label: "Assessment Form",
              purpose: "assessment-form",
            },
            {
              label: "Referral Organisation MoU",
              purpose: "referral-organisation-mou",
            },
          ].map((fileConfig) => (
            <ParticipantFile
              key={`ParticipantsParticipant-ParticipantFile-${fileConfig.purpose}`}
              id={`participant-files-default-${fileConfig.purpose}`}
              {...fileConfig}
              participant={participant}
              onMutate={refetch}
            />
          ))}
          <hr />
          <h2>Projects Attended</h2>
          {participantProjects.length === 0
            ? `
            ${fullName} hasn't attended any projects yet.
          `
            : `${getFullName(
                participant.sensitive_fields
              )} has attended the following projects:`}
          {participantProjects.map(({ project, attendances }) => {
            return (
              <div key={`${fileid}-Projects-${project.id}`}>
                <h3>
                  <Icon className="mv3" icon="spa" text={project.name} />
                </h3>
                <Link
                  to={getRegisterPath({
                    organisationSlug: organisation.slug,
                    programmeSlug: programme.slug,
                    registerId: attendances[0].id,
                  })}
                >
                  Last attendance:{" "}
                  {format(
                    getDatetime(attendances[0]),
                    "iiii do MMM, h:mm aaaa"
                  )}
                </Link>
                <br />
                Total attended: {attendances.length}
              </div>
            );
          })}
          <h2>Notes from Projects</h2>

          {notes.data?.register_participant_sensitive_fields === null ? (
            <p>REDACTED</p>
          ) : (
            <>
              {notes.data &&
                notes.data.register_participant_sensitive_fields.length === 0 &&
                `
            There are no notes from any of ${getFullName(
              participant.sensitive_fields
            )}'s projects on the system.
          `}
              {notes.data &&
                notes.data.register_participant_sensitive_fields.map((note) => {
                  return (
                    <div
                      key={`${fileid}-participant-notes-${note.register.id}`}
                    >
                      <Link
                        to={getRegisterPath({
                          organisationSlug: organisation.slug,
                          programmeSlug: note.register.project.programme.slug,
                          registerId: note.register.id,
                        })}
                      >
                        {note.register.project.name},{" "}
                        {note.register.location.name},{" "}
                        {format(
                          getDatetime(note.register),
                          "iiii do MMM, h:mm aaaa"
                        )}
                      </Link>
                      <br />
                      <em className="ml2">{note.notes}</em>
                    </div>
                  );
                })}
            </>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default withRouter(ParticipantsParticipant);

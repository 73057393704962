import React, {
  FormEvent,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";

import FETCH_PROJECT from "../graphql/queries/fetch-project";
import LoadingSpinner from "./LoadingSpinner";
import ProjectForm, { ProjectFormOnSuccessArgs } from "./ProjectForm";
import Button from "./Button";
import { getAdminProjectPath } from "../helpers/route-path";
import TextField from "./field/TextField";
import colours from "../constants/colours";
import CREATE_PROJECT_CATEGORY from "../graphql/queries/create-project-category";
import ErrorMessage from "./ErrorMessage";
import IconButton from "./IconButton";
import DialogTextInput from "./DialogTextInput";
import UPDATE_PROJECT_CATEGORY from "../graphql/queries/update-project-category";
import DELETE_PROJECT_CATEGORY from "../graphql/queries/delete-project-category";
import REGISTER_AGGREGATE from "../graphql/queries/register-aggregate";
import ConfirmDialog from "./ConfirmDialog";

const Categories = styled.div`
  max-width: 600px;
`;
const CategoriesLi = styled.li`
  border-bottom: 1px solid ${colours.borderGrey};
`;

interface ProjectCategoryProps {
  onUpdateOrDelete: () => void;
  category: {
    id: string;
    name: string;
  };
}
const ProjectCategory: React.FC<ProjectCategoryProps> = (props) => {
  const { onUpdateOrDelete, category } = props;
  const [
    getRegisterAggregate,
    { data: aggregateData, loading: aggregateLoading },
  ] = useLazyQuery(REGISTER_AGGREGATE, {
    variables: {
      dynamicWhere: {
        register_project_categories: {
          project_category_id: { _eq: category.id },
        },
      },
    },
  });
  const [updateProjectCategory] = useMutation(UPDATE_PROJECT_CATEGORY, {
    onCompleted: () => {
      onUpdateOrDelete();
    },
  });
  const [deleteProjectCategory] = useMutation(DELETE_PROJECT_CATEGORY, {
    onCompleted: () => {
      onUpdateOrDelete();
    },
  });
  const deleteCategory = useCallback(
    async (id: string) => {
      await deleteProjectCategory({ variables: { id } });
    },
    [deleteProjectCategory]
  );
  const updateCategory = useCallback(
    async (id: string, name: string) => {
      await updateProjectCategory({
        variables: {
          id,
          project_category: { name },
        },
      });
    },
    [updateProjectCategory]
  );

  const linkedRegisterCount =
    aggregateData?.register_aggregate.aggregate?.count || 0;

  const deleteMessage = `This category will also be removed from ${linkedRegisterCount} register${
    linkedRegisterCount === 1 ? "" : "s"
  }.`;

  return (
    <CategoriesLi className="flex justify-between items-center pv2">
      <div>{category.name}</div>
      <div>
        <DialogTextInput
          title="Category Name"
          onSave={(value) => updateCategory(category.id, value)}
          initialValue={category.name}
        />
        <ConfirmDialog
          title="Delete Category"
          message={deleteMessage}
          onConfirm={() => deleteCategory(category.id)}
          onOpen={getRegisterAggregate}
          Controller={(props) => <IconButton icon="delete" {...props} />}
          loading={aggregateLoading}
          confirmButtonText={"Delete"}
        />
      </div>
    </CategoriesLi>
  );
};

const AdminProjectsProject: React.FC = () => {
  const { projectSlug = "" } = useParams<{ projectSlug: string | undefined }>();
  const [categoryName, setCategoryName] = useState("");

  const { loading, error, data, refetch } = useQuery(FETCH_PROJECT, {
    variables: { projectSlug },
  });

  const project = data ? data.project[0] : undefined;

  const notFound = !loading && !error && !project;

  const history = useHistory();

  useEffect(() => {
    refetch();
  }, []);

  const [addProjectCategory, addProjectCategoryResult] = useMutation(
    CREATE_PROJECT_CATEGORY,
    {
      onCompleted: () => {
        refetch();
        setCategoryName("");
      },
    }
  );

  const newCategoryFormOnSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (project) {
        addProjectCategory({
          variables: {
            project_category: { project_id: project.id, name: categoryName },
          },
        });
      }
    },
    [categoryName, project, addProjectCategory]
  );

  return (
    <div>
      {loading && <LoadingSpinner type="overlay" />}
      {project && (
        <Fragment>
          <header className="flex justify-between items-center mb3">
            <h1>{project.name}</h1>
            <ProjectForm
              onClose={refetch}
              initialValues={project}
              Opener={(props) => (
                <Button {...props} raised fill>
                  Edit Project
                </Button>
              )}
              onSuccess={({ slug }: ProjectFormOnSuccessArgs) => {
                if (slug !== project.slug) {
                  history.replace(getAdminProjectPath({ projectSlug: slug }));
                }
              }}
            />
          </header>
          <Categories>
            <h2 className="mb3">Categories</h2>

            <ul>
              {project.categories.map((category) => (
                <ProjectCategory
                  key={`AdminProjectsProject-ProjectCategory-${category.id}`}
                  onUpdateOrDelete={refetch}
                  category={category}
                />
              ))}
            </ul>
            <form onSubmit={newCategoryFormOnSubmit}>
              <TextField
                value={categoryName}
                onChange={setCategoryName}
                name={"new-project-category"}
                label={"Add a new category (start typing and hit enter)"}
              />
              {addProjectCategoryResult.error && (
                <ErrorMessage className="mt2">
                  There was an error adding this category, does it already
                  exist?
                </ErrorMessage>
              )}
            </form>
          </Categories>
        </Fragment>
      )}
      {notFound && <p>Project Not Found</p>}
      {error && <p>{error.message}</p>}
    </div>
  );
};

export default AdminProjectsProject;

import React, { Fragment, useState, SyntheticEvent, useEffect } from "react";
import * as yup from "yup";
import { nanoid } from "nanoid";

import Dialog from "./Dialog";
import FlatButton from "./FlatButton";
import TextField from "./field/TextField";
import SelectField from "./field/SelectField";
import CountrySelect from "./CountrySelect";
import LanguagesSelect from "./LanguagesSelect";
import TextareaField from "./field/TextareaField";
import { useMutation, useQuery } from "@apollo/client";
import UPDATE_PARTICIPANT from "../graphql/queries/update-participant";
import CREATE_PARTICIPANT from "../graphql/queries/create-participant";
import PhoneNumber from "./field/PhoneNumber";
import ErrorMessage from "./ErrorMessage";
import countries from "../constants/countries.json";
import FETCH_PARTICIPANTS_BY_EMAIL from "../graphql/queries/fetch-participants-by-email";
import { getFullName } from "../helpers/user";
import FETCH_PARTICIPANTS_BY_PHONE from "../graphql/queries/fetch-participants-by-phone";
import Checkbox from "./field/Checkbox";
import {
  ParticipantId,
  SEX,
  DATE_OF_BIRTH_ACCURACY,
  dateOfBirthAccuracyText,
} from "../context/types";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import useSessionProgramme from "../hooks/useSessionProgramme";
import useSelf from "../hooks/useSelf";
import errorService from "../common-lib/error";
import { unknownErrorMessage } from "../constants/errors";
import {
  CreateParticipantMutationVariables,
  FetchParticipantByIdQuery,
} from "@/graphql/generated/graphql";
import { DialogActions } from "./DialogActions";
import { BooleanSelect } from "./field/BooleanSelect";

type Participant = NonNullable<FetchParticipantByIdQuery["participant_by_pk"]>;

const COUNTRY_CODES = countries.map((country) => country.code);
const schema = yup.object().shape({
  sensitive_fields: yup.object().shape({
    data: yup.object().shape({
      first_name: yup.string().required().label("First Name"),
      last_name: yup.string().required().label("Last Name"),
      phone: yup.string(),
      email: yup.string().email(),
      emergency_contact_name: yup
        .string()
        .nullable()
        .label("Emergency Contact Name"),
      emergency_contact_detail: yup
        .string()
        .nullable()
        .label("Emergency Contact Detail"),
      notes: yup.string(),
    }),
  }),
  sex: yup
    .string()
    .oneOf(SEX as any)
    .required()
    .label("Gender"),
  date_of_birth: yup
    .date()
    .transform((value, originalValue) => {
      if (isNaN(value.getTime())) {
        return undefined;
      }

      return value;
    })
    .required()
    .label("Date of Birth"),
  date_of_birth_accuracy: yup.string(),
  country_code: yup
    .string()
    .oneOf(COUNTRY_CODES)
    .transform((value) => value || undefined),
  preferred_contact_method: yup.string(),
  is_refugee: yup.boolean().nullable().label("Refugee"),
  is_caregiver: yup.boolean().nullable().defined().label("Caregiver"),
  has_disability: yup.boolean().nullable().defined().label("Has Disability"),
  referral_organisation: yup.string().nullable().label("Referral Organisation"),
});

interface OpenerProps {
  onClick: () => void;
}
interface Props {
  id?: ParticipantId;
  initialValues?: Participant;
  Opener: React.FC<OpenerProps>;
  onClose: () => void;
  onCreate?: (id: ParticipantId | undefined) => void;
}
const ParticipantForm: React.FC<Props> = (props) => {
  const {
    id,
    initialValues = {
      sensitive_fields: {},
    } as Participant,
    Opener,
    onClose,
    onCreate,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState(
    initialValues.sensitive_fields?.first_name || ""
  );
  const [lastName, setLastName] = useState(
    initialValues.sensitive_fields?.last_name || ""
  );
  const [sex, setSex] = useState(initialValues.sex || "");
  const [dateOfBirth, setDateOfBirth] = useState(
    initialValues.date_of_birth || ""
  );
  const [countryCode, setCountryCode] = useState(
    initialValues.country_code || ""
  );
  const [email, setEmail] = useState(
    initialValues.sensitive_fields?.email || ""
  );
  const [phone, setPhone] = useState(
    initialValues.sensitive_fields?.phone || ""
  );
  const [notes, setNotes] = useState(
    initialValues.sensitive_fields?.notes || ""
  );
  const [preferredContactMethod, setPreferredContactMethod] = useState(
    initialValues.preferred_contact_method || ""
  );
  const [languages, setLanguages] = useState<Array<string>>(
    initialValues.participant_languages
      ? initialValues.participant_languages.map(({ language }) => language.code)
      : []
  );
  const [dataProtectionSigned, setDataProtectionSigned] = useState(
    initialValues.data_protection_signed || false
  );
  const [dateOfBirthAccuracy, setDateOfBirthAccuracy] = useState(
    initialValues.date_of_birth_accuracy || "estimate"
  );
  const [isRefugee, setIsRefugee] = useState(initialValues.is_refugee);
  const [isCaregiver, setIsCaregiver] = useState(initialValues.is_caregiver);
  const [hasDisability, setHasDisability] = useState(
    initialValues.has_disability
  );

  const [emergencyContactName, setEmergencyContactName] = useState(
    initialValues.sensitive_fields?.emergency_contact_name || ""
  );
  const [emergencyContactDetail, setEmergencyContactDetail] = useState(
    initialValues.sensitive_fields?.emergency_contact_detail || ""
  );
  const [referralOrganisation, setReferralOrganisation] = useState(
    initialValues.referral_organisation || ""
  );

  const [validationError, setValidationError] = useState("");

  const [emailBlurred, setEmailBlurred] = useState(false);
  const [phoneBlurred, setPhoneBlurred] = useState(false);

  const user = useSelf();
  const organisation = useSessionOrganisation();
  const programme = useSessionProgramme();
  const resetForm = () => {
    setFirstName(initialValues.sensitive_fields?.first_name || "");
    setLastName(initialValues.sensitive_fields?.last_name || "");
    setSex(initialValues.sex || "");
    setDateOfBirth(initialValues.date_of_birth || "");
    setCountryCode(initialValues.country_code || "");
    setEmail(initialValues.sensitive_fields?.email || "");
    setPhone(initialValues.sensitive_fields?.phone || "");
    setPreferredContactMethod(initialValues.preferred_contact_method || "");
    setLanguages(
      initialValues.participant_languages
        ? initialValues.participant_languages.map(
            ({ language }) => language.code
          )
        : []
    );
    setDataProtectionSigned(initialValues.data_protection_signed || false);
    setIsRefugee(initialValues.is_refugee);
    setIsCaregiver(initialValues.is_caregiver);
    setHasDisability(initialValues.has_disability);
    setDateOfBirthAccuracy(initialValues.date_of_birth_accuracy || "estimate");
    setNotes(initialValues.sensitive_fields?.notes || "");

    setEmergencyContactName(
      initialValues.sensitive_fields?.emergency_contact_name || ""
    );
    setEmergencyContactDetail(
      initialValues.sensitive_fields?.emergency_contact_detail || ""
    );
    setReferralOrganisation(initialValues.referral_organisation || "");

    setValidationError("");
    setEmailBlurred(false);
    setPhoneBlurred(false);
  };

  const [emailId] = useState(nanoid());

  useEffect(() => {
    resetForm();
  }, [JSON.stringify(initialValues)]);

  const onRequestOpen = () => setIsOpen(true);
  const onRequestClose = () => {
    resetForm();
    setIsOpen(false);
    onClose();
  };
  const [updateParticipant] = useMutation(UPDATE_PARTICIPANT, {
    onCompleted: onRequestClose,
  });
  const [createParticipant] = useMutation(CREATE_PARTICIPANT, {
    onCompleted: (participant) => {
      /**
       * @todo notify user if error occurred
       */
      onRequestClose();
      if (onCreate) {
        onCreate(participant.insert_participant?.returning[0]?.id);
      }
    },
  });

  const shouldCheckEmail = Boolean(emailBlurred && email);
  const { data: emailData } = useQuery(FETCH_PARTICIPANTS_BY_EMAIL, {
    variables: { email, excludeId: id },
    skip: !shouldCheckEmail,
  });
  const emailTakenBy =
    emailData && emailData.participant[0] && emailData.participant[0].id !== id
      ? emailData.participant[0]
      : undefined;

  const shouldCheckPhone = Boolean(phoneBlurred && phone);
  const { data: phoneData } = useQuery(FETCH_PARTICIPANTS_BY_PHONE, {
    variables: { phone, excludeId: id },
    skip: !shouldCheckPhone,
  });
  const phoneTakenBy =
    phoneData && phoneData.participant[0] && phoneData.participant[0].id !== id
      ? phoneData.participant[0]
      : undefined;

  const onRequestSave = async () => {
    if (!organisation || !programme || !user) {
      errorService.error(
        new Error(
          `Attempting to save with the following context: ${JSON.stringify({
            organisation,
            programme,
            user,
          })}`
        )
      );
      return setValidationError(unknownErrorMessage);
    }
    const payload: CreateParticipantMutationVariables["participant"] = {
      sensitive_fields: {
        data: {
          first_name: firstName,
          last_name: lastName,
          phone,
          email,
          notes,
          emergency_contact_name: emergencyContactName,
          emergency_contact_detail: emergencyContactDetail,
        },
      },
      sex,
      date_of_birth: dateOfBirth || undefined,
      date_of_birth_accuracy: dateOfBirthAccuracy,
      country_code: countryCode,
      preferred_contact_method: preferredContactMethod,
      data_protection_signed: dataProtectionSigned,
      is_refugee: isRefugee,
      is_caregiver: isCaregiver,
      has_disability: hasDisability,
      referral_organisation: referralOrganisation,
    };

    try {
      await schema.validate(payload);

      if (id) {
        const sensitive_fields = payload.sensitive_fields?.data;
        delete payload.sensitive_fields;
        return updateParticipant({
          variables: {
            id,
            participant: payload,
            sensitive_fields,
            participant_languages: languages.map((language) => ({
              language_code: language,
              participant_id: id,
            })),
          },
        });
      } else {
        return createParticipant({
          variables: {
            participant: {
              ...payload,
              participant_languages: {
                data: languages.map((language) => ({
                  language_code: language,
                })),
              },
              organisation_participants: {
                data: [{ organisation_id: organisation.id }],
              },
              programme_participants: {
                data: [{ programme_id: programme.id }],
              },
              sensitive_fields: {
                data: payload.sensitive_fields?.data || {},
              },
            },
          },
        });
      }
    } catch (error) {
      const message =
        error instanceof Error ? error.message : "An unknown error occurred";

      if (error instanceof yup.ValidationError) {
        const errorPath = error.path;
        const errorField =
          errorPath?.split(".")[errorPath?.split(".").length - 1];
        const element = document.querySelector(`[name=${errorField}]`);
        if (element && element instanceof HTMLElement) {
          element.focus();
        }
      }

      return setValidationError(message);
    }
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <Dialog
        fullHeight
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={id ? "Edit Participant" : "Add Participant"}
      >
        <form onSubmit={onSubmit} autoComplete="off">
          <input className="clip" />
          <input name="password" type="password" className="clip" />
          <input name="username" className="clip" />

          <TextField
            name="first_name"
            label="First Name"
            value={firstName}
            onChange={setFirstName}
            autoFocus
            required
          />
          <TextField
            name="last_name"
            label="Last Name"
            value={lastName}
            onChange={setLastName}
            required
          />
          <SelectField
            name="sex"
            label="Gender"
            value={sex}
            onChange={setSex}
            options={[
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
              { value: "other", label: "Other" },
            ]}
            required
          />
          <TextField
            name="date_of_birth"
            label="Date of Birth"
            value={dateOfBirth}
            inputProps={{ type: "date" }}
            onChange={setDateOfBirth}
          />
          <SelectField
            name="date_of_birth_accuracy"
            label="Date of Birth Accuracy"
            value={dateOfBirthAccuracy as string}
            onChange={setDateOfBirthAccuracy}
            options={DATE_OF_BIRTH_ACCURACY.map((value) => ({
              value,
              label: dateOfBirthAccuracyText[value],
            }))}
          />
          <CountrySelect
            value={countryCode}
            label="Country"
            name="country"
            onSelect={setCountryCode}
          />
          <BooleanSelect
            name="is_refugee"
            label="Refugee"
            value={isRefugee}
            setValue={setIsRefugee}
          />
          <BooleanSelect
            name="is_caregiver"
            label="Caregiver"
            value={isCaregiver}
            setValue={setIsCaregiver}
            required
            withPreferNotToSay
          />
          <BooleanSelect
            name="has_disability"
            label="Has Disability"
            value={hasDisability}
            setValue={setHasDisability}
            required
            withPreferNotToSay
          />
          <LanguagesSelect
            value={languages}
            label="Languages"
            name="languages"
            onSelect={setLanguages}
          />
          <TextField
            name={emailId}
            label="Email"
            inputProps={{ autoComplete: "new-password" }}
            value={email}
            onChange={setEmail}
            onBlur={() => setEmailBlurred(true)}
          />
          {emailTakenBy && (
            <ErrorMessage className="mt2">
              It looks like someone with that email is already on the system.
              Their name is {getFullName(emailTakenBy.sensitive_fields)}
            </ErrorMessage>
          )}
          <PhoneNumber
            value={phone}
            onChange={setPhone}
            onBlur={() => setPhoneBlurred(true)}
          />
          {phoneTakenBy && (
            <ErrorMessage className="mt2">
              It looks like someone with that phone number is already on the
              system. Their name is {getFullName(phoneTakenBy.sensitive_fields)}
            </ErrorMessage>
          )}
          <TextareaField
            name="notes"
            label="Notes"
            value={notes}
            onChange={setNotes}
          />
          <TextField
            name="emergency_contact_name"
            label="Emergency Contact Name"
            value={emergencyContactName}
            onChange={setEmergencyContactName}
          />
          <TextField
            name="emergency_contact_detail"
            label="Emergency Contact Detail (phone/email)"
            value={emergencyContactDetail}
            onChange={setEmergencyContactDetail}
          />
          <TextField
            name="referral_organisation"
            label="Referral Organisation"
            value={referralOrganisation}
            onChange={setReferralOrganisation}
          />
          <Checkbox
            name="dataProtectionSigned"
            label="Data Protection Signed"
            checked={dataProtectionSigned}
            onChange={setDataProtectionSigned}
          />
        </form>
        <DialogActions>
          <footer className="flex justify-end mb3 pt3">
            {validationError && (
              <ErrorMessage className="mra">{validationError}</ErrorMessage>
            )}
            <FlatButton onClick={onRequestClose}>Cancel</FlatButton>
            <FlatButton onClick={onRequestSave}>Save</FlatButton>
          </footer>
        </DialogActions>
      </Dialog>
      {<Opener onClick={onRequestOpen} />}
    </Fragment>
  );
};

export default ParticipantForm;

import React, { useState, Fragment } from "react";

import Dialog from "./Dialog";
import useOrganisations from "../hooks/useOrganisations";
import { ProgrammeId } from "../context/types";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import errorService from "../common-lib/error";
import { unknownErrorMessage } from "../constants/errors";
import { FetchOrganisationsQuery } from "@/graphql/generated/graphql";

type Programme = FetchOrganisationsQuery["organisation"][number];

interface OpenerProps {
  onClick: () => void;
}
interface OrganisationsAllDialogProps {
  onSelect: (organisation: Programme) => Promise<any>;
  excludeIds?: Array<ProgrammeId>;
  onClose: () => void;
  Opener: React.FC<OpenerProps>;
}
const OrganisationsAllDialog: React.FC<OrganisationsAllDialogProps> = (
  props
) => {
  const { onSelect, excludeIds, onClose, Opener } = props;

  const { loading, organisations } = useOrganisations({
    excludeIds,
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const onRequestOpen = () => setIsOpen(true);
  const onRequestClose = () => setIsOpen(false);

  const onClick = async (organisation: Programme) => {
    try {
      await onSelect(organisation);

      setIsOpen(false);
      onClose();
    } catch (err) {
      errorService.error(err);
      setErrorMessage(unknownErrorMessage);
    }
  };

  return (
    <Fragment>
      <Dialog
        fullHeight
        title={"Organisations"}
        onRequestClose={onRequestClose}
        isOpen={isOpen}
      >
        <legend>Please select from the organisations below:</legend>
        <br />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <ul className="relative flex-auto">
          {loading && <LoadingSpinner type="overlay" />}
          {organisations.map((organisation) => {
            return (
              <li
                className="mb2 flex"
                key={`Organisations-li-${organisation.id}`}
              >
                <button
                  className="flex items-center tl"
                  onClick={() => onClick(organisation)}
                  disabled={loading}
                >
                  {organisation.name}
                </button>
              </li>
            );
          })}
        </ul>
      </Dialog>
      <Opener onClick={onRequestOpen} />
    </Fragment>
  );
};

export default OrganisationsAllDialog;

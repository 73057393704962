import React, { useEffect } from "react";
import useProjects, { Opts as UseProjectOpts } from "../hooks/useProjects";
import { ProjectId } from "../context/types";
import MultiAutocompleteField from "./field/MultiAutocompleteField";
import truthy from "../helpers/truthy";
import { useToast } from "./Toast";

interface ProjectMultiSelectProps {
  label?: string;
  name?: string;
  projectIds?: ProjectId[];
  setProjectIds: (projectIds: ProjectId[]) => void;
  useProjectOpts?: UseProjectOpts;
}
const ProjectMultiSelect: React.FC<ProjectMultiSelectProps> = (props) => {
  const {
    name = "project",
    label = "Project",
    projectIds = [],
    setProjectIds,
    useProjectOpts,
  } = props;
  const { projects } = useProjects(useProjectOpts);
  const toast = useToast();

  const options = projects.map((project) => ({
    value: project.id,
    label: `${project.name} (${project.organisation.name}/${project.programme.name})`,
  }));

  const currentSelection = projectIds
    .map((id) => options.find((option) => option.value === id))
    .filter(truthy);

  const invalidProjectIds = projectIds.filter(
    (projectId) =>
      !currentSelection.some((selected) => selected.value === projectId),
  );
  useEffect(() => {
    if (invalidProjectIds.length > 0) {
      /**
       * Ensure that the selected ids (from parent) are in sync with:
       * 1. available options, therefore
       * 2. what the user sees
       */
      setProjectIds(currentSelection.map((selected) => selected.value));
      toast.notify(
        "One or more projects have been automatically deselected, because of your programme/organisation selection",
      );
    }
  }, [invalidProjectIds.length]);

  return (
    <MultiAutocompleteField
      label={label}
      name={name}
      options={options}
      selected={currentSelection}
      onSelect={(selected) => setProjectIds(selected.map((proj) => proj.value))}
    />
  );
};

export default ProjectMultiSelect;

import React, { useEffect } from "react";
import useProgrammes from "../hooks/useProgrammes";
import { ProgrammeId } from "../context/types";
import MultiAutocompleteField from "./field/MultiAutocompleteField";
import truthy from "../helpers/truthy";

interface ProgrammeMultiSelectProps {
  label?: string;
  name?: string;
  programmeIds?: ProgrammeId[];
  setProgrammeIds: (programmeIds: ProgrammeId[]) => void;
}
const ProgrammeMultiSelect: React.FC<ProgrammeMultiSelectProps> = (props) => {
  const {
    name = "programme",
    label = "Programme",
    programmeIds = [],
    setProgrammeIds,
  } = props;
  const { programmes } = useProgrammes();

  const options = programmes.map((programme) => ({
    value: programme.id,
    label: programme.name,
  }));

  const currentSelection = programmeIds
    .map((id) => options.find((option) => option.value === id))
    .filter(truthy);

  const invalidProgrammeIds = programmeIds.filter(
    (programmeId) =>
      !currentSelection.some((selected) => selected.value === programmeId),
  );
  useEffect(() => {
    if (invalidProgrammeIds.length > 0) {
      /**
       * Ensure that the selected ids (from parent) are in sync with:
       * 1. available options, therefore
       * 2. what the user sees
       */
      setProgrammeIds(currentSelection.map((selected) => selected.value));
    }
  }, [invalidProgrammeIds.length]);

  return (
    <MultiAutocompleteField
      label={label}
      name={name}
      options={options}
      selected={currentSelection}
      onSelect={(selected) =>
        setProgrammeIds(selected.map((prog) => prog.value))
      }
    />
  );
};

export default ProgrammeMultiSelect;

import React, { Fragment, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";

import useProgrammes from "../hooks/useProgrammes";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import {
  getAdminProgrammesPath,
  getHomePath,
  getPartnerHubPath,
} from "../helpers/route-path";
import LeftPanel from "./LeftPanel";
import useSessionOrganisation from "../hooks/useSessionOrganisation";
import Button from "./Button";
import NoContent from "./NoContent";
import NavigationLinkButton from "./NavigationLinkButton";
import { names } from "@/common-lib/names";
import { useIsAdmin } from "@/hooks/useIsAdmin";

const SelectPartnerHub: React.FC = () => {
  const history = useHistory();
  const organisation = useSessionOrganisation();

  const { programmes, loading, error } = useProgrammes({
    organisation,
    type: "partner-hub",
  });

  const isAdmin = useIsAdmin();
  const goToAdmin = useCallback(() => {
    history.push(getAdminProgrammesPath());
  }, []);

  if (loading) {
    return <LoadingSpinner type="overlay" />;
  }

  if (!loading && !isAdmin && organisation && programmes.length === 1) {
    return (
      <Redirect
        to={getHomePath({
          organisationSlug: organisation?.slug,
          programmeSlug: programmes[0].slug,
        })}
      />
    );
  }

  return (
    <LeftPanel heading={`${organisation?.name ?? names.hubs.longPlural}`}>
      <header className="flex justify-between items-center mb3">
        <h1>{names.hubs.longPlural}</h1>
        {isAdmin && (
          <Button onClick={goToAdmin} raised fill>
            Make changes
          </Button>
        )}
      </header>
      <div className="flex flex-column flex-auto">
        {error && (
          <ErrorMessage>
            There was an error loading your Programmes. Please try refreshing
            the page.
          </ErrorMessage>
        )}
        {loading && <LoadingSpinner type="overlay" />}
        {!loading && !error && programmes.length === 0 && (
          <NoContent
            icon="groups"
            message={<Fragment>No {names.hubs.longPlural} found.</Fragment>}
          />
        )}
        {programmes.filter((p) => p.status === "active").length ? (
          <>
            <h2>Active</h2>
            <ProgrammeList
              programmes={programmes.filter((p) => p.status === "active")}
            />
          </>
        ) : null}
        {programmes.filter((p) => p.status === "closed").length ? (
          <>
            <h2>Closed</h2>
            <ProgrammeList
              programmes={programmes.filter((p) => p.status === "closed")}
            />
          </>
        ) : null}
      </div>
    </LeftPanel>
  );
};

export default SelectPartnerHub;

function ProgrammeList({
  programmes,
}: {
  programmes: ReturnType<typeof useProgrammes>["programmes"];
}) {
  return (
    <>
      {programmes.map((programme) => {
        return (
          <NavigationLinkButton
            key={`SelectPartnerHub-Programme-${programme.id}`}
            to={getPartnerHubPath({
              programmeSlug: programme.slug,
            })}
          >
            {programme.name}
          </NavigationLinkButton>
        );
      })}
    </>
  );
}

import { graphql } from "../generated";

const FETCH_PARTICIPANT_LATEST = graphql(`
  query FetchParticipantLatest(
    $dynamicWhere: register_participant_latest_bool_exp
  ) {
    register_participant_latest(
      where: $dynamicWhere
      distinct_on: participant_id
      limit: 10
    ) {
      participant {
        sensitive_fields {
          last_name
          first_name
        }
        date_of_birth
        date_of_birth_accuracy
        id
        country_code
      }
    }
  }
`);

export default FETCH_PARTICIPANT_LATEST;

/**
 * Adapted from https://www.npmjs.com/package/safe-localstorage
 */
import * as Sentry from "@sentry/browser";

const localStorageAvailable = () => {
  if (typeof window === "undefined") {
    return false
  }
  const inWindow =
    typeof window !== "undefined" &&
    typeof window.localStorage === "object" &&
    typeof window.localStorage.setItem === "function";
  if (!inWindow) {
    return false;
  }
  // Safari private mode has localStorage in the window, but throws when `setItem` is called
  const key = "safeLocalStorageTest";
  try {
    window.localStorage.setItem(key, "succeeds");
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    Sentry.captureException(e);
    return false;
  }
};

const getItem: typeof window.localStorage.getItem = (...args) => {
  if (localStorageAvailable()) {
    return window.localStorage.getItem(...args);
  } else {
    return null;
  }
};
const setItem: typeof window.localStorage.setItem = (...args) => {
  if (localStorageAvailable()) {
    return window.localStorage.setItem(...args);
  }
};
const removeItem: typeof window.localStorage.removeItem = (...args) => {
  if (localStorageAvailable()) {
    return window.localStorage.removeItem(...args);
  }
};
const clear: typeof window.localStorage.clear = (...args) => {
  if (localStorageAvailable()) {
    return window.localStorage.clear(...args);
  }
};

const safeLocalStorage = {
  getItem,
  setItem,
  removeItem,
  clear,
};

export default safeLocalStorage;

import { graphql } from "../generated";

const DELETE_PROJECT_CATEGORY= graphql(`
  mutation DeleteProjectCategory($id: uuid!) {
    delete_project_category_by_pk(id: $id) {
      id
      project_id
      name
    }
  }
`);

export default DELETE_PROJECT_CATEGORY;

import React, { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import CREATE_REGISTER_PARTICIPANT from "../graphql/queries/create-register-participant";

import { RegisterId } from "../context/types";
import { getFullName } from "../helpers/user";
import { getAge } from "../helpers/participant";

import Avatar from "./Avatar";
import { getCountryNameByCode } from "./CountrySelect";
import errorService from "@/common-lib/error";
import { ParticipantSearchResult } from "@/hooks/useParticipantSearch";
import { ParticipantLatestResult } from "@/hooks/useParticipantsLatest";
import { useIsProgrammeClosed } from "@/hooks/useIsProgrammeClosed";

interface RegisterAddFromParticipantItemProps {
  participant: ParticipantSearchResult | ParticipantLatestResult;
  registerId: RegisterId;
  onAdd: () => void;
}
const RegisterAddFromParticipantItem: React.FC<
  RegisterAddFromParticipantItemProps
> = (props) => {
  const [complete, setComplete] = useState(false);
  const { participant, registerId, onAdd } = props;
  const isProgrammeClosed = useIsProgrammeClosed();

  const [addParticipant, { loading }] = useMutation(
    CREATE_REGISTER_PARTICIPANT,
    {
      onCompleted: () => {
        setComplete(true);
        onAdd();
      },
    }
  );

  const onClick = useCallback(() => {
    if (!participant) {
      return;
    }
    addParticipant({
      variables: {
        register_participant: {
          register_id: registerId,
          participant_id: participant.id,
        },
      },
    });
  }, [participant]);

  useEffect(() => {
    if (!participant) {
      errorService.error(
        new Error("RegisterAddFromParticipantItem: no participant in render")
      );
    }
  }, [participant]);

  if (complete) {
    return null;
  }

  if (!participant) {
    return null;
  }

  return (
    <li className="card mb2 flex">
      <button
        className="flex items-center w-100 tl"
        onClick={onClick}
        disabled={loading || isProgrammeClosed}
      >
        <Avatar className="mr2" person={participant} />
        {getFullName(participant.sensitive_fields)}, {getAge(participant)},{" "}
        {getCountryNameByCode(participant.country_code)}
      </button>
    </li>
  );
};
export default RegisterAddFromParticipantItem;

import { graphql } from "../generated";

const DELETE_PARTICIPANT_FILE_BY_ID= graphql(`
  mutation DeleteParticipantFileById($id: uuid!) {
    delete_participant_files_by_pk(id: $id) {
      id
    }
  }
`);

export default DELETE_PARTICIPANT_FILE_BY_ID;

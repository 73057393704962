import { graphql } from "../generated";

const FETCH_LOCATIONS= graphql(`
  query FetchLocations(
    $dynamicWhere: location_bool_exp
    $dynamicOrderBy: [location_order_by!]
  ) {
    location(where: $dynamicWhere, order_by: $dynamicOrderBy) {
      id
      name
      slug
      organisation_id
      programme_locations {
        programme {
          id
          name
          slug
        }
      }
      organisation {
        id
        name
        slug
      }
    }
  }
`);

export default FETCH_LOCATIONS;

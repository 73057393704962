import { graphql } from "../generated";

const CREATE_ORGANISATION = graphql(`
  mutation CreateOrganisation($organisation: organisation_insert_input!) {
    insert_organisation(objects: [$organisation]) {
      returning {
        id
      }
    }
  }
`);

export default CREATE_ORGANISATION;

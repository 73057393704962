import { useQuery } from "@apollo/client";

import FETCH_PROJECTS from "../graphql/queries/fetch-projects";
import useSessionProgramme from "./useSessionProgramme";
import useSessionOrganisation from "./useSessionOrganisation";
import { useEffect } from "react";
import { OrganisationId, ProgrammeId } from "../context/types";
import { Project_Order_By } from "@/graphql/generated/graphql";

export interface Opts {
  orderBy?: Project_Order_By[];
  programmeIds?: ProgrammeId[];
  organisationIds?: OrganisationId[];
}
const useProjects = (opts: Opts = {}) => {
  const sessionProgramme = useSessionProgramme();
  const sessionOrganisation = useSessionOrganisation();
  const { orderBy = [], programmeIds, organisationIds } = opts;

  const whereOrganisationIds = organisationIds
    ? organisationIds
    : sessionOrganisation
    ? [sessionOrganisation.id]
    : [];

  const whereProgrammeIds = programmeIds
    ? programmeIds
    : sessionProgramme
    ? [sessionProgramme.id]
    : [];

  const variables = {
    dynamicOrderBy: orderBy,
    dynamicWhere: {
      ...(whereOrganisationIds.length
        ? { organisation_id: { _in: whereOrganisationIds } }
        : null),
      ...(whereProgrammeIds.length
        ? { programme_id: { _in: whereProgrammeIds } }
        : null),
    },
  };

  const { data, error, loading, refetch } = useQuery(FETCH_PROJECTS, {
    variables,
  });

  useEffect(() => {
    refetch();
  }, []);

  const projects = data ? data.project : [];

  return { projects, error, loading, refetch };
};

export default useProjects;

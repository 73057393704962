import React, {
  Fragment,
  useState,
  SyntheticEvent,
  useEffect,
  useMemo,
} from "react";
import * as yup from "yup";

import Dialog from "./Dialog";
import TextField from "./field/TextField";
import FlatButton from "./FlatButton";
import slugify from "../helpers/slugify";
import { useMutation } from "@apollo/client";
import CREATE_PROGRAMME from "../graphql/queries/create-programme";
import LoadingSpinner from "./LoadingSpinner";
import UPDATE_PROGRAMME from "../graphql/queries/update-programme";
import { useHistory } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";
import { getAdminProgrammePath } from "../helpers/route-path";
import MultiAutocompleteField, {
  FieldOption,
} from "./field/MultiAutocompleteField";
import useOrganisations from "../hooks/useOrganisations";
import {
  CreateProgrammeMutationVariables,
  UpdateProgrammeMutationVariables,
} from "@/graphql/generated/graphql";
import SelectField from "./field/SelectField";
import SingleAutocompleteField from "./field/SingleAutocompleteField";
import useProgrammeGroups from "@/hooks/useProgrammeGroups";
import { names } from "@/common-lib/names";
import { DialogActions } from "./DialogActions";

const schema = yup.object().shape({
  slug: yup.string().required().label("URL friendly name"),
  name: yup.string().required().label("Name"),
});

function organisationToFieldOption(organisation: {
  organisation?: {
    id: string;
    name: string;
  };
}): FieldOption;
function organisationToFieldOption(organisation: {
  id: string;
  name: string;
}): FieldOption;
function organisationToFieldOption(
  organisation:
    | {
        id: string;
        name: string;
      }
    | {
        organisation?: {
          id: string;
          name: string;
        };
      } = {}
): FieldOption {
  let org = { name: "", id: "" };
  if ("organisation" in organisation && organisation.organisation) {
    org = organisation.organisation;
  }
  if ("id" in organisation) {
    org = organisation;
  }

  return {
    label: org.name,
    value: org.id,
  };
}

interface OpenerProps {
  onClick: () => void;
}
interface ProgrammeFormProps {
  onClose: () => void;
  Opener: React.FC<OpenerProps>;
  initialValues?: {
    id?: string;
    name?: string;
    slug?: string;
    type?: string; // "partner-hub" | "amna-programme"
    organisation_programmes?: {
      organisation?: {
        id: string;
        name: string;
        slug: string;
      };
    }[];
    programme_group?: {
      id: string;
      name: string;
    } | null;
  };
}
const ProgrammeForm: React.FC<ProgrammeFormProps> = (props) => {
  const { Opener, onClose, initialValues = {} } = props;
  const transformedInitialValues = useMemo(
    () => ({
      id: initialValues.id,
      name: initialValues.name || "",
      slug: initialValues.slug || "",
      type: initialValues.type || "amna-programme",
      organisation_programmes:
        initialValues.organisation_programmes?.map(organisationToFieldOption) ||
        [],
      programme_group: initialValues?.programme_group
        ? {
            label: initialValues.programme_group.name,
            value: initialValues.programme_group.id,
          }
        : undefined,
    }),
    [JSON.stringify(initialValues)]
  );
  const [isOpen, setIsOpen] = useState(false);
  const [_name, setName] = useState(transformedInitialValues.name);
  const [slug, setSlug] = useState(transformedInitialValues.slug);
  const [type, setType] = useState<string>(
    transformedInitialValues.type || "amna-programme"
  );

  const { programmeGroups } = useProgrammeGroups();
  const [organisationProgrammes, setOrganisationProgrammes] = useState<
    FieldOption[]
  >(transformedInitialValues.organisation_programmes);
  const [programmeGroup, setProgrammeGroup] = useState<FieldOption | undefined>(
    transformedInitialValues.programme_group
  );

  const [validationError, setValidationError] = useState("");

  const resetForm = () => {
    setName(transformedInitialValues.name || "");
    setSlug(transformedInitialValues.slug || "");
  };

  useEffect(() => {
    resetForm();
  }, [JSON.stringify(transformedInitialValues)]);

  const onRequestClose = () => {
    resetForm();
    setIsOpen(false);
    onClose();
  };

  const history = useHistory();
  const { organisations } = useOrganisations();

  const [update, { loading: createLoading, error: createError }] = useMutation(
    UPDATE_PROGRAMME,
    {
      onCompleted: () => {
        if (slug !== transformedInitialValues.slug) {
          history.replace(getAdminProgrammePath({ programmeSlug: slug }));
        }

        onRequestClose();
      },
    }
  );
  const [create, { loading: updateLoading, error: updateError }] = useMutation(
    CREATE_PROGRAMME,
    {
      onCompleted: (data) => {
        if (data) {
          history.push(getAdminProgrammePath({ programmeSlug: slug }));
          onRequestClose();
        }
      },
    }
  );

  const loading = createLoading || updateLoading;
  const error = createError || updateError;
  const isEdit = Boolean(transformedInitialValues.id);

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    const payload: UpdateProgrammeMutationVariables["programme"] = {
      name: _name,
      slug: slug,
      type: type,
      programme_group_id: programmeGroup?.value,
    };

    try {
      await schema.validate(payload);
    } catch (err) {
      const message =
        error instanceof Error ? error.message : "An unknown error occurred";
      return setValidationError(message);
    }

    if (transformedInitialValues.id) {
      const variables: UpdateProgrammeMutationVariables = {
        id: transformedInitialValues.id,
        programme: payload,
        organisation_programmes: organisationProgrammes.map(({ value }) => ({
          organisation_id: value,
          programme_id: transformedInitialValues.id,
        })),
      };
      update({ variables });
    } else {
      const variables: CreateProgrammeMutationVariables = {
        programme: {
          ...payload,
          organisation_programmes: {
            data: organisationProgrammes.map(({ value }) => ({
              organisation_id: value,
              programme_id: transformedInitialValues.id,
            })),
          },
        },
      };
      create({ variables });
    }
  };

  const onNameBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!slug) {
      setSlug(slugify(e.target.value));
    }
  };

  const onSlugBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(slugify(e.target.value));
  };

  useEffect(() => {
    if (transformedInitialValues.organisation_programmes) {
      setOrganisationProgrammes(
        transformedInitialValues.organisation_programmes
      );
    }
  }, [transformedInitialValues]);

  useEffect(() => {
    if (type === "amna-programme") {
      setOrganisationProgrammes(
        organisations
          .filter((o) => o.slug === "amna")
          .map(organisationToFieldOption)
      );
    } else {
      setOrganisationProgrammes(
        transformedInitialValues.organisation_programmes
      );
    }
  }, [type]);

  return (
    <Fragment>
      <Dialog
        fullHeight
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={`${isEdit ? "Edit" : "New"} Programme / ${
          names.hubs.longSingle
        }`}
      >
        {loading && <LoadingSpinner type="overlay" />}
        <form onSubmit={onSubmit} autoComplete="off">
          <input className="clip" />
          <input type="password" className="clip" />
          <TextField
            name="name"
            label="Name"
            value={_name}
            onChange={setName}
            inputProps={{ onBlur: onNameBlur }}
          />
          <TextField
            name="slug"
            label="URL Friendly Name"
            value={slug}
            onChange={setSlug}
            inputProps={{ onBlur: onSlugBlur }}
          />
          <SelectField
            label="Type"
            name="type"
            value={type}
            onChange={setType}
            options={[
              { label: "Amna Programme", value: "amna-programme" },
              { label: names.hubs.longSingle, value: "partner-hub" },
            ]}
          />
          {type === "partner-hub" && (
            <MultiAutocompleteField
              label={`Which Organisations are part of this ${names.hubs.shortSingle}?`}
              name="organisation_programmes"
              selected={organisationProgrammes}
              options={organisations
                .filter((organisation) => organisation.slug !== "amna")
                .map(organisationToFieldOption)}
              onSelect={setOrganisationProgrammes}
            />
          )}{" "}
          {type === "amna-programme" && (
            <SingleAutocompleteField
              label="Which Programme group is this part of?"
              name="programme_group"
              selected={programmeGroup}
              options={programmeGroups.map((group) => ({
                label: group.name,
                value: group.id,
              }))}
              onSelect={setProgrammeGroup}
            />
          )}
        </form>
        <DialogActions>
          {(validationError || error?.message) && (
            <ErrorMessage className="mra mt3">
              {validationError || error?.message}
            </ErrorMessage>
          )}
          <footer className="flex justify-end mb3 pt3">
            <FlatButton onClick={onRequestClose}>Cancel</FlatButton>
            <FlatButton onClick={onSubmit}>Save</FlatButton>
          </footer>
        </DialogActions>
      </Dialog>
      <Opener onClick={() => setIsOpen(true)} />
    </Fragment>
  );
};

export default ProgrammeForm;

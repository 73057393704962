export type LocationId = string;
export type ProgrammeId = string;
export type Token = string;
export type ProjectSlug = string;
export type ProgrammeSlug = string;
export type LocationSlug = string;
export type OrganisationSlug = string;
export type OrganisationId = string;

export const DATE_OF_BIRTH_ACCURACY = ["exact", "estimate", "guess"] as const;
type DateOfBirthAccuracyTuple = typeof DATE_OF_BIRTH_ACCURACY;
export type DateOfBirthAccuracy = DateOfBirthAccuracyTuple[number];
export const dateOfBirthAccuracyText: { [key: string]: string } = {
  exact: "Exact",
  estimate: "Within a year or two",
  guess: "A guess (based on whether they're a child or an adult)",
};

export interface Location {
  id: LocationId;
  name: Name;
  projects: Array<ProjectId>;
}

export interface LocationsState {
  [id: string]: Location;
}

export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";

interface FetchLocationsSuccess {
  type: string;
  payload: Array<Location>;
}

export type LocationsActionTypes = FetchLocationsSuccess;

export type ParticipantId = string;

// https://stackoverflow.com/questions/44480644/typescript-string-union-to-string-array#answer-45486495
export const SEX = ["male", "female", "other"] as const;
type SexTuple = typeof SEX;
export type Sex = SexTuple[number];

export interface Participant {
  id: ParticipantId;
  first_name: string;
  last_name: string;
  sex: Sex;
  country: string;
  isRefugee: Boolean; // should this be legalStatus: REFUGEE, SEEKING_ASYLUM, LEAVE_TO_REMAIN, etc?
  caresFor: Array<ParticipantId>;
  isCaredForBy: Array<ParticipantId>; // [id1, id2]
  languages: Array<string>;
  date_of_birth?: string;
  birthYear?: number;
  birthMonth?: number;
  birthDay?: number;
  email?: string;
  phone?: string;
  comment?: string;
  locations: Array<LocationId>;
  dateOfFirstAttendance?: string; // primarily for archive registers( do we need this?)
}

export interface ParticipantsState {
  [id: string]: Participant | undefined;
}

export interface Programme {
  id: ProgrammeId;
  name: Name;
  slug: ProgrammeSlug;
  projects: Array<ProjectId>;
  locations: Array<LocationId>;
  status: "active" | "closed";
}

export interface ProgrammesState {
  [id: string]: Programme;
}

export type ProjectId = string;

export type RegisterId = string;

export type CategoryId = string;

export type UserId = string;
type Name = string;
type Email = string;

export const ROLES = ["user", "rti-admin"] as const;
type RoleTuple = typeof ROLES;
export type Role = RoleTuple[number];

export interface User {
  id: UserId;
  email: Email;
  first_name: Name;
  last_name: Name;
  role: Role;
  organisations: OrganisationId[];
  managerOrganisations?: OrganisationId[];
  siloedInOrganisations: OrganisationId[];
}

export interface UsersState {
  [id: string]: User;
}

export const JWT_EXPIRED = "JWT_EXPIRED";
export const USER_LOGOUT = "USER_LOGOUT";
export const LOGOUT_REASON = [JWT_EXPIRED, USER_LOGOUT] as const;
type LogoutReasonTuple = typeof LOGOUT_REASON;
export type LogoutReason = LogoutReasonTuple[number];

export const NOT_ASKED = "NOT_ASKED";
export const LOADING = "LOADING";
export const SUCCESS = "SUCCESS";
export const FAIL = "FAIL";
export const REQUEST_STATUS = [NOT_ASKED, LOADING, SUCCESS, FAIL] as const;
type RequestStatusTuple = typeof REQUEST_STATUS;
export type RequestStatus = RequestStatusTuple[number];

export const getFullName = (
  user?: {
    first_name: string;
    last_name: string;
  } | null
) => (user ? `${user.first_name} ${user.last_name}` : "REDACTED");

export const isActive = (user: { deactivated_at?: Date | null }) => {
  return user.deactivated_at === null;
};

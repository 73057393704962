import { graphql } from "../generated";

const CREATE_PARTICIPANT_SIBLING= graphql(`
  mutation CreateParticipantSibling(
    $participant_sibling: participant_sibling_insert_input!
  ) {
    insert_participant_sibling(objects: [$participant_sibling]) {
      returning {
        participant_id
        sibling_id
      }
    }
  }
`);

export default CREATE_PARTICIPANT_SIBLING;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import LoadingSpinner from "./LoadingSpinner";
import ProgrammeForm from "./ProgrammeForm";
import Button from "./Button";
import { getAdminProgrammePath } from "../helpers/route-path";
import {
  AdminGrid,
  AdminGridCell,
  AdminGridHeader,
  AdminGridHeading,
} from "./AdminGrid";
import useProgrammes, { getProgrammeType } from "../hooks/useProgrammes";
import { names } from "@/common-lib/names";
import { Programme_Order_By, Order_By } from "@/graphql/generated/graphql";

const defaultOrder = [{ name: Order_By.Asc }];
const headings: AdminGridHeading<Programme_Order_By>[] = [
  {
    label: "Name",
    order: {
      asc: [{ name: Order_By.Asc }],
      desc: [{ name: Order_By.Desc }],
    },
  },
  {
    label: "Type",
    order: {
      asc: [{ type: Order_By.Asc }, ...defaultOrder],
      desc: [{ type: Order_By.Desc }, ...defaultOrder],
    },
  },
  {
    label: "Status",
    order: {
      asc: [{ status: Order_By.Asc }, ...defaultOrder],
      desc: [{ status: Order_By.Desc }, ...defaultOrder],
    },
  },
];
const AdminProgrammes: React.FC = () => {
  const { loading, programmes, refetch } = useProgrammes();
  useEffect(() => {
    refetch();
  }, []);

  const [orderBy, setOrderBy] = useState<Programme_Order_By[]>(defaultOrder);

  const sortedProgrammes = [...programmes].sort((a, b) => {
    const order = orderBy[0];
    const key = Object.keys(order)[0];
    // @ts-ignore
    const ascOrDesc = order[key];
    // @ts-ignore
    if (a[key] < b[key]) {
      return ascOrDesc === "asc" ? -1 : 1;
    }
    // @ts-ignore
    if (a[key] > b[key]) {
      return ascOrDesc === "asc" ? 1 : -1;
    }

    return 0;
  });

  return (
    <div>
      <header className="flex justify-between items-center mb3">
        <h1>Admin/Programmes & {names.hubs.shortPlural} </h1>
        <ProgrammeForm
          onClose={refetch}
          Opener={(props) => (
            <Button {...props} raised fill>
              New Programme
            </Button>
          )}
        />
      </header>
      {loading && <LoadingSpinner type="overlay" />}

      <AdminGrid columns={headings.length}>
        <AdminGridHeader
          headings={headings}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
        />
        {sortedProgrammes.map((programme) => {
          const { id, slug, name } = programme;

          return (
            <>
              <AdminGridCell key={`AdminProgrammes-li-${id}-name`}>
                <Link to={getAdminProgrammePath({ programmeSlug: slug })}>
                  {name}
                </Link>
              </AdminGridCell>{" "}
              <AdminGridCell key={`AdminProgrammes-li-${id}-type`}>
                {getProgrammeType(programme).title}
              </AdminGridCell>
              <AdminGridCell
                className="ttc"
                key={`AdminProgrammes-li-${id}-status`}
              >
                {programme.status}
              </AdminGridCell>
            </>
          );
        })}
      </AdminGrid>
    </div>
  );
};

export default AdminProgrammes;

import React, {
  DetailedHTMLProps,
  useState,
  SyntheticEvent,
  useRef,
  useEffect,
} from "react";
import styled from "styled-components";
import Autosuggest, { BlurEvent } from "react-autosuggest";
import { nanoid } from "nanoid";

import { Wrapper, Label, InputWrapper } from "./Common";
import IconButton from "../IconButton";

function escapeRegexCharacters(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

const getSuggestions = (
  options: Array<FieldOption>,
  value: string,
  getSuggestionValue: (option: FieldOption) => string,
) => {
  const escapedValue = escapeRegexCharacters(value.trim());

  const regex = new RegExp("\\b" + escapedValue, "i");

  return options.filter((option) => regex.test(getSuggestionValue(option)));
  // .slice(0, 5)
};

const getSuggestionValue = (option: FieldOption) => {
  return option.label;
};

const renderSuggestion = (suggestion: FieldOption, data: { query: string }) => {
  return <span>{suggestion.label}</span>;
};

const SelectedValue = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  pointer-events: none;
`;
const SelectedValueText = styled.span`
  width: calc(100% - 2rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Clear = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 2rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
`;
interface InputProps extends DetailedHTMLProps<any, any> {}
export type FieldOption = {
  value: string;
  label: string;
};
export interface FieldOptionsByValue {
  [value: string]: FieldOption;
}
interface FieldProps {
  label: string;
  name: string;
  selected: FieldOption | undefined;
  options: Array<FieldOption>;
  onSelect: (option: FieldOption | undefined) => void;
  inputProps?: InputProps;
  inline?: boolean;
}

const SingleAutocompleteField: React.FC<FieldProps> = (props) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([] as Array<FieldOption>);
  const [autoComplete] = useState(nanoid());
  const clearButtonRef = useRef<HTMLDivElement>(null);

  const selectedValue = props.selected && props.selected.value;
  useEffect(() => {
    if (clearButtonRef && clearButtonRef.current) {
      clearButtonRef.current.focus();
    }
  }, [selectedValue]); // Only re-run the effect if count changes

  const { inline, label, name, inputProps, options, selected, onSelect } =
    props;

  const hasContent = value.length > 0;

  const stateProps = {
    focused,
    inline,
    hasContent: value.length > 0,
  };

  const onClear = (e: SyntheticEvent) => {
    onSelect(undefined);
  };

  const onChange = (
    event: SyntheticEvent,
    data: { newValue: string; method: string },
  ) => {
    const { newValue } = data;
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = (data: { value: string }) => {
    const { value } = data;

    setSuggestions(getSuggestions(options, value, getSuggestionValue));
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const onSuggestionSelected = (
    event: SyntheticEvent,
    data: {
      suggestion: FieldOption;
    },
  ) => {
    const { suggestion } = data;

    onSelect(suggestion);
    setValue("");
    if (clearButtonRef && clearButtonRef.current) {
      clearButtonRef.current.focus();
    }
  };

  const hasSelection = Boolean(selected);

  const onBlur = (
    event: any,
    params?: BlurEvent<FieldOption | null> | undefined,
  ) => {
    setFocused(false);
    setValue("");
    if (params && params.highlightedSuggestion) {
      onSuggestionSelected(event, { suggestion: params.highlightedSuggestion });
    }
  };

  return (
    <Wrapper {...stateProps}>
      <Label htmlFor={name} lifted={focused || hasContent || hasSelection}>
        {label}
      </Label>
      <InputWrapper {...stateProps}>
        {selected && !focused && (
          <SelectedValue>
            <SelectedValueText>{selected.label}</SelectedValueText>
          </SelectedValue>
        )}
        <Autosuggest
          id={`${name}-singleautocomplete`}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={() => true}
          inputProps={{
            autoComplete,
            value,
            onChange,
            onFocus: () => setFocused(true),
            onBlur,
            ...inputProps,
          }}
        />
        <Clear
          tabIndex={-1}
          onFocus={() => setFocused(false)}
          ref={clearButtonRef}
        >
          <IconButton onClick={onClear} icon="close" />
        </Clear>
      </InputWrapper>
    </Wrapper>
  );
};
export default SingleAutocompleteField;

import { graphql } from "../generated";

const FETCH_REGISTER_ATTENDEES = graphql(`
  query FetchRegisterAttendees($registerIds: [uuid!]) {
    register_participant(where: { register: { id: { _in: $registerIds } } }) {
      register_id
      participant {
        id
        _temp_id
        sensitive_fields {
          first_name
          last_name
        }
      }
    }
  }
`);

export default FETCH_REGISTER_ATTENDEES;

import React, { Fragment, ReactNode } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import styles from "./LeftPanel.module.css";
import { getFullName } from "../helpers/user";
import UserForm from "./UserForm";
import useSelf from "../hooks/useSelf";
import useLogout from "../hooks/useLogout";
import Logo from "./Logo";
import Warnings from "./Warnings";
import colours from "../constants/colours";

const SubHeader = styled.h2<{ text?: string; background?: string }>`
  color: ${(props) => (props.text ? props.text : `rgba(0, 0, 0, 0.54)`)};
  background-color: ${(props) => props.background};
  font-size: 0.875rem;
  box-sizing: border-box;
  list-style: none;
  font-weight: 500;
  line-height: 1.5em;
  margin: 0;
  padding: 14px 0;
  padding-left: 30px;
`;
const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
`;
const NavItem = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  text-decoration: none;

  & .material-icons {
    width: 30px;
    min-width: 30px;
  }
`;

const UserOptionsLi = styled.li<{ color?: string }>`
  display: flex;
  align-items: center;
  margin-top: 2px;

  ${(props) => props.color && `color: ${props.color};`}
`;

interface LeftPanelProps {
  children: ReactNode;
  heading?: ReactNode;
  headingColors?: { background: string; text: string };
  links?: Array<{ url: string; text: string; icon: string }>;
}

const LeftPanel: React.FC<LeftPanelProps> = (props) => {
  const { heading, links, headingColors } = props;
  const { children } = props;
  const user = useSelf();
  const logout = useLogout();
  const location = useLocation();

  return (
    <div className={styles.leftPanel}>
      <div
        className={[
          styles.leftPanel__leftPanel,
          "flex",
          "flex-column",
          "justify-between",
        ].join(" ")}
      >
        <div>
          <Link to="/" className="flex mb3">
            <Logo />
          </Link>
          <Divider />
          <nav>
            {heading && (
              <Fragment>
                <SubHeader {...headingColors}>{heading}</SubHeader>
                <Divider />
              </Fragment>
            )}
            {links && (
              <div className="pv2">
                {links.map((link) => {
                  const isCurrent = location?.pathname.startsWith(link.url);
                  return (
                    <NavItem key={`LeftPanel-NavItem-${link.url}`}>
                      <i className="material-icons">{link.icon}</i>
                      <Link
                        className={styles.leftPanel__navLink}
                        to={link.url}
                        style={{
                          textDecoration: isCurrent ? "underline" : undefined,
                        }}
                      >
                        {link.text}
                      </Link>
                    </NavItem>
                  );
                })}
              </div>
            )}
          </nav>
        </div>
        <footer>
          {/* <div className="mb2">
            Current session: <br />
            {getFullName(user)}{" "}
            <UserForm
              initialValues={user}
              userId={user.id}
              Opener={(props) => <button {...props}> (edit)</button>}
              self={true}
              onClose={() => undefined}
            />
            <br />
            {user.role.toUpperCase()}
          </div> */}
          <ul>
            {user && (
              <Fragment>
                <UserOptionsLi color={colours.textGreyLight}>
                  <i className="material-icons mr1">face</i>
                  {getFullName(user)}
                </UserOptionsLi>
                <UserOptionsLi>
                  <i className="material-icons mr1">manage_accounts</i>
                  <UserForm
                    initialValues={user}
                    userId={user.id}
                    Opener={(props) => (
                      <button {...props}>Update Profile</button>
                    )}
                    self={true}
                    onClose={() => undefined}
                  />
                </UserOptionsLi>
              </Fragment>
            )}
            <UserOptionsLi>
              <i className="material-icons mr1">logout</i>
              <button onClick={() => logout({ reason: "USER_LOGOUT" })}>
                Logout
              </button>
            </UserOptionsLi>
          </ul>
        </footer>
      </div>
      <div className={styles.leftPanel__panelSpacer} />
      <div className={styles.leftPanel__children}>{children}</div>
      <Warnings />
    </div>
  );
};

export default LeftPanel;

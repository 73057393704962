import { graphql } from "../generated";

const FETCH_REGISTER_PARTICIPANT_NOTES= graphql(`
  query FetchRegisterParticipantNotes($participantId: uuid!) {
    register_participant_sensitive_fields(
      where: {
        participant_id: { _eq: $participantId }
        notes: { _is_null: false, _nlike: "" }
      }
      order_by: { register: { date: desc, time: desc } }
    ) {
      notes
      register {
        id
        project {
          id
          name
          programme {
            id
            slug
          }
        }
        location {
          id
          name
        }
        date
        time
        register_facilitators {
          user {
            first_name
            last_name
            id
          }
        }
      }
    }
  }
`);

export default FETCH_REGISTER_PARTICIPANT_NOTES;
